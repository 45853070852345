import React from "react"

import { GetData, isDataLoaded } from "tools/Data"
import { FilterByCategory, UpdateOnChange, MatchRow, RemoveOnChange, GetFilter } from "tools/Filter"

var donut_charts = {}


var translations_en = {
	"CNIX Koksne un tās izstrādājumi (ieskaitot izstrādājumus no koka)  (44.-46.nodaļas)": "CNIX Wood and articles of wood (incl. wood products) (Chapters 44–46)",
	"CNXVI Mašīnas, mehānismi, elektriskās iekārtas (84.-85. nodaļas)": "CNXVI Machinery and mechanical appliances, electrical equipment (Chapters 84–85)",
	"CNXV Metāli un to izstrādājumi (72.-83. nodaļas, izņemot 77. nodaļu)": "CNXV Base metals and manufactured articles from base metals (Chapters 72–83 except Chapter 77)",
	"CNVI Ķīmiskās rūpniecības un saskarnozaru produkcija (28.-38. nodaļas)": "CNVI Products of the chemical and allied industries (Chapters 28–38)",
	"CNIV Pārtikas rūpniecības produkti (ieskaitot dzērienus un tabaku) (16.-24.nodaļas)": "CNIV Prepared foodstuffs (incl. beverages and tobacco (Chapters 16–24)",
	"CNII Augu valsts produkti (6.-14. nodaļas)": "CNII Vegetable products (Chapters 6–14)",
	"CNV Minerālie produkti (25.-27. nodaļas)": "CNV Mineral products (Chapters 25–27)",
	"CNXVII Transporta līdzekļi  (86.-89. nodaļas)": "CNXVII Transport vehicles (Sections 86–89)",
	"CNI Dzīvnieki un lopkopības produkcija (1.-5. nodaļas)": "CNI Live animals and animal products (Chapters 1–5)",
	"CNVII Plastmasas un izstrādājumi no tām, kaučuks un gumijas izstrādājumi (39.-40. nodaļas)": "CNVII Plastics and articles thereof, rubber and articles thereof (Chapters 39–40)",
	"CNXX Dažādas rūpniecības preces (94.-96. nodaļas)": "CNXX Miscellaneous manufactured articles (Chapters 94–96)",
	"CNXI Tekstilmateriāli un tekstilizstrādājumi (50.-63. nodaļas)": "CNXI Textiles and textile articles (Chapters 50–63)",
	"CNXIII Akmens, ģipša, cementa, stikla, keramikas izstrādājumi (68.-70. nodaļas)": "CNXIII Articles of stone, plaster, cement, glassware and ceramic products (Chapters 68–70)",
	"CNX Koksnes papīrmasa, papīrs, kartons  (47.-49. nodaļas)": "CNX Wood pulp, paper and paperboard (Chapters 47–49)",
	"CNXVIII Optiskās ierīces un aparāti, pulksteņi, mūzikas instrumenti  (90.-92. nodaļas)": "CNXVIII Optical instruments and apparatus, clocks and watches, musical instruments (Chapters 90–92)",
	"CNXII Apavi, galvassegas, lietussargi un citi priekšmeti (64.-67. nodaļas)": "CNXII Footwear, headgear, umbrellas and other articles (Chapters 64–67)",
	"CNXIV Dārgakmeņi un pusdārgakmeņi, dārgmetāli (71. nodaļa)": "CNXIV Precious, semiprecious stone, precious metals (Chapters 71)",
	"CNVIII Jēlādas, ādas, kažokādas un izstrādājumi no tām (41.-43. nodaļas)": "CNVIII Raw hides, leather, fur skins and articles thereof (Chapters 41–43)",
	"CNIII Tauki un eļļas (15.nodaļa)": "CNIII Animal or vegetable fats and oils (Chapter 15)",
	"CNXXII Pārējās preces": "CNXXII Other commodities",
	"CNXIX Ieroči un munīcija (93. nodaļa)": "CNXIX Arms and ammunition (Chapter 93)",
	"CNXXI Mākslas un antikvārie priekšmeti, veselas ražošanas iekārtas (97.-98. nodaļas)": "CNXXI Works of art, collectors’ pieces and antiques, manufactured industrial machinery (Chapters 97–98)",
	"00 Bez atšifrējuma": "00 No description",

	"Others": "Others",
	"Previous": "Previous"
}

var translations_lt = {
	"Others": "Pārējie",
	"Previous": "Iepriekšējie"
}


export function UpdateDonutChart(name) {
	//console.log("Updating donut " + name)

	if (!donut_charts[name]) {
		//console.log("such donut does not exist")
		return
	}
	function update(name, iter) {
		if (iter > 100) {
			//console.log("Iteracitons exceeded " + iter + "; could not get clientWidth for the parent (" + name + ")")
			return
		}

		let container_width = donut_charts[name]._impl.shell.container.clientWidth
		if (!container_width) {
			setTimeout(() => {
				update(name, iter + 1)
			}, 100)
			return
		}

		
		//console.log("window client width", window.innerWidth)
		let max_width = (window.innerWidth < 562)?180:window.innerWidth/4;
		var side = "right"
		var align = "right"
		var maxLineSymbols = 20;

		if (max_width <= 300){
			side = "bottom"
			align = "center"
			maxLineSymbols = 40
		}

		donut_charts[name].updateSettings({
			legend: {
				panel: {
					side: side,
					align: align
				},
				maxLineSymbols: maxLineSymbols
			}
		})

		donut_charts[name].reloadData()
		donut_charts[name].updateSize()

	}
	update(name, 0)
}

function buildDonutChart(section, filter, caller) {
	if (donut_charts[section]) {
		return
		//donut_charts[section].remove()
	}

	let max_width = (window.innerWidth < 562)?180:window.innerWidth/4;
	var side = "right"
	var align = "right"
	var maxLineSymbols = 20;
	if (max_width <= 300){
		side = "bottom"
		align = "center"
		maxLineSymbols = 40
	}

	var container = document.getElementById("chart-" + section)
	if (container) {
		if (!isDataLoaded()) {
			container.innerHTML = "Loading.."
			if (caller) {
				setTimeout(() => {
					caller.componentDidMount()
				}, 100)
				return
			}
		}
	
		function formatExportValue(number) {
			if (number > 1000 * 1000 * 1000) {
				number = Math.floor(number / (1000 * 1000 * 1000)) + "bn"
			}
			if (number > 1000 * 1000) {
				number = Math.floor(number / (1000 * 1000)) + "M"
			} else if (number > 1000) {
				number = Math.floor(number / 1000) + "k"
			}
			return "€ " + number
		}

		var chart = null;

		var settings = {
			data: {
				dataFunction: function(id,limit,offset,success,fail){
					getDonutData(filter, id, limit, offset, success, fail, chart);
				},
				sortField: "value",
			},
			area: {
				height: 350,
			},
			container: container,
			legend: {
				enabled: true,
				panel: {
					side: side,
					align: align
				},
				maxLineSymbols: maxLineSymbols,
				numberOfColumns: 1,
			},
			interaction: {
				others: {
					maxSlicesVisible: 3,
				},
			},
			labels: {
				enabled: true,
				insideLabel: "always",
			},
			info: {
				enabled: true,
			},
			pie: {
				style: {
					colorDistribution: "gradient",
					sliceColors: ["rgba(205,22,61,1)"],
				},
			},
			slice: {
				styleFunction: (slice) => {
					const cl = localStorage.getItem("lang")

					if (!slice.data.orig_name) slice.data.orig_name = slice.data.name
					slice.data.name = (cl === "en")?translations_en[slice.data.orig_name]:(translations_lt[slice.data.orig_name]? translations_lt[slice.data.orig_name]:slice.data.orig_name)
					//console.log(cl, slice.data.name, slice.data.orig_name)
					

					if (slice !== slice.pie.othersSlice && slice !== slice.pie.previousSlice) {
						slice.insideLabel.text =
							(slice.data.fraction * 100).toFixed(1) + "% " + "(" + formatExportValue(slice.data.value) + ")"
						slice.label.text = null// = false;//slice.data.name
					} else {
						slice.insideLabel.text = (cl === "en")?translations_en[slice.data.orig_name]:translations_lt[slice.data.orig_name]
						slice.label.text = ""
					}
				},
				style: {
					insideLabel: {
						textStyle: {
							fillColor: "rgba(0,0,0,1)",
						},
					},
				},
			},
			events: {
				onSelectionChange: function (ev, arg) {
					//console.log("Selection changed in Donut")
					let values = []
					for (let i = 0; i < arg.selection.length; i++) {
						values.push(arg.selection[i].id)
					}
					//console.log("New donut selection", values)
					FilterByCategory(filter, "category", values, arg.chart)
				},
			},
		}
		chart = new window.ZoomCharts.PieChart(settings)
		chart._id = "donut"+section
		donut_charts[section] = chart
		container.style.width = "100%"

		donut_charts[section].updateSize()
		UpdateOnChange(filter, chart)

	}
}

function getDonutData(filter, id, limit, offset,  success, fail, chart){
	var data = null
	//console.log("Reloading data", id)

	data = GetData("LV_Eksports")
	var countries = GetData("d_Country")

	var country_map = {}
	for (var i = 1; i < countries.length; i++) {
		country_map[countries[i][0]] = countries[i]
	}
	var donut_data = { subvalues: [] }
	var donut_map = {}

	var filters = GetFilter(filter, "category")


	for (i = 1; i < data.length; i++) {
		var country = country_map[data[i][4]]
		var row = data[i]
		var category = row[0]
		var value = row[2]
		var country = country[0] // short code
		var coordinates = [country[2], country[1]] 

		var row = data[i]
		if (!MatchRow(row, filter, [], {"code":4})) {
			continue
		}


		if (typeof(donut_map[category]) === "undefined"){
			donut_map[category] = donut_data.subvalues.length;
			donut_data.subvalues.push({
				id: category,
				name: category,
				value: value,
				country: country,
				coordinates: coordinates,
				style: {expandable: false}
			})
		} else {
			var obj_id = donut_map[category]
			donut_data.subvalues[obj_id].value += value
		}
	}
	donut_data.id = id

	var first_slice_offset = 0;

	if (filters){

		donut_data.subvalues = donut_data.subvalues.sort((a,b)=>{return b.value-a.value})
		for (i = 0; i < donut_data.subvalues.length; i++){

			if (filters["values"].indexOf(donut_data.subvalues[i]["id"]) > -1){
				first_slice_offset = i
				break
			}
		}
	}


	success(donut_data)

	if (filters && filters["values"]){
		//console.log("Filters Donut", filters, first_slice_offset)
		chart.setPieOffset(first_slice_offset)
		chart.selection(filters["values"])
	}
}

class DonutChart extends React.Component {
	constructor(props) {
		super(props)
		this.section = props.section
		this.filter = props.filter
	}
	componentDidMount() {
		buildDonutChart(this.section, this.filter, this)
	}
	componentDidUpdate() {
		buildDonutChart(this.section, this.filter, this)
	}
	componentWillUnmount() {
		RemoveOnChange(this.filter, donut_charts[this.section])
		donut_charts[this.section].remove()
		donut_charts[this.section] = null
	}
	render() {
		const id = "chart-" + this.section
		return React.createElement("div", { id: id, className: this.props.className })
	}
}

export default DonutChart
