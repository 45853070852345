import React from "react"

export default function Hamburger(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 28.043" {...props}>
			<g id="Group_189" data-name="Group 189" transform="translate(-328 -17)">
				<line
					id="Line_20"
					data-name="Line 20"
					x2={36}
					transform="translate(329.5 18.5)"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth={3}
				/>
				<line
					id="Line_21"
					data-name="Line 21"
					x2={36}
					transform="translate(329.5 31.022)"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth={3}
				/>
				<line
					id="Line_22"
					data-name="Line 22"
					x2={36}
					transform="translate(329.5 43.543)"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth={3}
				/>
			</g>
		</svg>
	)
}
