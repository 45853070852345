import React from "react"
import { Footer, Header } from "components"
import { Button, Col, Image, Row } from "react-bootstrap"
import styles from "./styles.module.scss"
import placeholder_chart from "assets/images/placeholder_chart.png"
import placeholder_map_pie from "assets/images/placeholder_map_pie.png"
import placeholder_map_pie_mobile from "assets/images/placeholder_map_pie_mobile.png"
import avatar_1 from "assets/images/avatar_1.png"
import avatar_2 from "assets/images/avatar_2.png"
import avatar_3 from "assets/images/avatar_3.png"
import BarGraph from "assets/icons/BarGraph"
import Polygon from "assets/icons/Polygon"
import FullScreenTrigger from "components/utility/FullScreenTrigger"
import Wrapper from "components/layout/Wrapper"
import TimeChart from "charts/TimeChart"
import GeoChart from "charts/GeoChart"
import DonutChart from "charts/DonutChart"
import { Link } from "react-router-dom"
import {UpdateTimeChart} from "charts/TimeChart"
import {UpdateDonutChart} from "charts/DonutChart"




class Dashboard extends React.Component {
	constructor(props){
		super(props)
		this.t = props.t
	}
	componentDidUpdate(){
		UpdateTimeChart("dashboard-1", this.t)
		UpdateTimeChart("dashboard-2", this.t)
		UpdateTimeChart("dashboard-3", this.t)
		UpdateDonutChart("dashboard-5", this.t)
		

	}
	componentDidMount(){
		UpdateTimeChart("dashboard-1", this.t)
		UpdateTimeChart("dashboard-2", this.t)
		UpdateTimeChart("dashboard-3", this.t)
		UpdateDonutChart("dashboard-5", this.t)

	}
	render(t){
		t = this.t
		return (
			<>
				<Wrapper>
					<Header t={t} />

					<Row md={3} className="p-2 dashboard">
						<Col className={styles.chart_col}>
							<FullScreenTrigger text={t("Eksportētāju pienesums tautsaimniecībā")} style={{ marginTop: 15 }} />
							<div className={styles.chart_block}>
								<Row>
									<Col sm={9}>
										<div className={styles.chart_title}>{t("Eksportētāju pienesums tautsaimniecībā")}</div>
									</Col>
									<Col sm={3}>
										<div className={styles.chart_block_btn}>
											<Link to="/contribution/contribution-1" className="small text-secondary ms-1">
												<Button variant="outline-primary" size="sm">
													<BarGraph width={15} /> {t("btn_analyse")}
												</Button>
											</Link>
										</div>
									</Col>
								</Row>

								<TimeChart section="dashboard-1" t={t} />

								<div className={`${styles.chart_block_btn_bottom}`}>
									<Link to="/contribution/contribution-1" className="small text-secondary ms-1">
										<Button variant="outline-primary" size="sm">
											<BarGraph width={15} /> {t("btn_analyse")}
										</Button>
									</Link>
								</div>
								<div className={styles.details}>
									<div className="avatar">
										<Image src={avatar_1} />
									</div>
									<div className="info">
										<p className="small mb-1">
											<strong>{t("review_0_name")}</strong> | {t("review_0_designation")}
										</p>
										<p className="small mb-0">
											{t("review_0_text")}
											{/*<Link to="/review/0" className="small text-secondary ms-1">
												<strong>{t("btn_read_more")}</strong>
												<span className="ms-1">
													<Polygon />
												</span>
											</Link>*/}
										</p>
									</div>
								</div>
							</div>
						</Col>

						<Col className={styles.chart_col}>
							<FullScreenTrigger text={t("Eksportētāju finanšu veselība")} style={{ marginTop: 15 }} />
							<div className={styles.chart_block}>
								<Row>
									<Col sm={9}>
										<div className={styles.chart_title}>{t("Eksportētāju finanšu veselība")}</div>
									</Col>
									<Col sm={3}>
										<div className={styles.chart_block_btn}>
											<Link to="/financial-health" className="small text-secondary ms-1">
												<Button variant="outline-primary" size="sm">
													<BarGraph width={15} /> {t("btn_analyse")}
												</Button>
											</Link>
										</div>
									</Col>
								</Row>
								<TimeChart section="dashboard-2" t={t}  />
								<div className={styles.chart_block_btn_bottom}>
									<Link to="/financial-health" className="small text-secondary ms-1">
										<Button variant="outline-primary" size="sm">
											<BarGraph width={15} /> {t("btn_analyse")}
										</Button>
									</Link>
								</div>
								<div className={styles.details}>
									<div className="avatar">
										<Image src={avatar_2} />
									</div>
									<div className="info">
										<p className="small mb-1">
											<strong>{t("review_1_name")}</strong> | {t("review_1_designation")}
										</p>
										<p className="small mb-0">
											{t("review_1_text")}
											{/*<Link to="/review/1" className="small text-secondary ms-1">
												<strong>{t("btn_read_more")}</strong>
												<span className="ms-1">
													<Polygon />
												</span>
											</Link>*/}
										</p>
									</div>
								</div>
							</div>
						</Col>

						<Col className={styles.chart_col}>
							<FullScreenTrigger text={t("Produktivitāte un inovācijas")} style={{ marginTop: 15 }} />
							<div className={styles.chart_block}>
								<Row>
									<Col sm={9}>
										<div className={styles.chart_title}>{t("Produktivitāte un inovācijas")}</div>
									</Col>
									<Col sm={3}>
										<div className={styles.chart_block_btn}>
											<Link to="/productivity" className="small text-secondary ms-1">
												<Button variant="outline-primary" size="sm">
													<BarGraph width={15} /> {t("btn_analyse")}
												</Button>
											</Link>
										</div>
									</Col>
								</Row>
								<TimeChart section="dashboard-3"  t={t} />
								<div className={styles.chart_block_btn_bottom}>
									<Link to="/productivity" className="small text-secondary ms-1">
										<Button variant="outline-primary" size="sm">
											<BarGraph width={15} /> {t("btn_analyse")}
										</Button>
									</Link>
								</div>
								<div className={styles.details}>
									<div className="avatar">
										<Image src={avatar_3} />
									</div>
									<div className="info">
										<p className="small mb-1">
											<strong>{t("review_2_name")}</strong> | {t("review_2_designation")}
										</p>
										<p className="small mb-0">
											{t("review_2_text")}
											{/*<Link to="/review/2" className="small text-secondary ms-1">
												<strong>{t("btn_read_more")}</strong>
												<span className="ms-1">
													<Polygon />
												</span>
											</Link>
											*/}
										</p>
									</div>
								</div>
							</div>
						</Col>
					</Row>

					<Row className="px-2">
						<Col className={styles.chart_col}>
							<FullScreenTrigger text={t("Latvijas eksports 2021. gadā")} />
							<div className={styles.bottom_chart_block}>
								<div className={styles.chart_title}>{t("Latvijas eksports 2021. gadā")}</div>

								<div className={styles.bottom_charts}>
									<div className={styles.half}>
										<GeoChart section="dashboard-4" filter="dashboard" />
									</div>
									<div className={styles.half}>
										<DonutChart section="dashboard-5" filter="dashboard" />
									</div>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						<Col>
							<Footer secondary t={t} />
						</Col>
					</Row>
				</Wrapper>
			</>
		)
	}
}

export default Dashboard
