import avatar from "assets/images/avatar_1.png"
import linda from "assets/images/linda_riekstina_snore.png"
import roberts from "assets/images/roberts_dlohi.png"
import toms from "assets/images/toms_auskaps.png"
import janis from "assets/images/janis_kulbardis.png"
import kristine from "assets/images/kristine_grapmane.png"
import janisO from "assets/images/janis_oslejs.png"
import katrina from "assets/images/katrina_allikas.png"
import romans from "assets/images/romans_sidorovs.png"
import zygimantas from "assets/images/zygimantas_surintas.png"
import kristineM from "assets/images/kristine_metuzale.png"

import sweden from "assets/images/sweden.png"
import germany from "assets/images/germany.png"
import norway from "assets/images/norway.png"
import australia from "assets/images/australia.png"
import america from "assets/images/america.png"
import skorea from "assets/images/skorea.png"
import israel from "assets/images/israel.png"
import uk from "assets/images/united_kingdom.png"
import netherlands from "assets/images/netherlands.png"
import japan from "assets/images/japan.png"


export const reviews = [
	{
		avatar: avatar,
		indx: 0,
	},
	{
		avatar: avatar,
		indx: 1,
	},
	{
		avatar: avatar,
		indx: 2,
	},

	{
		avatar: linda,
		indx: 3,
	},

	{
		avatar: roberts,
		indx: 4,
    },
	{
		avatar: toms,
		indx: 5,
    }
	,
	{
		avatar: janis,
		indx: 6,
    }
	,
	{
		avatar: kristine,
		indx: 7,
    }
	,
	{
		avatar: janisO,
		indx: 8,
    }
	,
	{
		avatar: katrina,
		indx: 9,
    }
	,
	{
		avatar: romans,
		indx: 10,
    }
	,
	{
		avatar: zygimantas,
		indx: 11,
    }
	,
	{
		avatar: kristineM,
		indx: 12,
    }

    /*
	{
		avatar: avatar,
		name: "Mārtiņš Tiknuss",
		designation: "LEA “The Red Jackets”, valdes priekšsēdētājs",
		excerpt:
			"NIP mērķis ir: eksporta apjomu palielināt līdz 22 miljardiem EUR 2023.gadā un līdz 27 miljardiem EUR 2027.gadā. Ar šī brīža eksporta izaugsmi tiek prognozēts, ka Latvija šo mērķi pat pārsniegs 2027. gadā",
		review: (
			<>
				Eksports ir mūsu valsts izaugsmes pamatā. Tā ir arī šī brīža valdības izvirzītā viena no prioritātēm, jo tas
				precīzi korelē ar valsts iekšzemes kopproduktu un tautsaimniecības.
				<br />
				<br />
				Iemesli faktam, ka Latvijai izdosies sasniegt un pat pārsniegt Nacionālās industriālās politikas pamatnostādnes
				(NIP) 2021.-2027 ir vairāki. Pakāpeniski nākamajos gados pirmskrīzes līmenī atgriezīsies pakalpojumu eksports,
				jo šobrīd pēdējos divos gados redzējām strauju preču eksporta izaugsmi. Kā arī straujais globālo cenu kāpums
				būtiski palielina eksporta nominālo (faktisko) vērtību, kas ir par pamatu straujai izaugsmei. Tomēr, lai šo
				mērķi sasniegtu, valsts atbalstam eksportētājiem ir jābūt visaptverošam un inovācijām un izaugsmi mērķētām.
				<br />
				<br />
				Priecē pēdējos gados vērojamā tendence, Latvijas eksportētājiem arvien nostiprinot savas pozīcijas globālajos
				tirgos un sekmīgi pārvarot arī Covid-19 vētru. Taču nav pamata būt pašapmierinātiem un nepieciešama uzņēmēju un
				valsts sektora sinerģija, lai palīdzētu mūsu eksportētājiem būt sekmīgākiem. Te pamatā ir ieguldījumi zinātnē un
				attīstībā, kuros Latvija diemžēl atpaliek no citām Eiropas valstīm.
				<br />
				<br />
				Latvijas ekonomikas konkurētspējas priekšrocības pamatā balstās uz tehnoloģiskiem faktoriem, ražošanas
				efektivitātes uzlabošanu un inovācijām, mazākā mērā uz lētu darbaspēku un zemām resursu cenām. Vidēja termiņa
				periodā Latvijas izaugsmes tempi var sasniegt 4-5% pieaugumu gadā, tomēr eksportētāji arvien ir ambiciozi un
				vēlas redzēt ātrāku izaugsmi īsākā laika periodā.
			</>
		),
	},
	{
		avatar: avatar,
		name: "Mārtiņš Tiknuss",
		designation: "LEA “The Red Jackets”, valdes priekšsēdētājs",
		excerpt:
			"NIP mērķis ir: eksporta apjomu palielināt līdz 22 miljardiem EUR 2023.gadā un līdz 27 miljardiem EUR 2027.gadā. Ar šī brīža eksporta izaugsmi tiek prognozēts, ka Latvija šo mērķi pat pārsniegs 2027. gadā",
		review: (
			<>
				Eksports ir mūsu valsts izaugsmes pamatā. Tā ir arī šī brīža valdības izvirzītā viena no prioritātēm, jo tas
				precīzi korelē ar valsts iekšzemes kopproduktu un tautsaimniecības.
				<br />
				<br />
				Iemesli faktam, ka Latvijai izdosies sasniegt un pat pārsniegt Nacionālās industriālās politikas pamatnostādnes
				(NIP) 2021.-2027 ir vairāki. Pakāpeniski nākamajos gados pirmskrīzes līmenī atgriezīsies pakalpojumu eksports,
				jo šobrīd pēdējos divos gados redzējām strauju preču eksporta izaugsmi. Kā arī straujais globālo cenu kāpums
				būtiski palielina eksporta nominālo (faktisko) vērtību, kas ir par pamatu straujai izaugsmei. Tomēr, lai šo
				mērķi sasniegtu, valsts atbalstam eksportētājiem ir jābūt visaptverošam un inovācijām un izaugsmi mērķētām.
				<br />
				<br />
				Priecē pēdējos gados vērojamā tendence, Latvijas eksportētājiem arvien nostiprinot savas pozīcijas globālajos
				tirgos un sekmīgi pārvarot arī Covid-19 vētru. Taču nav pamata būt pašapmierinātiem un nepieciešama uzņēmēju un
				valsts sektora sinerģija, lai palīdzētu mūsu eksportētājiem būt sekmīgākiem. Te pamatā ir ieguldījumi zinātnē un
				attīstībā, kuros Latvija diemžēl atpaliek no citām Eiropas valstīm.
				<br />
				<br />
				Latvijas ekonomikas konkurētspējas priekšrocības pamatā balstās uz tehnoloģiskiem faktoriem, ražošanas
				efektivitātes uzlabošanu un inovācijām, mazākā mērā uz lētu darbaspēku un zemām resursu cenām. Vidēja termiņa
				periodā Latvijas izaugsmes tempi var sasniegt 4-5% pieaugumu gadā, tomēr eksportētāji arvien ir ambiciozi un
				vēlas redzēt ātrāku izaugsmi īsākā laika periodā.
			</>
		),
	},*/
]

export const exp_reviews = [
	{
		avatar: avatar,
		indx: 0,
		
	},
	{
		avatar: avatar,
		indx: 1,
	},
	{
		avatar: avatar,
		indx: 2,
	},

	{
		avatar: sweden,
		indx: 3,
	},
	{
		avatar: germany,
		indx: 4,
	},
	{
		avatar: norway,
		indx: 5,
	},
	{
		avatar: australia,
		indx: 6,
	},
	{
		avatar: america,
		indx: 7,
	},
	{
		avatar: skorea,
		indx: 8,
	},
	{
		avatar: israel,
		indx: 9,
	},
	{
		avatar: uk,
		indx: 10,
	},
	{
		avatar: netherlands,
		indx: 11,
	},
	{
		avatar: japan,
		indx: 12,
	},
]
