import React from "react"
import { Col, Image, Row } from "react-bootstrap"
import { DashedBox } from "components"
import Polygon from "assets/icons/Polygon"
import { Link } from "react-router-dom"
import Parser from 'html-react-parser'

const FeedbackReview = ({ t, avatar, name, designation, company, website, review, id, title, expmarket }) => {
	let linkTo = "";
	let company_link = "";
	
	if(website){
		company_link = "<div className='mb-2'><a rel='nofollow' target='_blank' className='website-link' href='"+website+"'>"+company+"</a></div>"
	}
	else{
		company_link = "<p className='mb-2'>"+company+"</p>"
	}

	if(expmarket){
		linkTo = "/exp-review/";
	}
	else{
		linkTo = "/review/";
	}

	return (
		<>
			<DashedBox className="p-4 static-height-4">
				<Row className="g-0">
					<Col sm={3}>{avatar && <Image src={avatar} fluid className="mb-2" />}</Col>
					<Col sm={9}>
						<p className="mb-2">
							{title && <strong>{title}</strong>}
						</p>
						{Parser(company_link)}
						<p className="mb-0 fst-italic limit-lines-4">
							{review}
						</p>
							<Link to={linkTo+id} className="small text-secondary ms-1">
								{t("btn_read_more")}
								<span className="ms-1">
									<Polygon />
								</span>
							</Link>
					</Col>
				</Row>
			</DashedBox>
		</>
	)
}

export default FeedbackReview
