import React from "react"

export default function BarGraph(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
			<rect
				id="Rectangle_239"
				data-name="Rectangle 239"
				width="4.492"
				height="11.979"
				transform="translate(0 12.021)"
				fill="#fff"
			/>
			<rect
				id="Rectangle_241"
				data-name="Rectangle 241"
				width="4.492"
				height="14.973"
				transform="translate(19.508 9.027)"
				fill="#fff"
			/>
			<rect
				id="Rectangle_240"
				data-name="Rectangle 240"
				width="4.492"
				height={24}
				transform="translate(9.754)"
				fill="#fff"
			/>
		</svg>
	)
}
