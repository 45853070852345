import React from "react"
import { FeedbackReview, Footer, Header } from "components"
import { Breadcrumb, BreadcrumbItem, Col, Container, Row, Button } from "react-bootstrap"
import { reviews } from "content/reviews"
import { exp_reviews } from "content/reviews"
import Wrapper from "components/layout/Wrapper"
import PageTitle from "components/base/PageTitle"
import styles from "../Dashboard/styles.module.scss"
import { Link } from "react-router-dom";


class CompanyInterviews extends React.Component {
	constructor(props){
		super(props)
		this.t = props.t
		this.default_tab = ""
		this.active_tab = null
		this.active_sub_tabs = {"exporter-stories":"exporter-stories", "export-markets":"export-markets"}
	}
	handleSelect(key) {
		this.force_active_tab = key.tabIndex
		window.history.pushState({}, '', '/company-interviews/'+key.tabIndex)
		this.forceUpdate()	
	}

	handleSubSelect(key){
		var tab = key.tabIndex
		this.active_sub_tabs[this.active_tab] = tab;
		this.force_active_tab = this.active_tab
		this.forceUpdate()	
	}

	render() {
		var t = this.t
		console.log(this);
		var new_tab 
		if (this.force_active_tab){
			new_tab = this.force_active_tab
			this.force_active_tab = null
		} else if (this.props.tab){
			new_tab = this.props.tab
		} else {
			new_tab = this.default_tab
		}
		if (new_tab !== this.active_tab){
			this.active_tab = new_tab
			this.active_sub_tabs[this.active_tab] = this.active_tab
		}

		// var active_sub_tab = this.active_sub_tabs[this.active_tab]
		const sortedReviews = reviews.sort((a, b) => b.indx - a.indx);
		const sortedExpReviews = exp_reviews.sort((a, b) => b.indx - a.indx);

		if (this.active_tab == "exporter-stories"){
			
			
			return( 
				<Wrapper>
					<Header t={t} />
					<Container fluid className="px-4">
						<Row className="py-3 d-none d-sm-block">
							<Col>
								<Breadcrumb className={styles.breadcrumb}>
									<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>{t("home")}</BreadcrumbItem>
									<BreadcrumbItem linkProps={{ to: "/company-interviews"}} linkAs={Link}>{t("company_interviews_pagetitle")}</BreadcrumbItem>
									<BreadcrumbItem active className={styles.breadcrumbActiveItem}>{t("comp_tab_1")}</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>

						<PageTitle>{t("comp_tab_1_title")}</PageTitle>

						<Row md={3} className="g-3 flex-column flex-sm-row mt-2 cards-grid">
							{sortedReviews.map((item, index) => {
								if (item?.indx < 3) return null
								return (
									<Col key={item?.indx} xs={12} sm={12} md={6} xl={6} xxl={4}>
										<FeedbackReview
											avatar={item?.avatar}
											t={t}
											name={t("review_"+item?.indx+"_name")}
											designation={t("review_"+item?.indx+"_designation")}
											company={t("review_"+item?.indx+"_company")}
											website={t("review_"+item?.indx+"_website")}
											title={t("review_"+item?.indx+"_title")}
											review={t("review_"+item?.indx+"_excerpt")}
											id={item?.indx}
											expmarket={false}
										/>
									</Col>
								)
							})}
						</Row>

						
					</Container>

					<Row className="my-5 pt-5">
						<Col>
							<Footer secondary t={t} />
						</Col>
					</Row>
				</Wrapper>
			)
		} else if (this.active_tab == "export-markets") {
			return( 
				<Wrapper>
					<Header t={t} />
					<Container fluid className="px-4">
						<Row className="py-3 d-none d-sm-block">
							<Col>
								<Breadcrumb className={styles.breadcrumb}>
									<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>{t("home")}</BreadcrumbItem>
									<BreadcrumbItem linkProps={{ to: "/company-interviews"}} linkAs={Link}>{t("company_interviews_pagetitle")}</BreadcrumbItem>
									<BreadcrumbItem active className={styles.breadcrumbActiveItem}>{t("comp_tab_2")}</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>

						<PageTitle>{t("comp_tab_2_title")}</PageTitle>

						<Row md={3} className="g-3 flex-column flex-sm-row mt-2 cards-grid">
							{sortedExpReviews.map((item, index) => {
								if (item?.indx < 3) return null
								return (
									<Col key={item?.indx} xs={12} sm={12} md={6} xl={6} xxl={4}>
										<FeedbackReview
											avatar={item?.avatar}
											t={t}
											name={t("exp_review_"+item?.indx+"_name")}
											designation={t("exp_review_"+item?.indx+"_designation")}
											company={t("exp_review_"+item?.indx+"_company")}
											title={t("exp_review_"+item?.indx+"_title")}
											review={t("exp_review_"+item?.indx+"_excerpt")}
											id={item?.indx}
											expmarket={true}
										/>
									</Col>
								)
							})}
						</Row>
						
					</Container>

					<Row className="my-5 pt-5">
						<Col>
							<Footer secondary t={t} />
						</Col>
					</Row>
				</Wrapper>
			)
		} else {
	
			return(
				<Wrapper>
					<Header t={t} />
					<Container fluid className="px-4">
						<Row className="py-3 d-none d-sm-block">
							<Col>
								<Breadcrumb className={styles.breadcrumb}>
									<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>{t("home")}</BreadcrumbItem>
									<BreadcrumbItem href="/Uzņēmumu intervijas" active className={styles.breadcrumbActiveItem}>
										{t("company_interviews_pagetitle")}
									</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>

						<PageTitle>{t("comp_tab_1_title")}</PageTitle>

						<Row md={3} className="g-3 flex-column flex-sm-row mt-2 cards-grid">
							{sortedReviews.map((item, index) => {
								if (item?.indx < 3 || index > 8) return null
								return (
									<Col key={item?.indx} xs={12} sm={12} md={6} xl={6} xxl={4}>
										<FeedbackReview
											avatar={item?.avatar}
											t={t}
											name={t("review_"+item?.indx+"_name")}
											designation={t("review_"+item?.indx+"_designation")}
											company={t("review_"+item?.indx+"_company")}
											website={t("review_"+item?.indx+"_website")}
											title={t("review_"+item?.indx+"_title")}
											review={t("review_"+item?.indx+"_excerpt")}
											id={item?.indx}
											expmarket={false}
										/>
									</Col>
								)
							})}
						</Row>

						<Link to="/company-interviews/exporter-stories" className="d-block mb-5">
							<Button variant="" size="lg" className="more-reviews-btn mt-4">
								{t("btn_more_reviews")}
							</Button>
						</Link>

						<PageTitle>{t("comp_tab_2_title")}</PageTitle>

						<Row md={3} className="g-3 flex-column flex-sm-row mt-2 cards-grid">
							{sortedExpReviews.map((item, index) => {
								if (item?.indx < 3 || index > 8) return null
								return (
									<Col key={item?.indx} xs={12} sm={12} md={6} xl={6} xxl={4}>
										<FeedbackReview
											avatar={item?.avatar}
											t={t}
											name={t("exp_review_"+item?.indx+"_name")}
											designation={t("exp_review_"+item?.indx+"_designation")}
											company={t("exp_review_"+item?.indx+"_company")}
											title={t("exp_review_"+item?.indx+"_title")}
											review={t("exp_review_"+item?.indx+"_excerpt")}
											id={item?.indx}
											expmarket={true}
										/>
									</Col>
								)
							})}
						</Row>

						<Link to="/company-interviews/export-markets" className="d-block mb-5">
							<Button variant="" size="lg" className="more-reviews-btn mt-4">
								{t("btn_more_reviews")}
							</Button>
						</Link>

					</Container>

					<Row className="my-5 pt-5">
						<Col>
							<Footer secondary t={t} />
						</Col>
					</Row>
				</Wrapper>
			)
		}
	}

}

export default CompanyInterviews
