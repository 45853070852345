import React from "react"
import { useParams } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Container, Image, Row } from "react-bootstrap"
import { DashedBox, Footer, Header } from "components"
import { reviews } from "content/reviews"
import { exp_reviews } from "content/reviews"
import BackArrow from "components/utility/BackArrow"
import Wrapper from "components/layout/Wrapper"
import { Link } from "react-router-dom";
import parse from 'html-react-parser'
import styles from "../../../pages/Dashboard/styles.module.scss"


const SingleReview = ({ t }) => {
	const { id } = useParams()
	let url = window.location.href;
	if(url.includes("exp-review")){
		return (
			<Wrapper>
				<Header t={t} />
	
				<Container fluid className="px-4 post-container">
					{exp_reviews.map((item, index) => {
						
						if(Number(id) === item?.indx){
							return (
								<>
									<Row className="py-3">
										<Col className="d-none d-sm-block">
											<Breadcrumb className={styles.breadcrumb}>
												<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>{t("home")}</BreadcrumbItem>
												<BreadcrumbItem linkProps={{ to: "/company-interviews"}} linkAs={Link}>{t("company_interviews_pagetitle")}</BreadcrumbItem>
												<BreadcrumbItem linkProps={{ to: "/company-interviews/export-markets"}} linkAs={Link}>{t("comp_tab_2_title")}</BreadcrumbItem>
												<BreadcrumbItem active className={styles.breadcrumbActiveItem}>{t("exp_review_"+item?.indx+"_name")}</BreadcrumbItem>
											</Breadcrumb>
										</Col>
										<Col className="d-sm-none">
											<BackArrow width={8} /> {t("exp_review_"+item?.indx+"_name")}
										</Col>
									</Row>
									<DashedBox className="dashed-container" key={item?.indx}>
										<Row>
											<Col sm={12} className={styles.interview_block}>
												<div>
													<h2 className="post-title">{t("exp_review_"+item?.indx+"_title")}</h2>
												</div>
												<div class="post-header">
													<Image className="interview-avatar" src={item?.avatar} />
													<div>
														<h3 className="text-secondary mb-0">{t("exp_review_"+item?.indx+"_name")}</h3>
														<p className="text-secondary mb-0">{t("exp_review_"+item?.indx+"_designation")}</p>
													</div>
												</div>
												<p className="interview-body">{parse(t("exp_review_"+item?.indx+"_text"))}</p>
											</Col>
										</Row>
									</DashedBox>
								</>
							)
						}
					})}
				</Container>
	
				<Row className="my-5 pt-5">
					<Col>
						<Footer secondary t={t} />
					</Col>
				</Row>
			</Wrapper>
		)
	}
	else{
		return (
			<Wrapper>
				<Header t={t} />
	
				<Container fluid className="px-4 post-container">
					{reviews.map((item, index) => {

						if(Number(id) === item?.indx){
							return (
								<>
									<Row className="py-3">
										<Col className="d-none d-sm-block">
											<Breadcrumb className={styles.breadcrumb}>
												<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>{t("home")}</BreadcrumbItem>
												<BreadcrumbItem linkProps={{ to: "/company-interviews"}} linkAs={Link}>{t("company_interviews_pagetitle")}</BreadcrumbItem>
												<BreadcrumbItem linkProps={{ to: "/company-interviews/exporter-stories"}} linkAs={Link}>{t("comp_tab_1_title")}</BreadcrumbItem>
												<BreadcrumbItem active className={styles.breadcrumbActiveItem}>{t("review_"+item?.indx+"_name")}</BreadcrumbItem>
											</Breadcrumb>
										</Col>
										<Col className="d-sm-none">
											<BackArrow width={8} /> {t("review_"+item?.indx+"_name")}
										</Col>
									</Row>
									<DashedBox className="dashed-container" key={item?.indx}>
										<Row>
											<Col sm={12} className={styles.interview_block}>
												<div>
													<h2 className="post-title">{t("review_"+item?.indx+"_title")}</h2>
												</div>
												<div class="post-header">
													<Image className="interview-avatar" src={item?.avatar} />
													<div>
														<h3 className="text-secondary mb-0">{t("review_"+item?.indx+"_name")}</h3>
														<p className="text-secondary mb-0">{t("review_"+item?.indx+"_designation")}</p>
													</div>
												</div>
												<p className="interview-body">{parse(t("review_"+item?.indx+"_text"))}</p>
											</Col>
										</Row>
									</DashedBox>
								</>
							)
						}
					})}
				</Container>
	
				<Row className="my-5 pt-5">
					<Col>
						<Footer secondary t={t} />
					</Col>
				</Row>
			</Wrapper>
		)
	}
}

export default SingleReview
