import React from "react"
import styles from "./styles.module.scss"
import BackArrow from "components/utility/BackArrow"

const PageTitle = ({ children }) => {
	return (
		<h3 className={styles.page_title}>
			<BackArrow width={8} /> {children}
		</h3>
	)
}

export default PageTitle
