import React from "react"
import styles from "./styles.module.scss"

const DashedBox = ({ children, className, props }) => {
	return (
		<div className={`${styles.dashed_box} ${className && className}`} {...props}>
			{children}
		</div>
	)
}

export default DashedBox
