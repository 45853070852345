import * as XLSX from "xlsx"

var data_source = {}
var data_loading_status = 0

export function isDataLoaded(){
    return data_loading_status
}

export function GetData(sheet_name){
    if (data_loading_status === 1){
        return data_source[sheet_name];
    }
    throw "Data loading not completed"
}

export function LoadData() {
	// use async file reader

    fetch("/Full_Dataset_SDK.xlsx").then(response => checkStatus(response) && response.arrayBuffer())
    .then(raw => {
        //console.log(raw)
        const wb = XLSX.read(raw, { type: "binary" })
        const wsnames = wb.SheetNames

        for (var i = 0; i < wsnames.length; i++){

            const sheet_name = wsnames[i]
            const ws = wb.Sheets[sheet_name]
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            data_source[sheet_name] = data
        }
        data_loading_status = 1
        //console.log(data_source)
    })
                        
	return 1
}

/* helper to summarize tabular data */
export function SummarizeData(data, category_field, value_field) {
    let summarized_data = []
    let key_map = {}
    let len = data.length
    for (let i = 1; i < len; i++){
        let row = data[i]
        let category = row[category_field]
        let value = row[value_field]
        if (!value) continue

        if (typeof(key_map[category]) === "undefined") {
            summarized_data.push([category,value])
            key_map[category] = summarized_data.length-1
        } else {
            summarized_data[key_map[category]][1] += value
        }
    }
    return summarized_data
}

export function GroupByYear(data, date_field, value_field){
    let grouped_data = []
    let key_map = {}

    let len = data.length

    // sort data by date_index

    let sorted_data = data.sort((a,b)=>{return a[date_field]-b[date_field]})

    for (let i = 0; i < len; i++){
        let row = data[i]

        let category = new Date(row[date_field]).getFullYear();
        let date = row[date_field]
        let value = row[value_field]


        if (!value) continue

        if (typeof(key_map[category]) === "undefined") {
            grouped_data.push([date,value])
            key_map[category] = grouped_data.length-1
        } else {
            grouped_data[key_map[category]][1] += value
        }

    }
    return grouped_data

}

export function AddPreviousPeriod(data, date_index, value_field, prev_value_field, change_field, skip_last_items){
    // assumes sorted index
    let expanded_data = []
    let len = data.length
    let prev_value = 1
    let change = 1
    let d = new Date()
    let prev_d = null

    //console.log("Data", data)
    for (let i = 0; i < len; i++){

        let row = data[i]

        prev_value = null
        change = null

        if (i < len-skip_last_items){

            let value = row[value_field]

            d.setTime(parseInt(row[date_index])+86400*1000)

            d.setFullYear(parseInt(d.getFullYear())-1)

            prev_d = d.getTime()

            for (let j = i-1; j >= 0; j--){
                if (data[j][date_index] <= prev_d){
                    prev_value = data[j][value_field]
                    break;
                }
            }

            if (prev_value !== null && prev_value !== 0){
                change = value/prev_value-1
            }
        }
        row[prev_value_field] = prev_value
        row[change_field] = change
        
        expanded_data.push(row)
    }
    return expanded_data

}

export function ExcelSerialToDate(serial) {
	if (serial < 3000) {
		//assume this is year actually
		return new Date(serial, 1, 1).getTime()
	}
	var utc_days = Math.floor(serial - 25569)
	var utc_value = utc_days * 86400
	return utc_value * 1000
}

export function ConvertExcelSerialToDate(data, series, column) {
	var new_data = []
	for (var i = 0; i < data.length; i++) {
		var row = [...data[i]]
		row[column] = ExcelSerialToDate(row[column])
		if (row.length < series.length) {
			row = row.concat([...new Array(series.length - row.length)])
		}

		new_data.push(row)
	}
	return new_data
}


function checkStatus(response) {
	if (!response.ok) {
		throw new Error(`HTTP ${response.status} - ${response.statusText}`)
	}
	return response
}

