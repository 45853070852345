import React from "react"
import { DashedBox, Footer, Header } from "components"
import { Breadcrumb, BreadcrumbItem, Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap"
import placeholder_tab_3 from "assets/images/placeholder_tab_3.png"
import placeholder_tab_5 from "assets/images/placeholder_tab_5.png"
import placeholder_tab_7 from "assets/images/placeholder_tab_7.png"
import FullScreenTrigger from "components/utility/FullScreenTrigger"
import Wrapper from "components/layout/Wrapper"
import PageTitle from "components/base/PageTitle"
import TimeChart from "charts/TimeChart"
import { UpdateTimeChart } from "charts/TimeChart"
import { Link } from "react-router-dom"

import styles from "../Dashboard/styles.module.scss"


class Productivity extends React.Component {
	constructor(props){
		super(props)
		this.t = props.t
		this.default_tab = "real-exports-1"
		this.active_tab = null
		this.active_sub_tabs = {"real-exports-1":"real-exports-1", "research-development-1":"research-development-1"}
	}
	handleSelect(key) {
		this.force_active_tab = key.tabIndex
		window.history.pushState({}, '', '/productivity/'+key.tabIndex)
		this.forceUpdate()	
	}

	handleSubSelect(key){
		var tab = key.tabIndex
		this.active_sub_tabs[this.active_tab] = tab;
		UpdateTimeChart(tab, this.t)
		this.force_active_tab = this.active_tab
		this.forceUpdate()	
	}

	render() {
		var t = this.t
		console.log(this);

		var new_tab 
		if (this.force_active_tab){
			new_tab = this.force_active_tab
			this.force_active_tab = null
		} else if (this.props.tab){
			new_tab = this.props.tab
		} else {
			new_tab = this.default_tab
		}
		if (new_tab !== this.active_tab){
			this.active_tab = new_tab
			this.active_sub_tabs[this.active_tab] = this.active_tab
		}

		var active_sub_tab = this.active_sub_tabs[this.active_tab]

		if (this.active_tab !== active_sub_tab){
			UpdateTimeChart(active_sub_tab, this.t)
		} else {
			UpdateTimeChart(this.active_tab, this.t)
		}

		return (
			<Wrapper>
				<Header t={t} />

				<Container fluid className="px-4">
					<Row className="py-3 d-none d-sm-block">
						<Col>
							<Breadcrumb className={styles.breadcrumb}>
								<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>
									{t("home")}
								</BreadcrumbItem>
								<BreadcrumbItem href="/distribution" active className={styles.breadcrumbActiveItem}>
									{t("productivity_pagetitle")}
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>

					<PageTitle>{t("productivity_pagetitle")}</PageTitle>

					<Row>
						<Col>
							<Tabs defaultActiveKey="real-exports-1" activeKey={this.active_tab} onSelect={(tabIndex) => this.handleSelect({ tabIndex })}>
								<Tab eventKey="real-exports-1" title={t("prod_tab_1")}>
									<Tabs
										activeKey={this.active_sub_tabs["real-exports-1"]}
										className="justify-content-end mt-2"
										onSelect={(tabIndex) => this.handleSubSelect({ tabIndex })}
									>
										<Tab eventKey="real-exports-1" title={t("prod_tab_1_1")}>
											<FullScreenTrigger text={t("prod_tab_1_1_fs")} />
											<TimeChart section="real-exports-1" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("prod_tab_1_1_text_bold")}</strong> {t("prod_tab_1_1_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="real-exports-2" title={t("prod_tab_1_2")}>
											<FullScreenTrigger text={t("Latvijas ārpus-eirozonas reālais eksports (gada pieauguma tempi, %, devumi, pp.)")} />
											<TimeChart section="real-exports-2" t={t}  />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("prod_tab_1_1_text_bold")}</strong> {t("prod_tab_1_1_text")}
											</DashedBox>
										</Tab>
									</Tabs>
								</Tab>

								<Tab eventKey="research-development-1" title={t("prod_tab_2")}>
									<Tabs
										className="justify-content-end mt-2"
										activeKey={this.active_sub_tabs["research-development-1"]}

										onSelect={(tabIndex) => this.handleSubSelect({ tabIndex })}
									>
										<Tab eventKey="research-development-1" title={t("prod_tab_2_1")}>
											<FullScreenTrigger text={t("prod_tab_2_1_fs")} />
											<TimeChart section="research-development-1" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("prod_tab_2_1_text_bold")}</strong> {t("prod_tab_2_1_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="research-development-2" title={t("prod_tab_2_2")}>
											<FullScreenTrigger text={t("prod_tab_2_2_fs")} />
											<TimeChart section="research-development-2" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("prod_tab_2_2_text_bold")}</strong> {t("prod_tab_2_2_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="research-development-3" title={t("prod_tab_2_3")}>
											<FullScreenTrigger text={t("prod_tab_2_3_fs")} />
											<TimeChart section="research-development-3" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("prod_tab_2_3_text_bold")}</strong> {t("prod_tab_2_3_text")}
											</DashedBox>
										</Tab>
									</Tabs>
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Container>

				<Row>
					<Col>
						<Footer secondary t={t} />
					</Col>
				</Row>
			</Wrapper>
		)
	}
}

export default Productivity
