import React from "react"

export default function Previous(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="8.828" height="14.828" viewBox="0 0 8.828 14.828" {...props}>
			<path
				id="Path_1212"
				data-name="Path 1212"
				d="M600.5,692l6,6-6,6"
				transform="translate(607.914 705.414) rotate(180)"
				fill="none"
				stroke="#48484a"
				strokeLinecap="round"
				strokeWidth={2}
			/>
		</svg>
	)
}
