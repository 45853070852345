import React from "react"


import {
	GetData,
	isDataLoaded,
	SummarizeData,
	ConvertExcelSerialToDate,
} from "tools/Data"

import {
	FilterByCategory,
	UpdateOnChange,
	MatchRow,
	RemoveOnChange
} from "tools/Filter"


var combo_charts = {}

var translations_en = {
"G46 Vairumtirdzniecība, izņemot automobiļus un motociklus": "G46 Wholesale trade, except of motor vehicles and motorcycles",
"C16 Koksnes, koka un korķa izstrādājumu ražošana, izņemot mēbeles, salmu un pīto izstrādājumu ražošana": "C16 Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials",
"OTH Bez sadalījumam pa nozarēm": "OTH Other NACE activities",
"C10 Pārtikas produktu ražošana": "C10 Manufacture of food products",
"G45 Automobiļu un motociklu vairumtirdzniecība, mazumtirdzniecība un remonts": "G45 Wholesale and retail trade and repair of motor vehicles and motorcycles",
"G47 Mazumtirdzniecība, izņemot automobiļus un motociklus": "G47 Retail trade, except of motor vehicles and motorcycles",
"H52 Uzglabāšanas un transporta palīgdarbības": "H52 Warehousing and support activities for transportation",
"C25 Gatavo metālizstrādājumu ražošana, izņemot mašīnas un iekārtas": "C25 Manufacture of fabricated metal products, except machinery and equipment",
"C24 Metālu ražošana": "C24 Manufacture of basic metals",
"C23 Nemetālisko minerālu izstrādājumu ražošana": "C23 Manufacture of other non-metallic mineral products",
"C26 Datoru, elektronisko un optisko iekārtu ražošana": "C26 Manufacture of computer, electronic and optical products",
"C20 Ķīmisko vielu un ķīmisko produktu ražošana": "C20 Manufacture of chemicals and chemical products",
"C14 Apģērbu ražošana": "C14 Manufacture of wearing apparel",
"C27 Elektrisko iekārtu ražošana": "C27 Manufacture of electrical equipment",
"C21 Farmaceitisko pamatvielu un farmaceitisko preparātu ražošana": "C21 Manufacture of basic pharmaceutical products and pharmaceutical preparations",
"C28 Citur neklasificētu iekārtu, mehānismu un darba mašīnu ražošana": "C28 Manufacture of machinery and equipment n.e.c.",
"C31 Mēbeļu ražošana": "C31 Manufacture of furniture",
"C29 Automobiļu, piekabju un puspiekabju ražošana": "C29 Manufacture of motor vehicles, trailers and semi-trailers",
"A02 Mežsaimniecība un mežizstrāde": "A02 Forestry and logging",
"C22 Gumijas un plastmas izstrādājumu ražošana": "C22 Manufacture of rubber and plastic products",
"B08 Pārējā ieguves rūpniecība un karjeru izstrāde": "B08 Other mining and quarrying",
"C18 Poligrāfija un ierakstu reproducēšana": "C18 Printing and reproduction of recorded media",
"D35 Elektroenerģija, gāzes apgāde, siltumapgāde un gaisa kondicionēšana": "D35 Electricity, gas, steam and air conditioning supply",
"C11 Dzērienu ražošana": "C11 Manufacture of beverages",
"A01 Augkopība un lopkopība, medniecība un saistītas palīgdarbības": "A01 Crop and animal production, hunting and related service activities",
"C13 Tekstilizstrādājumu ražošana": "C13 Manufacture of textiles",
"C17 Papīra un papīra izstrādājumu ražošana": "C17 Manufacture of paper and paper products",
"H51 Gaisa transports": "H51 Air transport",
"F41 Ēku būvniecība": "F41 Construction of buildings",
"C32 Cita veida ražošana": "C32 Other manufacturing",
"E38 Atkritumu savākšana, apstrāde un izvietošana, materiālu pārstrāde": "E38 Waste collection, treatment and disposal activities; materials recovery",
"H49 Sauszemes transports un cauruļvadu transports": "H49 Land transport and transport via pipelines",
"NSP_CR Nav norādīts pa nozarēm komerciālu iemeslu dēļ": "NSP_CR Not specified because of commercial reasons",
"C30 Citu transportlīdzekļu ražošana": "C30 Manufacture of other transport equipment",
"F43 Specializētie būvdarbi": "F43 Specialised construction activities",
"N77 Iznomāšana un ekspluatācijas līzings": "N77 Rental and leasing activities",
"C33 Iekārtu un ierīču remonts un uzstādīšana": "C33 Repair and installation of machinery and equipment",
"A03 Zivsaimniecība": "A03 Fishing and aquaculture",
"K64 Finanšu pakalpojumu darbības, izņemot apdrošināšanu un pensiju uzkrāšanu": "K64 Financial service activities, except insurance and pension funding",
"L68 Operācijas ar nekustamo īpašumu": "L68 Real estate activities",
"I56 Ēdināšanas pakalpojumi": "I56 Food and beverage service activities",
"F42 Inženierbūvniecība": "F42 Civil engineering",
"J62 Datorprogrammēšana, konsultēšana un saistītas darbības": "J62 Computer programming, consultancy and related activities",
"M71 Arhitektūras un inženiertehniskie pakalpojumi, tehniskā pārbaude un analīze": "M71 Architectural and engineering activities; technical testing and analysis",
"M73 Reklāmas un tirgus izpētes pakalpojumi": "M73 Advertising and market research",
"M70 Centrālo biroju darbība, konsultēšana komercdarbībā un vadībzinībās": "M70 Activities of head offices; management consultancy activities",
"J61 Telekomunikācija": "J61 Telecommunications",
"N80 Apsardzes pakalpojumi un izmeklēšana": "N80 Security and investigation activities",
"C15 Ādas un ādas izstrādājumu ražošana": "C15 Manufacture of leather and related products",
"J63 Informācijas pakalpojumi": "J63 Information service activities",
"M74 Citi profesionālie, zinātniskie un tehniskie pakalpojumi": "M74 Other professional, scientific and technical activities",
"N82 Biroju administratīvās darbības un citas uzņēmumu palīgdarbības": "N82 Office administrative, office support and other business support activities",
"J58 Izdevējdarbība": "J58 Publishing activities",
"M72 Zinātniskās pētniecības darbs": "M72 Scientific research and development",
"M69 Juridiskie un grāmatvedības pakalpojumi": "M69 Legal and accounting activities",
"S96 Pārējo individuālo pakalpojumu sniegšana": "S96 Other personal service activities",
"K66 Finanšu pakalpojumu un apdrošināšanas darbības papildinošas darbības": "K66 Activities auxiliary to financial services and insurance activities",
"R92 Azartspēles un derības": "R92 Gambling and betting activities",
"N81 Būvniecības un ainavu arhitektu pakalpojumi": "N81 Services to buildings and landscape activities",
"R93 Sporta nodarbības, izklaides un atpūtas darbība": "R93 Sports activities and amusement and recreation activities",
"H53 Pasta un kurjeru darbība": "H53 Postal and courier activities",
"S94 Sabiedrisko, politisko un citu organizāciju darbība": "S94 Activities of membership organisations",
"R90 Radošas, mākslinieciskas un izklaides darbības": "R90 Creative, arts and entertainment activities",
"Q86 Veselības aizsardzība": "Q86 Human health activities",
"H50 Ūdens transports": "H50 Water transport",
"N79 Ceļojumu biroju, tūrisma operatoru rezervēšanas pakalpojumi un ar tiem saistīti pasākumi": "N79 Travel agency, tour operator and other reservation service and related activities",
"S95 Datoru, individuālās lietošanas priekšmetu un mājsaimniecības piederumu remonts": "S95 Repair of computers and personal and household goods",
"P85 Izglītība": "P85 Education",
"I55 Izmitināšana": "I55 Accommodation",
"E36 Ūdens ieguve, attīrīšana un apgāde": "E36 Water collection, treatment and supply",
"E37 Notekūdeņu savākšana un attīrīšana": "E37 Sewerage",
"C19 Koksa un naftas pārstrādes produktu ražošana": "C19 Manufacture of coke and refined petroleum products",
"R91 Bibliotēku, arhīvu, muzeju un citu kultūras iestāžu darbība": "R91 Libraries, archives, museums and other cultural activities",
"Q87 Sociālā aprūpe ar izmitināšanu": "Q87 Residential care activities"
}

export function UpdateComboChart(name,t) {
	//console.log("Updating " + name)

	if (!combo_charts[name]) return
	function update(name, iter) {
		if (iter > 100) {
			//console.log("Iteracitons exceeded " + iter + "; could not get clientWidth for the parent (" + name + ")")
			return
		}

		let container_width = combo_charts[name]._impl.shell.container.clientWidth
		if (!container_width) {
			setTimeout(() => {
				update(name, iter + 1)
			}, 100)
			return
		}


		combo_charts[name].replaceSettings({
			localization: {
				toolbar: {
					backButton: t("Atgriezties"),
					zoomoutButton: t("Attālināties"),
				}
			}
		});

		combo_charts[name].updateSettings({toolbar: {zoomOut: false}})
		combo_charts[name].updateSettings({toolbar: {zoomOut: true}})


		combo_charts[name].updateSize()
	}
	update(name, 0)
}

function buildComboChart(section, filter, caller) {
	if (combo_charts[section]) {
		return;
		//combo_charts[section].remove()
	}
	var container = document.getElementById("chart-" + section)
	if (container) {
		if (!isDataLoaded()) {
			container.innerHTML = "Loading.."
			if (caller) {
				setTimeout(() => {
					caller.componentDidMount()
				}, 100)
				return
			}
		}

		let chart = null;

		//console.log("window client width", window.innerWidth)
		let max_width = (window.innerWidth < 562)?180:window.innerWidth/4;

		var settings = {
			data: {
				dataFunction: function(id,limit,offset,success,fail){
					getData(filter, id, limit, offset, success, fail)
				},
				sortField: "value",
			},
			area: {
				height: 400,
			},
			horizontal: true,
			container: container,
			info: {
				enabled: true,
			},

			valueAxisDefault: {
				side: "right",
				style: {
					valueLabel: {
						angle: -90,
					},
					labelSpacingMode : "auto"
				}

			},
			localization: {
				toolbar: {
					backButton: caller.t("Atgriezties"),
					zoomoutButton: caller.t("Attālināties"),
				}
			},
			toolbar: {
				logScale: false,
				back: false,
				export: false
			},
			facetAxis: {
				defaultUnitWidth: 20,
				labels: {
					angle: -90,
					maxWidth: max_width,
					align: "right",
				},
				size: max_width
			},
			series: [
				{id: "1", style: columnStyles["lightGray"]}
			],
			interaction: {
				resizing: {
					enabled: false
				}
			},
			events: {
				onSelectionChange: function(ev,arg){
					//console.log("Selection changed")
					let values = [];
					for (let i = 0; i < arg.selection.length; i++){
						values.push(arg.selection[i].id)
					}
					//console.log("New selection", values)
					FilterByCategory(filter, "category", values, arg.chart);
				}
			},
			items: {
				styleFunction: function(item, data){
					let max_chars = max_width/10;

					const cl = localStorage.getItem("lang")
	
					let label = data["full_name"]

					//console.log("Label", label, data)
					if (cl === "en") label = translations_en[label]
					//console.log("translated label", label)
					
					let l = (max_chars?max_chars:10)*1.5

					item.label = label.substr(0,l)
					if (label.length > l){
						item.label += ".."
					}
				}
			},
			advanced: {
				themeCSSClass: "DVSL"
			}
		}
		chart = new window.ZoomCharts.FacetChart(settings)
		combo_charts[section] = chart
		container.style.width = "100%"
		chart._id = "combo" + section

		combo_charts[section].updateSize()
	
		UpdateOnChange(filter, chart, "default")
	}
}


class ComboChart extends React.Component {
	constructor(props) {
		super(props)
		this.t = props.t
		this.section = props.section
		this.filter = props.filter
	}
	componentDidMount() {
		buildComboChart(this.section, this.filter, this)
	}
	componentDidUpdate() {
		buildComboChart(this.section, this.filter, this)
	}
	componentWillUnmount() {
		RemoveOnChange(this.filter, combo_charts[this.section])

		combo_charts[this.section].remove()
		combo_charts[this.section] = null
	}
	render() {
		const id = "chart-" + this.section
		return React.createElement("div", { id: id, className: this.props.className })
	}
}

export default ComboChart

var columnStyles = {}

columnStyles["lightGray"] = {
	lineColor: "rgba(179,179,179,1)",
	fillColor: "rgba(179,179,179,1)",
	fillGradient: null,
	gradient: null
}


function getData(filter, id, limit, offset, success, fail){

	//console.log("Reloading data for " + filter)
	var data = GetData("ATN010c")

	var columns = data[0]

	data = ConvertExcelSerialToDate(data, columns, 1)

	var matched_data = [];

	for (var i = 0; i < data.length; i++) {
		var row  = data[i]
		if (!MatchRow(row, filter, ["category"], {"time": 1})){
			continue;
		}
		matched_data.push(row)
	}

	data = SummarizeData(matched_data, 0, 2)

	var combo_data = { subvalues: [] }

	for (var i = 0; i < data.length; i++) {
		var row = data[i]
		var category = row[0]
		var value = row[1]


		combo_data.subvalues.push({
			id: category,
			name: category.substr(0, 50) + (category.length > 32 ? ".." : ""),
			full_name: category,
			value: value,
			loaded: true,
			style:{expandable: false}
		})
	}

	success(combo_data)

}
