import React, {useState} from "react"
import { Breadcrumb, BreadcrumbItem, Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap"
import { DashedBox, Footer, Header } from "components"
import placeholder_tab_4 from "assets/images/placeholder_tab_4.png"
import Wrapper from "components/layout/Wrapper"
import FullScreenTrigger from "components/utility/FullScreenTrigger"
import PageTitle from "components/base/PageTitle"
import TimeChart from "charts/TimeChart"
import ComboChart from "charts/ComboChart"
import styles from "../Dashboard/styles.module.scss"
import { Link } from "react-router-dom"


import {UpdateTimeChart} from "charts/TimeChart"
import {UpdateComboChart } from "charts/ComboChart"

class Contribution extends React.Component {
	constructor(props) {
		super(props)
		this.t = props.t
		this.default_tab = "contribution-1"
		this.active_tab = null
		this.active_sub_tabs = {
			"contribution-2":"contribution-2",
			"contribution-3":"contribution-3",
		}
	}

	handleSelect(key) {
		this.force_active_tab = key.tabIndex
		window.history.pushState({}, '', '/contribution/'+key.tabIndex)
		this.forceUpdate()
	}

	handleSubSelect(key){
		var tab = key.tabIndex
		this.active_sub_tabs[this.active_tab] = tab;
		UpdateTimeChart(tab, this.t)
		this.force_active_tab = this.active_tab
		this.forceUpdate()	
	}

	render() {
		var t = this.t

		var new_tab 
		if (this.force_active_tab){
			new_tab = this.force_active_tab
			this.force_active_tab = null
		} else if (this.props.tab){
			new_tab = this.props.tab
		} else {
			new_tab = this.default_tab
		}
		if (new_tab !== this.active_tab){
			this.active_tab = new_tab
			this.active_sub_tabs[this.active_tab] = this.active_tab
		}

		var active_sub_tab = this.active_sub_tabs[this.active_tab]

		if (this.active_tab !== active_sub_tab){
			UpdateTimeChart(active_sub_tab, this.t)
		} else {
			UpdateTimeChart(this.active_tab, this.t)

		}
		if (this.active_tab === "nace")
			UpdateComboChart("nace-combo", this.t)

		
		return (
			<Wrapper>
				<Header t={t} />
				<Container fluid className="px-4">
					<Row className="py-3 d-none d-sm-block">
						<Col>
							<Breadcrumb className={styles.breadcrumb}>
								<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>
									{t("home")}
								</BreadcrumbItem>
								<BreadcrumbItem href="/distribution" active className={styles.breadcrumbActiveItem}>
									{t("contribution_pagetitle")}
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>

					<PageTitle>{t("contribution_pagetitle")}</PageTitle>

					<Row>
						<Col>
							<Tabs defaultActiveKey="contribution-1" activeKey={this.active_tab} onSelect={(tabIndex) => this.handleSelect({ tabIndex })}>
								<Tab eventKey="contribution-1" title={t("contribution_tab_1")}>
										<FullScreenTrigger
											text={t("contribution_tab_1_fs")}
											style={{ marginTop: 10, borderTopRightRadius: 10, borderTopLeftRadius: 10 }}
										/>
										<TimeChart section="contribution-1" t={t} />
										<DashedBox className="bg-white p-3 mt-4">
											<strong>{t("contribution_tab_1_text_bold")}</strong> {t("contribution_tab_1_text")}
										</DashedBox>
								</Tab>

								<Tab eventKey="contribution-2" title={t("contribution_tab_2")}>
									<Tabs
										activeKey={this.active_sub_tabs["contribution-2"]}
										className="justify-content-end mt-2"
										onSelect={(tabIndex) => this.handleSubSelect({ tabIndex })}
									>
										<Tab eventKey="contribution-2" title={t("contribution_tab_2_1")}>
											<FullScreenTrigger text={t("contribution_tab_2_1_fs")} />
											<TimeChart section="contribution-2" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("contribution_tab_2_text_bold")}</strong> {t("contribution_tab_2_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="contribution-2-2" title={t("contribution_tab_2_2")}>
											<FullScreenTrigger text={t("contribution_tab_2_2_fs")} />
											<TimeChart section="contribution-2-2" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("contribution_tab_2_text_bold")}</strong> {t("contribution_tab_2_text")}
											</DashedBox>
										</Tab>
									</Tabs>
								</Tab>

								<Tab eventKey="contribution-3" title={t("contribution_tab_3")}>
									<Tabs
										activeKey={this.active_sub_tabs["contribution-3"]}
										className="justify-content-end mt-2"
										onSelect={(tabIndex) => this.handleSubSelect({ tabIndex })}
									>
										<Tab eventKey="contribution-3" title={t("contribution_tab_3_1")}>
											<FullScreenTrigger text={t("contribution_tab_3_1_fs")} />
											<TimeChart section="contribution-3" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("contribution_tab_3_text_bold")}</strong> {t("contribution_tab_3_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="contribution-3-2" title={t("contribution_tab_3_2")}>
											<FullScreenTrigger text={t("contribution_tab_3_2_fs")} />
											<TimeChart section="contribution-3-2" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("contribution_tab_3_2_text_bold")}</strong> {t("contribution_tab_3_2_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="contribution-3-3" title={t("contribution_tab_3_3")}>
											<FullScreenTrigger text={t("contribution_tab_3_3_fs")} />
											<TimeChart section="contribution-3-3" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("contribution_tab_3_3_text_bold")}</strong> {t("contribution_tab_3_3_text")}
											</DashedBox>
										</Tab>
									</Tabs>
								</Tab>

								<Tab eventKey="nace" title={t("contribution_tab_4")}>
									<div className={styles.bottom_charts}>
										<div className={styles.half}>
											<TimeChart section="nace" filter="nace" t={t} />
										</div>
										<div className={styles.half}>
											<ComboChart section="nace-combo" filter="nace" t={t} />
										</div>
									</div>
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Container>

				<Row>
					<Col>
						<Footer secondary t={t} />
					</Col>
				</Row>
			</Wrapper>
		)
	}
}

export default Contribution
