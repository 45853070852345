import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Logo from "assets/logos/Logo"
import styles from "./styles.module.scss"
import Hamburger from "assets/icons/Hamburger"
import { Link } from "react-router-dom"
import { SideMenu } from "components"
import LangSwitcher from "components/base/LangSwitcher"

const Header = ({ home, t }) => {
	const [showMenu, setShowMenu] = useState(false)

	const handleMenuClose = () => setShowMenu(false)
	const handleMenuOpen = () => setShowMenu(true)

	return home ? (
		<>
			<Container fluid id={styles.header_normal}>
				<Row className="justify-content-between justify-content-sm-start align-items-center flex-nowrap flex-sm-wrap">
					<Col xs="auto" md={1} className="order-2 order-md-1">
						<div className={styles.hamburger} onClick={handleMenuOpen}>
							<Hamburger width={25} />
						</div>
					</Col>
					<Col xs="auto" md={11} className="order-1 order-md-2">
						<div className={styles.logo_normal}>
							<Link to="/">
								<Logo width={60} />
							</Link>
							<h4 className="d-inline-block text-white ms-4 mb-0 lh-lg" style={{ verticalAlign: "middle" }}>
								{t("project_title")}
							</h4>
							<div className={styles.main_lang_menu}>
								<LangSwitcher mode="dark" />
							</div>
						</div>
					</Col>
				</Row>
			</Container>

			<Row className="justify-content-center text-center d-none" id={styles.header_home}>
				<Col>
					<div className={styles.content}>
						<div className={styles.logo}>
							<Logo width={100} />
						</div>
					</div>
				</Col>
			</Row>
			<div class={styles.content_padding_home}></div>
			{showMenu && <SideMenu t={t} showMenu={showMenu} handleMenuClose={handleMenuClose} />}
		</>
	) : (
		<>
			<Container fluid id={styles.header_normal}>
				<Row className="justify-content-between justify-content-sm-start align-items-center flex-nowrap flex-sm-wrap">
					<Col xs="auto" md={1} className="order-2 order-md-1">
						<div className={styles.hamburger} onClick={handleMenuOpen}>
							<Hamburger width={25} />
						</div>
					</Col>
					<Col xs="auto" md={11} className="order-1 order-md-2">
						<div className={styles.logo_normal}>
							<Link to="/">
								<Logo width={60} />
							</Link>
							<h4 className="d-inline-block text-white ms-4 mb-0 lh-lg" style={{ verticalAlign: "middle" }}>
								{t("project_title")}
							</h4>
							<div className={styles.main_lang_menu}>
								<LangSwitcher mode="dark" />
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<div className={styles.content_padding}>-</div>

			{showMenu && <SideMenu t={t} showMenu={showMenu} handleMenuClose={handleMenuClose} />}
		</>
	)
}

export default Header
