import React from "react"
import { Col, Container, Image, Row} from "react-bootstrap"
import latvian_exporters_en from "assets/logos/latvian_exporters_en.png"
import latvian_exporters_lv from "assets/logos/latvian_exporters_lv.png"

import lddk from "assets/logos/lddk.png"
import liaa from "assets/logos/liaa.png"
import lursoft from "assets/logos/lursoft.png"
import latvijas from "assets/logos/latvijas.png"
import ministry_lv from "assets/logos/ministry_lv.png"
import ministry_en from "assets/logos/ministry_en.png"
import primekss from "assets/logos/primekss.png"
import gp from "assets/logos/gp.png"
import zoomcharts from "assets/logos/zoomcharts.png"
import home from "assets/images/home.png"

import styles from "./styles.module.scss"
import { Link } from "react-router-dom"

import { useLocation } from "react-router-dom"





const Footer = ({ primary, secondary, t }) => {

	const location = useLocation()
	const route = location.pathname

	const logos = [
		localStorage.getItem("lang") === "en"
			? latvian_exporters_en
			: localStorage.getItem("lang") === "lt"
			? latvian_exporters_lv
			: latvian_exporters_lv,
		liaa,
		lddk,
		lursoft,
		latvijas,
		localStorage.getItem("lang") === "en" ? ministry_en : localStorage.getItem("lang") === "lt" ? ministry_lv : ministry_lv,
		primekss,
		gp,
		[zoomcharts, "https://zoomcharts.com"],
	]

	return (
		<Container fluid className={`text-center ${styles.footer} ${secondary && styles.secondary_footer}`}>
			{primary && (
				<Container className={styles.inner_container}>
					<Row
						xs={4}
						md="auto"
						className={`justify-content-center justify-content-md-between align-items-center py-3 gy-3 ${styles.footer_content_row}`}
					>
						{
							<Col xs={12} className="d-md-none">
								<p className="lead">{t("Veidotāji")}:</p>
							</Col>
						}

						{logos.map((item, index) => {
							if (Array.isArray(item))
								return (
									<Col key="k{index}">
										<a href={item[1]}>
											<Image
												src={item[0]}
												width={
													index === logos.length - 1
														? 80
														: index === logos.length - 2
														? 40
														: index === logos.length - 3
														? 50
														: 60
												}
											/>
										</a>
									</Col>
								)
							else
								return (
									<Col key={index}>
										<Image
											src={item}
											width={
												index === logos.length - 1
													? 80
													: index === logos.length - 2
													? 40
													: index === logos.length - 3
													? 50
													: 60
											}
										/>
									</Col>
								)
						})}
					</Row>
				</Container>
			)}
			{secondary && (
				<a target="_new" href="https://zoomcharts.com">
					<Image src={zoomcharts} className={styles.zoomcharts_logo} />
				</a>
			)}
			{secondary && (
				<div className={styles.navigation}>
					<Link to="/">
						<span className={`${route === "/" && styles.active}`}>
							<Image src={home} />
						</span>
					</Link>
					<Link to="/dashboard">
						<span className={`${route.indexOf("/dashboard") > -1 && styles.active}`}>1</span>
					</Link>
					<Link to="/contribution/contribution-1">
						<span className={`${route.indexOf("/contribution") > -1 && styles.active}`}>2</span>
					</Link>
					<Link to="/financial-health">
						<span className={`${route.indexOf("/financial-health") > -1 && styles.active}`}>3</span>
					</Link>
					<Link to="/productivity">
						<span className={`${route.indexOf("/productivity") > -1 && styles.active}`}>4</span>
					</Link>
					<Link to="/company-interviews">
						<span className={`${route.indexOf("/company-interviews") > -1 && styles.active}`}>5</span>
					</Link>
				</div>
			)}
		</Container>
	)
}

export default Footer
