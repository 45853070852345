import React, {useState} from "react"
import i18n from "i18next"
import styles from "./styles.module.scss"

const LangSwitcher = ({mode}) => {
	const cl = localStorage.getItem("lang")
	const [current_language, setLanguage] = useState(cl?cl:"lt");
	
	var flag_class = "flag-" + (current_language === "en" ? "lt" : "en")
	if (mode){
		flag_class += "-" + mode
	}

	function handleClick(e){
		var new_language = ""
		if (current_language === "en"){
			new_language = "lt"
		} else {
			new_language = "en"
		}
		i18n.changeLanguage(new_language)
		localStorage.setItem("lang", new_language)
		setLanguage(new_language)
	}
	return (
		<div className={styles[flag_class]} onClick={handleClick}>
			
		</div>
	)
	/*return (
		<div style={{ width: 130 }} className={styles.lang_switcher}>
			<Form.Select size="sm" defaultValue={localStorage.getItem("lang")} onChange={handleLanguage}>
				<option value="lt">Latvian</option>
				<option value="en">English</option>
			</Form.Select>
		</div>
	)*/
}

export default LangSwitcher
