/* very basic filtering engine */

var filters = {}
var listeners = {}

// prepare basic filter instance
function initFilter(key, type){
    if (typeof(filters[key]) !== "undefined"){
        if (typeof(filters[key][type] !== "undefined")){
            return
        }
    } else {
        filters[key] = {}
    }
    var filter = {}
    if (type === "time"){
        filter = {from:null, to:null}
    } else if (type === "category"){
        filter = {category:null, values: []}
    }
    filters[key][type] = filter;
}

// add time filter
export function FilterByTime(section, from, to, origin){
    initFilter(section, "time")
    setFilter(section, "time", {from:from, to:to}, origin)
}

export function FilterByCategory(section, category, values, origin){
    initFilter(section, category)
    setFilter(section, category, { category: category, values: values}, origin)
}
export function RemoveOnChange(section, chart){
    if (!listeners[section]) return;
    for (let i = 0; i < listeners[section].length; i++){
        if (listeners[section][i][0]._id === chart._id){
            listeners[section].splice(i,1);
            break;
        }
    }
}

export function UpdateOnChange(section, chart, dataid){
    if (!listeners[section]) listeners[section] = [];
    for (let i = 0; i <  listeners[section].length; i++){
        if (chart._id && listeners[section][i][0]._id === chart._id) return;
    }
    listeners[section].push([chart,dataid]);
}


export function MatchRow(row, section, ignore_filters = [], match_indexes) {
    var current_filters = filters[section];
    for (let type in current_filters){
        
        let filter = current_filters[type]

        if (ignore_filters.indexOf(type) > -1) continue
        

        if (type === "time") {
            // match against time
            let value = row[match_indexes["time"]]
            if (value < filter["from"] || value > filter["to"]) return false
        } else {
            if (!filter["values"].length) continue;

            // match against category
            let category_index = match_indexes[type]

            if (category_index !== undefined){
                let value = row[category_index];
                if (filter["values"].indexOf(value) === -1) return false
            }
        }
        
    }

    return true;
}

export function GetFilter(section, category){
    return filters?filters[section]?filters[section][category]:null:null
}

// internal methods
function doUpdateCharts(section, origin){
    //console.log("Listeners", listeners[section])
    if (!listeners[section]) return;
    for (let i = 0; i < listeners[section].length; i++){
        var chart = listeners[section][i][0]
        if (origin && origin._id && chart._id === origin._id) {
            //console.log("Skipping as origin ", chart._id)
            continue;
        
        }
        //console.log("Reloading data for ", chart._id)
        var dataid = listeners[section][i][1]
        chart.reloadData(dataid);
    }
}

function setFilter(section, type, filter, origin){
    let changed = false;

    let current_filter = filters[section][type]

    //console.log("Current filter", section, type, current_filter)

    if (!current_filter) {
        changed = true
    } else {
        for (let i in current_filter){
            let prop = current_filter[i]
            if (Array.isArray(prop)){
                if (prop.length !== filter[i].length) {
                    changed = true;
                    break;
                }
                for (let j = 0; j < prop.length; j++){
                    if (prop[j] !== filter[i][j]){
                        changed = true;
                        break;
                    }
                } 
            } else {
                if (filter[i] !== prop){
                    changed = true;
                    break
                }
            }
        }
    }
    filters[section][type] = filter;

    //console.log("New filter set for ", section, type, filter, changed)
    if (changed){
        doUpdateCharts(section, origin)
    }
}
