import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "scss/main.scss"
import {LoadData} from "./tools/Data"
import Home from "pages/Home"
import Contribution from "pages/Contribution"
import Dashboard from "pages/Dashboard"
import FinancialHealth from "pages/FinancialHealth"
import Productivity from "pages/Productivity"
import CompanyInterviews from "pages/CompanyInterviews"
import SingleReview from "components/base/SingleReview"
import ScrollToTop from "components/utility/ScrollToTop"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { useTranslation } from "react-i18next"

import liaa from "assets/logos/liaa.png"

import toms1 from "assets/images/toms_1.JPG"
import toms2 from "assets/images/toms_2.jpg"
import toms3 from "assets/images/toms_3.jpg"

import kristine1 from "assets/images/kristine_1.jpeg"
import kristine2 from "assets/images/kristine_2.jpeg"
import kristine3 from "assets/images/kristine_3.jpg"

import janis1 from "assets/images/janis_1.jpg"
import janis2 from "assets/images/janis_2.png"
import janis3 from "assets/images/janis_3.png"

import linda1 from "assets/images/linda_1.jpg"
import linda2 from "assets/images/linda_2.jpg"
import linda3 from "assets/images/linda_3.jpg"

import roberts1 from "assets/images/roberts_1.jpg"
import roberts2 from "assets/images/roberts_2.jpg"
import roberts3 from "assets/images/roberts_3.jpg"

import janisO1 from "assets/images/janisO_1.jpeg"
import janisO2 from "assets/images/janisO_2.jpg"
import janisO3 from "assets/images/janisO_3.jpg"

import katrina1 from "assets/images/katrina_1.png"
import katrina2 from "assets/images/katrina_2.jpg"

import romans1 from "assets/images/romans_1.png"
import romans2 from "assets/images/romans_2.jpg"
import romans3 from "assets/images/romans_3.jpg"
import romans4 from "assets/images/romans_4.jpg"

import zygimantas1 from "assets/images/zygimantas_1.jpg"
import zygimantas2 from "assets/images/zygimantas_2.jpg"
import zygimantas3 from "assets/images/zygimantas_3.jpg"

import kristineM1 from "assets/images/kristineM_1.png"
import kristineM2 from "assets/images/kristineM_2.png"
import kristineM3 from "assets/images/kristineM_3.png"



import exp_sweden1 from "assets/images/exp_sweden_1.jpg"
import exp_sweden2 from "assets/images/exp_sweden_2.jpg"
import exp_sweden3 from "assets/images/exp_sweden_3.jpg"

import exp_germany1 from "assets/images/exp_germany_1.png"
import exp_germany2 from "assets/images/exp_germany_2.jpg"
import exp_germany3 from "assets/images/exp_germany_3.jpg"
import exp_germany4 from "assets/images/exp_germany_4.jpg"
import exp_germany5 from "assets/images/exp_germany_5.jpg"
import exp_germany6 from "assets/images/exp_germany_6.jpg"

import exp_norway1 from "assets/images/exp_norway_1.jpg"
import exp_norway2 from "assets/images/exp_norway_2.jpg"
import exp_norway3 from "assets/images/exp_norway_3.jpg"
import exp_norway4 from "assets/images/exp_norway_4.png"
import exp_norway5 from "assets/images/exp_norway_5.png"
import exp_norway6 from "assets/images/exp_norway_6.JPG"

import exp_australia1 from "assets/images/exp_australia_1.jpeg"
import exp_australia2 from "assets/images/exp_australia_2.jpg"
import exp_australia3 from "assets/images/exp_australia_3.jpg"
import exp_australia4 from "assets/images/exp_australia_4.jpg"
import exp_australia5 from "assets/images/exp_australia_5.jpg"
import exp_australia6 from "assets/images/exp_australia_6.jpg"

import exp_america1 from "assets/images/exp_america_1.jpeg"
import exp_america2 from "assets/images/exp_america_2.jpg"
import exp_america3 from "assets/images/exp_america_3.jpg"
import exp_america4 from "assets/images/exp_america_4.jpg"
import exp_america5 from "assets/images/exp_america_5.jpg"
import exp_america6 from "assets/images/exp_america_6.jpeg"

import exp_skorea1 from "assets/images/exp_skorea_1.jpg"
import exp_skorea2 from "assets/images/exp_skorea_2.jpg"
import exp_skorea3 from "assets/images/exp_skorea_3.jpg"
import exp_skorea4 from "assets/images/exp_skorea_4.JPG"
import exp_skorea5 from "assets/images/exp_skorea_5.jpg"
import exp_skorea6 from "assets/images/exp_skorea_6.JPG"

import exp_israel1 from "assets/images/exp_israel_1.jpg"
import exp_israel2 from "assets/images/exp_israel_2.jpg"
import exp_israel3 from "assets/images/exp_israel_3.jpeg"
import exp_israel4 from "assets/images/exp_israel_4.jpg"

import exp_uk1 from "assets/images/exp_uk_1.jpeg"
import exp_uk2 from "assets/images/exp_uk_2.jpeg"
import exp_uk3 from "assets/images/exp_uk_3.jpeg"
import exp_uk4 from "assets/images/exp_uk_4.jpg"
import exp_uk5 from "assets/images/exp_uk_5.jpg"
import exp_uk6 from "assets/images/exp_uk_6.jpeg"

import exp_netherlands1 from "assets/images/exp_netherlands_1.jpg"
import exp_netherlands2 from "assets/images/exp_netherlands_2.jpeg"
import exp_netherlands3 from "assets/images/exp_netherlands_3.jpg"
import exp_netherlands4 from "assets/images/exp_netherlands_4.jpeg"
import exp_netherlands5 from "assets/images/exp_netherlands_5.jpg"
import exp_netherlands6 from "assets/images/exp_netherlands_6.jpg"

import exp_japan1 from "assets/images/exp_japan_1.jpeg"
import exp_japan2 from "assets/images/exp_japan_2.png"
import exp_japan3 from "assets/images/exp_japan_3.png"
import exp_japan4 from "assets/images/exp_japan_4.jpg"
import exp_japan5 from "assets/images/exp_japan_5.png"
import exp_japan6 from "assets/images/exp_japan_6.jpg"

const support_by_liaa = "			<dd></dd><b>Raksts tapis sadarbībā ar Latvijas Investīciju un attīstības aģentūru.</b>"+
	"			<dd></dd><img style=\"width:93px\" src=\""+liaa+"\">";

const support_by_liaa_en = "			<dd></dd><b>The article has been made in a partnership with Investment and Development Agency of Latvia (LIAA)</b>"+
"			<dd></dd><img style=\"width:93px\" src=\""+liaa+"\">";

const translationsLt = {
	btn_home: "Doties uz Eksporta Analīzi",
	btn_analyse: "Analīze",
	btn_read_more: "Lasīt vairāk",
	btn_more_reviews: "Skatīt visus",
	home: "Sākums",
	project_title: "Latvijas Eksporta Barometrs",
	home_block_1:
		"”Eksporta barometra” mērķi ir vairāki un attiecas uz plašu mērķa auditoriju - gan valsts pārvaldi, gan uzņēmumiem un sabiedrību kopumā. “Eksporta barometrs” interaktīvā veidā ilustrēLatvijas eksporta kopainu. Eksports ir mūsu valsts izaugsmes pamatā. Valstu ekonomikas aug un attīstās pateicoties eksportam, un šobrīd Latvija atpaliek no Lietuvas un Igaunijas eksporta attīstībā, par spīti aktīvām pūlēm to sekmēt.",
	home_block_2:
		"Pētījumā tika analizēti visi Latvijas preču un pakalpojumu eksportētāji, kuru eksporta apjoms ir virs 1 miljona eiro. Pēdējo trīs gadu rādītāji izmantoti par pamatu barometra veidošanā, lai būtu iespēja salīdzināt tendences vairāku gadu griezumā, īpaši ņemot vērā arī pandēmijas ietekmi uz eksporta attīstību kopumā.",
	contribution_pagetitle: "Eksportētāju pienesums tautsaimniecībā",
	contribution_tab_1: "Eksportētāju skaits",
	contribution_tab_1_fs: "Eksportētāju skaits ar eksporta apjomu virs 1 miljona eiro",
	contribution_tab_1_text_bold: "Daiga Kiopa - Lursoft valdes locekle:",
	contribution_tab_1_text:
		"Statistika parāda, ka eksportējošajiem uzņēmumiem, kuru eksporta apjoms gadā pārsniedz 1 milj. EUR, pēdējos 3 gados pieaudzis eksports uz trešajām valstīm, savukārt – eksportētāju skaits uz ES valstīm 2020. gada laikā nedaudz sarucis. Tas savukārt liek secināt, ka eksportētājiem, kas produktus un pakalpojumus eksportē uz trešām valstīm, jāvelta lielāka uzmanība un resursi gan darījumu risku, gan sadarbības partneru izvērtēšanai.",

	contribution_tab_2: "Darbinieku skaits",
	contribution_tab_2_text_bold: "Daiga Kiopa - Lursoft valdes locekle:",
	contribution_tab_2_text:
		"Salīdzinot Latvijas eksportējošo uzņēmumu un uzņēmumu, kuri neeksportē (vairāk kā 1 milj. EUR), strādājošo skaita izmaiņu pēdējo 2 gadu laikā, redzam, ka procentuāli neeksportējošos uzņēmumos darbinieku skaits pēdējā gada laikā ir samazinājies straujāk nekā eksportējošajos uzņēmumos. Savukārt, vērtējot uzņēmuma vidējo darbinieku skaita izmaiņu, var secināt, ka šis rādītājs 2020.gadā, salīdzinot ar iepriekšējo gadu, pieaudzis pakalpojumu eksportētājiem, turpretī – preču eskportētājiem vidējais darbinieku skaits  nedaudz samazinājies. Viens no izskaidrojumiem šādai izmaiņai ir uzņēmumu ražošanas procesu automatizācijas intensīvāka ieviešana.",
	contribution_tab_2_1: "Darbinieku skaits kopā",
	contribution_tab_2_1_fs: "Darbinieku skaits kopā (pēc VID datiem)",
	contribution_tab_2_2: "Vidējais darbinieku skaits uzņēmumā",
	contribution_tab_2_2_fs: "Darbinieku skaits pa gadiem",

	contribution_tab_3: "Nodokļu apjoms",
	contribution_tab_3_text_bold: "Daiga Kiopa - Lursoft valdes locekle:",
	contribution_tab_3_text:
		"Salīdzinot eksportējošo uzņēmumu skaitu ar neeksportējošajiem uzņēmumiem, redzams, ka neeksportējošo uzņēmumu procentuālais īpatsvars  norādītajā uzņēmumu grupā pārsniedz 70%. Savukārt, ja vērtējam eksportējošo uzņēmumu samaksāto nodokļu apjomu, redzam, ka to pienesums samaksātajos nodokļos ik gadu svārstās robežās no 45-50%. Tas liecina, ka, kaut arī eksportētāju skaits ir mazāks, to pienesums tautsaimniecībā, tai skaitā samaksāto nodokļu apjoms ir ievērojami lielāks nekā neeksportējošajiem uzņēmumiem.",
	contribution_tab_3_1: "Kopējais nodokļu apjoms",
	contribution_tab_3_1_fs: "Kopējais nodokļu apjoms pa gadiem",
	contribution_tab_3_2: "Nodokļu apjoms uz 1 uzņēmumu",
	contribution_tab_3_2_fs: "Eksportētāju skaits ar eksporta apjomu virs 1 miljona eiro",
	contribution_tab_3_2_text_bold: "Daiga Kiopa - Lursoft valdes locekle:",
	contribution_tab_3_2_text:
		"Statistika liecina, ka pakalpojumus eksportējošie uzņēmumi, salīdzinot ar preču eksportētājiem, ir lielāks samaksāto nodokļu apjoms gan rēķinot  uz 1 uzņēmumu, gan - uz 1 darbinieku.",
	contribution_tab_3_3: "Nodokļi uz 1 darbinieku",
	contribution_tab_3_3_fs: "Budžetā samaksātie nodokļi uz 1 darbinieku pa gadiem",
	contribution_tab_3_3_text_bold: "Daiga Kiopa - Lursoft valdes locekle:",
	contribution_tab_3_3_text:
		"Svarīgi uzsvērt, ka, sīkāk analizējot uzņēmumu samaksātos nodokļus, eksportējošos uzņēmumos ir augstākas valsts sociālās obligātās iemaksas  uz 1 darbinieku – šis rādītājs, kas ir tieši proporcionāls bruto darba algai un netieši raksturo gan darba ražīgumu, gan darbinieku sociālās garantijas. Tas savukārt apliecina, ka eskportējošos uzņēmumos ir augstāks atalgojuma līmenis, salīdzinot ar neeksportējošiem uzņēmumiem. Šie rādītāji parāda, ka eksportējošos uzņēmumos strādājošie var vairāk patērēt iekšējā tirgū, tādējādi tiešā veidā veicinot arī vietējo ekonomisko aktivitāti.",

	contribution_tab_4: "NACE",

	financial_health_pagetitle: "Eksportētāju finanšu veselība",
	fh_tab_1: "Darbības apjoms",
	fh_tab_1_1: "Apgrozījums uz 1 darbinieku",
	fh_tab_1_1_text_bold:
		'Mārtiņš Tiknuss - Latvijas Eksportētāju asociācijas "The Red Jackets", valdes priekšsēdētājs: ',
	fh_tab_1_1_text:
		"Lai mēs spētu konkurēt pasaules tirgos ar produktu cenu un veicinātu eksportējošo uzņēmumu spēju attīstīt ražotnes un vieglāk piesaistīt finansējumu no bankām vai kapitāla fondiem mums ir jāveicina uzņēmēju spēja audzēt pievienoto vērtību un samazināt tās pašizmaksu, kas nozīmē sniegt atbalstu aktīvu iegādē un investīcijām darba ražības palielināšanā, kā arī strādāt pie pamata izmaksu pozīciju pielāgošanas reģiona līmenim, lai energoresursi būtu vienā cenu līmenī, lai nodokļu slogs būtu salīdzināms ar konkurentiem.",
	fh_tab_1_2: "Rentabilitāte (aktīvu atdeve)",
	fh_tab_2: "Pelnītspēja",
	fh_tab_2_1: "Peļņa pēc nodokļiem",
	fh_tab_2_1_text_bold:
		'Mārtiņš Tiknuss - Latvijas Eksportētāju asociācijas "The Red Jackets", valdes priekšsēdētājs: ',
	fh_tab_2_1_text:
		'Mārtiņš Tiknuss - Latvijas Eksportētāju asociācijas "The Red Jackets", valdes priekšsēdētājs: Lai mēs spētu konkurēt pasaules tirgos ar produktu cenu un veicinātu eksportējošo uzņēmumu spēju attīstīt ražotnes un vieglāk piesaistīt finansējumu no bankām vai kapitāla fondiem mums ir jāveicina uzņēmēju spēja audzēt pievienoto vērtību un samazināt tās pašizmaksu, kas nozīmē sniegt atbalstu aktīvu iegādē un investīcijām darba ražības palielināšanā, kā arī strādāt pie pamata izmaksu pozīciju pielāgošanas reģiona līmenim, lai energoresursi būtu vienā cenu līmenī, lai nodokļu slogs būtu salīdzināms ar konkurentiem.',
	fh_tab_2_2: "Peļņa pēc nodokļiem uz 1 darbinieku",
	fh_tab_2_3: "Bruto peļņas robeža",

	productivity_pagetitle: "Produktivitāte un inovācijas",
	prod_tab_1: "Reālais eksports",
	prod_tab_1_1: "Kopējais reālais eksports",
	prod_tab_1_1_fs: "Latvijas kopējais reālais eksports (gada pieauguma tempi, %, devumi, pp.)",
	prod_tab_1_1_text_bold: "Jānis Ošlejs - Primekss, valdes priekšsēdētājs: ",
	prod_tab_1_1_text:
		"Reālā eksporta dati liecina, ka Latvijas eksporta pieaugumu pēdējo gadu laikā nodrošināja lielākoties pasaules pieprasījuma straujā izaugsme, bet bremzēja Latvijas preču un pakalpojumu pašizmaksas kāpums. Pasaules kopējam pieprasījumam COVID-19 krīzes iespaidā stagnējot, Latvijas uzņēmējiem būs īpaši jācenšas, lai panāktu eksporta izaugsmi. Lai nelabvēlīgos ārējos apstākļos nodrošinātu izaugsmi, ir nepieciešams palielināt valsts atbalstu investīcijām iekārtās, digitalizācijā, izpētē un attīstībā",
	prod_tab_1_2: "Ārpus-eirozonas reālais eksports",
	prod_tab_2: "Pētniecības un attīstības rādītāji",
	prod_tab_2_1: "Izdevumi zinātniski pētnieciskajam darbam",
	prod_tab_2_1_fs: "Izdevumi zinātniski pētnieciskajam darbam pa sektoriem",
	prod_tab_2_1_text_bold: "Jānis Hermanis - LDDK, finanšu un nodokļu eksperts:",
	prod_tab_2_1_text:
		"Latvijā izdevumi zinātniski pētnieciskajam darbam pēdējos gados pakāpeniski pieaug, tomēr šis palielinājums notiek aptuveni tādā pašā ātrumā kā IKP izaugsme – kā rezultātā šo ieguldījumu attiecība pret IKP nesasniedz tādu līmeni kā citās valstīs. Puse no kopējiem ieguldījumiem Latvijā tiek veikti augstākās izglītības sektorā, trešdaļa - uzņēmējdarbības sektorā, bet aptuveni 1/6 – valsts sektorā. Salīdzinājumos ar citām valstīm Latvijā ieguldījumu atpalicība veidojas galvenokārt uzņēmējdarbības sektorā, kur izdevumiem vajadzētu būt vairākas reizes lielākiem.",
	prod_tab_2_2: "Finansējums",
	prod_tab_2_2_fs: "Nodarbinātais zinātniskais personāls, % no kopējā nodarbināto skaita (pilna laika slodzēs), KOPĀ",
	prod_tab_2_2_text_bold: "Jānis Hermanis - LDDK, finanšu un nodokļu eksperts:",
	prod_tab_2_2_text:
		"Latvijā finansējums zinātniski pētnieciskajam darbam ilgstošu laika periodu nav bijis pietiekams. Tas svārstījies robežās starp 0,4% un 0,7% no IKP, kas ir ievērojami mazāk nekā citās ES valstīs (t.sk. Igaunijā un Lietuvā), kā arī būtiski atpaliek no stratēģiskajiem mērķiem, kuri uzstādīti Nacionālajā Attīstības plānā. Latvijā finansējuma īpatsvars (% pret IKP) atpaliek no pārējām Baltijas valstīm visos sektoros, tomēr galvenokārt šī starpība veidojas uz uzņēmējdarbības sektora rēķina. Jāatzīmē, ka faktiskie Latvijas rādītāji par finansējumu P&A varētu būt augstāki nekā uzrādīts statistikā, jo uzņēmēji vairākkārt ir norādījuši uz pietiekami augstu administratīvo slogu saistībā ar šādas statistikas uzrādīšanu. Tas nozīmē, ka uzskaites process par ieguldījumiem P&A būtu jāpadara vienkāršāks un respondentiem draudzīgāks.",
	prod_tab_2_3: "Nodarbinātais personāls",
	prod_tab_2_3_fs: "Nodarbinātais zinātniskais personāls, % no kopējā nodarbināto skaita (pilna laika slodzēs), KOPĀ",
	prod_tab_2_3_text_bold: "Jānis Hermanis - LDDK, finanšu un nodokļu eksperts:",
	prod_tab_2_3_text:
		"Latvijā zinātniski pētnieciskā personāla īpatsvars ir 0,7% no kopējās nodarbināto slodzes. Tas ir aptuveni 2 reizes zemāks nekā vidēji ES valstīs, un par 1/3 – nekā Igaunijā un Lietuvā. Latvijas rādītājs atpaliek arī no uzstādītajiem NAP mērķiem, saskaņā ar kuriem 2027.gadā būtu jāsasniedz 1%, kas ir aptuveni pašreizējais Igaunijas un Lietuvas līmenis. Latvijas zinātniski pētnieciskā personāla īpatsvars valsts un augstākās izglītības sektoros ir līdzvērtīgs ar pārējām Baltijas valstīm visos sektoros. Atpalicība no citām valstīm veidojas galvenokārt uzņēmējdarbības sektorā, kur Latvijas personālam vajadzētu būt vismaz 3 reizes lielākam, lai atrastos vismaz Igaunijas un Lietuvas līmenī. Arī šajā gadījumā Latvijas faktiskie rādītāji varētu būt labāki, ja uzņēmumiem, kuri nodarbojas ar pētniecību un attīstību, tiktu vienkāršots statistikas uzskaites process.",

	company_interviews_pagetitle: "Uzņēmumu intervijas",
	comp_tab_1: "Uzņēmēju pieredzes stāsti",
	comp_tab_1_title: "Uzņēmēju pieredzes stāsti",
	comp_tab_2: "Pieredze eksporta tirgos",
	comp_tab_2_title: "Pieredze eksporta tirgos",
	
	review_0_name: "Mārtiņš Tiknuss",
	review_0_designation: "LEA “The Red Jackets”, valdes priekšsēdētājs",
	review_0_text:
		"NIP mērķis ir: eksporta apjomu palielināt līdz 22 miljardiem EUR 2023.gadā un līdz 27 miljardiem EUR 2027.gadā. Ar šī brīža eksporta izaugsmi tiek prognozēts, ka Latvija šo mērķi pat pārsniegs 2027. gadā",
	review_0_excerpt:
		"NIP mērķis ir: eksporta apjomu palielināt līdz 22 miljardiem EUR 2023.gadā un līdz 27 miljardiem EUR 2027.gadā. Ar šī brīža eksporta izaugsmi tiek prognozēts, ka Latvija šo mērķi pat pārsniegs 2027. gadā",
	


	review_3_name: "Linda Riekstiņa-Šnore",
	review_3_company: "“Ette Tete”",
	review_3_website: "http://www.ettetete.com",
	review_3_designation: "“Ette Tete” (www.ettetete.com), dibinātāja, valdes locekle",
	review_3_excerpt:
		`Šobrīd bērnu mēbeļu ražotājs "Ette Tete" visvairāk eksportē uz Nīderlandi, Beļģiju, Vāciju, Šveici, Itāliju un Dienvidkoreju. Uzņēmums strādā pie savu produktu pielāgošanas ASV standartiem, lai jau nākamgad varētu sākt aktīvu partneru piesaisti ASV tirgū.`
	,
	review_3_title: "Nevar sēdēt un gaidīt, kad eksporta partneri paši uzrunās",
	review_3_text:
`<dd></dd>Šobrīd bērnu mēbeļu ražotājs "Ette Tete" visvairāk eksportē uz Nīderlandi, Beļģiju, Vāciju, Šveici, Itāliju un Dienvidkoreju. Uzņēmums strādā pie savu produktu pielāgošanas ASV standartiem, lai jau nākamgad varētu sākt aktīvu partneru piesaisti ASV tirgū.`+
`<dd></dd>"Ette Tete" stāsts aizsākās 2016. gadā, kad līdz ar ģimenes pieaugumu radās nepieciešamība pēc bērnu mēbelēm. Ražošanu palīdzēja sākt LEADER projekta atbalsts, kur uzņēmums ieguva 70 % līdzfinansējumu CNC iekārtu iegādei. Pēc tam "Ette Tete" iestājās Latvijas Investīciju un attīstības aģentūras (LIAA) biznesa inkubatorā.`+
`<dd></dd><div class="quote-style">"Mēs nelaidām garām nevienu inkubatora piedāvāto iespēju un visu informāciju tvērām, cik varējām, tai skaitā par "Instagram" iespējām, cenu veidošanu u. tml. Domāju, ka tas ļāva mums izvairīties no daudzām iesācēju kļūdām un koncentrēties uz uzņēmējdarbības attīstīšanu. Atbalstu LEADER projektā varētu pielīdzināt sēklas iesēšanai, bet inkubācijas programmu – laistīšanai, kā rezultātā šobrīd varam plaukt un zelt. Gan jau mēs būtu varējuši attīstīties arī bez šīm atbalsta iespējām, taču tad process noteikti būtu bijis daudz lēnāks un, iespējams, sāpīgāks," saka "Ette Tete" dibinātāja Linda Riekstiņa-Šnore.</div>`+
`<dd></dd>Runājot par valsts atbalstu, viņa izceļ arī LIAA līdzfinansējumu produktu sertificēšanai, kam šobrīd var saņemt 90 % līdzfinansējumu. Eiropā sertifikācija esot salīdzinoši viegla, jo viens sertifikāts ir derīgs vairākās valstīs. Taču, piemēram, kad Lielbritānija pieņēma lēmumu par izstāšanos no Eiropas Savienības, uzņēmumam bija nepieciešams jauns sertifikāts, kas radīja papildu izmaksas. "Sertifikāta iegūšana mūsu produktu pārdošanai Dienvidkorejā izmaksāja 6,5 tūkstošus eiro, bet ar līdzfinansējumu šīs izmaksas varējām ievērojami samazināt," pieredzē dalās L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">No pirmās dienas ar skatu ārzemju virzienā</h3>`+
`<dd></dd>Jau pašos pirmsākumos pārdošana notika ar skatu eksporta virzienā. Uzņēmums sāka pārdot mēbeles e-komercijas platformās "Etsy" un "Amazon", kas padarīja tās pieejamas ārvalstu pircējiem. Pirmais tirgus, uz kuru tika nosūtīts "Ette Tete" produkts, bija ASV. Eksporta tirgi dabiski paplašinājās, līdz uzņēmumu sāka uzrunāt izplatītāji un tirgotāji no ārzemēm. Pirmais sadarbības līgums tika parakstīts ar partneri Itālijā. "Tad arī nonācām pie secinājuma, ka būt tikai e-komercijas platformās vairs nevar. Ja interese nāk ne tikai no gala patērētājiem, bet arī no potenciālajiem partneriem, tad pašiem jākļūst proaktīviem pastāvīgu, stabilu ārvalstu sadarbības partneru meklēšanā un piesaistē," stāsta L. Riekstiņa-Šnore.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+linda1+`"></div>`+
`<dd></dd>Viņa uzskata, ka uzņēmuma eksporta veiksmes atslēga, pirmkārt, ir produkts, kas pirmos piecus gadus ir gājis pa priekšu, jo citiem nekā tāda nebija. Otrkārt, tas ir klientu serviss gan e-komercijā ar gala patērētāju, gan sadarbībā ar ārvalstu partneriem. "Mēs mēģinām nodrošināt, lai sadarbība ar mums kā ražotāju ir patīkama un veiksmīga," saka L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">Eksportā jābūt proaktīvam</h3>`+
`<dd></dd>Viena no klientu piesaistes stratēģijām joprojām ir sevis pozicionēšana e-komercijas platformās. Uzņēmums ir secinājis: jo aktīvāks tas ir e-komercijā, jo zīmols kļūst redzamāks un atpazīstamāks. Un tas veicina klientu piesaisti.`+
`<dd></dd><div class="quote-style">"Taču šodien vairs nedrīkst sēdēt un gaidīt, kad partneris mūs atradīs. Pašam ir jābūt ļoti aktīvam. Mēs nopietni strādājam pie tā, un tagad uzņēmumā ir darbinieks, kas ar to strādā. Braucam daudz dažādās tirdzniecības misijās un piedalāmies izstādēs, ļoti aktīvi meklējam sadarbības partnerus," teic L. Riekstiņa-Šnore.</div>`+
`<dd></dd>Piemēram, uzņēmums "Ette Tete" ir piedalījies Latvijas nacionālajā stendā zīdaiņu un bērnu preču izstādē "Kind + Juged", "Spielwarenmesse", kā arī tirdzniecības misijā Japānā. Arī šajos pasākumos uzņēmums ir piedalījies ar valsts atbalstu. "Nekādā gadījumā nevajadzētu cerēt, ka, aizbraucot uz izstādi, mājās dosies ar veselu sarakstu jaunu klientu. Tas tā nenotiek, un uz to nevajadzētu paļauties. Varbūt vienā tirgū tiešām pietiks ar aizbraukšanu uz izstādi, taču kādā citā partneris jāatrod pašam. Ir jāizmēģina dažādi ceļi, lai redzētu, kurš konkrētajā tirgū strādā," spriež L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">99 % produkcijas pārdod ārpus Latvijas</h3>`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+linda2+`"></div>`+
`<dd></dd>Šobrīd 2016. gadā dibinātais uzņēmums "Ette Tete" ir izaudzis līdz 2,5 miljonu eiro apgrozījumam un nodarbina 30 darbiniekus. Vairāk nekā 99 % no apgrozījuma veido eksports. Latvijā ražotās bērnu mēbeles ir nopērkamas dažādās valstīs, bet pastāvīgas partnerattiecības ir 12 valstīs, tai skaitā Nīderlandē, Vācijā. Visaktīvāk uzņēmums eksportē uz Beļģiju, Vāciju un Dienvidkoreju. "Eksporta galamērķu tops mūsu produktiem nepārtraukti mainās. Tas ir atkarīgs no partnera – kur ir visspēcīgākais, ambiciozākais partneris, tur arī ir izrāviens. Piemēram, tagad Beļģija ir viens no trim mums nozīmīgākajiem eksporta tirgiem, lai gan vēl pirms neilga laika tā nebija ne tuvu šai pozīcijai," saka L. Riekstiņa-Šnore.`+
`<dd></dd>Nākotnē uzņēmēja cer kāpināt eksporta apjomu, taču tas ir atkarīgs arī no ārējiem apstākļiem. Lai gan "Ette Tete" nebija nozīmīgu sadarbības partneru Krievijā vai Ukrainā un karadarbība uzņēmumu tieši nav skārusi, ir jūtama netieša ietekme saistībā ar izejmateriālu cenu kāpumu. Gan vietējie, gan importētie izejmateriāli ir kļuvuši dārgāki, un korekcijas nākotnes plānos ieviesīs inflācija.`+
`<dd></dd>"Šobrīd strādājam pie savu produktu pielāgošanas ASV standartiem. Ceram to paveikt līdz šī gada beigām, lai jau nākamgad varētu sākt aktīvu partneru piesaisti ASV tirgū," stāsta L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">Labi jāpazīst savs produkts un tā pircējs</h3>`+
`<dd></dd>L.Riekstiņa-Šnore teic, ka, domājot par paplašināšanos eksporta tirgū, ir cītīgi jāizvērtē konkrētā valsts – cik liels ir tirgus, vai tajā ir vērts meklēt jaunus sadarbības partnerus. "Ette Tete" gadījumā vislabākais pieprasījums ir attīstītajās valstīs, kur patērētājiem ir pieejami brīvi līdzekļi. Turpretī attīstības valstīs, kur svarīgāka ir cena, Latvijas ražotāja produktus ieviest būtu grūti. "Šādu eksporta kanālu attīstīšanai zūd jēga, jo nebūs pietiekamas atdeves," viņa norāda.`+
`<dd></dd><div class="quote-style">Tāpēc citiem eksportētājiem uzņēmēja iesaka padomāt par produktu un kā tas tiks tirgots. Tāpat jāapsver stratēģija – meklēt pēc iespējas vairāk partneru vai mazāk, bet lojālākus. Tāpat ir jāpadomā par to, vai piemērotāki būs lielie izplatītāji vai mazie veikaliņi.</div>`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+linda3+`"></div>`+
`<dd></dd>L.Riekstiņas-Šnores pieredze liecina, ka dažkārt lielāka vērtība ir dažiem maziem un lojāliem partneriem, nevis daudziem, nelojāliem partneriem. "Ik pa laikam esam saskārušies ar to, ka tirgū ir vairāki partneri, kas sīvi konkurē cits ar citu. No tā cieš produkts, partneri kļūst neapmierināti, sāk sūdzēties arī mums. Lai veicinātu attīstību, ir vajadzīga konkurence, bet pārāk sīvi apstākļi zīmolam var kaitēt," spriež uzņēmēja.`+

	support_by_liaa,

	review_4_name: "Roberts Dlohi",
	review_4_company: "“PERUZA”",
	review_4_website: "https://www.peruza.com",
	review_4_designation: "“PERUZA” (https://peruza.com), valdes priekšsēdētājs",
	review_4_excerpt: `Lai gan "PERUZA" industriālās iekārtas pārtikas nozarei izstrādā un eksportē jau gandrīz 30 gadus, sistemātiski uzņēmums tam pievēršas tikai pēdējos 10 gadus. Un rezultāts šādai attieksmes maiņai ir redzams – šobrīd "PERUZA" eksports veido jau 80 % no apgrozījuma.`,
	review_4_title:"Pēdējā desmitgadē eksportam pievēršas daudz sistemātiskāk",
	review_4_text:
`<dd></dd>Lai gan "PERUZA" industriālās iekārtas pārtikas nozarei izstrādā un eksportē jau gandrīz 30 gadus, sistemātiski uzņēmums tam pievēršas tikai pēdējos 10 gadus. Un rezultāts šādai attieksmes maiņai ir redzams – šobrīd "PERUZA" eksports veido jau 80 % no apgrozījuma.`+
`<dd></dd>Uzņēmumam "PERUZA" ir trīs darbības virzieni – zivju pārstrādes iekārtu izstrāde un ražošana, e-komercijas un paku loģistikas risinājumi un robotizācija, automatizācija, datorredze un mākslīgais intelekts. "Vēsturiski uzņēmums ražoja mazo zivju pārstrādes iekārtas. Tas mums deva daudz pieredzes, lai tagad izstrādātu arī iekārtas lielo zivju pārstrādei," stāsta SIA "PERUZA" valdes priekšsēdētājs Roberts Dlohi.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+roberts1+`"></div>`+
`<dd></dd>2021. gadā uzņēmuma apgrozījums sasniedza 5,5 miljonus eiro, un vidēji 80 % no tā nodrošināja eksports. Vieni no pirmajiem eksporta tirgiem tālajā 1993. gadā bija Francija un Krievija. Sadarbojoties ar franču partneriem un kolēģi no Astrahaņas, tika īstenots storu ikru ražotnes projekts. Apmierinātu klientu ieteikumu atbalstītam, uzņēmumam izdevās realizēt projektus arī citās valstīs. Pirmos 15 pastāvēšanas gadus uzņēmums jaunus klientus ieguva, pateicoties esošo klientu ieteikumiem.`+
`<dd></dd><h3 class="post-subtitle">Pārorientējas uz jauno biznesa realitāti</h3>`+
`<dd></dd>Pie eksporta mārketinga aktivitātēm "PERUZA" mērķtiecīgi sāka strādāt tikai pēdējās desmitgades laikā. Šajā periodā uzņēmuma struktūrā visos līmeņos ir veiktas kardinālas izmaiņas, tāpat ir mainīta filozofija.`+
`<dd></dd><div class="quote-style">R. Dlohi atsaucas uz Albertam Einšteinam piedēvēto domu: "Darīt atkal to pašu un sagaidīt citu rezultātu ir muļķīgi." "Ja gribam nonākt citā līmenī, tad to nevarēs ar to pašu domāšanu, attieksmi, struktūru un procedūrām. Mēs vēlamies augt, tāpēc mums ir vizionāri jāskatās, kurā virzienā jāmaina viss nepieciešamais, lai tas būtu iespējams," tā R. Dlohi.</div>`+
`<dd></dd>Uzņēmums ir spējis pārorientēties no vecā projektu piesaistes modeļa, kad klienti paši to meklē un atrod, uz jauno – aktīvu un mērķtiecīgu projektu piesaistes modeli.`+
`<dd></dd>Šobrīd "PERUZA" eksportē uz daudzām pasaules valstīm, un eksporta galamērķi visu laiku mainās. R. Dlohi skaidro, ka tāda ir šī biznesa īpatnība: uzņēmums veido ražošanas līdzekļus, kam jākalpo ilgi, tāpēc klienti, kas ir apmierinājuši savus ražošanas apjomu mērķus, jaunas iekārtas pērk tikai pēc ilgāka laika. "Mēs jau esam iemācījušies un rēķināmies, ka, ieejot jaunā tirgū, viss var būt un arī būs citādi: valoda, laika zona, biznesa un attiecību prakses, izejvielas. Ar to rēķināmies un saprotam, ka atkal daudz kas jāmācās no jauna," stāsta R. Dlohi. `+
`<dd></dd>Uzņēmums ir eksportējis uz Kanādu, Japānu un Filipīnām, bet šogad 1. vietā būs Islande, jo vasarā parakstīja eksporta līgumu par 4,3 miljoniem eiro, kas ir 78 % no uzņēmuma pagājušā gada apgrozījuma. "PERUZA" aktīvi eksportē uz Fēru Salām, mēģina ielauzties Skandināvijā, īpaši Norvēģijā un Somijā. Šī gada eksporta rādītājus ietekmēs arī Krievijas sāktais karš Ukrainā. Tieši februārī bija sagatavots eksporta līgums parakstīšanai ar lielu Krievijas uzņēmumu par 2,5 miljoniem eiro, taču karš šo darījumu izjauca.`+
`<dd></dd><h3 class="post-subtitle">Katrs jaunais tirgus ir rūpīgi jāizanalizē</h3>`+
`<dd></dd>"Šobrīd mēs ļoti aktīvi kopjam savus eksporta tirgus ar visiem iespējamajiem instrumentiem un rīkiem, piemēram, attīstām digitālo mārketingu, izmantojot LIAA sniegtās atbalsta iespējas un līdzfinansējumu dažādām aktivitātēm un eksporta konsultāciju uzņēmuma "Gateway&Partners" pakalpojumus jaunu partneru piesaistei," stāsta R. Dlohi.`+
`<dd></dd>Viņš uzsver, ka pirms jauna eksporta tirgus apgūšanas ir jāizvērtē, kurš produkts kurā valstī varētu gūt panākumus. Uzņēmums balstās uz dažādos avotos pieejamu informāciju un savu iepriekšējo pieredzi un izpratni par tirgiem un izvirza uz pārdomātiem minējumiem balstītu hipotēzi, kura valsts varētu būt nākamais eksporta galamērķis. "PERUZA" analizē, kuri tirgi ir svarīgi un konkrētajā periodā aizsniedzami, un ar tiem arī strādā. "Pats svarīgākais faktors ir tas, vai konkrētajā tirgū ir klienti, kas ir gatavi maksāt," piebilst R. Dlohi. Piemēram, ja runa ir par lašu apstrādi, tad ir jāsāk Norvēģijā, bet pēc tam jādodas uz Čīli. Tāpat uzņēmums vērtē, kuros tirgos ir lielākais potenciāls paku loģistikai un procesu robotizācijai.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+roberts2+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Laiki un mārketings mainās</h3>`+
`<dd></dd>Lai gan uzņēmumam ļoti svarīga mārketinga daļa ir personīgais kontakts ar klientu, ko iegūst, piedaloties izstādēs, "PERUZA" aktīvi strādā pie digitālā mārketinga – satura publicēšanas internetā un meklēšanas rezultātu optimizācijas.`+
`<dd></dd><div class="quote-style">"Pamazām sajūtam ieguldītā darba augļus. Piemēram, esam sākuši saņemt pieprasījumus mājaslapā, kas agrāk nenotika vispār," teic R. Dlohi.</div>`+
`<dd></dd>Viņš uzsver, ka visu darbību kopums – dalība izstādēs, klātbūtne internetā un citu mārketinga kanālu izmantošana – atgādina klientam, ka ir tāds uzņēmums "PERUZA" ar lielisku produktu. "Šodienas "PERUZA" komanda strādā pilnīgi citādāk, nekā tas bija pirms 10 gadiem, un šāda attīstība ir nepieciešama un likumsakarīga uzņēmuma izaugsmei," secina R. Dlohi.`+
`<dd></dd><h3 class="post-subtitle">Valsts atbalsts palīdz samazināt izmaksas</h3>`+
`<dd></dd>Ar LIAA uzņēmums "PERUZA" ir sadarbojies vairākos projektos. Savulaik aģentūra administrēja jaunu produktu izstrādes projektus, ko uzņēmums izmantoja. "PERUZA" izveidoja vairākas jaunas iekārtu koncepcijas un prototipus zivju apstrādei. Daži produkti ir kļuvuši ļoti veiksmīgi, piemēram, ātrdarbīga mazo zivju ķidāšanas līnija, kas spēj apstrādāt līdz 1000 brētliņu vai reņģu vienā minūtē. Savukārt citiem produktiem pēc laba realizācijas starta atklājās būtiski ierobežojumi, un tāpēc to realizācija tika pārtraukta. R. Dlohi skaidro, ka no jauna izstrādātu iekārtu spējas un ierobežojumi bieži apstiprinās tikai darbā ar lielākiem apjomiem, ilgstoši strādājot. Tomēr arī neveiksmīgā pieredze ir palīdzējusi veidot citas, jau veiksmīgākas iekārtas. "LIAA sniegtais atbalsts bija vērtīgs, jo samazināja risku kļūdīties, savukārt tieši caur kļūdām notiek attīstība," akcentē uzņēmējs.`+
`<dd></dd>Tagad uzņēmums ar LIAA turpina sadarbību starptautiskās konkurētspējas veicināšanas projektā, piedaloties dažādās izstādēs un vizītēs. Viena no svarīgākajām un lielākajām zivrūpniecības izstādēm pasaulē ir "Seafood Expo Global/Seafood Processing Global" Barselonā, Spānijā. Tajā uzņēmums piedalās jau vairāk nekā 15 gadus.`+
`<dd></dd><div class="quote-style">"Dalība šādā izstādē izmaksā no 40 līdz 50 tūkstošiem eiro, taču ar LIAA līdzfinansējumu mēs savas izmaksas varam samazināt. Šoruden izmantojām iespēju piedalīties LIAA organizētajā Latvijas ekonomikas ministres Ilzes Indriksones vizītē Japānā. Aģentūra zināja, ka Japāna ir viens no mūsu eksporta galamērķiem, līdz ar to mēs laikus saņēmām informāciju par šādas vizītes norisi un pieteicāmies dalībai tajā," saka R. Dlohi.</div>`+
`<dd></dd>"PERUZA" sadarbojas arī ar vairākiem kompetences centriem, lai izstrādātu jaunus produktus. Piemēram, tā ir tapusi cidoniju apstrādes un kaltēšanas līnija sukāžu ražošanai, gulošu pacientu mājas aprūpes gulta, kuras funkcijas vada dators, un datorredzes un mākslīgā intelekta attīstības risinājumi atkritumu šķirošanai. "Kad mēs izstrādājam kaut ko jaunu, ir nepieciešams pārliecināties, ka tas strādā, un šajā ziņā Latvijā šobrīd nav labāka atbalsta par kompetences centriem. Savukārt projektos, kam nepieciešams liels finansējums, mēs sadarbojamies ar "Altum" kā finanšu avotu," saka R. Dlohi.`+
`<dd></dd>Viņš izceļ arī "Eksporta un inovācijas balvu", ko jau daudzus gadus organizē LIAA sadarbībā ar Ekonomikas ministriju, Latvijas Eksportētāju asociāciju "The Red Jackets" un "Altum". "Tādā veidā tiek cildināti tie, kas ir kaut ko sasnieguši un ar kuriem mēs varam lepoties gan vietējā, gan starptautiskā mērogā. Vienmēr ir patīkami un uzmundrinoši, ka kāds uzsit pa plecu un uzteic par labi padarītu darbu," saka R. Dlohi. Pērn "PERUZA" ieguva 2. vietu konkursa kategorijā "Uz zināšanām balstīta inovācija".`+
`<dd></dd><h3 class="post-subtitle">Līdzīgi kā futbolā</h3>`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+roberts3+`"></div>`+
`<dd></dd>R. Dlohi uzskata, ka Latvijas eksportspējas attīstība norit līdzīgi kā futbola pasaulē, kur ir sistēma pasaules čempionu audzināšanai: viss sākas ar pagalmu komandām, kam seko skolu komandas, jaunatnes sporta skolu komandas, dažādu līmeņu sacensības un čempionu līga. "Jo augstāk kāpj sportists, jo mērķtiecīgāka un specifiskāka palīdzība, instrumenti un rīki tiek doti. Sākumā dod iespēju spēlēt uz laba laukuma, bet vēlāk nodrošina trenerus, masāžas, diētu, treniņnometnes. Tā pamazām tiek atlasīti un uz priekšu bīdīti labākie kandidāti. Šādā sistēmā visi saprot, kas un kā jādara, lai attīstītos un kāptu uz augšu, līdz var kļūt par pasaules čempioniem," viņš saka. R. Dlohi uzskata, ka Latvijas uzņēmumiem dažādās brieduma pakāpēs ir iespēja saņemt sev atbilstošus un nepieciešamus instrumentus un rīkus. Valsts institūcijas dod iespēju tiekties uz čempiona titulu katram, kurš to vēlas un ir gatavs smagi strādāt. "Es neuzskatu, ka birokrātija ir pārāk liela. Tas viss ir vajadzīgs, lai līdzekļi netiktu izšķērdēti," piebilst uzņēmējs.`+

	support_by_liaa,


	review_5_name: "Toms Auškāps",
	review_5_company:"AS “BALTICOVO”",
	review_5_website: "http://www.balticovo.lv",
	review_5_designation: "AS “BALTICOVO” (www.balticovo.lv), komunikācijas un attīstības direktors, valdes loceklis",
	review_5_excerpt: "BALTICOVO ir lielākais olu un olu produktu ražotājs Ziemeļeiropā. Uzņēmumā tiek nodarbināti vairāk kā 300 darbinieki, kas diendienā rūpējas par mājputnu audzēšanu un olu ražošanu, olu šķirošanu, iepakošanu un dažādu olu produktu ražošanu.",
	review_5_title:"Latvijas olu produktus eksportē pat uz Jaunzēlandi",
	review_5_text:
	
`<dd></dd>Ziemeļeiropas lielākā olu un olu produktu ražotāja AS "Balticovo" vistas gadā izdēj teju 700 miljonus olu, no kurām 70 % tiek eksportēti uz vairāk nekā 20 pasaules valstīm, tostarp Jaunzēlandi.`+
`<dd></dd>2021. gadā "Balticovo" strādāja ar 62,4 miljonu eiro apgrozījumu. Runājot par uzņēmuma nākotnes plāniem, "Balticovo" komunikācijas un attīstības direktors un valdes loceklis Toms Auškāps norāda, ka uzņēmums realizē produktus ES iekšējā tirgū un tā konkurētspējas priekšrocības ir atkarīgas no tā, cik veiksmīgi uzņēmums spēj izmantot Eiropas Savienības stūrakmeņa, proti, brīvas preču aprites, ekskluzīvās iespējas vienotajā tirgū.`+
`<dd></dd><div class="quote-style">"Ja mēs attīstīsimies un augsim, tas būs uz eksporta rēķina," pārliecināti apgalvo T. Auškāps.</div>`+
`<dd></dd>Viņš uzskata, ka ikvienam uzņēmējam, kurš vēlas eksportēt, ir nepieciešams saprast terminu "eksportspēja", kā arī to, vai uzņēmumam ir laiks, resursi un kvalitatīvs produkts ārvalstu tirgiem. "Ļoti svarīgi izpildīt šo mājasdarbu, rūpīgi pārdomājot un analizējot. Eksports ir mērķtiecīgs un apzināts darbs, kas iet roku rokā ar tirgus izpēti. Pirms praksē izmēģināt eksportu, noteikti ir labāk ieguldīt vairāk laika mērķa tirgus izpētē. Tas ļaus izvairīties no nevajadzīgas "apdedzināšanās", pasargāt investīcijas un neiesaldēt naudu. Rūpīgi jāsagatavojas, lai nekļūtu par upuri vai izsmieklu," brīdina T. Auškāps.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+toms1+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Lielākais eksporta tirgus – Ziemeļvalstis</h3>`+
`<dd></dd>"Balticovo" stāsts sākās pirms 50 gadiem. Pirmie soļi ārzemju tirgū tika sperti tuvākajās kaimiņvalstīs, taču tāds eksports, kādu zinām šodien, attīstījās tikai pēc 2010. gada. "Tas bija mūsu lielais attīstības laiks, kad tika izveidoti olu pārstrādes cehi šķidro olu un vārīto olu produktu ražošanai. Ar šo produktu virzienu attīstību sākās realizācija ārpus mājas tirgus Latvijā, startējot jaunos tirgos Zviedrijā, Dānijā un Čehijā," stāsta T. Auškāps. Šie tirgi ir arī uzņēma šī brīža galvenais eksporta galamērķis. Tāpat "Balticovo" eksportē uz Lietuvu un Igauniju, ko uztver kā mājas tirgu, kā arī Poliju, Skandināviju, Vāciju u. c. valstīm.`+
`<dd></dd><div class="quote-style">"Mēs esam pasaules līmeņa spēlētājs. Mēs nevaram atpalikt no ārvalstu konkurentiem. Ja citās valstīs atbalsta programmas ir bagātīgas, ārzemju spēlētāji mūs izkonkurē.</div>`+
`<dd></dd>Piemēram, Vācijas un Čehijas tirgū mēs cīnāmies ar poļiem. Tiklīdz Polijas uzņēmumiem ir pieejamas kādas subsīdijas vai PVN atlaides, mēs to uzreiz jūtam. Tāpēc būtu ļoti vērtīgi, ja Latvijā kāda organizācija uzraudzītu atbalsta programmu pieejamību citās valstīs. Tas palīdzētu gūt jaunāko informāciju un neatpalikt no ārvalstu uzņēmumiem, lai turpmāk ar tiem veiksmīgi konkurētu starptautiskajā tirgū. Tas ir ļoti svarīgi tieši lielajiem Latvijas eksporta uzņēmumiem, jo tie visvairāk izjūt, ja kādā tirgū rodas novirzes," norāda T. Auškāps.`+
`<dd></dd>Tāpat viņš izceļ to, ka citas valstis ļoti rūpējas par saviem uzņēmumiem, nodrošinot ērtus apstākļus to pastāvēšanai un darbībai valsts teritorijā. Tas palīdz aizsargāt tirgu no "iebraucējiem". "Ārpus mājas tirgus "Balticovo" konkurenti gandrīz vienmēr vēlas, lai mēs attiecīgos tirgus pametam. Vienlaikus mūsu klienti – mazumtirdzniecības ķēdes, vairumtirgotāji un izplatītāji – no mums sagaida zemāku cenu, identisku apkalpošanas līmeni un augstāku kvalitāti nekā vietējiem ražotājiem. Tieši tik skarbs ir mūsu produkcijas realizācijas konteksts ES vienotajā tirgū. Tāpēc svarīgi atrast spēcīgus partnerus, kas ir gatavi sadarboties ilgtermiņā un stāties pretī katras valsts maigā protekcionisma radītajām blaknēm," atklāj T. Auškāps.`+
`<dd></dd><h3 class="post-subtitle">Jauna tirgus iekarošana var prasīt pat četrus gadus</h3>`+
`<dd></dd>"Balticovo" eksportē savu produkciju uz 20 valstīm. Jauna eksporta tirgus apgūšana ir salīdzinoši lēns un ilgs process, kas no sākotnējās ieceres līdz pirmo produktu eksportam var prasīt trīs vai četrus gadus vai pat ilgāk. Tieši šā ilgā un sarežģītā procesa dēļ "Balticovo" lēmums par jaunu eksporta tirgu attīstīšanu balstās uz rūpīgu tirgus analīzi. Tiek ņemta vērā gan iepriekš iegūta pieredze par tirgiem, gan detalizēta tirgus izpēte, kas pamatojas uz informāciju par tirgus pašpietiekamību un par to, cik liels ir olu un olu produktu patēriņa apjoms attiecīgajā valstī. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+toms2+`"></div>`+
`<dd></dd>"Eiropā jaunu tirgu apguve, protams, prasa īsāku laiku, bet ārpus Eiropas robežām tas ir sarežģītāks un ilgstošāks process, kas iekļauj darbu ar mērķa valsts pārtikas un veterinārajiem dienestiem, pārtikas sertifikātu saskaņošanu, revīziju, prasību salāgošanu, u. tml.," stāsta T. Auškāps.  Piemēram, plānojot eksportu uz Honkongu, ražotnes revīzija Latvijā notika vien četrus gadus pēc pirmajiem soļiem šī tirgus attīstīšanai. Turpretī darba uzsākšana Jaunzēlandes tirgū prasīja "tikai" 12 mēnešus. Jaunzēlande stingri kontrolē dažādu preču importu, pamatojoties uz tādiem kritērijiem kā "kopienas aizsardzība", Zemes ozona slāņa aizsardzība, toksisko vielu kontrole un patērētāju aizsardzība. Tas ir ļoti plašs un leģitīms prasību kopums, lai aizsargātu Jaunzēlandes tirgu un kontrolētu iespējamo apdraudējumu vietējai ekonomikai. Šo iemeslu dēļ Jaunzēlandē tiek ievērots stingrs importēto produktu karantīnas režīms, lai aizsargātu lauksaimniecības un mežsaimniecības nozari no kaitēkļu invāzijas un slimībām.`+
`<dd></dd><div class="quote-style">"Mūsu pieredze Jaunzēlandes tirgū bija veiksmīga, lielākoties pateicoties tam, ka atradām kompetentu, pieredzējušu un galvenais motivētu importētāju, kas varēja veikt dokumentācijas saskaņošanu starp Jaunzēlandes atbildīgo iestādi un Latvijas Pārtikas un veterināro dienestu," pieredzē dalās T. Auškāps.</div>`+
`<dd></dd>Viņš uzskata, ka Latvijai ir jāturpina likt lielāks uzsvars uz eksportu ārpus Eiropas Savienības un investīcijām trešo valstu tirgos, proti, Austrālijā, Jaunzēlandē, Japānā, Korejā un Dienvidaustrumāzijas reģionā. Lai gan darba uzsākšana šādos ģeogrāfiski attālos tirgos prasa daudz laika un resursu, tas paver arī plašākas iespējas izaugsmei. Pirmās konsultācijas un citu nepieciešamo atbalstu darba uzsākšanai šādu tirgos var sniegt LIAA ārējo ekonomisko pārstāvniecību vadītāji. Ārpus Eiropas šādas pārstāvniecības ir ASV, Kanādā, Japāņa, Apvienotajos Arābu Emirātos, Dienvidkorejā un Ķīnā, bet nākošā gada sākumā darbu sāks arī pārstāvniecība Austrālijā.`+
`<dd></dd><h3 class="post-subtitle">Jāizmanto visas atbalsta iespējas</h3>`+
`<dd></dd>T. Auškāps aicina izmantot visas piedāvātās atbalsta iespējas. Tas jo īpaši attiecas uz maziem un vidējiem uzņēmumiem. "Šīs iespējas ir jāizmanto lietderīgi. Ir jākonsultējas un jāprasa padoms pieredzējušiem eksportētājiem. Vēlams atrast mentoru no viņu vidus. Nevajag kautrēties uzdot jautājumus, bet ir jāpārliecinās, ka šie jautājumi ir pareizi. Nezinu nevienu lielu uzņēmumu, kurš nebūtu gatavs palīdzēt ar padomu vai dalīties pieredzē," viņš iedrošina.`+
`<dd></dd>"Balticovo" valdes loceklis T. Auškāps uzsver, ka ieguldījumiem eksportā nav garantijas attiecībā uz paredzamo atdevi un sekmēm nākotnē.`+
`<dd></dd><div class="quote-style">"Eksports nav pasaka, jo ne vienmēr lielais darbs nes rezultātus. Tam ir nepieciešama liela pacietība. Jo tālāk vēlies kaut ko aizvest, jo tas ir sarežģītāk. Jo vairāk faktoru ietekmē procesu, jo vairāk nezināmo.</div>`+
`<dd></dd>Arī attālums visu sarežģī, jo tad uzņēmums īsti nespēj personīgi kontrolēt situāciju.  Tieši tāpēc ir nepieciešams izmantot gan Latvijas Investīciju un attīstības aģentūras, gan citu organizāciju piedāvātos rīkus un atbalsta mehānismus eksporta attīstīšanai – tiešās kontaktbiržas, izstādes, organizētās tikšanās. Jebkurš atbalsts – ceļa izdevumi, izstādes vietu maksa, viesnīcu līdzfinansējums – ir ļoti svarīgs," viņš norāda.`+
`<dd></dd>Kopš "Balticovo" ir kļuvis par lielo komersantu, atbalsta iespējas ir samazinājušās. Tomēr T. Auškāps uzsver, ka līdz 2016. gadam neatsverams partneris uzņēmuma eksporta veicināšanā bija Latvijas Investīciju un attīstības aģentūra. Tā sniedza nozīmīgu finansiālo atbalstu dalībai dažādās nozares izstādēs ārzemēs. "Izstādes ir viena no vissvarīgākajām platformām, lai uzturētu attiecības ar esošajiem klientiem, klātienē satiktos ar tādu uzņēmumu pārstāvjiem, kas vēl nav mūsu klienti, bet ar ko notiek pārrunas, kā arī parādītu citiem savus jaunumus un iepazītos ar konkurentu jaunumiem un piedāvājumu. Izstādes ir ļoti laba vieta, kur satikties ar ārvalstu klientiem un saprast, kādā virzienā attīstās tirgus. Katru otro gadu mēs piedalāmies SIAL izstādē Parīzē vai Anuga izstādē Ķelnē, kā arī Gulfood Dubaijā," stāsta T. Auškāps.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+toms3+`"></div>`+



	support_by_liaa,

	
	review_6_name: "Jānis Kūlbārdis",
	review_6_company:"“AZERON”",
	review_6_website: "http://www.azeron.eu",
	review_6_designation: "“AZERON” (www.azeron.eu), līdzdibinātājs",
	review_6_excerpt: "AZERON ir tehnoloģiju uzņēmums, kas nodarbojas ar pasaulē inovatīvu un unikālu videospēļu kontrolieru ražošanu. Šāds kontrolieris ir jebkura videospēļu spēlētāja sapnis, jo ievērojami atvieglo video spēles spēlēšanu, aizstājot parastās tastatūras izmantošanu.",
	review_6_title:"Gandrīz 100 % eksports bez neviena ārzemju komandējuma",
	review_6_text:
`<dd></dd>Videospēļu kontrolieru ražotāja "Azeron" bizness, izņemot ierīču izgatavošanu, ir pilnībā digitāls – klienti produktu iepazīst internetā un tur arī to pasūta. Šī iemesla dēļ tikai dažus gadus vecais uzņēmums, kam gandrīz 100 % no 2,5 miljonu eiro apgrozījuma veido eksports, nekad nav bijis ārzemju komandējumos, lai tiktos ar potenciālajiem klientiem. `+
`<dd></dd><div class="quote-style">""Azeron" veiksmes atslēga ir izcils produkts un vēl izcilāka komanda. Ja kādas no šīm lietām trūktu, visticamāk, mēs nebūtu tur, kur esam tagad. Tāpat ir svarīgi nebaidīties darīt un meklēt to, kas ir vajadzīgs," saka "Azeron" līdzdibinātājs Jānis Kūlbārdis.</div> `+
`<dd></dd><h3 class="post-subtitle">Palīdz labāk spēlēt datorspēles</h3> `+
`<dd></dd>"Azeron" ražo videospēļu kontrolierus, kas atvieglo videospēļu spēlēšanu, aizstājot parastās tastatūras izmantošanu, – lai gan produkts pēc izskata līdzinās datorpelei, tā ir tastatūra. Darbs pie prototipa izstrādes aizsākās 2012. gadā lauku mājā Ventspils novadā. Pamazām idejas autoram Imantam Daiginam pievienojās pārējā komanda – 3D modelētāja, programmētājs, ražošanas un biznesa procesu vadītāji –, un no idejas izauga unikāls un vizuāli pievilcīgs produkts. "Lai par to paziņotu plašākai pasaulei, uzrunājām vairākus influencerus, līdz beidzot atsaucās spēlētāju vidū populārais "YouTube" satura veidotājs "Beaks", kam tolaik bija pusmiljons sekotāju. Saņēmis kontroliera prototipu, "Beaks" savā "YouTube" kontā publicēja tā apskatu. Mēneša laikā šī video skatījumu skaits sasniedza četrus miljonus, un mēs sākām saņemt simtiem vēstuļu dienā no cilvēkiem, kas būtu gatavi mūsu produktu nopirkt. Tā dibinājām uzņēmumu un sākām attīstīt ražošanu, lai apmierinātu augošo pieprasījumu," saka J. Kūlbārdis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janis1+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Inkubators palīdz attīstīties lētāk</h3> `+
`<dd></dd>Vēl pirms "Azeron" dibināšanas viņi iesniedza dokumentus dalībai LIAA Ventspils biznesa inkubatora pirmsinkubācijas programmā. Pēc tam, kad uzņēmums jau bija nodibināts, tas iestājās arī inkubatorā, kur darbojamies arī tagad. "Esam ļoti apmierināti un pateicīgi par iespēju attīstīt uzņēmumu inkubatorā. Iespējams, mēs būtu izveidojuši uzņēmumu, arī nepiedaloties inkubatorā, bet atbalsts, ko esam līdz šim saņēmuši, ir ļāvis attīstīties daudz lētāk un efektīvāk," saka J. Kūlbārdis. `+
`<dd></dd><div class="quote-style">Inkubatora dalībniekiem ir pieejamas mācības un konsultācijas, tāpat var saņemt grantus un līdzfinansējumu dažādiem mērķiem, piemēram, grāmatvedībai, telpu īrei, mārketingam, u. tml. Viņš stāsta, ka inkubatora vide ir ļoti draudzīga, pretimnākoša un elastīga.</div>  `+
`<dd></dd>Uzņēmējs atceras gadījumu, kad zvanīja inkubatora vadītājai septiņos vakarā un prasīja, vai viņa nepazīst kādu, kas var palīdzēt kādā konkrētā jautājumā. Viņa LIAA tīklā ātri vien sagādāja nepieciešamos kontaktus. "Ir grūti spriest, kādā attīstības stadijā mēs šobrīd būtu, ja nebūtu inkubatora atbalsta, taču viens ir skaidrs – ar pašu spēkiem mēs nebūtu spējuši investēt attīstībā tik daudz. Katrs eiro, ko esam investējuši, ir devis atpakaļ vairākkārtīgi," akcentē J. Kūlbārdis. `+
`<dd></dd>Viņa skatījumā vērtīgs uzlabojums inkubatoru programmā būtu individuāla pieeja katram dalībniekam un viņa vajadzībām. Piemēram, "Azeron" kā inkubatora biedrs var saņemt atbalstu IT pakalpojumiem, grāmatvedībai, mārketinga pakalpojumiem, konsultācijām un citiem pakalpojumiem, ja tos saņemam kā ārpakalpojumu. Taču, ja kādu no šīm funkcijām uzņēmums izvēlas veikt iekšēji, šīm izmaksām nav iespējams saņemt atbalstu. "To var interpretēt kā atbalsta neesamību štata darbinieku pieņemšanai un jaunu darbavietu veidošanai. Līdz šim grantu programmā grāmatvedības pakalpojumus mums sniedza ārpakalpojuma uzņēmums. Tikko esam pārņēmuši grāmatvedības funkciju paši, un, veicot pirmos aprēķinus, secinājām, ka mūsu pieskaitāmie izdevumi ir samazinājušies par 50 %, kas ir līdzvērtīgi LIAA grantu atbalstam. Šobrīd strādājam pie grāmatvedības sistēmas automatizācijas, un, kad tā būs sakārtota, arī darba efektivitāte ievērojami uzlabosies. Beigās sanāk, ka izdevīgāk ir darīt pašam bez atbalsta," teic J. Kūlbārdis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janis2+`"></div>`+
`<dd></dd><h3 class="post-subtitle">60 % eksporta veido ASV</h3> `+
`<dd></dd>"Azeron" ražošana un administrācija notiek Ventspilī, bet tirdzniecība – savā tīmekļvietnē un "Amazon" platformā. Kontrolieri var iegādāties jebkurš interesents jebkurā pasaules vietā, kur "FedEx" vai DHL nodrošina piegādi. "Gandrīz 100 % no saražotās produkcijas eksportējam, taču neviens no komandas nekad nav bijis komandējumā ārpus Latvijas," atklāj J. Kūlbārdis. `+
`<dd></dd>Uzņēmuma svarīgākie eksporta galamērķi ir ASV, Kanāda, Lielbritānija, Francija, Vācija, Austrālija, Japāna. Latvijas tirgū paliek tikai 0,1 % no saražotās produkcijas. Sākotnēji lielākais tirgus bija ASV, un arī šodien tas saglabā savas pozīcijas, veidojot 60 % no apgrozījuma. `+
`<dd></dd>Ievērojamu kāpumu uzņēmums novēro Japānas tirgū – gada laikā tas ir audzis no aptuveni 2 līdz 3 % līdz 10 % no apgrozījuma. J. Kūlbārdim grūti nosaukt konkrētus iemeslus – visticamāk, tas ir vairāku apstākļu kopums. "Azeron" sadarbojas ar lielāko mehānisko slēdžu ražotāju pasaulē "Omron", kas atrodas Japānā. Šis ražotājs par Latvijas uzņēmumu rakstīja un stāstīja savos kanālos. Tāpat arī LIAA organizētajās Latvijas dienās Tokijā bija informācija par "Azeron", un no klientiem uzņēmums saņēma labas atsauksmes. "Pārdošanas apjomu pieaugumu redzējām arī tad, kad parādījāmies Japānas "Amazon" vietnē. Cilvēki par mums aktīvāk sāka runāt, rakstīt un attiecīgi – pirkt kontrolierus. Kopumā mūsu klienti par mums sāka izrādīt interesi ļoti organiski un līdz šim nav pārstājuši to darīt," stāsta J. Kūlbārdis. `+
`<dd></dd><div class="quote-style">Uzņēmumam eksporta tirgos nav ne vietējo partneru, ne vairumtirgotāju vai mazumtirgotāju. "Tas nav nepieciešams. Mūsu mārketinga nodaļa no pašiem pirmsākumiem ļoti mērķtiecīgi strādā pie produkta reklamēšanas un klientu piesaistes interneta vidē. Mārketingam atvēlam pieklājīgu budžetu – strādājam ar digitālo mārketingu, sadarbojamies ar influenceriem, un mums ir arī savs "Discord" serveris videospēļu spēlētāju vidē, kur tūkstošiem klientu diskutē savā starpā," saka J. Kūlbārdis.</div> `+
`<dd></dd>Arī dalība izstādēs uzņēmumam nav nepieciešama. "Pirmkārt, mūsu klienti ir sasniedzami interneta vidē. Otrkārt, ieguldot tādu pašu budžetu digitālajā mārketingā, mēs varam sasniegt daudz labākus pārdošanas rezultātus," uzskata uzņēmējs. `+
`<dd></dd><h3 class="post-subtitle">Materiālu piegāde kļūst ilgāka un dārgāka</h3> `+
`<dd></dd>Daudzus uzņēmumus pašlaik ir skāris Krievijas sāktais karš Ukrainā. J. Kūlbārdis teic, ka "Azeron" pārdošanas rezultātus saistībā ar tirgiem, kas ir aizvērušies, šī situācija ietekmē nebūtiski un karš nav tieši iespaidojis pārdošanu. Taču tas atsaucas uz piegādēm. Liela daļa "Azeron" izejmateriālu tiek sūtīti no Āzijas un tiek piegādāti ar aviopārvadājumiem. Šobrīd lidojums, piemēram, no Honkongas uz Šarla de Gola lidostu Francijā ir piecas stundas ilgāks nekā agrāk. "Tas nozīmē, ka, pirmkārt, piegādes aizņem vairāk laika, otrkārt, tās ir dārgākas – lidojums ir garāks, un arī par degvielu jāmaksā vairāk. Piegādes cenas pieauga jau Covid-19 pandēmijas sākumā saistībā ar pastiprinātajiem ierobežojumiem un prasībām dažādās valstīs, taču pēc kara sākuma cena ir pieaugusi vēl vairāk, un to jūt arī gala patērētājs," saka J. Kūlbārdis. `+
`<dd></dd>Šis ir viens no apstākļiem, kāpēc viņš iesaka rūpīgi izvēlēties sadarbības partnerus un meklēt tādus, kas grib augt kopā, nevis tādus, kas tikai izraksta rēķinu. Piemēram, "FedEx" Latvijas komanda jau no paša sākuma pamanīja "Azeron" potenciālu un par saviem pakalpojumiem piedāvāja tādu cenu, kādu prasa lielajiem klientiem. "Tāpat "FedEx" bez maksas mūs konsultēja par dažādiem tirgiem, nodrošināja noderīgus kontaktus un pat palīdzēja attīstīt drošu iepakojumu, kamēr citi pārvadātāji pat nevēlējās ar mums runāt. Tādus partnerus nav viegli atrast, taču ir ļoti svarīgi mēģināt meklēt," iesaka J. Kūlbārdis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janis3+`"></div>`+

	support_by_liaa,

	review_7_name: "Kristīne Grapmane",
	review_7_company:"SIA „STENDERS”",
	review_7_website: "http://www.stenders-cosmetics.lv",
	review_7_designation: "SIA „STENDERS”  (www.stenders-cosmetics.lv), izpilddirektore",
	review_7_excerpt: `Pēdējie gadi spilgti ilustrē nepieciešamību attīstīt eksportu dažādos ģeogrāfiskos virzienos. Tāpēc vannas rituālu produktu ražotājs "Stenders" mērķtiecīgi stiprina esošos eksporta tirgus un apgūst jaunus tirgus Eiropā, ASV, Kanādā un pat Austrālijā.`,
	review_7_title:"„Stendera” vannas rituālus bauda pat Austrālijā",
	review_7_text:

`<dd></dd>Pēdējie gadi spilgti ilustrē nepieciešamību attīstīt eksportu dažādos ģeogrāfiskos virzienos. Tāpēc vannas rituālu produktu ražotājs "Stenders" mērķtiecīgi stiprina esošos eksporta tirgus un apgūst jaunus tirgus Eiropā, ASV, Kanādā un pat Austrālijā. `+
`<dd></dd><div class="quote-style">"Veiksmīga eksporta pamatā ir savas vērtības un produkta unikalitātes apzināšanās. Kad tas ir izdarīts, atliek drosmīgi iet uz priekšu, jo katram labam produktam ir sava niša un tirgus. Latvija ir ļoti labs tirgus, kur testēt savu produktu. Taču, lai attīstītos, ir jāeksportē. Un noteikti ir vērts izmantot visus iespējamos eksporta atbalsta mehānismus – gudri un pārdomāti. Izaugsme ir ārpusē," uzsver "Stendera" izpilddirektore Kristīne Grapmane.</div>`+
`<dd></dd><h3 class="post-subtitle">Pašu spēkiem vai ar franšīzes partneriem</h3>`+
`<dd></dd>Sadarbība ar "Stenderu" eksporta tirgos var tikt ierosināta no divām pusēm – uzņēmums pats strādā pie kāda tirgus attīstīšanas, vai arī partneris no konkrētas valsts to uzrunā. Ierasta prakse ieiešanai jaunā tirgū ir rūpīga tirgus izpēte, analizējot ekonomisko situāciju valstī, produktu tirgus nišu un konkurentus. "Ir konkurenti, kuriem sekojam līdzi ciešāk, un, balstoties uz viņu aktivitātēm, skatāmies, kuros tirgos ir vērts startēt," atklāj K. Grapmane. `+
`<dd></dd><div class="quote-style">Tāpat ir svarīgi izpētīt arī pārdošanas kanālu uzbūvi – vai labāk iet tirgū ar vairumtirdzniecības biznesa modeli, vai sākt ar franšīzi. "Daži tirgi kosmētikas nozarē ir noteicošāki, piemēram, Vācija. Ja veiksmīgi attīsties Vācijā, tad startēt Francijā, Šveicē un citur, iespējams, ir vieglāk," viņa stāsta.</div>`+
`<dd></dd>Pie tirgus pētījumiem un partneru piesaistes strādā gan komanda uzņēmuma iekšienē, gan arī ārpakalpojumu uzņēmumi, piemēram, eksporta konsultāciju uzņēmums "Gateway&Partners". "Ar partneru piesaisti iegūstam papildu resursus, jaudu un skatījumu," saka K. Grapmane. `+
`<dd></dd>Lai attīstītu eksportu, "Stenders" piedalās arī starptautiskās izstādēs. Šogad uzņēmums ir pieņēmis lēmumu piedalīties vienā no lielākajām kosmētikas izstādēm pasaulē "Cosmoprof 2023", kas norisinās Boloņā, Itālijā. `+
`<dd></dd><h3 class="post-subtitle">Eksports veido 70 % no apgrozījuma</h3>`+
`<dd></dd>Eksports veido 70 % no "Stendera" apgrozījuma. "Mūsu lielākais eksporta tirgus joprojām ir Ķīna, taču saistībā ar striktajiem Covid-19 vīrusa piesardzības pasākumiem un ierobežojumiem šī tirgus apjoms šogad ir krities. Jau kopš vīrusa izplatības sākuma Ķīnā ir spēkā nulles gadījumu politika – netiek akceptēts neviens saslimšanas gadījums, pilsētas joprojām periodiski tiek slēgtas, un ir strikti ierobežojumi gan tirdzniecībai, gan importam. Šie faktori būtiski pastiprina tirgus situācijas neprognozējamību," skaidro K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristine1+`"></div>`+
`<dd></dd>"Stenders" ir beidzis savu sadarbību ar Krievijas partneriem saistībā ar Krievijas sākto karu Ukrainā. Lai mazinātu ģeopolitisko risku ietekmi, šī gada fokuss ir bijis uz attīstību citos tirgos. Tālajos Austrumos veiksmīgā sadarbība ar Jordānijas franšīzes partneri rezultējas jaunos veikalos un vairumtirdzniecības kanālos, tāpat eksporta tirgiem ir pievienojusies Irāka un Katara. Šogad "Stenders" ir atvēris pirmo veikalu Austrālijā. Eiropā uzņēmums šobrīd ir agrīnā sākuma stadijā – ir vērojams progress franšīzes partnera darbībā Nīderlandē, un darbība tiek attīstīta arī Vācijā. "Jauni tirgi vienmēr saistās ar lielām investīcijām, izpēti, pacietību un risku. Lai straujāk virzītos uz mērķa sasniegšanu, svarīgi definēt prioritātes un fokusu konkrētā tirgū," saka K. Grapmane. `+
`<dd></dd><h3 class="post-subtitle">Izaicinājums – piesaistīt un attīstīt darbiniekus</h3>`+
`<dd></dd>Nākamais lielākais izaicinājums aiz ģeogrāfiskajiem un politiskajiem riskiem ir cilvēkresursi. "Lai mēs varētu veiksmīgi eksportēt, darbiniekiem ir jābūt izglītotiem, jāspēj attīstīties un sekot līdz pārmaiņām, jaunākajām tendencēm. Iegūt un noturēt labus speciālistus ir izaicinoši," uzsver K. Grapmane. `+
`<dd></dd>Lai vēl vairāk veicinātu darbinieku zināšanu apguvi un attīstību, šogad uzņēmums iekšēji ir pārskatījis cilvēkresursu attīstības stratēģiju "Stendera" ilgtspējības un labbūtības programmā. Pašreiz uzņēmums izmanto LIAA Mini MBA mācību programmu "Inovāciju vadība" un izvērtē arī citas iespējas, lai organizāciju attīstītu pēc "agile" principiem. "Mūsu darbinieku attīstība ir svarīgs kritērijs, tāpēc turpināsim izmantot pieejamo atbalstu cilvēkresursu attīstībai," piebilst K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristine2+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Atbalsts ir nepieciešams arī lielajiem uzņēmumiem</h3>`+
`<dd></dd>Mazajiem un vidējiem uzņēmumiem ir pieejams plašs atbalsta instrumentu klāsts. Turpretī lielajiem ražotājiem, tostarp "Stenderam", kam ir vairāk nekā 250 darbinieku, atbalsta iespējas ir ļoti ierobežotas. "Jauni tirgi vienmēr saistās ar lielām investīcijām, izpēti, pacietību un risku. Ja būtu pieejami rīki eksporta veicināšanai no valsts puses, to varētu attīstīt ar daudz lielāku jaudu. Šobrīd eksporta attīstība atstāta uzņēmuma paša ziņā un notiek atbilstoši iespējām. Tāpēc mēs aktīvi iestājamies par to, lai arī lielajiem uzņēmumiem ir pieejams lielāks atbalsts un plašāks rīku klāsts eksporta veicināšanai," saka K. Grapmane. `+
`<dd></dd>Runājot par atbalstu no valsts puses, viņa norāda arī uz birokrātisko procesu, piemēram, LIAA mācību dokumentācijas noformēšanai. "Mēs apzināmies, ka saistībā ar mūsu uzņēmuma struktūru birokrātiskais ceļš ir garāks un sarežģītāks, un tas noteikti ir nepieciešams, tomēr būtu vērtīgi iziet to vienreiz, lai nākamajos projektos process virzītos operatīvāk un skaidrāk. Tāpēc Latvijas Eksportētāju asociācijas sāktais darbs pie "Zaļā koridora" regulējuma, kas paredz ātrāku procesu virzību eksportētājiem svarīgos jautājumos, ir tik nozīmīgs," papildina K. Grapmane. `+
`<dd></dd>Tomēr viņa norāda, ka kopumā gadu gaitā uzņēmuma sadarbība ar valsts pārvaldes institūcijām uzlabojas. "Atsaucība no darbiniekiem ir lielāka nekā agrāk, un attieksme ir pretimnākošāka. LIAA pārstāvji iedziļinās problēmās, palīdz to risināšanā, komentē, kas būtu nepieciešams. Es zinu, ka man ir visi nepieciešamie kontakti un es vienmēr varu zvanīt, un man palīdzēs. Es novērtēju LIAA palīdzību – konsultācijas, skaidrojumus un situācijas izvērtējumus," teic K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristine3+`"></div>`+

	support_by_liaa,

	review_8_name: "Jānis Ošlejs",
	review_8_company:"SIA „Primekss”",
	review_8_website: "http://www.primekss.com",
	review_8_designation: "SIA „Primekss” (www.primekss.com), izpilddirektors",
	review_8_excerpt: `Eksporta veiksmes atslēga ir attīstīt to sava biznesa nišu, kurā uzņēmuma produktiem ir konkurences priekšrocības, uzskata industriālo betona grīdu ierīkošanas uzņēmuma "Primekss" vadītājs Jānis Ošlejs. Tieši šādu ceļu iet "Primekss", kura 2022. gada apgrozījums būs 90 miljoni eiro, no kā eksports veidos 95 %.`,
	review_8_title:"Eksportā ir svarīgi atrast savu nišu",
	review_8_text:

`<dd></dd>Eksporta veiksmes atslēga ir attīstīt to sava biznesa nišu, kurā uzņēmuma produktiem ir konkurences priekšrocības, uzskata industriālo betona grīdu ierīkošanas uzņēmuma "Primekss" vadītājs Jānis Ošlejs. Tieši šādu ceļu iet "Primekss", kura 2022. gada apgrozījums būs 90 miljoni eiro, no kā eksports veidos 95 %.`+
`<dd></dd><div class="quote-style">No savas pieredzes J. Ošlejs iesaka rūpīgi attīstīt produktus tādiem klientiem, kuriem šis piedāvājums ir visinteresantākais, – tieši viņi būs gatavi pirkt vairāk vai maksāt dārgāk.</div>`+
`<dd></dd>Tas, savukārt, paver plašākas attīstības iespējas uzņēmumam. Tāpēc svarīga ir spēja koncentrēties un "neizšķīst" pārāk daudzos eksporta tirgos ar pārāk daudziem klientiem.`+
`<dd></dd><h3 class="post-subtitle">No Skandināvijas līdz Indijai </h3>`+
`<dd></dd>"Mēs diezgan ātri sapratām, ka Latvijas tirgus ir neliels un izaugsmes iespējas ir ierobežotas, tāpēc jau 2002. gadā pievērsāmies eksportam. Sākām ar ģeogrāfiski tuvākajām valstīm – Lietuvu un Igauniju," stāsta J. Ošlejs. Vēlāk, Latvijai iestājoties Eiropas Savienībā un paveroties darba iespējām vienotajā Eiropas tirgū, uzņēmums sāka eksportēt uz Zviedriju. Tai pakāpeniski sekoja pārējās Skandināvijas un citas valstis. Uzņēmuma šī brīža lielākais eksporta tirgus ir Skandināvija, bet tas eksportē arī uz Āziju, Indiju, Izraēlu, citām Eiropas valstīm, tai skaitā Apvienoto Karalisti. Nozīmīgs eksporta galamērķis ir ASV un pat Dienvidāfrika. Pirms Krievijas iebrukšanas Ukrainā uzņēmums strādāja arī Krievijā un Baltkrievijā – tagad šie tirgi ir aizvērti.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janisO1+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Seko saviem klientiem</h3>`+
`<dd></dd>Lai attīstītu eksportu jaunā tirgū, "Primekss" pirmām kārtām analizē sava produkta konkurētspēju. Protams, visērtāk ir sākt ar tuvākajiem tirgiem, kur ir viegli un ērti aizbraukt un kultūras atšķirības ir nelielas. Eksportēt no Latvijas uz Lietuvu ir nesalīdzināmi vieglāk nekā uz Austrāliju. Tomēr svarīgākais kritērijs ir konkurētspēja un sekošana klientu vajadzībām.`+
`<dd></dd><div class="quote-style">"Mūsu stratēģija ir arī sekot klientiem. Mums ir vairāki lielie klienti, piemēram, "IKEA", "DHL" un "Volvo". Kurp dodas šie uzņēmumi, turp mēs dodamies viņiem līdzi," stāsta J. Ošlejs.</div>`+
`<dd></dd>"Primekss" mērķtiecīgi izvēlējās attīstīt eksportu ASV, kas ir liels tirgus ar plašām izaugsmes iespējām. "Sapratām, ka mums noteikti ir vērts uz turieni doties, un aktīvi strādājām šī virziena attīstīšanā," saka J. Ošlejs.`+
`<dd></dd>Skandināvija ģeogrāfiski ir pietiekami tuvs un aizsniedzams tirgus, lai uzņēmums pats varētu nodrošināt rūpnīcu grīdu, ēku rāmju un pamatu betonēšanu, bet Amerikā tas sadarbojas ar partneriem – lielajām ASV betonēšanas firmām, kas tur pārdod un iebūvē "Primekss" tehnoloģijas.`+
`<dd></dd><h3 class="post-subtitle">Ir īstais brīdis investēt attīstībā</h3>`+
`<dd></dd>"Nevienam nav noslēpums, ka Latvijas eksporta apjoms ir kritiski zems. Salīdzinājumā ar kaimiņvalstīm rādītāji ir zemi gan apstrādes rūpniecības, gan pakalpojumu eksportā," uzsver J. Ošlejs. Viņš skaidro, ka vēsturiski ir izveidojusies situācija, ka Latvijā banku kredītu un citu rūpniecības finansējumu apjoms ir bijis būtiski zemāks nekā kaimiņvalstīs un vienlaikus rūpniecības uzņēmumu pašizmaksa – augstāka nekā kaimiņvalstīs. Līdz ar to vietējo rūpniecības uzņēmumu ienākumi ir zemāki un attīstība ir notikusi lēnāk.`+
`<dd></dd>Liela Latvijas kļūda uzņēmēja skatījumā ir bijis pārāk liels uzsvars uz tranzīta un banku nozares attīstīšanu uz eksporta rūpniecības un eksporta pakalpojumu sniegšanas uzņēmumu attīstības rēķina. "Tagad ir īstais brīdis šo situāciju labot, tas ir šodienas valdības uzdevums. Pretējā gadījumā mūs gaida stagnācija," saka J. Ošlejs, piebilstot, ka tieši eksportējošiem uzņēmumiem ir iespējams sasniegt neierobežoti lielu pārdošanas apjomu, tātad neierobežoti lielus ienākumus, kas ļauj maksāt augstas algas un palielināt labi apmaksātu darbvietu skaitu. "Tāpēc visas tās valstis, kas kā attīstības pamatu ir likušas eksportu, šobrīd ir bagātas un pārtikušas, bet valstis, kurās valsts atbalstu saņem vietējā tirgū strādājošie, ātri pārvēršas par oligarhu paradīzēm, jo tad var pelnīt, vienkārši sarunājot ar politiķiem. Eksportā vai nu ir labs produkts, vai nav, nav nekādas shēmošanas un korupcijas," teic J. Ošlejs.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janisO2+`"></div>`+
`<dd></dd>Viņa skatījumā, lai mēs sasniegtu tādu dzīves līmeni, kāds ir Lietuvā un Igaunijā, ir daudz straujāk un intensīvāk jāinvestē eksportējošo uzņēmumu attīstībā. Banku kredīti, kapitāls, Eiropas fondu līdzekļi, kas uzņēmumiem pieejami investīcijām iekārtās un inovācijās, ir nepietiekami. "Lai varētu panākt izaugsmi, mums nesalīdzināmi ātrāk jāpalielina pieejamais finansējums. Tāpēc es aicinātu valdību vismaz trīskāršot eksportētājiem pieejamo līdzekļu daudzumu. Tas ir minimums, lai mēs varētu panākt Lietuvu un Igauniju," saka J. Ošlejs.`+
`<dd></dd><h3 class="post-subtitle">Svārstības dod iespēju attīstīties</h3>`+
`<dd></dd>"Šobrīd ģeopolitiskā situācija un vide ir ļoti mainīga. Tā, piemēram, daudzi Latvijas uzņēmumi izdevīgi pirka izejvielas Krievijā, kas vairs nav pieejamas. Alternatīvas ir dārgākas, tātad palielinās ražošanas pašizmaksa un samazinās peļņa. Lai varētu turpināt sekmīgi ražot, jāpārveido ražošanas procesi, bet tam nepieciešamas jaunas iekārtas, vai jāveido jauni, labāk pārdodami produkti, kam nepieciešami ieguldījumi zinātnē un attīstībā. Lai pārorientētos, ir vajadzīgas investīcijas," uzsver J. Ošlejs.`+
`<dd></dd><div class="quote-style">Pārmaiņu laikos ražotāji un pakalpojumu sniedzēji saskaras ar daudziem izaicinājumiem, taču vienlaikus tās ir iespējas. Uzņēmējs ir pārliecināts: katru reizi, kad ekonomika ir svārstīga, paveras milzīgas iespējas straujai attīstībai.</div>`+
`<dd></dd>"Ikviena krīze rada zaudētājus, bet vienlaikus arī uzvarētājus – kādam krīze vienmēr ienes vairāk naudas. Šobrīd, piemēram, enerģijas cenas ir ļoti augstas, tāpēc daudz mājsaimniecību ir lielas zaudētājas, bet enerģijas ieguves un tirdzniecības uzņēmumi – ieguvēji. Mazie veikali bankrotē, un Rīgas skatlogi ir tukši, turpretī lielie interneta tirgotāji strauji kāpina apgrozījumu. Ķīnā ražot ir grūti, Krievijā tas nav iespējams, tāpēc ir kļuvis izdevīgi ražot Eiropā," stāsta J. Ošlejs. Viņa skatījumā uzņēmumu un valsts vadītājiem ir jāuzdod sev jautājums: kā ir mainījusies pasaule? Ieguvējiem no pārmaiņām ir jākļūst par uzņēmuma klientiem, un to virzienā ir jāvērš valsts stratēģiskais atbalsts. ""Primekss" gan Covid-19 pandēmijas, gan Krievijas krīzes laikā ir strauji audzis un attīstījies, koncentrējoties tieši uz augošajiem sava tirgus segmentiem," stāsta J. Ošlejs.`+
`<dd></dd><h3 class="post-subtitle">Valsts atbalsts ir noderīgs</h3>`+
`<dd></dd>"Savā pastāvēšanas laikā esam saņēmuši ļoti plašu atbalstu no dažādām Latvijas organizācijām, lai nokļūtu tur, kur mēs esam tagad, un spētu eksportēt gandrīz uz visām pasaules malām, vienlaikus ierindojoties starp 20 lielajiem uzņēmumiem ar augstākajām vidējām algām, spējot audzēt darbvietu skaitu un nomaksāt nodokļus," teic J. Ošlejs.`+
`<dd></dd>Uzņēmums ir attīstījies, izmantojot dažādus valsts atbalsta instrumentus, piemēram, Latvijas Investīciju un attīstības aģentūras (LIAA) atbalstu tehnoloģiju izstrādei un ražošanas iekārtu iegādei, "Altum" kapitāla fonda atbalstu, ar peļņu atgriezis "Altum" atbalstīta riska kapitāla fonda investīcijas, izmantojis valsts atbalstu zinātnes un tehnoloģiju attīstībai viedo materiālu kompetences centra ietvaros. Tāpat ar valsts līdzfinansējumu "Primekss" ir piedalījies pasaulē lielākajā būvniecības nozares izstādē Dubaijā "Big 5", kā arī dažādās celtniecības izstādēs Skandināvijā, Vācijā, ASV un citur. "Līdzfinansējums dalībai izstādēs ir ļoti labs rīks atbalsta arsenālā. Mēs plaši to izmantojam," norāda J. Ošlejs. Viņš ir piedalījies arī dažādās LIAA organizētās tirdzniecības misijās. Pieredze liecina, ka tās ir īpaši vērtīgas tirgos, kur lielāka teikšana ir valsts izpildvarai.`+
`<dd></dd>"Noteikti jāuzteic LIAA koordinatoru darbs ārvalstīs. Viņu atbalstu mēs esam veiksmīgi izmantojuši eksporta tirgu attīstīšanai. Esam ļoti pateicīgi LIAA pārstāvniecībām par daudzkārtējo detalizēto atbalstu dažādos reģionos. Piemēram, Apvienotajos Arābu Emirātos LIAA pārstāve Inga Ulmane saziņā un vietējo birokrātisko jautājumu risināšanā. Tāpat ļoti labs un noderīgs valsts atbalsts ir vīzu kārtošanas jautājumos. Tas ir spilgts paraugs tam, cik ļoti veiksmīgi var darboties Latvijas valsts un atbalstīt vietējos ražotājus un eksportētājus. Manuprāt, LIAA direktors Kaspars Rožkalns ar savu komandu dara ļoti labu un vērtīgu darbu," tā J. Ošlejs.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janisO3+`"></div>`+

	support_by_liaa,

	review_9_name: "Katrīna Allikas",
	review_9_company:"SIA „Sonarworks”",
	review_9_website: "http://www.sonarworks.com",
	review_9_designation: "SIA „Sonarworks” (www.sonarworks.com), Globālā mārketinga vadītāja",
	review_9_excerpt: `Šobrīd Latvijas profesionālas skaņas digitālās kalibrēšanas jaunuzņēmuma "Sonarworks" produkcija ir pieejama visā pasaulē, izņemot Krieviju un Baltkrieviju. Pat esot Fidži salā, mūziķis var iegādāties "Sonarworks" produkciju uzņēmuma mājaslapā vai pie izplatītājiem, un tā tiks piegādāta.`,
	review_9_title:"Sonarworks tehnoloģijas lieto Grammy skaņu inženieri visā pasaulē",
	review_9_text:

`<dd></dd>Šobrīd Latvijas profesionālas skaņas digitālās kalibrēšanas jaunuzņēmuma "Sonarworks" produkcija ir pieejama visā pasaulē, izņemot Krieviju un Baltkrieviju. Pat esot Fidži salā, mūziķis var iegādāties "Sonarworks" produkciju uzņēmuma mājaslapā vai pie izplatītājiem, un tā tiks piegādāta. `+
`<dd></dd>"Sonarworks" globālā mārketinga vadītāja Katrīna Allikas uzskata, ka veiksmes atslēga eksportā ir produkts. Uzņēmumam ir divi produkti — viens ir "SoundID Reference" — austiņu un skaļruņu kalibrēšanas programmatūra, kas audioinženieriem un producentiem nodrošina pareizu references skaņu. To jau izmanto vairāk nekā 100 tūkstošos ierakstu studiju visā pasaulē, tajā skaitā 50 ar "Grammy" balvu apbalvoti skaņu inženieri, kuri producē un miksē tādu pasaules līmeņa zvaigžņu mūziku kā "Lady Gaga", "Coldplay", Madonna, Adele u.c. Uzņēmums jau desmit gadus sekmīgi darbojas šajā biznesā, bet pēdējos trīs gadus ir pievērsies klausītāju tirgum, licenzējot "SoundID" tehnoloģiju citiem uzņēmumiem Amerikā un Ķīnā — tie šādi uzlabo savus produktus. Šogad "Sonarworks" lielākajā pasaules tehnoloģiju izstādē CES (Consumer Electronics Show) Lasvegasā saņēma apbalvojumu par otrās paaudzes "SoundID" un par integrāciju ar "Grell" austiņām. Līdzīgi panākumi bija pērn, kad uzņēmuma risinājums "SoundID" saņēma inovāciju balvu kā neatkarīga tehnoloģija, bet austiņu ražotājs "HELM Audio" saņēma balvu  par "SoundID" integrāciju.`+
`<dd></dd><div class="quote-style">Jau no pirmsākumiem uzņēmums ir darbojies pēc skaidriem pamatprincipiem, ka produktam ir jārisina nozīmīga nozares problēma un tam ir jābūt vienkārši lietojamam. "Esam ieguldījuši lielu darbu produkta dizainā un lietojamības uzlabošanā, jo saprotam, ka mūsu produktam ir jāpalīdz mūzikas radītājiem ātrāk pievērsties radošajam darbam," viņa uzsver.  </div>`+
`<dd></dd>Tikpat svarīgs kā produkts ir loģistikas optimizācija un nepārtraukta procesu uzlabošana, lai nodrošinātu ērtus norēķinus, ātru produkta piegādi un spēcīgu komandu, kas var palīdzēt klientiem dažādu jautājumu un neskaidrību gadījumā. "Un pāri visam ir komanda. Nozīmīgu laiku veltām, lai paplašinātu komandu ar jauniem kolēģiem. Tas saskan ar mūsu ambīcijām un uzņēmuma vērtībām, jo ticam, ka savus mērķus varam sasniegt tikai kā vienota komanda," teic K. Allikas. `+
`<dd></dd><h3 class="post-subtitle">Lielākais tirgus — Amerika </h3>`+
`<dd></dd>Eksports veido 100% no "Sonarworks" 2021. gada 3,85 miljonu eiro apgrozījuma. Lielākais tirgus ir Amerika, kas veido 40% no pārdošanas apjoma. "Amerikā ir lielākais mūzikas radīšanas tirgus. Jau no uzņēmuma pirmsākumiem braucām uz turieni, lai tiktos ar "Grammy" balvu ieguvušiem audioinženieriem un saprastu, vai mūsu produkts risina nozīmīgu nozares problēmu," saka K. Allikas. Tur mūzikas radīšana ir attīstījusies straujāk un plašāk, salīdzinot ar Latviju, tāpēc klienti labāk saprata, kādu problēmu "Sonarworks" produkts risina un cik liela ir tā vērtība. Viņa atzīst, ka Latvijā nebija tik viegli, jo vietējais tirgus bija ļoti ierobežots un līdz galam nesaprata digitālās kalibrācijas ieguvumu.`+
`<dd></dd><div class="quote-style">"Esam uzkrājuši labu izpratni par mūsu lielākajiem tirgiem, līdz ar to tagad darbojamies vairāk pie tā, lai veicinātu mūsu produkta atpazīstamību šajās valstīs," saka K. Allikas. </div>`+
`<dd></dd>Sākoties Krievijas uzbrukumam Ukrainai, "Sonarworks" pārtrauca preču tirdzniecību un piegādi uz Krieviju un Baltkrieviju, tajā skaitā pārtrauca attiecības ar piegādātājiem, kas turpināja strādāt ar Krieviju. "Uz Ukrainu turpinājām piegādāt un to darām arī šobrīd, tikai nācās nomainīt piegādātāju no "FedEx" uz "Latvijas Pastu". Tas palielināja piegādes laiku, bet galvenais ir tas, ka varam nodrošināt pakalpojumu un turpināt atbalstīt Ukrainas ekonomiku," uzsver K. Allikas.`+
`<dd></dd><h3 class="post-subtitle">Vispirms izpēta tirgus potenciālu </h3>`+
`<dd></dd>Pieņemot lēmumu par ieiešanu jaunos tirgos, "Sonarworks" pamatā vadās pēc tā, cik liels ir tirgus potenciāls, proti, gan pēc tā izmēra, gan maksātspējas. Tas paļaujas uz "Google Analytics" pieejamo informāciju, produkta datiem un lietotāju intervijām, kā arī iegādājas pietuvinātus tirgus pētījumus un pieprasa datus no izplatīšanas partneriem. K. Allikas skaidro, ka profesionālo audiotirgu ir sarežģīti izprast, jo nepastāv publiski pieejami tirgus pētījumi par tirgus apjomu un potenciālu.`+
`<dd></dd><div class="quote-style">"Pēc mūsu pieredzes katrs uzņēmums šajā nozarē mēģina atrast savu pieeju, kā izprast tirgus potenciālu. Mums palīdz izplatīšanas partneri, kas dalās ar nozīmīgu informāciju par reģionālajiem tirgiem un to attīstību," viņa atklāj.</div>`+
`<dd></dd>Svarīgākie rādītāji, kas jāapskata un jāņem vērā pirms eksporta sākšanas jaunā tirgū, ir konkrētā produkta potenciāls šajā tirgū, maksātspēja, konkurence, kā arī jānovērtē konkrētās valsts īpatnības — maksāšanas veidi, valodas barjera, uzņēmuma spēja strādāt ar vietējiem influenceriem. Piemēram, Ķīnā ir pavisam citas sociālo mediju platformas un to apgūšanai vajadzīgi nozīmīgi resursi un stratēģija.`+
`<dd></dd><h3 class="post-subtitle">Iesaka izmantot kolēģu un valsts atbalstu </h3>`+
`<dd></dd>Uzņēmumiem, kas vēl tikai domā par eksportu, K. Allikas iesaka veikt rūpīgu izpēti. Viņa rekomendē vērsties pie organizācijām ar pieredzi — Latvijas Investīciju un attīstības aģentūrā, Latvijas Eksportētāju asociācijā "The Red Jackets", Ekonomikas ministrijā un Ārlietu ministrijā, Eiropas Biznesa atbalsta tīklā Latvijā, "Altum" utt. Tāpat ir svarīgi vērsties pēc padoma pie citiem nozares uzņēmumiem. "Latvijas kompānijām ir labi panākumi ārējos tirgos, līdz ar to atrast nozares uzņēmumus, kas varētu dalīties pieredzē, nebūs sarežģīti. Brīnišķīga Latvijas priekšrocība ir tās izmērs un ciešo sakaru loks biznesa vidē," norāda K. Allikas, piebilstot, ka ir iespējas iegūt gan informatīvo, gan finansiālo atbalstu no valsts un privātajiem uzņēmumiem un fondiem. Tāpēc viņa iesaka veltīt laiku, lai apzinātu iespējas. "Latvijas sabiedrība ir ļoti atsaucīga, tikai jāmāk skaidri noformulēt mērķi un nepieciešamo atbalstu," saka viņa.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+katrina1+`"></div>`+
`<dd></dd>"Sonarworks" ir riska kapitāla finansēts uzņēmums, tādēļ nozīmīgs ir bijis gan investoru atbalsts, gan LIAA un "Altum" finansējums. "Mēs katru gadu cenšamies piedalīties vismaz pāris nozares pasākumos, lai satiktu savus lietotājus un uzklausītu, kas viņiem ir svarīgs mūsu nākotnes produkta attīstībā, lai tiktos ar izplatīšanas partneriem un izprastu, kā varam viņus atbalstīt, lai paplašinātu reģionālo biznesa attīstību, kā arī lai mācītos no tirgus saprast tendences, jauninājumus un to, kā mūsu produkts var būt daļa no nozares nākotnes. LIAA finansējums izmaksu segšanai izstāžu apmeklēšanai ir bijis īpaši nozīmīgs," saka K. Allikas.`+
`<dd></dd><h3 class="post-subtitle">Attiecības — attīstības pamatnosacījums </h3>`+
`<dd></dd><div class="quote-style">"Darbojoties jaunuzņēmumā, nepārtraukti nākas saskarties ar šķēršļiem. Sākotnēji viens no lielākajiem izaicinājumiem bija izveidot izplatīšanas tīklu un atrast uzticamus partnerus, kas izprastu tirgu labāk par mums," stāsta K. Allikas. Šos izaicinājumus izdevās pārvarēt, paplašinot komandu un veidojot sakarus, kā arī vairākkārt dodoties uz Ameriku, tiekoties ar partneriem un iepazīstot mērķauditoriju, lai saprastu, kā labāk pasniegt produktu. </div>`+
`<dd></dd>"Biznesa attīstība jebkurā tirgū ir nepārtraukts darbs — digitālās attīstības laikmetā mērķauditorija mainās. Tādēļ, lai nezaudētu savu vietu tirgū, ir svarīgi izprast tās vajadzības un sava produkta vietu tā attīstības ciklā," norāda K. Allikas. Tāpat jārēķinās ar to, ka partneru uzņēmumos mainās darbinieki, kas atbild par produkcijas virzīšanu tirgū, tādēļ nozīmīga darba sastāvdaļa ir nozares pasākumu apmeklēšana, partneru apciemošana, trenēšana un mācības. Covid-19 pandēmijas laikā "Sonarworks" saskārās ar apgrozījuma kritumu, jo izplatīšanas partneru uzņēmumos nomainījās daudz darbinieku un viss ieguldītais darbs bija praktiski jāsāk no jauna. "Līdz ar to esam sapratuši, ka attiecību veidošana un stiprināšana ir viens no veiksmīga biznesa attīstības pamatnosacījumiem," teic K. Allikas.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+katrina2+`"></div>`+

	support_by_liaa,

	review_10_name: "Romāns Sidorovs",
	review_10_company:"SIA „GroGlass”",
	review_10_website: "http://www.groglass.com",
	review_10_designation: "SIA „GroGlass” (www.groglass.com), valdes loceklis",
	review_10_excerpt: `"Groglass" ir viens no nedaudziem uzņēmumiem pasaulē, kas ražo stiklu ar pretatspīduma pārklājumu, ko izmanto mākslas darbu ierāmēšanā un saglabāšanā, kā arī vairākos citos tehniskos lietojumos. Uzņēmuma attīstīta tehnoloģija var arī nodrošināt 99% ultravioleto staru aizsardzību, kā arī mazāk nekā 1% gaismas atspīdumu, panākot sevišķi augstu attēla dzidrību.`,
	review_10_title:"Latvijā māk ražot neredzamu stiklu",
	review_10_text:

`<dd></dd>"Groglass" ir viens no nedaudziem uzņēmumiem pasaulē, kas ražo stiklu ar pretatspīduma pārklājumu, ko izmanto mākslas darbu ierāmēšanā un saglabāšanā, kā arī vairākos citos tehniskos lietojumos. Uzņēmuma attīstīta tehnoloģija var arī nodrošināt 99% ultravioleto staru aizsardzību, kā arī mazāk nekā 1% gaismas atspīdumu, panākot sevišķi augstu attēla dzidrību.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans1+`"></div>`+
`<dd></dd>Nanotehnoloģiju uzņēmuma "Groglass" pirmsākumi ir saistīti ar Latvijas pētniecības centru "Sidrabe", kas izgudroja vakuumuzputināšanas tehnoloģiju stiklam. "Sidrabes" investori, kuri pēc Padomju Savienības sabrukuma sāka investēt dažādos augsto tehnoloģiju un zinātniski ietilpīgos uzņēmumos, pieņēma lēmumu attīstīt un komercializēt šo daudzsološo tehnoloģiju. Tā "Groglass" tapa kā māsasuzņēmums. "Mūsu ražotnē ir viena "Sidrabes" iekārta, uz kuras ir uzraksts "Made in Latvia", un mums ir gods katru dienu strādāt ar tādu augsto tehnoloģiju iekārtu, kas ir ražota tepat Latvijā," stāsta "Groglass" valdes loceklis Romāns Sidorovs. `+
`<dd></dd>Pērn "Groglass" apgrozījums bija 23 miljoni eiro, bet operatīvā peļņa — vairāk nekā 8 miljoni eiro. "Mēs katru gadu augam aptuveni par 10 līdz 15%," viņš saka. Uzņēmuma divās ražotnēs Rīgā strādā ap 200 darbinieku. `+
`<dd></dd><h3 class="post-subtitle">Produkta izstrāde prasa septiņus gadus </h3>`+
`<dd></dd>"Groglass" pamatprodukts ir neredzamais stikls. Atšķirībā no parastā stikla, šim materiālam ir pretatspīduma un no ultravioletajiem stariem aizsargājošas funkcijas, kas ir svarīgas ierāmēšanas darbnīcām, lielām galerijām un muzejiem, kā arī klientiem, kuri izmanto uzņēmuma produkciju tādos tehniskajos lietojumos kā elektroniski displeji un ledusskapju vitrīnas.  `+
`<dd></dd><div class="quote-style">"Mēs esam tikai otrais uzņēmums pasaulē, kas prot ražot “neredzamo” akrilu ar augstu kvalitāti. Šī produkta attīstība prasīja vairāk nekā septiņus gadus. Pieredzējām ļoti daudz šķēršļu, bet ticam tam, ko darām, un kopā ar investoru atbalstu mums bija iespēja pabeigt šī produkta izstrādi," saka R. Sidorovs. </div>`+
`<dd></dd>Nozīmīgu "Groglass" biznesa daļu veido sadarbība ar ierāmēšanas darbnīcām. Tāpat uzņēmums piegādā stiklu kompānijām, kas no tā ražo stiklu pārtikas veikalu ledusskapju vitrīnām. "Vēl mēs ražojam stiklu elektroniskiem ekrāniem, ko var izmantot, piemēram, kā informācijas tablo lidostās vai stacijās, vai reklāmām, kā arī muzeju vitrīnu stiklu," stāsta R. Sidorovs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans2+`"></div>`+
`<dd></dd>Pārsvarā "Groglass" klienti ir citi uzņēmumi, bet nesen ražotājs ir sācis darbu ar patērētāju segmentu. Viņš skaidro, ka pagaidām šis biznesa virziens veido vien mazu daļu no uzņēmuma apgrozījuma, bet tas eksperimentē un nākotnē plāno paplašināt sadarbību ar patērētājiem ierāmēšanas biznesā. `+
`<dd></dd><h3 class="post-subtitle">Eksportē no pirmās dienas </h3>`+
`<dd></dd>Jau no pirmās darbības dienas "Groglass" eksportē teju visu saražoto produkciju. "Jau no 2010. gada mūsu eksports ir gandrīz 100%," saka R. Sidorovs. Pirmā valsts, uz kuru uzņēmums aizsūtīja savu produkciju siltumnīcām, bija Nīderlande. Uzrunājot potenciālos partnerus, tieši Nīderlandē bija pirmie klienti, kas pieņēma lēmumu pamēģināt Latvijā ražoto risinājumu. Kopš tā laika eksporta galamērķu skaits ir pieaudzis līdz 45 valstīm. Lielākais eksporta tirgus ir Eiropas valstis, tām seko ASV, Ķīna un Austrālija. `+
`<dd></dd><div class="quote-style">"Konkurence eksporta tirgos ir ļoti sīva. Turpināt strādāt un augt mums palīdz tas, ka izvēlējāmies savu nišu un fokusu — neredzamo stiklu. Varbūt globālā mērogā šī niša nav ļoti liela, bet mēs tajā esam labākie pasaulē un mums ir iespēja sniegt vislabāko produktu un apkalpošanu klientiem un tādējādi noturēt mūsu tirgus daļu," norāda R. Sidorovs.</div>`+
`<dd></dd>Uzņēmumam būtu iespēja paplašināties vairākos segmentos, kur konkurences līmenis ir daudz augstāks, bet tajos, visticamāk, pat ar lielām investīcijām mārketingā nekad nebūs tik labas tirgus pozīcijas. Līdz ar to uzņēmums fokusējas uz savu nišu. `+
`<dd></dd>Krievijas karš Ukrainā komerciālā ziņā "Groglass" darbību neietekmē, jo Krievijas un Ukrainas tirgus veidoja bija mazāk nekā pusprocentu no kopējā apgrozījuma. Taču kara sekas ir ietekmējušas elektroenerģijas un gāzes cenas. Uzņēmuma pamatizejviela ir stikls, kura ražošanā tiek izmantota gāze, kuras cena ir pieaugusi. Tāpat uzņēmumu ietekmē elektroenerģijas cenas pieaugums. "Taču mēs varējām pielāgoties, uzlabot efektivitāti ražošanā, kompensēt kaut daļu no izmaksām, paaugstinot cenas. Klienti ir saprotoši," saka R. Sidorovs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans3+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Spēj pielāgoties mainīgiem apstākļiem </h3>`+
`<dd></dd>"Groglass" DNS ietver divas būtiskas komponentes — spēju pielāgoties un palikt pārbaudītām vērtībām.`+
`<dd></dd><div class="quote-style">"Mēs esam tirgus līderi gandrīz visos segmentos, kuros strādājam, bet tajā pašā laikā mums ir konkurenti, kuru apgrozījums ir miljardos. Mūsu apgrozījums ir aptuveni 25 miljoni eiro, kas pēc noklusējuma paredz, ka mums ir lielākas iespējas pielāgoties izmaiņām. Bez tā mēs nevaram turpināt eksportēt teju 100% mūsu produkcijas un saglabāt tirgus līdera pozīcijas," norāda R. Sidorovs. </div>`+
`<dd></dd>Paralēli tam, ka uzņēmums ir elastīgs un pielāgojas apstākļiem, tas paliek pie pārbaudītam vērtībām. Protams, ja kaut kas nedarbojas, kā paredzēts, "Groglass" komanda veic izmaiņas un meklē jaunas metodes. "Ja mēs ticam, ka varam saražot kādu produktu, mēs meklējam iespējas, kā to izdarīt," viņš saka. `+
`<dd></dd><h3 class="post-subtitle">Jau kaļ plānus par trešo ražotni </h3>`+
`<dd></dd>"Groglass" savā attīstībā ir izmantojis dažādus valsts atbalsta instrumentus. Uzņēmums seko līdzi tam, kādas atbalsta programmas ir pieejamas, galvenokārt pievēršot uzmanību atbalstam dalībai izstādēs, pētniecības projektos utt. Ražotājs izmanto arī subsīdijas pētniecības izmaksu segšanai.  `+
`<dd></dd><div class="quote-style">"Mēs izmantojam Kompetenču centru programmas, jo mums katru gadu pētniecības izmaksas ir mērāmas vairākos miljonos eiro. Turklāt tie ir ilgtermiņa projekti — viena produkta attīstība var ilgt pat septiņus gadus. Tās ir ļoti lielas investīcijas, bet rezultātā ir iespēja radīt unikālu produktu," skaidro R. Sidorovs. </div>`+
`<dd></dd>Tāpat "Groglass" izmanto valsts atbalstu ieguldījumiem tehnoloģijās un iekārtās. Piemēram, ar JPR projekta līdzfinansējumu uzņēmums uzbūvēja otru rūpnīcu. "Tagad domājam par trešo rūpnīcu, kas prasīs vairāku desmitu miljonu eiro investīcijas. Tagad ir atvērtas vairākas atbalsta programmas, un mēs ceram, ka mums izdosies saņemt valsts atbalstu jaunas ražotnes izveidei," saka R. Sidorovs. `+
`<dd></dd>Viņš pozitīvi vērtē sadarbību ar Latvijas Investīciju un attīstības aģentūru (LIAA), Centrālo finanšu un līgumu aģentūru un Kompetenču centru. "Prieks, ka LIAA ir uzņēmēju pusē un uzklausa mūs ne tikai ierēdņu līmenī, bet arī vadītāju līmenī. Es ceru, ka šāda attieksme turpināsies arī nākotnē, jo tas ir svarīgi, lai varētu palīdzēt arī citiem Latvijas uzņēmumiem sasniegt mērķus eksportā," uzskata R. Sidorovs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans4+`"></div>`+

	support_by_liaa,

	review_11_name: "Žygimantas Surintas",
	review_11_company:"SIA „Smartlynx Airlines”",
	review_11_website: "http://www.smartlynx.aero",
	review_11_designation: "SIA „Smartlynx Airlines” (www.smartlynx.aero), izpilddirektors",
	review_11_excerpt: `Latvijas pilna servisa gaisa kuģu nomas ar apkalpi (ACMI), kā arī čarterreisu un kravu pārvadājumu operatoram SmartLynx Airlines vietējais tirgus veido mazāk nekā 3 % no kopējā apgrozījuma. Turklāt, ņemot vērā iespējamo lejupslīdi reģionā, uzņēmums nākamajā gadā plāno par 10–15 % palielināt ārpus Eiropas gūtos ienākumus. `,
	review_11_title:"Pasaule ir liela – neveiciet visu uzņēmējdarbību tikai vietējā tirgū",
	review_11_text:

`<dd></dd>Latvijas pilna servisa gaisa kuģu nomas ar apkalpi (ACMI), kā arī čarterreisu un kravu pārvadājumu operatoram SmartLynx Airlines vietējais tirgus veido mazāk nekā 3 % no kopējā apgrozījuma. Turklāt, ņemot vērā iespējamo lejupslīdi reģionā, uzņēmums nākamajā gadā plāno par 10–15 % palielināt ārpus Eiropas gūtos ienākumus. `+
`<dd></dd>“Jauni uzņēmumi nereti saka – vispirms mēs izmēģināsim vietējo tirgu, jo tas ir lētāk un risks ir zemāks, un pēc tam mēs sāksim eksportēt un centīsimies iekļūt starptautiskajos tirgos. Mana personīgā pieredze liecina, ka tā ir viena no lielākajām kļūdām, ko iespējams pieļaut. Neveiciet visu uzņēmējdarbību vietējā tirgū,” saka SmartLynx izpilddirektors Žigimants Surints (Žygimantas Surintas).  `+
`<dd></dd><div class="quote-style">Jaunuzņēmumiem visbiežāk ir ierobežoti līdzekļi, un to uzņēmējdarbības panākumus veicina tirgus lielums un pieprasījums. Iespējams, produkts būtu daudz sekmīgāks, ja uzņēmums uzreiz ieguldītu eksporta tirgos, nevis tērētu naudu produktu testēšanai vietējā tirgū.  </div>`+
`<dd></dd>“Jūs varat būt ļoti pieredzējis un jums var būt lielisks produkts un uzņēmums, bet, ja koncentrēsieties tikai uz vietējo tirgu, jums būs ierobežotas iespējas augt. Vienlaikus jums svarīgi sākt pelnīt, lai turpinātu izaugsmi un kļūtu par nopietnu un lielu eksportētāju,” skaidro Ž.Surints. SmartLynx izpilddirektors iesaka izvērtēt iespējas sadarboties un konkurēt lielajos tirgos. Un uzņēmumam tam ir rūpīgi jāsagatavojas. Viena lieta ir redzēt skaitļus Excel tabulā, bet pavisam cita – tos realizēt. “Šis ir arī viens no lielākajiem SmartLynx izaicinājumiem. Globālajā tirgū ir ļoti augsti kvalitātes un izpildes standarti, tāpēc jābūt zinošiem un sagatavotiem prasības izpildīt, jo starptautiskajā tirgū jums tiek dota  tikai viena vai divas iespējas. Tāpēc nedrīkst kļūdīties,” uzsver Ž. Surints. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+zygimantas1+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Iespēju meklēšana ārpus Eiropas Savienības </h3>`+
`<dd></dd>1992. gadā SmartLynx Airlines uzsāka pilnos čarterreisus – tolaik uzņēmuma nosaukums bija LatCharter Airlines. Tā bija pirmā lidsabiedrība, kas nodrošināja kompleksos ceļojumus no Rīgas, un tā bija gatava arī piedāvāt privātos čarterreisus sporta, pasākumu, koncertu, valdības, militārām un uzņēmumu vadītāju vajadzībām. LatCharter bija arī oficiālais tā laika prezidentes Vairas Vīķes-Freibergas pārvadātājs. “Uzņēmums sākotnēji tika izveidots tikai vietējā tirgus apkalpošanai, bet tas ir mainījies. Tagad mēs galvenokārt koncentrējamies uz eksportu un darbību dažādos starptautiskajos tirgos. Latvijas tirgus veido mazāk nekā 3 % no mūsu kopējā apgrozījuma, un visas Baltijas valstis kopā aptuveni  6–7 %,” stāsta Ž. Surints.  `+
`<dd></dd>Patlaban flotē ir 49 lidmašīnas, bet 2023. gada vasarā tajā būs aptuveni 65 lidaparāti. No 2022. gada janvāra līdz novembrim SmartLynx ir veicis vairāk nekā 30 tūkstošus lidojumu un pārvadājis 4 miljonus pasažieru. SmartLynx kopumā strādā 365 darbinieki. Pašlaik SmartLynx Latvijā, tai skaitā Latvijas meitasuzņēmumos un apakšuzņēmumos, ir 327 pilna laika darbinieki. Igaunijā ir 31 darbinieks, Maltā – 27, Lietuvā – 6 un Vācijā – 1.  `+
`<dd></dd><div class="quote-style">SmartLynx atrodas Latvijā, jo Rīgā aviācijas nozare ir visattīstītākā salīdzinājumā ar citām Baltijas valstīm. Ž. Surints uzsver, ka Latvijā šajā jomā var iegūt speciālo izglītību – šeit ir skolas, kas sagatavo tehniķus, pilotus un dispečerus.  </div>`+
`<dd></dd>“Latvijā atrodas vairāki aviācijas uzņēmumi, kas sniedz iespējas speciālistiem mainīt darbavietu, lai gūtu visaptverošu pieredzi un mācītos viens no otra. Arī personāla izmaksas ir zemākas salīdzinājumā ar Igauniju un Lietuvu,” viņš papildina. `+
`<dd></dd><h3 class="post-subtitle">Lielākais tirgus – Apvienotā Karaliste </h3>`+
`<dd></dd>Pašlaik SmartLynx lielākais tirgus ir Apvienotā Karaliste, kam seko Beniluksa valstis un Vācija. Nozīmīgu uzņēmējdarbības daļu veido arī Tuvo Austrumu un Āzijas tirgus. “Arī Āfrika mums ir salīdzinoši nozīmīgs tirgus. Pašlaik mēs Āfrikā izmantojam astoņas lidmašīnas,” stāsta Ž. Surints. Savukārt Kanāda ir SmartLynx jaunais tirgus. “Lielākā daļa mūsu ieņēmumu tiek gūti no Eiropas Savienības (ES) valstīm, taču vienlaikus esam ļoti globāls uzņēmums, izvēršot darbību ārpus Eiropas robežām,” papildina Ž.Surints. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+zygimantas2+`"></div>`+
`<dd></dd>SmartLynx konsolidētais apgrozījums šajā gadā sasniegs aptuveni 300 miljonus eiro. “Līdzīgi kā citu ES valstu uzņēmumi arī mums ir bažas par ekonomikas lejupslīdi. Mēs meklējam iespējas pārdošanu diversificēt -  samazināt ES valstīs gūtos ienākumus un palielināt uzņēmējdarbību Ziemeļamerikas un Tuvo Austrumu tirgū. Nākamajā gadā  uzņēmuma plānos ir palielināt ārpus ES gūto ieņēmumu daļu par 10–15 %. Tas mums palīdzēs pārciest ekonomiski nestabilo laiku,” skaidro Ž. Surints. `+
`<dd></dd><h3 class="post-subtitle">Degvielas cenas ietekmēs pieprasījumu </h3>`+
`<dd></dd>SmartLynx ietekmē arī Krievijas sāktais karš Ukrainā. Galvenokārt tāpēc, ka četras no uzņēmuma kravas lidmašīnām finansēja ar Krieviju saistīts uzņēmums. “Šīm lidmašīnām tika piemērotas ES un ASV sankcijas, tādēļ šīs lidmašīnas netiek izmantotas  un  šobrīd novietotas stāvēšanai. Tas ir ļoti būtisks trieciens mūsu uzņēmējdarbībai, jo četras lidmašīnas veido 5 % no mūsu kopējās flotes,” stāsta Ž. Surints. Viena lidmašīna ir palikusi Ukrainā, un to nav iespējams atgūt, jo Ukrainas gaisa telpai ir piemēroti ierobežojumi. “Mēs redzam vēl citus netiešus triecienus mūsu uzņēmējdarbībai, kas galvenokārt ietekmē iekšējo pieprasījumu un palielina dzīves dārdzību, kas ir tieši saistīts ar karu un ekonomisko situāciju,” papildina Ž.Surints. `+
`<dd></dd>Runājot par degvielas cenām, Ž. Surints stāsta, ka tās uzņēmumam ir ļoti būtiskas – degvielas cenas galvenokārt ietekmē pakalpojuma izmaksas gala klientiem. Viņš ir ļoti reālistisks: neviens nezina, kā pieprasījumu ietekmēs augstākas cenas. `+
`<dd></dd><h3 class="post-subtitle">Ļoti piesardzīga jaunu tirgu meklēšana </h3>`+
`<dd></dd><div class="quote-style">Saistībā ar eksporta stratēģiju un lēmumu pieņemšanas procesu par jaunu tirgu apgūšanu Ž. Surints uzskata, ka visi lēmumi tiek pieņemti, balstoties uz tiem pašiem principiem kā jebkurā citā uzņēmējdarbības jomā. To pamatā ir galvenie rādītāji: iedzīvotāju, konkurentu un lidmašīnu skaits un tips, valsts ģeogrāfiskā atrašanās vieta un ceļošanas paradumi. </div>`+
`<dd></dd>“Vācija un Apvienotā Karaliste ir tirgi, kuros iedzīvotāji Eiropā ceļo visbiežāk. Tas nozīmē, ka pieprasījums ir nepārtraukti. Bet vienmēr ir arī augsta konkurence un augsti iekšējie šķēršļi. Tāpēc mēs vienmēr meklējam tirgus, kuros ir ekonomiska un politiska stabilitāte. Ļoti svarīgs ir arī regulējums, jo ir valstis, kurās pastāv ierobežojumi attiecībā uz gaisa kuģu nomu ar apkalpi,” stāsta Ž. Surints. Piemēram, dažās valstīs gaisa kuģu noma ar apkalpi nav atļauta, jo valsts iestādes noteikušas dažādus ierobežojumus. “Ir arī daudzas mazas valstis, kurās saistību neizpildes risks ir pārāk augsts, un mēs izvairāmies no šiem tirgiem. Riska faktors ir visai augsts, tāpēc, meklējot mūsu uzņēmumam jaunus tirgus, mums vispirms jāveic rūpīga izpēte,” skaidro Ž.Surints. `+
`<dd></dd><h3 class="post-subtitle">Tie paši uzņēmējdarbības principi </h3>`+
`<dd></dd>Tā kā aviācijas nozare ir sarežģīta, cilvēki reizēm domā, ka uzņēmējdarbība šajā sektorā zināmā mērā atšķiras. Tomēr Ž. Surints uzsvēra, ka visu uzņēmumu pamatā vairāk vai mazāk ir tie paši principi. SmartLynx arī jāmeklē tirgi, kas uzņēmumam var nodrošināt ieņēmumus. Uzņēmums piedalās izstādēs, un tas ir ļoti spēcīgs mārketinga instruments jaunu klientu piesaistē. Vēl viens populārs instruments ir starpniecības darījumi. Vēsturiski vairāk nekā 40 % darījumu tika veikti ar dažādu starpnieku palīdzību. “Tagad, kad mūsu uzņēmums paplašinās, mums būs vairāk tiešu darījumu ar gala klientiem,” noslēdz Ž. Surints. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+zygimantas3+`"></div>`+

	support_by_liaa,

	review_12_name: "Kristīne Metuzāle",
	review_12_company:"SIA „Tilde”",
	review_12_website: "http://www.tilde.lv",
	review_12_designation: "SIA „Tilde” (www.tilde.lv) vecākā lielo klientu attiecību vadītāja",
	review_12_excerpt: `Valodu tehnoloģiju uzņēmums "Tilde" jau četras reizes ir uzvarējis mašīntulkošanas sacensībās "World Machine Translation Competition", kurās piedalās mašīntulkošanas risinājumu izstrādātāji no visas pasaules. Šīs ir kā nozares ikgadējās olimpiskās spēles, kurās tiek novērtētas izpētes rezultātā iegūtās jaunākās mašīntulkošanas tehnoloģijas. Lai nepārtraukti attīstītos tālāk, "Tilde" jau tagad strādā pie valodu tehnoloģiju izpētes kvantu datoru jomā. `,
	review_12_title:"Globalizācija veicina pieprasījumu pēc valodu tehnoloģijām",
	review_12_text:

`<dd></dd>Valodu tehnoloģiju uzņēmums "Tilde" jau četras reizes ir uzvarējis mašīntulkošanas sacensībās "World Machine Translation Competition", kurās piedalās mašīntulkošanas risinājumu izstrādātāji no visas pasaules. Šīs ir kā nozares ikgadējās olimpiskās spēles, kurās tiek novērtētas izpētes rezultātā iegūtās jaunākās mašīntulkošanas tehnoloģijas. Lai nepārtraukti attīstītos tālāk, "Tilde" jau tagad strādā pie valodu tehnoloģiju izpētes kvantu datoru jomā. `+
`<dd></dd><div class="quote-style">"Tildē" ļoti lielu uzmanību pievērš pētniecībai un attīstībai. Uzņēmums vairāk nekā 22 % sava apgrozījuma iegulda izpētes darbā, lai attīstītu jaunas tehnoloģijas. "Tādējādi mēs varam apmierināt visu laiku augošās klientu vajadzības un būt starp nozares līderiem," teic SIA "Tilde" vecākā lielo klientu attiecību vadītāja Kristīne Metuzāle.</div>`+
`<dd></dd>Viņa uzskata, ka uzņēmuma nepārtrauktās investīcijas pētniecībā un attīstībā ļauj tam ne tikai būt stipram šodien, bet arī piedāvāt tikpat labus risinājumus rīt un parīt, kad tehnoloģijas būs attīstījušās vēl tālāk. "Piemēram, mēs tagad jau strādājam pie valodu tehnoloģiju izpētes kvantu datoru jomā. Jau tagad gribam saprast, kas šajā jomā notiks pēc vairākiem gadiem, lai mēs būtu gatavi nākotnei," saka K. Metuzāle. `+
`<dd></dd><h3 class="post-subtitle">Palīdz efektīvāk strādāt un labāk saprasties </h3>`+
`<dd></dd>"Tilde" attīsta mākslīgajā intelektā balstītas valodu tehnoloģijas, kas atļauj pārvarēt dažādas valodu barjeras komunikācijā valsts pārvaldē, uzņēmumos un citās organizācijās. "Valodu tehnoloģijām mūsdienu komunikācijā ir arvien nozīmīgāka loma, jo tās ļauj mums komunicēt. Piemēram, cilvēki var saņemt ziņas no viena kanāla tikai tajā valodā, kuru zina. Taču ar valodu tehnoloģiju palīdzību ir iespējams sekot līdzi ziņām arī valodās, kuras cilvēks nepārvalda. Līdz ar to var saņemt informāciju no dažādiem kanāliem un avotiem, saprast, kas ir patiess un kas varbūt nav patiess. Tehnoloģijas nākotnē mums ļaus dzīvot demokrātiskākā un integrētākā pasaulē. Protams, mēs vēlētos redzēt, ka "Tilde" ir nozīmīga daļa no šīs pasaules," norāda K. Metuzāle. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristineM1+`"></div>`+
`<dd></dd>Uzņēmuma izstrādātās tehnoloģijas ļauj efektīvāk strādāt, ietaupīt laiku, izveidot efektīvus procesus, kā arī paplašināt iespējas eksportā. "Augošā starptautiskā sadarbība un mobilitāte noved pie tā, ka uzņēmumiem ir vajadzīgi arvien vairāk un efektīvāki līdzekļi komunikācijai un klientu apkalpošanai. Piemēram, virtuālie asistenti un mašīntulkošanas risinājumi starptautiskajā komunikācijā ir arvien vairāk nepieciešami. Tāpēc mēs ļoti cītīgi sekojam klientu vajadzību, kā arī tirgu un tehnoloģiju attīstībai. Mums visu laiku ir jāpiedāvā produkti, kas vislabāk atbilst klientu vajadzībām," viņa saka.`+
`<dd></dd>K. Metuzāle stāsta, ka balss tehnoloģijas nodrošina daudzām organizācijām un valsts pārvaldei daudz efektīvāku darba procesu, piemēram, sapulču vadīšanu un balss transkribēšanu, t. i., runas pārvēršanu rakstītā tekstā. Mums visiem ir daudz sapulču, t. sk. arī tiešsaistes sapulču, kurās izrunātais jāprotokolē. Kāpēc lai šo procesu neautomatizētu? Ir cilvēki, kam darba pienākumos ir doties vizītēs uz vietas, kur ir jāveic novērojumi, kas vēlāk ir jāiesniedz rakstiskā formā. Izmantojot balss risinājumus, šādos gadījumos cilvēks var ierunāt savus novērojumus, un, atgriežoties birojā, šis teksts jau būs rakstiskā formā. Piemēram, arī ārstiem balss tehnoloģijas ļauj daudz ātrāk un efektīvāk veikt svarīgus pierakstus, ko nodot pacientam vai citiem kolēģiem. `+
`<dd></dd><h3 class="post-subtitle">Ar 30 gadu pieredzi </h3>`+
`<dd></dd>Šobrīd uzņēmums strādā ar mākslīgo intelektu un attīsta galvenokārt trīs tehnoloģiju virzienus: mašīntulkošanu, virtuālos asistentus un balss atpazīšanas tehnoloģijas. "Visas šīs tehnoloģijas balstās mākslīgajā intelektā," teic K. Metuzāle. `+
`<dd></dd><div class="quote-style">"Tildes" pirmsākumi meklējami jau pirms 30 gadiem, kad Latvijā ienāca pirmie datori, kuri neatbalstīja rakstīšanu latviešu valodā. "Toreiz, lai rakstītu latviešu valodā, vajadzēja spiest speciālu taustiņu kombināciju garumzīmēm un mīkstinājuma zīmēm. Viens no simboliem, ko vajadzēja spiest, bija "∼". No tā arī ir cēlies "Tildes" nosaukumus un logo," viņa stāsta.  </div>`+
`<dd></dd>Uzņēmums sāka darbību valodu tehnoloģiju nozarē, nodrošinot latviešu burtu pieejamību tā laika datoros, tad veidojot elektroniskās vārdnīcas un citus risinājumus. Laikam un tehnoloģijām ejot uz priekšu, mēs esam nonākuši mākslīgā intelekta laikmetā. Tagad "Tildē" strādā jau vairāk nekā 150 darbinieku trijos birojos. Galvenais birojs ir Rīgā, bet uzņēmumam ir arī biroji Lietuvā un Igaunijā. `+
`<dd></dd><h3 class="post-subtitle">Eksports veido 59 % no apgrozījuma </h3>`+
`<dd></dd>2021. gadā "Tildes" apgrozījums bija 6,7 miljoni eiro. "Mūsu apgrozījums turpina augt, un arī eksporta daļa apgrozījumā ir ļoti būtiska. Eksports veido 59 % no visa apgrozījuma," saka K. Metuzāle. Uzņēmums arvien straujāk paplašinās ārvalstu tirgos un tajos turpina darboties fokusētāk un efektīvāk. `+
`<dd></dd>"Tilde" galvenokārt strādā Eiropā. "Eiropā ir ļoti daudz valodu izaicinājumu, jo ir 24 oficiālās valodas, kurās visam ir jābūt tulkotam. Taču Eiropā plaši lieto 80 valodas. Daudzas valsts pārvaldes organizācijas sastopas ar to, ka ir lielas iedzīvotāju grupas, kuras nerunā vietējā valodā vai pat nevienā oficiālajā Eiropas valodā (piem., bēgļi). Šīm organizācijām ir vajadzīgi rīki, lai komunicētu ar šīm iedzīvotāju grupām, piemēram, sociālajos, veselības vai izglītības jautājumos," uzsver K. Metuzāle.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristineM2+`"></div>`+
`<dd></dd>Uzņēmums ir attīstījis vairāk nekā 90 dažādu valodu kombināciju mašīntulkošanā. Pēc klientu pieprasījuma mašīntulkošanas risinājumi tikuši attīstīti arī eksotiskākām valodām, tādām kā vjetnamiešu, korejiešu, taju, Meksikas spāņu un citām valodām. Uzņēmums ir arī pielāgojis mašīntulku dažādām specifiskām klientu industrijām, t. sk. medicīnai, tieslietām un citām nozarēm. "Eiropas uzņēmumiem ir globāla sadarbība, un tiem ir vajadzīgi rīki, lai novērstu valodu barjeras, padarītu efektīvāku gan iekšējo, gan ārējo komunikāciju," viņa skaidro. `+
`<dd></dd><h3 class="post-subtitle">Eksporta pamatā ir spēcīgs produkts </h3>`+
`<dd></dd>K. Metuzāle stāsta, ka "Tildes" eksporta pieredze sākās ar spēcīgu produktu, kas vispirms tika piedāvāts Baltijas valstīm, kuru valodas pēc uzbūves ir sarežģītas. Šī pieredze ļāva ienākt arī citu valstu tirgos. Viņa uzskata, ka uzņēmuma panākumu atslēga eksportā ir risinājumi ar unikālu pievienoto vērtību, kas atšķiras no citiem risinājumiem šajā nozarē.`+
`<dd></dd><div class="quote-style">"Mūsu risinājumi labi apmierina klientu vajadzības. Tas mums dod stingru pozīciju tirgū. Valodu tehnoloģiju risinājumi nav visiem pilnīgi skaidri, un ikdienā to lietojums nav pats par sevi saprotams, tāpēc mums ir jāiegulda liels darbs izglītošanā. Tas mums ļauj atvērt daudzas durvis," saka K. Metuzāle. </div>`+
`<dd></dd>Lai atvērtu jaunu eksporta tirgu durvis, "Tilde" dodas tirdzniecības misijās, piedalās dažādos pasākumos, kā arī sadarbojas ar Latvijas Investīciju un attīstības aģentūras (LIAA) pārstāvniecībām ārzemēs. "Šobrīd mēs strādājam ar LIAA pārstāvniecību Norvēģijā, un mums jau ir vairāki potenciālie sadarbības partneri," saka K. Metuzāle, piebilstot, ka vislielākais gandarījums ir tad, kad uzņēmuma slava ir aizgājusi tik tālu, ka klienti "Tildi" uzrunā paši, jo zina, ka viņiem šie risinājumi ir vajadzīgi un sniegs lielu pievienoto vērtību. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristineM3+`"></div>`+

	support_by_liaa,







	exp_review_3_name: "Pieredzes stāsti eksportā uz Zviedriju",
	exp_review_3_company:"UPB, Putnu Fabrika Ķekava, Blindsave",
	exp_review_3_designation: "UPB, Putnu Fabrika Ķekava, Blindsave",
	exp_review_3_excerpt: `Zviedrija ir viena no piecām svarīgākajām Latvijas eksporta partnerēm. Visvairāk Latvija uz Zviedriju eksportē koksni un tās izstrādājumus, metālu un tā izstrādājumus, kā arī būvmateriālus. Starp populārākajām tirdzniecības nozarēm ir arī inženierzinātnes un informācijas tehnoloģiju pakalpojumi.`,
	exp_review_3_title:"Zviedrija — tuvs, stabils un turīgs eksporta tirgus",
	exp_review_3_text:

`<dd></dd>Zviedrija ir viena no piecām svarīgākajām Latvijas eksporta partnerēm. Visvairāk Latvija uz Zviedriju eksportē koksni un tās izstrādājumus, metālu un tā izstrādājumus, kā arī būvmateriālus. Starp populārākajām tirdzniecības nozarēm ir arī inženierzinātnes un informācijas tehnoloģiju pakalpojumi.`+
`<dd></dd>Latvijas Eksportētāju asociācijas The Red Jackets valdes priekšsēdētājs Mārtiņš Tiknuss informē, ka 2022. gadā Latvijas preču un pakalpojumu eksporta apmērs uz Zviedriju sasniedza 1,46 miljardus eiro. Latvijā ražotu preču un pakalpojumu importa īpatsvars veido 1,3 % no kopējā Zviedrijas importa. “Latvijas eksportam uz Zviedriju vēl ir liels izaugsmes potenciāls,” viņš ir pārliecināts. M. Tiknuss šo valsti vērtē kā viegli sasniedzamu, stabilu, prognozējamu un pietiekami turīgu. Vienlaikus zviedru uzņēmumi nereti vēlas preces iepirkt pat lētāk, nekā Latvijas uzņēmumi spēj saražot.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_sweden1+`"></div>`+
`<dd></dd>Latvijas Investīciju un attīstības aģentūras pārstāvniecības Zviedrijā vadītāja Valtera Jēkabsona skatījumā zviedri no Latvijas un Baltijas uzņēmējiem gaida kvalitāti par zemu cenu. Viņiem bieži vien ir entuziasms sākt sarunu, ja kāds produkts interesē, taču viņu gaidītā cena var neatbilst uzņēmēju reālajām iespējām. “Ir svarīgi saprast, vai mēs ar savu cenu vispār varam iekļūt šajā tirgū. Latvijas uzņēmēji spēj radīt kvalitatīvus produktus mazā daudzumā, nevis saražot milzīgu apjomu. Lai gan Zviedrijas tirgus nav gigantisks, Latvijas uzņēmēji māk tam pielāgoties, ko lielie konkurenti varbūt nemaz negrib. Esam tik mazi, ka Zviedrijas tirgus mums ir pietiekami liels,” vērtē M. Tiknuss.`+
`<dd></dd><div class="quote-style">Arī florbola vārtsargu aprīkojuma ražotāja BlindSave īpašnieks Andis Blinds izceļ Zviedrijas labo pirktspēju: “Zviedrijas tirgus ar 10,4 miljoniem iedzīvotāju ir pietiekami liels un maksātspējīgs.”</div>`+
`<dd></dd>Savukārt AS Putnu fabrika Ķekava un SIA Lielzeltiņi eksporta direktors Skandināvijā Dmitrijs Zolotarjovs akcentē, ka Zviedrijā biznesa partneri augstu vērtē lojalitāti, uzticamību, ātru informācijas apmaiņu un uzņēmējdarbības pārredzamību.`+
`<dd></dd>“Gandrīz visi zviedri neatkarīgi no vecuma runā angliski, turklāt dara to labi. Mums visi līgumi pārsvarā ir angļu valodā,” saka rūpnieciskā holdinga UPB valdes loceklis Uģis Grīnbergs. Arī A. Blinds izceļ to, ka visi zviedri prot angļu valodu — viņiem bez problēmām var rakstīt e-pastus angliski. Turpretī, piemēram, Spānijā viņa sūtītos e-pastus izlasa vien tad, ja tie ir spāņu valodā.`+
`<dd></dd><h3 class="post-subtitle">Tuvākais racionālais tirgus</h3>`+
`<dd></dd>“Saliekamā dzelzsbetona konstrukcijas ir apjomīgas un smagas — to transportēšana rada ievērojamas izmaksas, tāpēc rādiuss, kurā tās var efektīvi pārvadāt, ir diezgan neliels. Tādējādi Zviedrija mums ir vistuvākais un racionālākais tirgus. Nozīme bija arī tam, ka zviedriem jau bija pieredze ar saliekamā dzelzsbetona konstrukcijām,” stāsta U. Grīnbergs. Ar šādu motivāciju UPB 2005. gadā sāka eksportu uz Zviedriju, papildus saliekamā dzelzsbetona konstrukcijām piedāvājot arī pašu projektētas un ražotas metālkonstrukcijas un fasāžu risinājumus.`+
`<dd></dd><div class="quote-style">Pirmajā gadā eksporta apgrozījums bija daži miljoni eiro, bet patlaban — jau vairāk nekā 100 miljoni eiro. Zviedrija ir UPB lielākais eksporta tirgus un veido 35–40 % uzņēmuma apgrozījuma.</div>`+
`<dd></dd>Arī D. Zolotarjovs norāda, ka ģeogrāfiski Zviedrija ir viens no tuvākajiem eksporta tirgiem, kas paver plašas uzņēmējdarbības attīstības iespējas. “Vietējie ražotāji spēj nodrošināt vien aptuveni 55–60 % no putnu gaļas patēriņa. Attiecīgi 35–40 % produkcijas tiek importēti — tas mums paver perspektīvu tirgus potenciālu,” viņš saka.`+
`<dd></dd>Savukārt BlindSave uz Zviedriju eksportē savu aprīkojumu florbola vārtsargiem, jo šajā valstī visaktīvāk nodarbojas ar šo sporta veidu. Eksports uz Zviedriju veido 35 % no uzņēmuma apgrozījuma.`+
`<dd></dd><h3 class="post-subtitle">Sakārtota juridiskā vide</h3>`+
`<dd></dd>U. Grīnberga skatījumā uzņēmējdarbības un juridiskā vide Zviedrijā ir diezgan labi sakārtota, ar saprātīgu nodokļu politiku. Raksturojot biznesu Zviedrijā, viņš min UPB piemēru — ar 100 miljonu eiro apgrozījumu un aptuveni 20 projektiem gadā šajā valstī rotācijas kārtībā strādā 200–300 montāžas speciālistu un darbu vadītāju, projektu vadītāju, pārdošanas speciālistu un inženieru, kuri regulāri lido uz turieni, bet uzņēmumam tur nav sava biroja. Tā kā UPB projekti tiek īstenoti visā Zviedrijas teritorijā, vienkāršāk ir visus reģionus apkalpot no Latvijas.`+
`<dd></dd><div class="quote-style">“Viss ir diezgan sakārtots un tipveida — noteikumi par uzņēmējdarbību, nodokļiem, līgumiem, būvniecības, vides un darba drošības prasībām, kārtību un visu, kas notiek būvlaukumā. Es pieļauju, ka līdzīgi ir arī citās nozarēs,” spriež U. Grīnbergs.</div>`+
`<dd></dd>Viņam šķiet, ka zviedri ir viena no retajām nācijām, ar kurām var runāt atklātu valodu, un šādu attieksmi novērtē arī paši zviedri. Ja ir problēmas, par tām var runāt un laikus kopā meklēt risinājumu. “Lielākais pluss ir tas, ka viņi meklē izeju kopā, nevis nostājās pozā “te ir līgums — pildi vai maksā soda naudu”,” pieredzē dalās U. Grīnbergs.`+
`<dd></dd><h3 class="post-subtitle">Lēmumus pieņem kopā</h3>`+
`<dd></dd><div class="quote-style">“Zviedrijā ir ļoti spēcīga lēmumu kolektīvā pieņemšana — visi iesaistīsies, izteiks savas bažas un argumentus un skatīsies, kā lēmums ietekmēs konkrētā speciālista atbildības jomu. Un tikai tad, kad visas puses būs vienojušās, nonāks pie kopīga lēmuma. Tieši tāpēc lēmumu pieņemšanas process Zviedrijā ir lēns,” saka M. Tiknuss.</div>`+
`<dd></dd>Arī U. Grīnbergs norāda, ka zviedri lēmumus pieņem sapulcēs — konsultējoties, runājot, mēģinot izvērtēt visus riskus vēl pirms līguma noslēgšanas vai pat piedāvājuma izteikšanas. Tiek izrunāti gan viņiem pašiem, gan partneriem riskantie jautājumi. Viņš atceras kādu gadījumu, kad klients norādīja uz pozīciju, kur UPB piedāvātā cena bija pārāk zema un aicināja to pārbaudīt vēlreiz. “Citās valstīs, visticamāk, neko tādu nenorādīs, un, ja mēs tiešām būsim aizmirsuši iecenot vienu stāvu vai ko citu, viņi pieņems piedāvājumu un neko neteiks. Savukārt zviedri bieži pievērš uzmanību tam, lai riski jau sākumā būtu skaidri un viss būtu saprotams,” stāsta U. Grīnbergs. M. Tiknuss šo gan nosauc par atsevišķu izņēmuma gadījumu, kāds ir iespējams vien ļoti seniem partneriem. “Savukārt, sākot sadarbību ar jaunu partneri, vairums zviedru nevēlēsies tērēt laiku, lai to apmācītu. Ja partneris jutīs, ka mēs kaut ko nezinām, piemēram, sniedzam divreiz izdevīgāku cenu piedāvājumu nekā pašreizējais vai tieši otrādi — divreiz dārgāku, zviedrs neparādīs, kurā virzienā esam kļūdījušies. Zviedri arī nekad nekaulēsies. Eksportētājiem ir jāzina, kam un ko piedāvā un ar ko konkurē. Ja piedāvājums nebūs adekvātā amplitūdā, nekādu apmācību, atbildi vai atgriezenisko saiti tas nesaņems,” par sadarbības sākuma posmu komentē M. Tiknuss.`+
`<dd></dd><h3 class="post-subtitle">Jāspēj kliedēt visas bažas</h3>`+
`<dd></dd>M. Tiknuss uzsver, ka daudzi Zviedrijas uzņēmumi lielu nozīmi piešķir reputācijai. Tāpēc Latvijas uzņēmumiem, kas grib uz šo tirgu eksportēt, ir jāpiegādā produkcija norunātajā laikā un kvalitātē. “Viņiem ir ļoti, ļoti svarīgi, lai nekas nenoietu greizi, jo viņi riskē ar gadiem ilgi veidotu reputāciju, klientu uzticību un biznesa mērogu, ko var ļoti ātri pazaudēt. Katrs jauns piegādātājs ir riskants. Šis aspekts ir jāņem vērā, ja kāds vēlas eksportēt uz Zviedriju — būs vieglāk strādāt, ja uzņēmējs spēs kliedēt visas bažas un parādīt, ka tam var uzticēties,” viņš skaidro.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_sweden2+`"></div>`+
`<dd></dd>Zviedrijā Latvijas uzņēmumiem ir jākonkurē ne vien ar citiem eksportētājiem, bet arī ar vietējiem zviedru uzņēmumiem. Tāpēc M. Tiknuss aicina izprast tirgus dalībniekus, ar kuriem uzņēmums konkurē, kā arī analizēt, vai tas, ko šobrīd pērk Zviedrijas tirgū, nav pasaules lielo tirgus dalībnieku radīts produkta pielāgojums, ko zviedri ir spiesti pirkt. Iespējams, Latvijas uzņēmēji var uzlabot esošo produktu kvalitāti, īpaši pielāgojot tos Zviedrijas tirgum, un pārdot dārgāk. “Mūsu uzņēmumi var ieņemt specifisku nišu jau esošā kategorijā, pievēršot uzmanību tam, kā produktu piedāvāt kvalitatīvāku,” rosina M. Tiknuss.`+
`<dd></dd><h3 class="post-subtitle">Uzticību iegūst palēnām</h3>`+
`<dd></dd><div class="quote-style">“Sākt sadarbību ar Zviedrijas uzņēmumiem nav viegls un ātrs process — no pirmās tikšanās reizes līdz pirmajam pasūtījumam var paiet pat trīs gadi. Taču, kad tas izdosies, sadarbība būs ilgtermiņa. Tāpēc, strādājot ar šo tirgu, jābūt ļoti pacietīgam,” iesaka D. Zolotarjovs.</div>`+
`<dd></dd>Arī U. Grīnbergs uzskata, ka zviedru uzticību var iegūt lēnām. UPB 2005. gadā darbību šajā tirgū sāka ar diezgan nelieliem objektiem, bet patlaban zviedru ģenerāluzņēmēji uztic Latvijas uzņēmumam lielus, sarežģītus objektus, kuros UPB gan projektē, gan ražo, gan paši piegādā un būvē. “2016. gadā pirmā lielākā augstceltne, ko uzbūvējām — Munksjotornet Jenčepingā — bija 16 stāvu augsta, bet šobrīd esam pabeiguši 36 stāvu augstceltni — CityGate Gēteborgā. Ar mūsu būvētajām augstceltnēm esam mainījuši Zviedrijas lielo pilsētu panorāmu,” lepojas U. Grīnbergs.`+
`<dd></dd>D. Zolotarjovs norāda, ka zviedru uzņēmumi, ja vien viņus viss apmierina, piegādātājus maina reti. Arī M. Tiknuss zina teikt, ka, lai nomainītu esošo ilgtermiņa piegādātāju, zviedri veic ilgu partnera pārbaudīšanu, tāpēc uzticamus ilgtermiņa partnerus nemaina bieži.`+
`<dd></dd><div class="quote-style">“Zviedri ir samērā konsekventi, attiecības veido lēni un ilgtermiņā, ļoti svarīga ir reputācija. Veicot “auksto” uzrunāšanu, rezultāts nāks krietni gausāk, nekā tad, ja uzņēmumu ieteiks kāda persona, kas ir klienta uzticības lokā. Šādā gadījumā līdz lēmuma pieņēmējiem var nokļūt zibenīgi — sevišķi, ja arī klientam ir interese par piedāvāto produktu,” zina teikt V. Jēkabsons. </div>`+
`<dd></dd>Viņa novērojumi liecina, ka Zviedrijā procesus būtiski paātrina personīga pazīšanās, jo īpaši mazu un vidēju uzņēmumu kontekstā.`+
`<dd></dd><h3 class="post-subtitle">Grūtības — dažādo prasību ievērošana</h3>`+
`<dd></dd>“Strādājot Zviedrijā ir vairāk plusu nekā mīnusu. Patlaban sarežģītākais ir salāgot zviedru arodbiedrību prasības ar to, ko mums prasa Latvijas un Eiropas Savienības likumi un noteikumi. Piemēram, kā precīzi sarēķināt atvaļinājumus, ja mūsu darbinieki ir UPB darbinieki Latvijā un strādā būvobjektos gan Zviedrijā, gan Norvēģijā, gan arī Lielbritānijā un prasības katrā valstī ir atšķirīgas,” pieredzē dalās U. Grīnbergs.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_sweden3+`"></div>`+
`<dd></dd>Zviedrijā darba ņēmējam ir obligāti jānoslēdz līgums ar arodbiedrību — bez tā nevar strādāt, un tas ir jāievēro. UPB ir jāpilda arodbiedrību galvenās prasības, bet tās ir paredzētas līgumos, pamatojoties uz vietējā tirgus tradīcijām un noteikumiem. Uzņēmumam ir jāsalāgo vairāku valstu nodokļu, jurisdikcijas, likumu prasības, un tas nav vienkārši. “Tas ir pats sarežģītākais — grāmatā vai līgumā var izlasīt vienu, bet praksē un tradīcijās tur visu dara nedaudz atšķirīgi. Un arī to, kas ir skaidri uzrakstīts, katras reģions var interpretēt citādi. Tāpēc visu laiku ir jākomunicē ar arodbiedrībām, jādiskutē par šiem jautājumiem, pirms katra projekta ir jāizrunā, kāds būs darbalaiks, kāda būs algas likme un citi nosacījumi,” stāsta U. Grīnbergs.`+
`<dd></dd><h3 class="post-subtitle">Miers palīdz uzkrāt turību</h3>`+
`<dd></dd>M. Tiknuss izceļ svarīgu faktu par Zviedrijas tirgu — valstī vairāk nekā 200 gadu nav bijis kara. Tāpēc Zviedrijas uzņēmumiem ir bijusi iespēja veidot pēctecību un uzkrāt savu bagātību. Ja kāds Latvijas uzņēmums izveido sadarbību ar Zviedrijas kompāniju, ir liela iespējamība, ka tas ir ģimenes uzņēmums vairākās paaudzēs. “Zviedru uzņēmumi rīkojas līdzīgi kā patiesi bagāti cilvēki. Visupirms viņi domā par to, kā nepazaudēt to, kas viņiem ir, un tikai pēc tam — kā kaut ko attīstīt un nopelnīt vēl vairāk. Zviedri nedzenas pēc peļņas, jo tā gadu gaitā ir uzkrājusies, nodota no vienas paaudzes nākamajai. Austrumeiropas valstīm, kuras bija iesaistītas karadarbībā, vienkārši fiziski nebija iespējams turpināt uzņēmējdarbību kara skartajās vietās. Zviedrijā tā nebija,” piebilst M. Tiknuss.`+
`<dd></dd><h3 class="post-subtitle">Vēl ir plašas sadarbības iespējas</h3>`+
`<dd></dd>M. Tiknuss uzskata, ka Zviedrijas tirgus nebūt nav pietiekami apgūts: “Lai kuras nozares virzienā mēs skatītos, Latvijas uzņēmumiem ir iespēja atrast savu nišu un izplesties Zviedrijā.” Viņš cer, ka nākotnē Latvija un Zviedrija attīstīs inovāciju programmas, lai abu valstu pārstāvji kopīgi strādātu pie jaunu produktu izstrādes. Līdzīgas programmas ir gan ar Norvēģiju, gan Somiju. “Šādas programmas veicinās abu valstu uzņēmējdarbības attiecību nostiprināšanos,” uzskata M. Tiknuss.`+
`<dd></dd>V. Jēkabsons ir pārliecināts, ka Zviedrija arī turpmāk būs viena no Latvijai nozīmīgākajām tirdzniecības partnerēm. To nosaka līdzīgā mentalitāte, ģeogrāfiskais tuvums, zviedru investoru plašā pārstāvniecība Latvijā, kā arī cenu atšķirības, kas gan laika gaitā sarūk. Lai Latvijas eksporta apjoms uz Zviedriju palielinātos, viņaprāt, ir jāturpina meklēt veidus, kā saražot labas kvalitātes preces par pieejamu cenu. Arīdzan jāpiestrādā pie valsts tēla uzlabošanas. `+
`<dd></dd><div class="quote-style">“Daudziem zviedriem Latvija joprojām neasociējas ar lietām, kuras gribam pārdot. Tāpēc ir svarīgi ar darbiem pierādīt, ka esam uzticami. Ir jādibina sakari, palīdzēs arī mārketings. Par uzņēmumu un tā produktu jābūt plaši pieejamai informācijai. Ļoti nopietni ir jāuztver arī ilgtspējas jautājumi, kas Zviedrijā tiek prasīts visās nozarēs un visos līmeņos,” viņš uzskaita.</div>`+
`<dd></dd>Ja uzņēmums plāno sākt eksportu uz Zviedriju, tas var konsultēties ar Latvijas Investīciju un attīstības aģentūras pārstāvniecības Zviedrijā vadītāju Valteru Jēkabsonu vai Gateway & Partners partneri Lauru Valteri.`+

	support_by_liaa,


	exp_review_4_name: "Pieredzes stāsti eksportā uz Vāciju",
	exp_review_4_company:"EKJU, Karavela, Labrains",
	exp_review_4_designation: "EKJU, Karavela, Labrains",
	exp_review_4_excerpt: `2022. gadā Vācija bija Latvijas uzņēmumu trešais lielākais eksporta tirgus. Galvenās eksporta nozares ir kokrūpniecība un dažādi koksnes izstrādājumi, metāls un mašīnbūve, pārtika. Pamazām to pulkam pievienojas arī citas nozares – ķīmiskā rūpniecība un informācijas tehnoloģiju pakalpojumi.`,
	exp_review_4_title:"Vācija – eksporta tirgus pacietīgiem uzņēmumiem ar nopietniem nolūkiem",
	exp_review_4_text:

`<dd></dd>2022. gadā Vācija bija Latvijas uzņēmumu trešais lielākais eksporta tirgus. Galvenās eksporta nozares ir kokrūpniecība un dažādi koksnes izstrādājumi, metāls un mašīnbūve, pārtika. Pamazām to pulkam pievienojas arī citas nozares – ķīmiskā rūpniecība un informācijas tehnoloģiju pakalpojumi.`+
`<dd></dd>“Vācijas tirgus Latvijas uzņēmējiem vienmēr ir bijis svarīgs, turklāt tā nozīme pieaug. Skaitļi runā paši par sevi – pērn Latvijas uzņēmēji uz Vāciju eksportēja preces gandrīz 900 miljonu eiro vērtībā,” saka Vācijas-Baltijas Tirdzniecības kameras Igaunijā, Latvijā, Lietuvā (AHK) preses un sabiedrisko attiecību vadītāja Līva Melbārzde.`+
`<dd></dd><h3 class="post-subtitle">Uzticības iegūšana prasa laiku</h3>`+
`<dd></dd>“Lai gan Vācija ir ļoti iekārojams tirgus un daudzi uzņēmēji vēlas tur būt, šajā valstī ir jābūt pacietīgiem.`+
`<dd></dd><div class="quote-style">Vāciešiem ir nepieciešams ilgs laiks, lai uzticētos jaunam partnerim. Taču tad, kad uzticība ir iegūta, var paļauties, ka šī būs ilgtermiņa sadarbība,” spriež L. Melbārzde.</div>`+
`<dd></dd>Viņa zina teikt, ka ir gadījumi, kad Latvijas uzņēmumi, kas jau veiksmīgi eksportē uz citām valstīm, saprot, ka Vācijā lietas notiek mazliet atšķirīgi. “Ir nepieciešams ieguldīt laiku tieši Vācijas tirgus un konkurentu izpētē. Ir rūpīgi jāizstrādā stratēģija, izpētot vissīkākās nianses, arī dažādus noteikumus, kas attiecas uz darbību šajā valstī. Tas ir laikietilpīgs process, kas prasīs vismaz gadu,” brīdina L. Melbārzde.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany1+`"></div>`+
`<dd></dd>Vācijā ieteicams rīkoties līdzīgi kā Amerikas Savienotajās Valstīs: izvēlēties vienu lielo pilsētu vai vismaz vienu federālo zemi un sākt eksportu tur. “Vācija ir dažāda – starp federālajām zemēm ir lielas atšķirības gan mentalitātē, gan biznesā, gan ekonomikā. Tāpēc eksportā noteikti nevajadzētu mērķēt uzreiz uz visu Vācijas tirgu. Tas vienkārši var būt par lielu,” pieļauj L. Melbārzde.`+
`<dd></dd><h3 class="post-subtitle">Jābūt disciplīnai visos līmeņos</h3>`+
`<dd></dd>“Vācijas tirgus ir konservatīvs. Tur ir diezgan izteikta hierarhija un, piemēram, tiek sagaidīts, ka direktors runās ar direktoru. Tas gan nenozīmē, ka tirdzniecības pārstāvim neizveidosies labs kontakts ar direktoru,” spriež L. Melbārzde.`+
`<dd></dd>Vācija ir dārza mēbeļu ražotāja SIA EKJU trešais lielākais tirgus un veido 14 % no apgrozījuma. Uzņēmuma valdes priekšsēdētājs Māris Danieks spriež, ka katrs klients ir citāds un to prasības ir atšķirīgas, bet kopumā biznesa vide Vācijā esot stingra. `+
`<dd></dd><div class="quote-style">“Domājot par eksportu uz Vāciju, ir jābūt disciplīnai visos līmeņos. Visiem lēmumiem ir jābūt labi apdomātiem. Visam ir jābūt izrēķinātam – katram gramam, centimetram, miligramam,” viņš uzsver. Prasības ir striktas – uzņēmumam jābūt apdrošinātam, vajadzīgs audits no klienta, produktiem jābūt sertificētiem – bieži vien tikai konkrētā Vācijas iestādē, turklāt var gadīties, ka šis sertifikāts nebūs derīgs citos tirgos.</div>`+
`<dd></dd> “Ir stingra piegāžu sistēma – preces ir jāpiegādā precīzi laikā un precīzā apjomā. Ja plkst. 14.35 ir jāpiebrauc pie izkraušanas rampas, tad ir jāpiebrauc tieši šajā laikā. Vēlāk tevi nepieņems, jo rindā jau ir nākamais. Viss ir disciplinēti, pēc grafika. Sankcijas par nepiegādātiem produktiem vai termiņu kavēšanu ir visos tirgos, bet citās valstīs ir iespējams vienoties, ka šīs sankcijas nepiemēro. Vācijā tā nav,” saka M. Danieks, piebilstot, ka visos gadījumos ir jārēķinās ar pēcapmaksu, turklāt tās periods ir garš. SIA EKJU ir klienti, kuriem ir pat sešu mēnešu pēcapmaksas periods.`+
`<dd></dd>M. Danieks pēc pieredzes zina teikt, ka daudzi klienti neveic pasūtījumu, kamēr nav atbraukuši uz rūpnīcu un veikuši auditu: “Viņi grib redzēt, vai uzņēmums tiešām pats ražo un kādās telpās tas notiek. Klienti šādā veidā mēģina novērst riskus. Piemēram, vai ražotājam no Latvijas ir nepieciešamā kapacitāte izpildīt lielu pasūtījumu. Partneri pārliecinās, ka esi gana liels ražotājs un spēsi saražot vajadzīgo apjomu. Tikai tad sākas sarunas par darījumu veikšanu.”`+
`<dd></dd><h3 class="post-subtitle">Rūpīgi jāraksta un jālasa līgumi</h3>`+
`<dd></dd>“Vācieši ir principiāli. Esmu nonākusi pie secinājuma, ka ar vāciešiem pirms līguma noslēgšanas jāmēģina izrunāt visas iespējamās situācijasun tas viss jāparedz līgumā. Slēdzot līgumu, jābūt ar stingru mugurkaulu, jāpastāv par savām interesēm,” norāda kosmētikas ražotāja SIA Labrains vadītāja Līga Brūniņa.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany2+`"></div>`+
`<dd></dd>Arī M. Danieks teic, ka Vācijā liela nozīme ir līgumā rakstītajam. SIA EKJU pieredzē ir dārga mācība, kad uzņēmums kļūdījās un noteica pārāk zemu cenu, turklāt tas bija mirkli pirms Covid-19 pandēmijas, kas izraisīja izejvielu cenu celšanos. Partneris uzskatīja, ka tas nav force majeur, un solīto nācās izpildīt. “Mēs tikām galā, bet mācība ir tāda: kad kaut ko paraksti, atkāpšanās ceļa nav,” novērojis uzņēmējs.`+
`<dd></dd><h3 class="post-subtitle">Vācu valodas zināšanas – vēlamas</h3>`+
`<dd></dd>“Mērķējot uz Vācijas tirgu, vācu valodas zināšanas ir ļoti vēlamas. Protams, Vācijas uzņēmēji pārsvarā runā arī angliski, bet, lai radītu uzticību, valodai ir liela nozīme. Tāpēc pirmajam solim ir vajadzīga vācu valoda. Pēc tam, protams, detaļas var runāt, piemēram, arī angliski, bet, lai nodibinātu kontaktu un sāktu attiecības, vācietim ir jājūt, ka no partnera puses ir spēja komunicēt vācu valodā. Tas attiecas arī uz mājaslapu – būtu labi, ja tai būtu arī vācu valodas versija,” rekomendē L. Melbārzde.`+
`<dd></dd><div class="quote-style">M. Danieks spriež, ka bez vācu valodas būtu krietni grūtāk strādāt Vācijas tirgū. “Protams, vācieši runā angliski, taču vācu valodas zināšanas ir atslēga šajā tirgū. Bez tās Vācijā nav vērts tērēt laiku,” viņš uzskata.</div>`+
`<dd></dd>Šobrīd L. Brūniņai nav vācu valodas zināšanu. “Taču es zinu, ka tās ir vajadzīgas. Varbūt Berlīnē var iztikt bez vācu valodas, bet citur vācu valodas zināšanas ir svarīgas,” viņa secina.`+
`<dd></dd><h3 class="post-subtitle">Vakariņu sarunas būs ilgas</h3>`+
`<dd></dd>“Ja tiek saņemts uzaicinājums uz vakariņām, ir jārēķinās, ka tās būs diezgan garas – vāciešiem patīk ilgi un pamatīgi runāt. Šādam pasākumam ir jāatvēl laiks,” norāda L. Melbārzde. Viņa stāsta, ka vācieši ir atvērti saviesīgām sarunām. Tāpēc, ja gribas nodibināt kontaktu, jau iepriekš ir jāsagatavo stāsts par savu uzņēmumu, savu valsti, kā arī par sevi. “Bieži vien kontaktu dibināšanā vāciešiem ir svarīgi saprast, piemēram, kāds ir partnera ģimenes statuss. Viņi šajā ziņā nav aizspriedumaini, bet sarunās ir normāli pievērsties tēmām, kas skar dzīvi ārpus darba,” teic L. Melbārzde.`+
`<dd></dd>Vācija ir plaša zeme – nevajag pieņemt, ka visi vācu uzņēmēji ir vācieši pēc tautības. Tāpēc viņa iesaka pie viesību galda necilāt striktus politiskos vai reliģiskos uzskatus.`+
`<dd></dd><div class="quote-style">“Reizēm vāciešu skatījums uz Latviju ir balstīts uz maldīgiem priekšstatiem, tāpēc nevajadzētu būt pārāk kritiskiem pret sevi un savu valsti. Labāk visu stāstīt no pozitīvā rakursa, ka mēs esam interesanti, moderni, progresīvi. Parasti viņiem patīk stāsti par mūsu digitalizācijas sasniegumiem, piemēram, valsts pārvaldes kontekstā. Atšķirībā no mums viņiem ir krietni vairāk papīru. Šajā jomā mēs esam jau soli tālāk nekā viņi – vācieši mūs vērtē kā interesantu piemēru,” spriež L. Melbārzde.</div>`+
`<dd></dd>Ja sarunas partnerim ir doktora grāds, L. Melbārzde iesaka sarunā šo personu tā arī uzrunāt – par doktoru. Izņēmums – ja cilvēks pats piedāvā pāriet uz vienkāršāku uzrunu. “Parasti viņiem šie tituli ir nozīmīgi. Arī formālā uzruna vāciešiem ir svarīga, līdz tiek abpusēji piedāvāts pāriet no Jūs uz Tu,” zina teikt L. Melbārzde.`+
`<dd></dd><h3 class="post-subtitle">Regulāri jādodas pie klientiem</h3>`+
`<dd></dd>Kosmētikas ražotājam SIA Labrains ir 16 produktu, un uzņēmums tos visus eksportē uz Vāciju. Šobrīd Vācijas tirgus veido ap 5 % no apgrozījuma. “Mēs sākām eksportēt tikai 2022. gada septembrī. Paiet laiks, kamēr patērētāji iepazīstas ar mūsu produktiem, bet mēs jau redzam, ka ir atkārtoti pasūtījumi un viss virzās uz priekšu. Es būšu priecīga, ja mūsu eksports uz Vāciju šī gada laikā sasniegs 15 % no apgrozījuma. Ar katru mēnesi apjoms pieaug aptuveni par 3 % mēnesī,” stāsta L. Brūniņa.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany3+`"></div>`+
`<dd></dd>Uzņēmums sadarbojas ar tirdzniecības aģentu, kas strādā ar mazumtirdzniecības veikaliem un nodrošina komunikāciju ar partneriem. Ja SIA Labrains izlems par labu sadarbībai ar kosmetologiem vai dermatologiem, visticamāk, būs vajadzīgs arī izplatītājs. Lai gan uzņēmumam Vācijas tirgū ir aģents, L. Brūniņa tik un tā regulāri dodas uz šo valsti. “Šobrīd aktīvi attīstās mūsu produktu eksports uz Vāciju, tāpēc es lidoju uz turieni vismaz reizi divos mēnešos. Tikai esot uz vietas, var iepazīt cilvēkus, veidot kontaktus, tīkloties. Braucot jau otro, trešo, ceturto reizi, sāk veidoties personiskas sarunas, un šie cilvēki iepazīstina ar citiem. Eksports prasa laiku,” viņa saka.`+
`<dd></dd><h3 class="post-subtitle">Parāda un pierāda sevi izstādēs</h3>`+
`<dd></dd>Daudziem uzņēmumiem pirmais solis Vācijas tirgus apguvē ir izstādes. SIA Labrains jau divas reizes ir piedalījies Eiropas lielākajā dabiskās kosmētikas izstādē Vivaness Biofach Vācijā. L. Brūniņas skatījumā šādi var dibināt kontaktus un veidot partnerattiecības. Pēc daudzu gadu pieredzes M. Danieks ir novērojis, ka uzņēmums tiek ilgi vērots. “Pirmajā gadā pie stenda aprunāties atnāk tikai daži ārzemnieki. Vācieši nāk paskatīties otrajā gadā un tikai trešajā gadā sēžas pie sarunu galda,” viņš teic.`+
`<dd></dd>Arī zivju konservu ražotājam SIA Karavela Vācijas tirgu izdevās apgūt, braucot uz izstādēm. “Mūsu eksports Vācijā sākās 2009. gadā, kad mēs devāmies uz starptautisko izstādi Anuga. Mēs dažādu uzņēmumu pārstāvjiem, kam bija līdzīgas konservu bundžas kā mums, gājām klāt un teicām: “Labdien! Mēs esam no Latvijas, mēs gribētu jums kaut ko uzražot!” Lai cik dīvaini nebūtu, bija daži, kas teica: “Labi, mums ir interese!”,” eksporta pirmsākumus atceras SIA Karavela attīstības direktors Jānis Endele. Taču nav tādas vienas universālas izstādes, kas derēs katram uzņēmumam. Tāpēc viņš iesaka rūpīgi izvēlēties izstādes, kurās piedalīties.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany4+`"></div>`+
`<dd></dd>Latvijas pārstāvji regulāri piedalās arī grāmatu izstādē Frankfurt Book Fair, kas šogad notiks no 18. līdz 22. oktobrim. Šajā izstādē arī šogad būs Latvijas Investīciju un attīstības aģentūras organizēts Latvijas nacionālais stends – uzņēmēji var saņemt valsts atbalstu dalībai tajā. Tāpat iecienīta ir bērnu mēbeļu izstāde Kind + Jugend 2023, kas šogad notiks no 7. līdz 9. septembrim. Arī tajā būs Latvijas nacionālais stends. Uzņēmēji var piedalīties arī ar individuālu stendu vai doties konkurentu un nozares izpētes braucienā. Arī šādām darbībām vēl līdz jūnijam ir pieejams Latvijas Investīciju un attīstības aģentūras administrēts valsts atbalsts Starptautiskās konkurētspējas veicināšanas programmā.`+
`<dd></dd><h3 class="post-subtitle">Četros gados dubulto eksporta apjomu</h3>`+
`<dd></dd>2022. gadā SIA Karavela apgrozījums Vācijā bija gandrīz 14 miljoni eiro. No kopējā apgrozījuma tie bija nepilni 20 %. Gadā Vācijas tirgus patērē ap 300 miljoniem bundžu siļķu filejas dažādās mērcēs. Šogad SIA Karavela saražos aptuveni 10 miljonu no tā – 3 %. Uzņēmums mērķē uz 10 % no tirgus gada apjoma – līdz 30 miljoniem bundžu.`+
`<dd></dd>2019. gadā SIA Karavela nopirka Vācijas trešo lielāko zivju produktu ražotāju Larsen Danish Seafood. Šodien ar Larsen zīmolu uzņēmums ir tirgus līderis trīs produktu – šprotu, laša un mīdīju konservu – grupās. “Kopš 2019. gada mēs esam dubultojuši eksporta apjomu Vācijā. Tas mums ļauj pragmatiski prognozēt, ka nākamajos četros gados mūsu darba uzdevums ir atkal dubultot apgrozījumu šajā tirgū,” saka J. Endele.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany5+`"></div>`+
`<dd></dd>J. Endele arī uzskata, ka SIA Karavela paraugs ar ielaušanos Vācijas tirgū liecina: tas pats ir iespējams citiem pārtikas uzņēmumiem, piemēram, gaļas vai piena produktu ražotājiem. `+
`<dd></dd><h3 class="post-subtitle">Līdz veikala plauktam ir vairāki ceļi</h3>`+
`<dd></dd>J. Endele skaidro, ka Latvijas pārtikas ražotājam Vācijā ir četri iespējamie biznesa virzieni:`+
`<ul><li>emigrantu tirgus;</li><li>lielo veikalu ķēžu privātās preču zīmes;</li><li>sadarbība ar izplatītājiem;</li><li>sadarbība ar specializēto bio produktu iepircējiem.</li></ul>`+
`<dd></dd><div class="quote-style">“Emigranti, kas pērk mums pazīstamu garšu produktus, ir ļoti maza tirgus daļa, turklāt tā katru gadu sarūk. Taču tas ir pirmais solis, kā sākt eksportu,” viņš norāda.</div>`+
`<dd></dd>Ar aģentu starpniecību SIA Karavela produkcija ir nopērkama Aldi, Lidl veikalos. “Mēs esam eksperti produkta ražošanā, aģents – pārdošanā. Kad partneris mums iesaka darīt citādi, esam iemācījušies pieņemt racionālu lēmumu. Aģents zina labāk – ja jau viņš nav beidzis savu pastāvēšanu, tad kaut ko saprot šajā biznesā. Protams, pieņemt spēles noteikumus, kaut gan pašam šķiet, ka zini labāk, nav vienkārši. Ir jāuzticas partnerim, kas zina otru biznesa pusi,” iesaka J. Endele.`+
`<dd></dd>Savukārt izcils iepircējs, pēc viņa vārdiem, ir cilvēks, kurš saprot, ko patērētājs ēdīs: `+
`<dd></dd><div class="quote-style">“Līdz ar to ražotājam ir jāmāk piedāvāt kaut ko unikālu – kamēr tavs stāsts iepircēju nepārliecinās, plauktā netiksi.”</div>`+
`<dd></dd><h3 class="post-subtitle">Produktu izstrāde ir sarežģīta</h3>`+
`<dd></dd>“Tas ir mīts, ka uzņēmums Latvijā var radīt produktu, kas vācietim patīk. 2019. gadā, kad nopirkām Vācijas zivju pārstrādes uzņēmumu Larsen Danish Seafood, domājām – ražosim tādus un tādus produktus! Patiesībā neviens no tiem nav bijis īsti veiksmīgs,” atzīst J. Endele. Uzņēmējs skaidro, ka produktu izstrāde šim tirgum ir sarežģīta; tā prasa laiku un analīzi. Ir produkti, ko vācieši vēsturiski ir pieraduši ēst. Tas ietver dažādus aspektus, kaut vai – iepakojumu. Kad nāca krīze un cenas visam kāpa, SIA Karavela izdomāja siļķu konserviem atstāt to pašu cenu, bet piedāvāt produktu mazākās bundžās. “Ieguldījām daudz laika un līdzekļu, bet beigās čiks vien sanāca, jo vācieši to nepieņem. Viņi salīdzina 200 un 125 gramu bundžas cenu un, ja mazais iepakojums maksā tikpat, cik lielais, ir gatavi pamēģināt. Parasti mazam iepakojumam cena ir augstāka, bet vācietim nav vajadzīgs dārgāks produkts,” stāsta J. Endele. Taču viņš nemet plinti krūmos: “Pat ja nesanāk, visu laiku ir jāmeklē inovācijas un produkti ar pievienoto vērtību.”`+
`<dd></dd>Savukārt M. Danieks piebilst, ka klientu prasības kvalitātes un dizaina jomā Vācijā ir daudz augstākas nekā, piemēram, Francijā vai Lielbritānijā. “Vācieši ir prasīgāki, viņus vairāk interesē augsta kvalitāte. Vācijas klienti prasa klasiskāku, bet kvalitatīvu produktu,” viņš atklāj.`+
`<dd></dd><h3 class="post-subtitle">Jāizpēta veikalu plaukti</h3>`+
`<dd></dd>J. Endele citiem uzņēmējiem iesaka vispirms aizbraukt uz Vāciju un veikt veikalu izpēti. Vienas dienas laikā ir iespējams apskatīt visu galveno tirgus spēlētāju veikalus. Viņš rosina veikt t. s. plauktu lasīšanu: nofotografēt veikalu plauktus ar konkrētās kategorijas produktiem, nopirkt visu konkurējošo piedāvājumu savā segmentā. Ja līdzīgu produktu nav, neesot vērts tērēt laiku un censties piedāvāt ko jaunu. Un, atbraucot mājās, visi konkurentu produkti ir jānogaršo un jāanalizē to iepakojums, cena, garša, mārketings.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany6+`"></div>`+
`<dd></dd>Nākamais solis ir potenciālo partneru uzrunāšana. Taču Vācijā, tāpat kā citos tirgos, esošajam piegādātājam vienmēr ir priekšrocības. “Vācijā iepircēji ir konservatīvi. Ja partneri viss apmierina, tas savus esošos piegādātājus maina nelabprāt. Un, ja vien ražotājs nav sastrādājis kaut kādas problēmas, vienāda cenas piedāvājuma gadījumā vienmēr paliks esošais piegādātājs. Ja tirgotājs nav pieņēmis lēmumu mainīt ražotāju, ir sarežģīti tikt veikala plauktā,” zina teikt J. Endele.`+
`<dd></dd><h3 class="post-subtitle">Solis tuvāk eksportam – iesaistīšanās nozares apvienībā</h3>`+
`<dd></dd>L. Melbārzde piebilst, ka vāciešiem patīk dažādas apvienības, asociācijas, klasteri un interešu grupas. Tāpēc Latvijas uzņēmējiem, kas plāno eksportēt uz Vāciju, viņa iesaka iesaistīties savai nozarei atbilstošās organizācijās. “Vācijas biznesa vidē ir diezgan tipiski apvienoties šādās organizācijās, tajā skaitā arī tirdzniecības un rūpniecības kamerās. Tas ir arī noteikts pluss mūsu Vācijas-Baltijas Tirdzniecības kameras biedriem – šī kamera darbojas vairāk nekā 90 pasaules valstīs. Kad vācieši redz, ka uzņēmums ir tās biedrs, pieņem to kā savējo,” teic L. Melbārzde.`+
`<dd></dd>M. Danieks ir pārliecināts, ka Vācijā biznesa potenciāls Latvijas uzņēmumiem ir milzīgs: “Atliek vien strādāt, lai šo tirgu apgūtu.”`+

	support_by_liaa,


	exp_review_5_name: "Pieredzes stāsti eksportā uz Norvēģiju",
	exp_review_5_company:"HansaMatrix, Tenax Panel, Dores Fabrika",
	exp_review_5_designation: "HansaMatrix, Tenax Panel, Dores Fabrika",
	exp_review_5_excerpt: `Norvēģija ir Eiropas piektā lielākā valsts. Izrādās, tās nosaukums nozīmē “ceļš uz ziemeļiem”. Latvijas iedzīvotājiem šī ziemeļzeme galvenokārt asociējas ar vikingiem, fjordiem, troļļiem, taču arī Latvijas un Norvēģijas ekonomiskās sadarbības saites nav mazsvarīgas un kļūst arvien ciešākas.`,
	exp_review_5_title:"Latvijas eksporta ceļš uz ziemeļiem. Norvēģijas potenciāls vēl aizvien ir liels",
	exp_review_5_text:

`<dd></dd>Norvēģija ir Eiropas piektā lielākā valsts. Izrādās, tās nosaukums nozīmē “ceļš uz ziemeļiem”. Latvijas iedzīvotājiem šī ziemeļzeme galvenokārt asociējas ar vikingiem, fjordiem, troļļiem, taču arī Latvijas un Norvēģijas ekonomiskās sadarbības saites nav mazsvarīgas un kļūst arvien ciešākas.`+
`<dd></dd>Līdz šim Norvēģijā vislabāk bija zināmi Latvijas tradicionāli spēcīgo nozaru, piemēram, būvniecības un kokrūpniecības, uzņēmumi. Taču pēdējos gados izceļas produkti ar augstu pievienoto vērtību.`+
`<dd></dd><div class="quote-style">“Redzam, ka līdztekus spēcīgai un augošai abu valstu ekonomiskajai sadarbībai aizvien vairāk paplašinās arī kopīgi pētniecības un zinātnes projekti — jau patlaban virkne Latvijas uzņēmumu sadarbojas ar Norvēģijas neatkarīgo pētniecības institūtu SINTEF, kas ir lielākais Eiropā,” stāsta Latvijas Investīciju un attīstības aģentūras (LIAA) pārstāvniecības Norvēģijā vadītāja Evita Nedzvecka. </div>`+
`<dd></dd><h3 class="post-subtitle">Viens no straujāk augošajiem eksporta tirgiem</h3>`+
`<dd></dd>“Latvijas uzņēmumu interese par eksporta iespējām Norvēģijā un Ziemeļvalstīs strauji pieaug. Tas ir skaidrojams ne vien ar ģeogrāfisko tuvumu, bet arī ar līdzīgo komunikācijas un biznesa kultūru,” vērtē LIAA pārstāvniecības Norvēģijā vadītājas vietnieks Austris Keišs. Arī elektronisko sistēmu un to komponentu ražotājas AS HansaMatrix valdes priekšsēdētājs Jānis Sams spriež, ka līdzīgā domāšana padara sadarbību ar partneriem Norvēģijā sekmīgāku nekā, piemēram, Itālijā vai Spānijā. “Mēs esam mēģinājuši eksportēt arī uz šīm valstīm, bet tur domāšanas atšķirības noveda pie tā, ka darbi nevirzījās mums ierastajā ātrumā. Temperaments ir pavisam cits, un esam pamanījuši, ka ar skandināviem mums veicas labāk,” viņš atklāj. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway1+`"></div>`+
`<dd></dd>Augsto tehnoloģiju nozarē svarīgs ir fiziskais tuvums, kas ļauj risināt nepieciešamos jautājumus klātienē — klients no rīta var ierasties ražotnē un jau vakarā būt atpakaļ mājās. J. Sams uzsver, ka tiešsaistes vide ir ļoti ērta, bet ne visu var izdarīt videozvana laikā. A. Keišs papildina: “Ģeogrāfiskais novietojums nodrošina labas loģistikas iespējas — ātrākas piegādes, zemākus riskus un konkurētspējīgākas izmaksas. Protams, neaizmirsīsim, ka Latvijā ir Baltijas valstīs lielākā lidosta un Oslo no Rīgas ir tikai pusotras stundas lidojuma attālumā.”`+
`<dd></dd><h3 class="post-subtitle">Koksne, metāls, grāmatas</h3>`+
`<dd></dd>Koksne un tās izstrādājumi veido 23 % no Latvijas uzņēmumu kopējā eksporta uz Norvēģiju, bet metāla izstrādājumi — 17 %. E. Nedzvecka stāsta, ka Norvēģija importē daudz būvmateriālu, jo būvniecība ir viens no veidiem, kā valsts stimulē ekonomiku. Līdz ar to, kad Norvēģijā ir daudz valsts pasūtījumu celtniecības nozarē, ievērojami palielinās Latvijas eksporta apjoms uz šo valsti. `+
`<dd></dd><div class="quote-style">Z. Fogelmane atsaucas uz kāda klienta teikto: “Katram norvēģim ir laiva jūrā, vasarnīca kalnos un traktors, kam ir vajadzīgs angārs.” Viņa norāda, ka šobrīd Norvēģijas tirgus ir aktīvs un industriālo ēku būvniecībā ir redzams stabils pieprasījums.</div>`+
`<dd></dd>Savukārt, ieejot teju jebkurā grāmatnīcā Norvēģijā un atverot kādu grāmatu, ir diezgan liela varbūtība, ka tā būs iespiesta Latvijā. “Drukāšanas pakalpojumi un iespieddarbi veido 7 % no Latvijas eksporta uz Norvēģiju,” saka A. Keišs.`+
`<dd></dd><h3 class="post-subtitle">Latvijā ražotos produktus izmanto lielāko ēku būvniecībā</h3>`+
`<dd></dd>Siltumizolācijas materiālu ražotājam SIA Tenax Panel pirmie projekti Norvēģijā bija jau 2008. gadā. “Īstenojot vairāku privātmāju projektus, uzreiz izkristalizējās šim tirgum piemēroti produkti — sendvičpaneļi un lentveida pamatu sistēmas, kā arī Tenax grupas uzņēmuma Tenapors ražotās būvju pamatu sistēmas,” saka SIA Tenax Panel Eksporta pārdošanas vadītāja Zaiga Fogelmane.`+
`<dd></dd>Patlaban uzņēmuma galvenais produkts Norvēģijā ir sendvičpaneļi sienām, griestiem, jumtiem, kā arī pašu ražotās detaļas. Uzņēmums arīdzan sniedz inženierpakalpojumus, nodrošinot rasējumus, projekta dizainu un klientu konsultēšanu. Savukārt Tenax grupas uzņēmums Tenax Install sniedz montāžas, projektu vadības un inženierpakalpojumus.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway2+`"></div>`+
`<dd></dd>Pašlaik Norvēģija veido aptuveni 30 % no Tenax Panel apgrozījuma Skandināvijas tirgū. Uzņēmuma lielākais veiksmes stāsts Norvēģijā ir minerālvates pildījuma sendvičpaneļu piegāde tirdzniecības centram Lagunen Bergenā, kas 2018. gadā bija lielākais Norvēģijā. Uzņēmuma produkcija tika izmantota arī tobrīd lielākajā loģistikas centrā — Europris Mosā. “Šogad piegādājam produkciju divām loģistikas parka Drøbak ēkām, kuru kopējā platība ir 21 tūkstotis kvadrātmetru. Lepojamies, ka varam apgādāt tik ievērojamus projektus,” saka Z. Fogelmane.`+
`<dd></dd><h3 class="post-subtitle">Eksportēt pārtiku ir izaicinoši</h3>`+
`<dd></dd>“Jārēķinās, ka ne visām nozarēm Norvēģija ir pateicīgākais tirgus. Kā piemēru varu minēt pārtiku, kas ir viena no Norvēģijas stratēģiskajām nozarēm, kurā vietējā valdība ar dažādu instrumentu palīdzību cenšas atbalstīt vietējos ražotājus, arī apgrūtinot ārējo spēlētāju ienākšanu tirgū,” stāsta E. Nedzvecka. `+
`<dd></dd><div class="quote-style">Piemēram, siera kategorijā importētāju saraksts Norvēģijā ir diezgan īss — tie ir uzņēmumi, kuri izsoles kārtībā ir ieguvuši importa kvotas un var importēt produkciju bez ievedmuitas. Pārējiem ievedmuita var būt pat 277 % no siera kilograma cenas, tādējādi padarot šo produktu daudz dārgāku un mazāk konkurētspējīgu nekā vietējo ražojumu. </div>`+
`<dd></dd>Tāpēc pārtikas eksports uz Norvēģiju ir tikai 3 %, turklāt par to parūpējas daži uzņēmumi — Atlas Premium, kas no Norvēģijas iepērk zivis, pārstrādā un pēc tam sūta atpakaļ, un Orkla, kam Latvijā ir ražotnes.`+
`<dd></dd>“Ja pārtikas uzņēmums pēta eksporta iespējas Ziemeļvalstīs, Norvēģija noteikti nav labākais tirgus, ar ko sākt, jo veicamo soļu skaits un grūtības pakāpe ir augstāka nekā, piemēram, blakus esošajā Zviedrijā un Somijā, kur pārtikas eksportētājiem veicas daudz labāk,” piebilst E. Nedzvecka.`+
`<dd></dd><h3 class="post-subtitle">Norvēģija nav Eiropas Savienībā</h3>`+
`<dd></dd>“Saistībā ar to, ka Norvēģija nav Eiropas Savienībā, virknē nozaru ir jārēķinās ar papildu birokrātisko slogu. Turklāt daudzi sektori Norvēģijā tiek aizsargāti, lai atbalstītu vietējos ražotājus,” teic E. Nedzvecka.`+
`<dd></dd>J. Sams piebilst, ka Norvēģija nav arī eirozonā, tāpēc valūtas kursa svārstības reizēm rada negaidītus zaudējumus. “Taču tas īsti nav ietekmējams un kontrolējams. Tā ir arī visās pārējās valstīs, kur ir cita valūta,” viņš saka. Vēl J. Sams iesaka neaizmirst par transfertcenu un, jau slēdzot līgumu, rekomendē apdomāt vienas valūtas, piemēram, eiro, izmantošanas iespēju. “Tad nav lieku pārsteigumu. Citreiz tie var būt pozitīvi, bet citreiz — negatīvi. Un par negatīvajiem uzņēmējam ir jāmaksā,” viņš teic.`+
`<dd></dd><h3 class="post-subtitle">Valodas zināšanas — noderīgs bonuss</h3>`+
`<dd></dd>“Norvēģija ir kārdinošs eksporta tirgus ne tikai Latvijas, bet arī daudzu citu valstu uzņēmumiem. Tāpēc kā vienu no sākotnējiem potenciālo sadarbības partneru atlases filtriem norvēģi izmanto tieši valodu, tādēļ bieži vien priekšrocība būs tiem, kas norvēģus var uzrunāt viņu valodā. Latvijā interese par norvēģu, zviedru un citām skandināvu valodām pieaug, kas paver arī aizvien plašākas ekonomiskās sadarbības iespējas,” secina A. Keišs.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway3+`"></div>`+
`<dd></dd>J. Sams teic, ka vietējie runā savā mēlē: “Ja ārzemju uzņēmumu pārstāv vietējais, tas stiprina uzticību.” HansaMatrix Norvēģijā sadarbojas ar pārdošanas aģentu. Viņš strādā nedaudz ilgāk kā gadu, un šobrīd ir grūti novērtēt, kā šī sadarbība ietekmē biznesa rezultātus. Taču J. Sams secina, ka ir svarīgi, lai tirdzniecības aģents būtu ne vien vietējais, bet arī spētu pārstāvēt uzņēmumu tehnoloģiskā līmenī. “Aģents nevar būt tikai pārdevējs — jo tehniskāks uzņēmums, jo labāk jāsaprot, ko tas var piedāvāt. Aģenta radītais pirmais iespaids ir mūsu vizītkarte. Ja viņš ir tehniski kompetents, ir lielākas iespējas, ka būs rezultāts,” teic J. Sams.`+
`<dd></dd><h3 class="post-subtitle">Ciena privāto dzīvi</h3>`+
`<dd></dd>E. Nedzvecka akcentē, ka norvēģiem ļoti svarīgi ir jautājumi, kas skar atpūtu, darbinieku mentālo labsajūtu, kā arī privātās dzīves un darba līdzsvaru. Lai gan panākumi darbā un biznesā ir svarīgi arī norvēģiem, tomēr ne mazāk būtiska norvēģu dzīves daļa ir fiziskās aktivitātes, hobiji, ceļošana un kopīgi pavadīts laiks ar ģimeni.`+
`<dd></dd>“Savā pieredzē vairākkārt esmu novērojusi, ka arī biznesa sarunās un vakariņās norvēģu uzņēmēji labprāt stāsta par saviem hobijiem un interesēm ārpus darba, jo arī tas var pavērt jaunas perspektīvas uz problēmu risinājumiem. Norvēģi ļoti novērtē, ka potenciālie ārvalstu biznesa partneri ne vien kvalitatīvi spēj izpildīt sadarbības nosacījumus, bet arī vērtību līmenī pietiekamu uzmanību velta atpūtai un veselīgam līdzsvaram starp darbu un atpūtu. Tāpēc uzstājība un zvani pēc darba laika vai brīvdienās noteikti nebūs piemērotākais komunikācijas veids Norvēģijā,” skaidro E. Nedzvecka.`+
`<dd></dd><h3 class="post-subtitle">Demokrātiska pieeja katram jautājumam</h3>`+
`<dd></dd>E. Nedzvecka zina teikt, ka ieiešana Norvēģijas tirgū eksportētājiem prasa lielus resursus — kā finansiāli, tā laika ziņā. Jārēķinās arī ar to, ka sākotnējā rīcības plāna īstenošana, visticamāk, kavēsies, jo Norvēģijā lēmumu pieņemšanas process ir ilgs. Tas ir skaidrojams ar ļoti demokrātisku pieeju ikvienam jautājumam — pieņemtais lēmums ir kolektīvs, nevis vienpersonisks, un tas, protams, prasa laiku. “Tāpēc uzņēmumiem ir jāapbruņojas ar pacietību,” iesaka E. Nedzvecka.`+
`<dd></dd>Savukārt J. Sama skatījumā norvēģi ir izlēmīgāki nekā pārējie Ziemeļvalstu kaimiņi. Ja norvēģiem kāda problēma vai risinājums šķiet racionāls, tad lēmums var sekot krietni ātrāk, nekā to varētu sagaidīt kaimiņvalstīs.`+
`<dd></dd><h3 class="post-subtitle">Attiecību izveide nebūs viegla</h3>`+
`<dd></dd>“Norvēģijā viena no panākumu atslēgām ir uzticībā balstītu attiecību veidošana. Kopumā norvēģi jaunu kontaktu un draudzības saišu veidošanā ir ļoti ieturēti, tāpēc jārēķinās, ka arī jaunu sadarbības partneru atrašana un attiecību nostiprināšana Norvēģijā prasa ilgāku laiku,” uzsver E. Nedzvecka. Arī Z. Fogelmane spriež, ka Norvēģijas biznesa vide ir slēgta. “Ir jāspēj nodibināt attiecības, ir jānokļūst viņu uzticības lokā,” viņa teic. SIA Tenax Panel Eksporta pārdošanas vadītāja arīdzan izceļ, ka biznesā ar norvēģiem nepietiek ar attālinātu komunikāciju vai komandējumiem reizi pusgadā — ir svarīga klātesamība. Tā palīdz mazināt distanci ar biznesa partneri.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway4+`"></div>`+
`<dd></dd>J. Sams skaidro, ka uzticības iegūšana notiek caur darbiem — paveicot apsolīto, tiek iegūti kredītpunkti, kuri summējas. Ja solītais netiek izdarīts, sadarbību izveidot būšot grūti. “Pirmajam iespaidam ir liels spēks. Ja sākumā iegūsi uzticību, tad sadarbība pēc tam veidosies daudz veselīgāka,” viņš piebilst. Arī koka māju ražotāja SIA Dores fabrika direktors Jānis Lisenko norāda, ka ilgtermiņa sadarbībā galvenā atslēga ir izpildīt solīto. Jo īpaši svarīgi tas ir sadarbībā ar jaunu partneri. `+
`<dd></dd>J. Sams iesaka apzināties savas iespējas, citādi var veltīgi pazaudēt daudz enerģijas. “Lai cik smieklīgi tas skanētu, lielām ambīcijām tomēr ir jābūt pamatotām. Nevar pēkšņi izdomāt rīt dubultot apgrozījumu — ir jāpasēž un jāparēķina, ko tas maksā. Ja nav investoru, kas nodrošina šādam solim vajadzīgo kapitālu, ir jāstrādā ar savu finansējumu, un tad izaugsmei ir jābūt saprātīgai. Tāpēc uzņēmums, kura apgrozījums ir 20 vai 30 miljoni eiro, nevar piedāvāt sadarbību kompānijai, kuras apgrozījums ir pusmiljards eiro. Tas būs izaicinājums abām pusēm. Bieži vien uzņēmēji ir tik ļoti aizrāvušies ar attīstību, izloloto produktu un ģenialitāti, ka par šīm pamatlietām piemirst,” piebilst J. Sams.`+
`<dd></dd><h3 class="post-subtitle">Kā cimds ar roku</h3>`+
`<dd></dd>Norvēģijai HansaMatrix piegādā trīs dažādu grupu produktus. To vidū ir uzņēmuma ražotās un montētās 3D kameras, kas tiek izmantotas robotredzes risinājumos. Akvakultūras nozarei Latvijas uzņēmums piegādā zemūdens kameras, vinčas, vadības blokus, kabeļus un barošanas iekārtas, kas veido sistēmu, kura tiek izmantota procesu pārraudzībai un vadībai zivju fermās fjordos. Trešais virziens ir enerģijas pārvaldība. Šajā jomā HansaMatrix klients ir uzņēmums, kas nodarbojas ar elektropārvaldes kļūdu identificēšanu.`+
`<dd></dd>Patlaban eksports uz Norvēģiju veido apmēram 25 % no HansaMatrix apgrozījuma. Taču tas var strauji mainīties, jo ir klienti, kuri gadu ir neaktīvi, bet tad pasūta ļoti lielu apjomu. Piemēram, kāds Norvēģijas uzņēmums ar savu produktu nesen ieguva tiesības startēt Saūda Arābijas elektroenerģijas tirgū, un tam pēkšņi radās nepieciešamība pēc produktiem trīskāršā apjomā. “Tāpēc šobrīd eksporta apjoms uz Norvēģiju ir 25 %, bet nākamgad tie var būt gan 50 %, gan 0 %,” skaidro J. Sams.`+
`<dd></dd>Taču, lai nebūtu tā, ka vienā gadā bizness apstājas, HansaMatrix veido ilgtermiņa sadarbību ar partneriem. Gadījumos, kad, piemēram, tiek plānots apjoma samazinājums, bieži vien abi partneri kopā izsver iespējas.`+
`<dd></dd><div class="quote-style">“Mēs ar klientu esam kā cimds ar roku — nevienu uzņēmumu, ar kuru ir izveidotas stabilas ilgtermiņa attiecības, neinteresē pazaudēt sadarbības partneri. Normālā situācijā viņi sadarbībā ir tikpat ieinteresēti, cik mēs. Tāpēc ciešā sadarbība ir ne tikai labos, bet arī grūtos laikos. Tad mēs sēžamies pie viena galda un domājam, ko varam darīt lietas labā,” saka J. Sams.</div>`+
`<dd></dd><h3 class="post-subtitle">Saskata lielu potenciālu</h3>`+
`<dd></dd>“Norvēģijas tirgus nav vienkāršs. Valsts ir ļoti specifiska ar saviem standartiem, prasībām un nacionālismu,” saka J. Lisenko. Viņa novērojumi liecina, ka norvēģi nav īpaši atvērti importa produktam. “Tāpēc ir jābrauc un jārunā. Viņi grib garantijas, ka viss atbildīs viņu gaidām. Šobrīd Norvēģijā aktīvi meklējam partnerus tieši rūpnieciski ražotu koka karkasa māju būvniecībā. Tas nav viegli, Latvijas vārds ne vienmēr ir tas, ko Skandināvijā grib dzirdēt pirmo. Mums šajos tirgos ir bijis daudz priekšteču, kuru darbi nav veicinājuši labu slavu mūsu reģionam un uzticība ir zudusi. Tāpēc mēs braucam pie potenciālajiem partneriem un rādām, stāstām un mēģinām pārliecināt par to, ka nav viss tik slikti, kā viņi domā,” saka J. Lisenko.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway5+`"></div>`+
`<dd></dd>Dores fabrikas pieredze liecina — lai ieietu Norvēģijas tirgū, uzņēmumam ir jāatbilst dažādiem būvniecības normatīviem un standartiem, jābūt reģistrētiem dažādos reģistros. J. Lisenko stāsta, ka norvēģi prasa līdzšinējo pieredzi Norvēģijā. Ja ir atsauksmes no esošajiem klientiem un partneriem, ir vieglāk. Tāpat partneri vērtē uzņēmuma pieredzi savā zemē un citās valstīs. Dores fabrikas gadījumā vairāk nekā 10 gadu pieredze un 1000 realizētu projektu ir priekšrocība. “Paskatoties kartē, mūsu darbības lauks ir visa pasaule. Esam veduši savas mājas gan uz Jaunzēlandi, gan uz Eiropu,” saka J. Lisenko.`+
`<dd></dd>Šobrīd Norvēģija neveido nozīmīgu daļu Dores fabrikas apgrozījumā. “Taču tas ir mūsu fokusa tirgus, uz ko orientējamies un kur redzam lielu potenciālu. Mums vēl priekšā milzīgs darbs,” viņš atzīst. Lai atrastu jaunus partnerus, uzņēmums gan meklē tos pats, gan sadarbojas ar LIAA un Gateway&Partners.`+
`<dd></dd><h3 class="post-subtitle">Palīdz iegūt jaunus eksporta līgumus</h3>`+
`<dd></dd>E. Nedzvecka teic, ka gan LIAA pārstāvniecība Norvēģijā, gan citās valstīs piedāvā virkni atbalsta aktivitāšu, lai sekmētu un palīdzētu Latvijas uzņēmumiem atrast jaunus sadarbības partnerus, kas rezultētos noslēgtos eksporta līgumos. “Pārstāvniecība uzņēmumiem var piedāvāt ļoti praktisku palīdzību — kontaktpersonu sarakstu un tirgus apskatu sagatavošanu, atbalstu organizējot piegādātāju tikšanās, informatīvus pasākumus. Tāpat ne mazāk svarīgi faktori ir pārstāvju kontaktu tīkls, zināšanas un ieskati konkrētās valsts tirgū, kas uzņēmumiem var palīdzēt labāk izvērtēt eksporta iespējas,” piebilst Pārstāvniecības Norvēģijā vadītāja.`+
`<dd></dd><h3 class="post-subtitle">2,2 % no Latvijas kopējā eksporta nonāk Norvēģijā</h3>`+
`<dd></dd>Latvijas kopējais preču un pakalpojumu tirdzniecības apgrozījums ar Norvēģiju 2022. gadā bija 815,6 miljoni eiro. Tas ierindoja Norvēģiju 19. vietā Latvijas ārējās tirdzniecības partneru vidū. Kopējais preču un pakalpojumu eksports uz Norvēģiju bija 631,9 miljoni eiro, kas veido 2,2 % no Latvijas kopējā eksporta. Savukārt preču un pakalpojumu imports veidoja 183,7 miljonus eiro, kas ir 0,6 % no Latvijas importa. Salīdzinājumā ar 2021. gadu eksports ir pieaudzis par 33 %, bet imports ir palielinājies par 41 %. Norvēģija 2022. gadā bija Latvijas 13. nozīmīgākais eksporta un 27. nozīmīgākais importa partneris.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway6+`"></div>`+
`<dd></dd>2022. gada beigās pēc ārvalstu ieguldījumiem Latvijā reģistrēto uzņēmumu pamatkapitālā Norvēģija bija 9. vietā. Kopējais ieguldījumu apjoms 242 uzņēmumos bija 304 miljoni eiro, un tie nodarbina vairāk nekā 6000 darbinieku. Lielākie Norvēģijas kapitāla uzņēmumi Latvijā ir Linstow, Orkla, DNB, Moller Baltic Import, Visma, Narvesen, Circle K, Atea, Zalaris un Norwegian.`+
`<dd></dd>Nākotnē E. Nedzvecka saskata lielu eksporta potenciālu ne vien tām nozarēm, kas ir Latvijas eksporta mugurkauls, piemēram, kokapstrādei, metālapstrādei un rūpniecības preču ražošanai, bet arī tiem uzņēmumiem, kuri mērķtiecīgi investē pētniecībā un inovācijās, tādējādi nodrošinot starptautiski konkurētspējīgas un ilgtspējīgas preces un pakalpojumus ar augstu pievienoto vērtību. “Nākotne pieder uzņēmīgiem, inovatīviem un zaļiem uzņēmumiem,” viņa uzsver.`+

	support_by_liaa,


	exp_review_6_name: "Pieredzes stāsti eksportā uz Austrāliju",
	exp_review_6_company:"VALPRO, Laflora, Grindeks",
	exp_review_6_designation: "VALPRO, Laflora, Grindeks",
	exp_review_6_excerpt: `Mūsdienās attālums vairs netiek uzskatīts par šķērsli, un tirgus ar 26 miljoniem iedzīvotāju vilina arī Latvijas ražotājus, kuri redz lielu tirdzniecības potenciālu, iedzīvotāju atvērtību un gatavību sadarboties.`,
	exp_review_6_title:"Galamērķis – Austrālija. Eksporta tirgus 14 tūkstošu kilometru attālumā",
	exp_review_6_text:

`<dd></dd>Mūsdienās attālums vairs netiek uzskatīts par šķērsli, un tirgus ar 26 miljoniem iedzīvotāju vilina arī Latvijas ražotājus, kuri redz lielu tirdzniecības potenciālu, iedzīvotāju atvērtību un gatavību sadarboties.`+
`<dd></dd>Eiropas Savienība ir Austrālijas trešais lielākais tirdzniecības partneris, un arī tirdzniecības apjomam starp Austrāliju un Latviju ir tendence augt. Pirms diviem gadiem tika atvērta Latvijas vēstniecība Austrālijā, bet kopš šī gada sākuma šajā kontinentā ir arī mūsu valsts ekonomiskā pārstāvniecība. “Ar vēstniecības atvēršanu un plānoto brīvās tirdzniecības nolīguma noslēgšanu ekonomiskā pārstāvniecība bija loģisks nākamais solis, kas parāda šī tirgus nozīmi Latvijai,” norāda Latvijas Investīciju un attīstības aģentūras (LIAA) pārstāvniecības vadītāja Austrālijā Sabīne Kazaka. Par brīvās tirdzniecības nolīgumu tiek runāts jau kopš 2018. gada, un tā noslēgšanas gadījumā tiks samazināti tarifi dažādu preču eksportam. Paredzēts, ka šis solis veicinās lielāku ieinteresētību par Eiropu. “Jau šobrīd redzam arvien lielāku interesi par Latvijas risinājumiem, tāpēc Latvijas pārstāvju klātesamība ir būtiska un vērtīga,” teic S. Kazaka.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia1+`"></div>`+
`<dd></dd>2022. gadā Latvijas kopējais preču un pakalpojumu tirdzniecības apgrozījums ar Austrāliju bija 81,7 miljoni eiro. Tas Austrāliju kā Latvijas ārējās tirdzniecības partneri ierindo 50. vietā. Preču un pakalpojumu eksports bija 52,5 miljoni eiro, kas veido 0,2% no Latvijas kopējā eksporta, savukārt imports bija 29,2 miljoni eiro. “Salīdzinājumā ar 2021. gadu pērn eksports pieauga par 31%, bet imports – par 11%. Tendence ir augoša, un ir skaidrs, ka ar vēstniecības un pārstāvniecības atvēršanu eksporta apjoms noteikti augs,” paredz S. Kazaka.`+
`<dd></dd><h3 class="post-subtitle">Šobrīd līderos – tradicionālās nozares</h3>`+
`<dd></dd>Trīs lielākās Latvijas eksporta preču grupas uz Austrāliju ir koksne un tās izstrādājumi, mašīnas, mehānismi un dažādas elektriskās iekārtas, kā arī dažādi minerālie materiāli, piemēram, kūdra. Kūdras ražotāja SIA Laflora apgrozījumā eksports uz Austrāliju veido aptuveni 1%, un pēdējos gados ir vērojams pieprasījuma pieaugums. SIA Laflora eksporta departamenta vadītāja Irina Sintniece izaugsmi skaidro ar ierobežotu piedāvājumu no citiem uzņēmumiem, kā arī mainīgo kūdras pieejamību tirgū. “Kūdras substrātā ir viegli un efektīvi audzēt jebkuru kultūru, bet, ņemot vērā Baltijas un Austrālijas dažādo klimatu, ūdens resursu pieejamību, augsnes sastāvu, kā arī kultūru atšķirības, uz Austrāliju piegādājam izejvielu – naturālu frakcionētu kūdru bez papildu piedevām, lai klients pats var pievienot nepieciešamo minerālmēslojumu un piedāvāt tirgū produkciju, kas atbilst vietējai specifikai,” stāsta I. Sintniece.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia2+`"></div>`+
`<dd></dd>S. Kazaka norāda, ka kokmateriālu deficīts Austrālijā ir radījis lielākas iespējas Latvijas uzņēmumiem. Vēl pērn esot bijuši vairāki ēku projekti, kuros sākotnēji izmantoti Austrālijas un Jaunzēlandes kokmateriāli, taču projektu vidū produktu nepieejamības un cenu dēļ ir nācies projektā iekļaut arī kokmateriālus no Eiropas. Ņemot vērā klimata, augsnes un mitruma atšķirības, šādu produktu apvienošana ne vienmēr ir vienkārša un iespējama, tāpēc Austrālijas uzņēmumi izskata iespējas visus kokmateriālus pasūtīt no vienas valsts vai reģiona. “Šobrīd strādāju ar vairākiem Austrālijas uzņēmumiem, kas plāno iepirkt visus projektā paredzētos kokmateriālus uzreiz no Eiropas, iespējams – pat no Latvijas. Interese par Latvijas kokmateriāliem ir tiešām liela, ko apliecina Austrālijas uzņēmumu vizītes Latvijā jūnija beigās,” atklāj S. Kazaka.`+
`<dd></dd><h3 class="post-subtitle">Liels tirgus ar 26 miljoniem iedzīvotāju</h3>`+
`<dd></dd>“Austrālijā ir 26 miljoni iedzīvotāju, turklāt pēdējo 20 gadu laikā to skaits ir pieaudzis par pieciem miljoniem,” norāda farmācijas uzņēmuma AS Grindeks valdes priekšsēdētājs Juris Hmeļņickis. Tas, ka iedzīvotāju skaits aug, nozīmē, ka sabiedrība kļūst vecāka un tai agrāk vai vēlāk vajadzēs medikamentus. Ilgus gadus Grindeks strādāja ar biznesa klientiem, bet pagājušā gadā saskatīja iespējas piedāvāt savu produkciju arī galapatērētājiem. “Mēs veidosim 50 līdz 60 produktu portfeli, ko mūsu pacientiem piedāvāt uzreiz. Esam izvēlējušies Austrāliju kā stratēģisku tirgu, kur mērķtiecīgi piedāvāt plašāku medikamentu klāstu, nevis tikai dažus produktus, kā agrāk,” teic J. Hmeļņickis.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia3+`"></div>`+
`<dd></dd>S. Kazaka piebilst, ka tirgus ir liels, bet arī konkurence ir sīva. Tomēr viņa uzskata, ka augstas kvalitātes un pievienotās vērtības precēm un pakalpojumiem ir lielas iespējas. Galvenais esot nodrošināt konkurētspējīgu cenu. “Covid-19 pandēmijas rezultātā konteineru cenas bija tik augstas, ka vairākiem Latvijas uzņēmumiem pat nācās uz brīdi pārtraukt piegādes, taču šobrīd izmaksas tuvojas līmenim, kāds tas bija pirms pandēmijas,” norāda S. Kazaka. `+
`<dd></dd><h3 class="post-subtitle">Produktam ir jābūt gatavam izturēt ilgu ceļu</h3>`+
`<dd></dd>Jūras kravu pārvadājumi ar konteineriem uz Austrāliju ceļo divus un dažreiz pat trīs mēnešus. “Sūtījumi tiek pakļauti dažādām temperatūras svārstībām un mitrumam, tāpēc uzņēmumam ir jābūt pārliecinātam, ka galapunktā produkts būs tieši tāds, kāds tas ir paredzēts,” uzsver S. Kazaka.`+
`<dd></dd>Arī degvielas kannu ražotāja SIA VALPRO Pārdošanas dienesta vadītājs Mārtiņš Orskis skaidro, ka jūrā ir augsts sāls līmenis, kas var ietekmēt produkcijas kvalitāti, tāpēc pirms pirmajiem pasūtījumiem ir jāsaprot, vai šis piegādes veids ir piemērots un kāds iepakojums ir pats labākais,” viņš akcentē.`+
`<dd></dd><div class="quote-style">M. Orskis iesaka laikus vienoties ar klientu par pasūtījuma piegādes prognozēm, lai nebūtu nepatīkamu pārsteigumu. Savukārt I. Sintniece rekomendē izvērtēt piegādes izmaksu svārstības, jo loģistika ir būtiska izmaksu sastāvdaļa, kas ietekmē produkta cenu. </div>`+
`<dd></dd>“Transporta pakalpojumi uz Austrāliju nav lēti, tāpēc visi mēģina samazināt šīs izmaksas. Lielus sūtījumus, protams, sūtām pa jūru, kas prasa ilgāku laiku. Taču, ja pasūtījums ir divas vai trīs paletes, tad sūtām tās ar lidmašīnu,” teic J. Hmeļņickis.`+
`<dd></dd>M. Orskis atgādina, ka Austrālijā ir savs dolārs, tāpēc viņa skatījumā ir izdevīgi vienoties ar partneri par norēķiniem eiro. “Tāpat ir arī jāpiefiksē laika zona, kurā plānot komunikāciju un sanāksmes,” iesaka M. Orskis.`+
`<dd></dd><h3 class="post-subtitle">No sertifikācijas nav jābaidās</h3>`+
`<dd></dd>M. Orskis stāsta, ka teju jebkur, importējot preces, tās ir jāsertificē – arī Austrālijā. “Preces nepieciešams sertificēt atbilstoši viņu prasībām. Milzīga atšķirība no citām valstīm ir tā, ka Austrālijā ir ļoti augsta kvalitātes latiņa un viņi neļauj importēt zemas kvalitātes produktus,” teic M. Orskis. Tik augsts kvalitātes siets ir noteikta priekšrocība. Piemēram, vienā no pārbaudēm VALPRO kanna tika mesta zemē no konkrēta augstuma, un tika konstatēts, ka tās vāks reizēm atveras. Risinot šo problēmu, uzņēmums nonāca pie īpaša sprosta, kas kannu droši noslēdz. Austrālieši kopā ar latviešiem šo risinājumu patentēja. “Tā mēs no austrāliešu augstajām prasībām ieguvām vērtīgu produkta kvalitātes uzlabojumu,” pieredzē dalās M. Orskis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia4+`"></div>`+
`<dd></dd>Arī S. Kazaka aicina nebaidīties no sertifikācijas. Protams, šis process prasa darbu un laiku, taču noteikumi ir skaidri: “Vienkārši jāapbruņojas ar pacietību.” AS Grindeks no medikamentu reģistrācijas līdz ieiešanai šajā tirgū paiet pusotrs līdz divi gadi. Šobrīd Austrālija veido aptuveni 1% no uzņēmuma apgrozījuma. “Mēs eksportējam uz vairāk nekā 100 valstīm. Protams, ir tirgi, uz kuriem eksports ir lielāks, bet mēs esam priecīgi par katru stabilu un regulāru noieta kanālu. Mēs gribētu ievērojami kāpināt apgrozījumu Austrālijā,” atklāj J. Hmeļņickis. Viņš piebilst, ka, esot Austrālijā, var attīstīt arī eksportu uz Jaunzēlandi. Proti, ja produkti jau ir piereģistrēti Austrālijā, arī Jaunzēlandes iestādēm rodas lielāka uzticība uzņēmumam un ir vieglāk ieiet šajā tirgū. “Pašlaik mēs nefokusējamies uz Jaunzēlandi, bet domāju, ka ilgtermiņā tā var kļūt par vēl vienu eksporta valsti ar stabilu noietu,” paredz J. Hmeļņickis.`+
`<dd></dd><h3 class="post-subtitle">Valsts kontinenta lielumā ar vairākām laika zonām</h3>`+
`<dd></dd>“Ja valsts aizņem veselu kontinentu, tajā ir seši štati un vēl katrā sava konstitūcija, parlaments un pat klimats, ir skaidrs, ka pieprasījums katrā reģionā var būt atšķirīgs,” norāda S. Kazaka. `+
`<dd></dd><div class="quote-style">Šobrīd Austrālijā ir ziema, bet tikai četru stundu brauciena attālumā no Melburnas ir sniegs un ziemas prieki, bet divu stundu lidojums šķir no iespējas baudīt sauli un +20 grādus. </div>`+
`<dd></dd>Temperatūras atšķirības nosaka arī dzīvās dabas izmaiņas. “Piemēram, Dienvidaustrālijas pilsētā Adelaidē mājo baltās skudras, kas ir postošākais kokmateriālu termīts. Lai gan koka karkasa mājas Austrālijā ir ļoti izplatītas, šajā reģionā koka karkasa, terašu un sētu ražotājiem lielas tirgus iespējas nav,” skaidro S. Kazaka.`+
`<dd></dd><h3 class="post-subtitle">Teju katram austrālietim ir Latvijā ražota degvielas kanna</h3>`+
`<dd></dd>VALPRO sadarbība ar Austrāliju ir sena. “Rūpnīcā, kur šobrīd atrodas VALPRO, jau pagājušā gadsimtā ražoja ugunsdzēsības iekārtas un degvielas kannas. Jau tad Valmierā ražotās degvielas kannas ceļoja uz Austrāliju. Atmodas gados austrālieši atkal uzmeklēja senos partnerus, jo gribēja saņemt to pašu iepriekš pārbaudīto kvalitāti. Tā 1992. gadā latvieši kopā ar austrāliešiem uzņēmumu privatizēja un izveidoja kopuzņēmumu A/S Valpro Corp. Šobrīd Austrālija veido aptuveni 5% no VALPRO apgrozījuma.`+
`<dd></dd>Uzņēmuma klasiskā 20 litru degvielas kanna ir gandrīz katram austrālietim. “Viens ir skaidrs – mūsu jauda ir iespaidīga. Taču, piedāvājot vienu un to pašu, agri vai vēlu sasniegsim tirgus piepildījumu, tāpēc izstrādājam jaunus produktus – mums ir arī divu, piecu un 10 litru kannas, kā arī dažādi aksesuāri, izlējēju stiprinājumi mašīnām,” stāsta M. Orskis.`+
`<dd></dd>Lai gan Austrālija nav visblīvāk apdzīvotā valsts, iedzīvotājiem ir jāveic lieli attālumi. Tur populārs ir kempingu tūrisms, kā arī ir augsti attīstīta lauksaimniecība – abos gadījumos ir vajadzīga degvielas rezerve. “Līdz ar to pieprasījums pēc mūsu produkta ir liels,” priecājas M. Orskis.`+
`<dd></dd><h3 class="post-subtitle">Sadarbības partneri ir tirgus atslēga</h3>`+
`<dd></dd>S. Kazaka norāda, ka veiksmīgai ienākšanai tirgū nereti tiek izmantoti sadarbības partneri, kas palīdz ne tikai ar informāciju par biznesa kultūru un cilvēkresursiem mērķa tirgū, bet arī nodrošina klātesamības efektu. “Tāpat kā citās valstīs, uzticamība šeit rodas, satiekoties klātienē. Tieši tāpēc veiksmīgai darījumu uzsākšanai iesaku izmantot ne tikai sadarbības partnerus, bet arī pašiem atbraukt un iepazīties klātienē,” viņa norāda. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia5+`"></div>`+
`<dd></dd>Arī M. Orskis uzskata, ka ir jādodas tikties ar partneriem klātienē, lai nodibinātu kontaktus un radītu uzticības pilnas attiecības. “E-pasti ir laba lieta, bet vienmēr ir vērtīgi satikt partnerus klātienē. Austrālijā ir pietiekami liela latviešu diaspora, LIAA pārstāvniecība un vēstniecība – šie kontakti ir jāizmanto. Viņi ir pretimnākoši un vēlas, lai Latvijas uzņēmumiem šajā tirgū viss izdodas,” stāsta J. Hmeļņickis.`+
`<dd></dd>I. Sintniece iesaka atrast uzticamu sadarbības partneri, kas prot risināt problēmas un rast kompromisu. “Piemēram, vienreiz no klienta saņēmām pretenziju par to, ka paletēs ir atrasti termīti, kas sākuši bojāt koka dēļus. Mūsu platuma grādos termīti kokzāģētavās nav, tāpēc mums nācās apmeklēt piegādes partnerus. Rūpīgi izvērtējot situāciju, izrādījās, ka kūdra no konteineriem tika izkrauta noliktavā un vairākus mēnešus netika izmantota, kā rezultātā paletēs paspēja iemitināties termīti. Austrālija ir ļoti tālu, lai riskētu sadarboties ar finansiāli nestabilu uzņēmumu vai strauji palielināt pārdošanas apjomus,” viņa dalās pieredzē.`+
`<dd></dd><h3 class="post-subtitle">Atvērti, taču ar saviem noteikumiem</h3>`+
`<dd></dd>“Kā jau katram tirgum, arī Austrālijai ir sava specifika. Biznesa vide ir atšķirīga, taču var redzēt, ka austrālieši daudz strādā ar uzņēmumiem Eiropā un ir atvērti sadarbībai ar Latviju,” norāda S. Kazaka. J. Hmeļņickis vērtē, ka Austrālijā ir noturīgs noiets, stabila valūta un skaidri spēles noteikumi. “Ir stingri noteikumi, lai ieietu Austrālijas tirgū. Man ir radies iespaids, ka viņi ir ļoti pedantiski – viss ir saplānots, salikts pa plauktiņiem. Man tas patīk, un es redzu, ka ilgtermiņā tur ir iespējams veidot stabilu un augošu biznesu,” viņš saka, piebilstot, ka šajā tirgū ir atvērti cilvēki. Visi, ar kuriem J. Hmeļņickis ir ticies, ir imigranti – iebraukuši pirms vairākiem desmitiem gadu vai arī ir dzimuši Austrālijā, bet viņu vecāki bija imigranti. “Valsts ir toleranta pret iebraucējiem. To var just pat uz ielas,” viņš teic.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia6+`"></div>`+
`<dd></dd>S. Kazaka dzīvo un strādā Melburnā, kuras sabiedrību raksturo kā ļoti starptautisku un atvērtu. “Austrālijas pamatiedzīvotāji ir aborigēni, kuri gan šobrīd veido ap 3% no kopējā iedzīvotāju skaita. Praktiski visiem, kurus satieku, vecāki vai vecvecāki ir iebraukuši Austrālijā, līdz ar to sajūtas par pārcelšanos uz citu pasaules malu nav svešas un visi ir ļoti laipni,” norāda S. Kazaka.`+
`<dd></dd><h3 class="post-subtitle">Saprotoši un gatavi sadarboties</h3>`+
`<dd></dd>M. Orska novērojumi liecina, ka austrālieši ir saprotoši, gatavi sadarboties un atvērti dažādiem risinājumam. Piemēram, uz VALPRO kannām ir redzama dažāda informācija, un tās izvietošana aizņem daudz laika. Kad ražotājs partneriem vienkārši pajautāja, vai tas tiešam ir vajadzīgs, izrādījās, ka bez tā var iztikt. Viņš secina, ka partneri Austrālijā ir vērsti uz sadarbību un vēlas kopā rast risinājumu. M. Orskis salīdzina: “Vācijā viss notiek ļoti punktuāli, strikti, saskaņā ar līgumu. Austrālijā tā nav. Tur partneri ir pretimnākoši un gatavi visu izrunāt. To ir svarīgi atcerēties komunikācijā un ikdienas saziņā ar austrāliešiem.”`+
`<dd></dd>Komunikāciju ar partneriem uzņēmums uztur jau 30 gadu. Darbinieki partneruzņēmumā ik pa laikam nomainās, taču saglabājas draudzīgas attiecības. “Ja paklausās mana kolēģa un austrāliešu partneru sarunas, var dzirdēt, ka viņi vispirms pārrunā to, kā klājas ģimenei, un tikai tad nonāk pie jautājumiem par pasūtījumu. Ir svarīgi veidot tuvas attiecības un uzturēt draudzīgu attieksmi. Manuprāt, tā ir viena no mūsu veiksmes atslēgām Austrālijā,” spriež M. Orskis. Arī S. Kazakas pieredze liecina, ka tuvas attiecības ir atslēga ilgtermiņa sadarbībai, taču nedrīkst pārkāpt robežas, kur attiecību rezultātā netiek pildītas līgumiskās saistības.`+
`<dd></dd><h3 class="post-subtitle">Atbildes ne vienmēr būs ļoti ātras</h3>`+
`<dd></dd>Lai gan angļu valodai Austrālijā nav oficiālās valsts valodas statuss, tā de facto tiek lietota kā valsts valoda, tāpēc Latvijas uzņēmumiem ar šī tirgus partneriem ir viegli saprasties. J. Hmeļņickis uzsver, ka austrāliešu angļu valoda ir viegli saprotama un to nevar salīdzināt, piemēram, ar skotiem – arī viņi runā angliski, bet saprast ir daudz grūtāk. `+
`<dd></dd>S. Kazaka norāda, ka biznesa vidē Austrālijas akcentu bieži nesanāk sastapt, bet nereti izruna un pat vārdi var būt citādi. Komunikācija ar Austrālijas uzņēmumiem notiek mazliet lēnāk, nekā ierasts Latvijā, turklāt jāņem vērā, ka no decembra vidus līdz janvāra vidum biznesa vide nedaudz apstājas un sākas atvaļinājumu periods. Šajā laikā S. Kazaka neiesaka plānot līgumu slēgšanu, vizītes un biznesa tikšanās.`+
`<dd></dd><div class="quote-style">“Pēc Latvijas uzņēmēju vizītēm Austrālijā un Austrālijas uzņēmumu vizītēm Latvijā varu teikt, ka lēmumu pieņemšana var būt ātra, ja abām pusēm ir skaidri, pieņemami noteikumi un labs savstarpējais kontakts. Ir bijuši vairāki gadījumi, kur ilgi gaidām atbildi no Austrālijas uzņēmuma, un, tiklīdz piedāvājam tikšanos klātienē, atbildi saņemam uzreiz, kas ir lielisks pierādījums tam, cik svarīgas ir klātienes tikšanās,” uzsver S. Kazaka.</div>`+
`<dd></dd><h3 class="post-subtitle">Iespējas, kuras jāizmanto</h3>`+
`<dd></dd>“Pat viens veiksmīgs projekts sniedz lielu potenciālu izaugt Austrālijas tirgū, jo iepriekšējā pieredze tiek augstu vērtēta un parāda, ka uzņēmumam attālums nav šķērslis,” norāda S. Kazaka. Šī iemesla dēļ viņa aicina uzņēmējus mazliet sašaurināt mērķauditoriju un precīzāk definēt sadarbības partnera profilu. “Visa pamatā ir tirgus un pieprasījuma izpēte, tāpēc LIAA pārstāvniecības ir lielisks sākuma punkts, kur griezties jauna eksporta tirgus sasniegšanā. Katrā valstī ir sava specifika, kas jāzina jau pirms sadarbību veidošanas, lai nodrošinātu veiksmīgāku ienākšanu tirgū. Ikdienā daudz strādājam arī ar lielajiem uzņēmumiem, kuriem palīdzam palielināt tirgus daļu vai ieviest tirgū jaunus produktus vai pakalpojumus, tāpēc galvenais ir zināt savas iespējas un izmantot tās,” saka S. Kazaka. Viņa lielu potenciālu saskata arī informācijas un komunikācijas tehnoloģijās un visā digitalizācijas sektorā.`+
`<dd></dd><div class="quote-style">“Šobrīd Austrālijā tiek izmantoti Latvijas tehnoloģiju risinājumi, un jau pāris mēnešos redzu ne tikai lielāku interesi par esošajiem risinājumiem, bet arī potenciālu citiem uzņēmumiem un digitālajiem pakalpojumiem,” norāda S. Kazaka. Viņa gan uzsver, ka Austrālijā interneta ātrums ir mainīgs un ir daudz neapgūtu teritoriju.</div>`+
`<dd></dd>Šajā gadā ir paredzēta ministru vizīte un tirdzniecības misija, kā arī abu valstu uzņēmēju un investoru vizītes. “Jau ir uzņemti pirmie Latvijas uzņēmumi Austrālijā un mēneša laikā plānotas vēl divas vizītes. Arī no investoriem ir vērojama interese. Šobrīd strādāju pie vizītes uz Latviju septembrī. Manuprāt, vizīšu skaits pat pirms tirdzniecības misijas lieliski parāda uzņēmēju interesi, tirgus nozīmi un, cerams, arī pārliecina citus latviešus, ka attālums nav šķērslis,” ir optimistiska S. Kazaka. `+

	support_by_liaa,



	exp_review_7_name: "Pieredzes stāsti eksportā uz Amerikas Savienotajām Valstīm",
	exp_review_7_company:"DeskTime, Gamechanger Audio, Giraffe360",
	exp_review_7_designation: "DeskTime, Gamechanger Audio, Giraffe360",
	exp_review_7_excerpt: `Šķiet, nav neviena, kurš nebūtu dzirdējis teicienu, ka Amerika ir lielo iespēju zeme. Lai gan ASV ir ģeogrāfiski liels un juridiski sarežģīts tirgus ar būtiskām reģionālām atšķirībām un sīvu konkurenci, ne viens vien Latvijas uzņēmums šos izaicinājumus ir gatavs pārvarēt un pierādīt, ka neiespējamais ir iespējams.`,
	exp_review_7_title:"Lielo iespēju zeme – Amerika",
	exp_review_7_text:

`<dd></dd>Šķiet, nav neviena, kurš nebūtu dzirdējis teicienu, ka Amerika ir lielo iespēju zeme. Lai gan ASV ir ģeogrāfiski liels un juridiski sarežģīts tirgus ar būtiskām reģionālām atšķirībām un sīvu konkurenci, ne viens vien Latvijas uzņēmums šos izaicinājumus ir gatavs pārvarēt un pierādīt, ka neiespējamais ir iespējams.`+
`<dd></dd>Latvijas Amerikas Tirdzniecības kameras valdes loceklis un Ņujorkas advokātu biroja Slokenbergs PLLC dibinātājs Ivars Slokenbergs teic, ka Latvijas kompānijām ASV tirgus vienmēr ir bijis svarīgs, taču dažādu iemeslu dēļ uzņēmēji to nav pilnvērtīgi izmantojuši un praktisku iemeslu dēļ tas daudziem nav bijis pieejams. Kāpēc? `+
`<dd></dd><div class="quote-style">Pirmkārt, viens no lielākajiem izaicinājumiem ir ASV ģeogrāfiskais attālums. Otrkārt, bizness ASV ir dārgs – ir nepieciešams ieguldīt nozīmīgus līdzekļus, lai attīstītos šajā tirgū. Treškārt, ASV ir ļoti izaicinošs tirgus – ģeogrāfiski liels, ar būtiskām reģionālām atšķirībām un sīvu konkurenci. Turklāt tas ir juridiski sarežģīts. </div>`+
`<dd></dd>“Vēsturiski Latvijas uzņēmējiem vienmēr ir bijis izdevīgāk ieguldīt resursus un pūliņus Baltijā, citu kaimiņvalstu un austrumu puslodes reģionu tirgos, mazāk pievēršot uzmanību Ziemeļamerikai,” saka I. Slokenbergs.`+
`<dd></dd>Tomēr laiki mainās, un viņa novērojumi liecina, ka pēdējo 10 gadu laikā uzskati par ASV tirgus nepieejamību ir mainījušies. “Latvija lielā mērā izkļuva no globālās ekonomiskās krīzes, pārorientējot esošos eksporta tirgus, uzlabojot produktivitāti un meklējot jaunas valstis, kur Latvijas preces un pakalpojumi būtu konkurētspējīgi. Šajos jaunajos tirgos varam ieskaitīt arī ASV, jo, neskatoties uz lielo attālumu, dārdzību un sarežģītību, ASV dinamisko, lielo un iespējām bagāto tirgu vairs nevar ignorēt. Latvijas ražotāji saprot, ka ar Eiropas tirgus apguvi nepietiek un ASV piedāvā plašas izaugsmes iespējas,” uzsver I. Slokenbergs.`+
`<dd></dd><h3 class="post-subtitle">Nozīmīgs ārējās tirdzniecības partneris</h3>`+
`<dd></dd>Latvijas kopējais preču un pakalpojumu tirdzniecības apgrozījums ar ASV 2022. gadā bija 1,5 miljardi eiro, kas ierindoja ASV 11. vietā Latvijas ārējās tirdzniecības partneru vidū. No šī apjoma kopējais preču un pakalpojumu eksports uz ASV bija viens miljards eiro, kas veidoja 3,6 % no Latvijas kopējā eksporta, un ASV bija Latvijas 9. nozīmīgākais eksporta partneris.`+
`<dd></dd><div class="quote-style">Visvairāk Latvija uz ASV eksportē koksni un tās izstrādājumus (34 %), mašīnas, mehānismus un elektriskās iekārtas (24 %), optiskās ierīces un aparātus (8 %), ķīmiskās rūpniecības produkciju (7 %), pārtikas rūpniecības produktus (6 %) un būvmateriālus (4 %). </div>`+
`<dd></dd>“Latvijas uz ASV eksportēto produktu klāsts ir dažāds – no kokmateriāliem un optiskajiem instrumentiem līdz pārtikai,” teic I. Slokenbergs.`+
`<dd></dd>ASV un Eiropa kopā veido pasaules lielāko divpusējo ekonomisko kopienu – 2021. gadā kopējais Eiropas–ASV tirdzniecības apgrozījums bija 1,2 triljoni eiro. “Tiešās investīcijas starp Eiropu un ASV ir tikpat iespaidīgas – ASV investīcijas Eiropas Savienībā ir četras reizes lielākas nekā ASV investīcijas Āzijas un Klusā okeāna reģionā, un Eiropas Savienības valstu kopējās investīcijas ASV ir 10 reižu lielākas nekā Eiropas Savienības valstu kopīgās investīcijas Indijā un Ķīnā,” uzsver I. Slokenbergs. Viņš ir pārliecināts, ka šajā milzīgajā tirdzniecības un investīciju plūsmā ir iespējas arī Latvijas uzņēmējiem.`+
`<dd></dd><h3 class="post-subtitle">Eksporta ainu ietekmē jaunuzņēmumi</h3>`+
`<dd></dd>Pēdējā desmitgadē Latvijā ir attīstījusies tehnoloģiju jaunuzņēmumu nozare. Šādi uzņēmumi jau no pirmās dienas tēmē uz globāliem tirgiem, jo to piedāvātajiem tehnoloģiju risinājumiem nepietiek ar pašmāju patērētājiem. “Turklāt ASV gan tehnoloģiju patērētāju skaita, gan iespējkapitāla investīciju piesaistes ziņā ir lielākais globālais tirgus jaunuzņēmumiem. Tāpēc vairums jaunuzņēmumu jau no pirmās dienas plāno vai sapņo par ieiešanu ASV,” saka I. Slokenbergs.`+
`<dd></dd>Viņš gan piebilst, ka ASV ir nozīmīgs tirgus ne tikai jaunuzņēmumiem – tur iespējas atradīsies jebkuram eksportētājam. ASV rada 24 % no globālā ekonomiskā kopprodukta, tur ir teju 340 miljonu iedzīvotāju, vidēji – ar augstu pirktspēju. “Tur ir sakārtota un paredzama uzņēmējdarbības vide ar plašām iespējām – katrs var atrast savu nišu. Protams, eksports jāorganizē rūpīgi, izpētot un atrodot piemērotāko tirgū ienākšanas punktu, investējot līdzekļus un piesaistot uzticamus sadarbības partnerus un padomdevējus, tai skaitā advokātus, grāmatvedības un nodokļu konsultantus un citus profesionāļus,” rekomendē I. Slokenbergs.`+
`<dd></dd><h3 class="post-subtitle">Liels un dažāds tirgus</h3>`+
`<dd></dd>I. Slokenbergs skaidro, ka ASV ir federāla valsts, kurā gandrīz 10 miljonu kvadrātkilometru plašā teritorijā ir 50 štatu, katrs ar atsevišķu likumdošanu. Nekustamā īpašuma tehnoloģiju jaunuzņēmuma Giraffe360 līdzdibinātāja un vadītāja Mika Opelta skatījumā Amerikas izmērs nosaka šī tirgus pievilcību: ja izdodas nostiprināties šajā lielajā tirgū ar 339 miljoniem iedzīvotāju, atdeve ir proporcionāli lielāka nekā jebkurā Eiropas valstī individuāli. “Kad es te atbraucu pirmo reizi, mans draugs teica, ka šī ir brīvo zeme – tāda sajūta ir arī man. Amerika ir ļoti dinamiska,” saka M. Opelts. ASV pagaidām veido 20 % no Giraffe360 apgrozījuma, bet tas ir uzņēmuma straujāk augošais tirgus. Viņš paredz, ka 18 līdz 24 mēnešu laikā uzņēmums lielāko apgrozījuma daļu gūs Amerikā. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america1+`"></div>`+
`<dd></dd>Lielais izmērs nosaka, ka šajā tirgū ir jāņem vērā dažādas biznesa vides un kultūras. “Ņujorka, Silīcija ieleja, Teksasa, ASV vidiene un dienvidi ir ģeogrāfiski un kulturāli atšķirīgi. Tāpat ir liela atšķirība, kā kārtot biznesa attiecības ar lielajām ASV bāzētajām globālajām kompānijām, kurās mēdz būt lēna birokrātija, un mazajiem uzņēmumiem, ar kuriem attiecības var kārtot veiklāk un ar mazāk formalitātēm. To nevar apgūt, skatoties Holivudas seriālus, kur redzētais mēdz neatbilst realitātei, – ASV biznesa kultūra jāizjūt pašam uz savas ādas,” saka I. Slokenbergs.`+
`<dd></dd><h3 class="post-subtitle">Kultūras atšķirības nav milzīgas</h3>`+
`<dd></dd>“It kā šķiet, ka kultūras atšķirību ziņā ASV un Latvijai vajadzētu būt kā dienai pret nakti. Taču mūsu produkta gadījumā atšķirības nav tik milzīgas. ASV skatās uz darbinieku pavadīto laiku un produktivitātes stundām, bet ne tik daudz uz to, vai darbinieks šajā brīdī tiešām sēž pie datora un klikšķina taustiņus,” saka darbalaika uzskaites rīka DeskTime biznesa attīstības vadītājs Uģis Briedis.`+
`<dd></dd>Arī M. Opelts uzskata, ka salīdzinājumā ar Eiropu Amerikā ir mazi kultūras uzliktie ierobežojumi. Piemēram, Zviedrijā, Francijā, Lielbritānijā un Vācijā ir kultūras specifika, kas jāņem vērā. Taču Amerika ir brīvs un nepiespiests tirgus, kas ir fokusēts uz komerciju un ātrumu. “Amerika ir mazāk orientēta uz pagātni, vēsturi un kultūru – tur cilvēkus vairāk interesē, kā viņi var ar šo produktu nopelnīt,” viņš spriež.`+
`<dd></dd><h3 class="post-subtitle">ASV ir dārgs tirgus</h3>`+
`<dd></dd>M. Opelts uzskata, ka ASV tirgū barjeru ir daudz mazāk nekā Eiropas tirgos. “Vienīgā lielā barjera ir nauda. Amerika ir ļoti dārgs tirgus, sākot no darbinieku algām līdz biroja nomai. Biznesa izmaksas Eiropā uz Amerikas fona šķiet mazas, pat salīdzinot ar Šveici,” viņš teic. Uzņēmēja pieredze liecina, ka jebkuras izmaksas, pie kurām ir pierasts Latvijā, ASV ir jāreizina ar četri vai pat ar 10. Tāpēc M. Opelts uzskata, ka šajā tirgū nevar startēt vieglprātīgi. Lai ieietu ASV tirgū, ir jābūt lielam investīciju atbalstam vai veiksmīgai pieredzei citos eksporta tirgos. “Ar pieredzi Lielbritānijā un Vācijā es Amerikā jūtos daudz komfortablāk, turklāt tagad mums ir pieejams kapitāls, lai šeit iesakņotos,” saka M. Opelts.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america2+`"></div>`+
`<dd></dd>Arī U. Briedis labāk iesaka uzkrāt pieredzi un līdzekļus Eiropā un pēc tam tos ieguldīt Amerikā: “ASV tirgus izklausās ļoti vilinošs. Taču, ja ir iespēja, es ieteiktu uzaudzēt eksporta muskuļus Eiropā un tikai tad doties uz Ameriku. Bizness Eiropā ir salīdzinoši lētāks – kad Eiropa ienes stabilu naudu, to var tērēt Amerikā. Arī mēs savulaik gribējām uzreiz mesties iekšā Amerikā, bet sanāca apdedzināties.”`+
`<dd></dd><h3 class="post-subtitle">Uzreiz sāk ar ASV</h3>`+
`<dd></dd>Lai gan eksperti neiesaka izvēlēties ASV kā pirmo eksporta tirgu, mūzikas tehnoloģiju uzņēmuma Gamechanger Audio pieredze ir gluži pretēja. “Ģitāra ir ASV nacionālais instruments – katram divdesmitajam tā ir mājās, bet katrs trīsdesmitais to spēlē samērā nopietni. Ir pat atsevišķi noteikumi, kas ļauj ģitāru ņemt līdzi lidmašīnas salonā un aizliedz aviokompānijām pieprasīt ģitāras nodošanu nododamajā bagāžā, ja klients tā nevēlas. Tāpēc mēs uzreiz sākām ar biznesu Amerikā un angļu valodā,” saka Gamechanger Audio līdzdibinātājs Didzis Dubovskis.`+
`<dd></dd>Uzņēmums biznesu sāka ar iepriekšpārdošanu. Gamechanger Audio izziņoja savu pirmo produktu – ģitāras pedāli Plus Pedal – un deva iespēju to rezervēt un iegādāties par puscenu. “Tā bija kā pūļa finansējuma kampaņa mūsu mājaslapā. Tā mēs tikām pie pirmajiem reālajiem klientiem,” stāsta D. Dubovskis.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america3+`"></div>`+
`<dd></dd>Tagad Gamechanger Audio produktus izmanto daudzi pasaulē slaveni mūziķi. To vidū ir Aerosmith ģitārists, Rolling Stones basģitārists, Rodžera Votersa, Nika Keiva un citu mūzikas grupu dalībnieki. Sadarbībā ar White Stripes dibināju Džeku Vaitu Gamechanger Audio ir izstrādājis produktu, kas atbilst tieši viņa vēlmēm.`+
`<dd></dd><h3 class="post-subtitle">Iztiek bez izplatītājiem</h3>`+
`<dd></dd>D. Dubovskis teic, ka pasaule mainās: ja agrāk nebija iedomājams startēt šajā tirgū bez izplatītāju starpniecības, tagad tas kļūst iespējams. “Mūsu nišā izplatītājs iepērk produktus par pusi no produkta gala cenas, bet veikals – par trešdaļu vai ceturtdaļu. Mēs nevarējām uzreiz piedāvāt lielu ražošanas jaudu, nebijām droši par to, cik daudz cilvēku pirks mūsu produktu un nebijām izdomājuši, kā vislabāk to pārdot. Šī iemesla dēļ mēs paši izplatām savus produktus ASV un veicam darbu ar veikaliem. Šobrīd mēs Amerikā esam vairāk nekā 100 mūzikas veikalos. Aptuveni 80 % mūsu produkcijas tiek pārdoti veikalos, bet 20 % – mūsu mājaslapā,” atklāj D. Dubovskis.`+
`<dd></dd>Internetveikals palīdz veidot klientu bāzi, turklāt pārdošanas apjoms savā tīmekļvietnē ir svarīgs skaitlis, par ko interesējas mūzikas veikali. Ja potenciālajam partnerim var nosaukt nopietnus skaitļus, sadarbība izvēršas veiksmīgāka. “Mūsu izvēlētā stratēģija bija pašu spēkiem iegūt mazu klientu bāzi, lai pēc tam ietu jau pie lielākiem un lielākiem klientiem – mūzikas veikaliem. Tā bija piesardzīga mazbudžeta stratēģija. Ja tagad mums būtu jāsāk vēlreiz un mums būtu pieejami lielāki finanšu līdzekļi, iespējams, mēs to darītu citādi – ar lielāku “blīkšķi”,” pieļauj D. Dubovskis.`+
`<dd></dd><h3 class="post-subtitle">Eksportē no pirmās dienas</h3>`+
`<dd></dd>Giraffe360 nedomā par ASV kā eksporta tirgu. “Mēs apkalpojam klientus 30 valstīs, un to vidū ir gan amerikāņi, gan šveicieši. Liela daļa mūsu vācu klientu ir ar Turcijas izcelsmi, bet ASV – ar Latīņamerikas izcelsmi. Tāpēc domāt par ASV kā vienu tirgu vai klientu ir kā šaut sev kājā. Otrā pusē vienmēr ir cilvēks – katram domāšana un izpratne ir pilnīgi atšķirīga. Tāpēc pieejai vajadzētu būt stipri vienkāršākai – noskaidrot, kādas ir klienta prasības un vai mēs varam tās izpildīt,” spriež M. Opelts.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america4+`"></div>`+
`<dd></dd>Arī DeskTime īpašnieka Draugiem Group filozofija paredz uzreiz startēt eksportā. “DeskTime jau no pirmās dienas tiek piedāvāts eksportā, un tas ir angļu valodā. Tāpat uzreiz aktīvi strādājām ar meklētājprogrammu optimizāciju un sabiedriskajām attiecībām, lai produktu maksimāli virzītu ārējos tirgos. Tādā veidā šis produkts ir organiski aizgājis arī līdz ASV,” stāsta U. Briedis.`+
`<dd></dd><h3 class="post-subtitle">Biznesu vada no Latvijas</h3>`+
`<dd></dd>DeskTime ir pusmiljons lietotāju visā pasaulē. “Mēs no Torņkalna strādājam ar pilnīgi visu pasauli. Tas ir izdarāms tepat no Latvijas, tas nav nekas neiespējams,” saka U. Briedis. Eksporta tirgos DeskTime strādā ar partneriem, bet ASV ir izņēmums, un bizness tiek vadīts no Latvijas. `+
`<dd></dd>“Visi ir uz vietas, un informācijas apmaiņa norit ātri – tas ir liels bonuss ne tikai mums, bet arī citiem grupas uzņēmumiem. Ja ir jāveic kādas izmaiņas, mūs neierobežo laika zona. Protams, uzņēmumam attīstoties, rodas domas, ka varbūt vajadzētu pārcelt kādas darbības uz ASV, bet pašlaik mēs diezgan labi tiekam galā no Latvijas,” saka U. Briedis. Ņemot vērā, ka Latviju no ASV šķir daudzas stundas, DeskTime tehniskā atbalsta nodaļa strādā vairākās maiņās.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america5+`"></div>`+
`<dd></dd>Arī Gamechanger Audio biznesu vada no Latvijas. “Mūsu priekšrocība ir ASV nodokļu sistēma, kur ievedmuita un muitošanas procedūras sākas no 800 dolāriem. Līdz ar to mēs ar savu produktu par 400 dolāriem un 29 eiro kurjerpkalpojumiem varam veiksmīgi apkalpot šo tirgu no Rīgas – jau pēc divām dienām paciņa ir Amerikā,” atklāj D. Dubovskis.`+
`<dd></dd><h3 class="post-subtitle">Konkurentu netrūkst</h3>`+
`<dd></dd>Runājot par izaicinājumiem, U. Briedis atzīst, ka DeskTime nav ne pirmais, ne vienīgais uzņēmums, kas ASV piedāvā darbinieku laika uzskaites programmatūru. “Kopš Covid-19 pandēmijas laika uzskaites tirgus segments ir kļuvis ļoti piesātināts. Mūsu konkurentu skaits ir pieaudzis četras reizes. Visā pasaulē sāk izstrādāt labākus vai sliktākus laika uzskaites risinājumus, bet mēs stabili turamies top 10,” viņš teic.`+
`<dd></dd>Vēl viens izaicinājums ir tas, ka DeskTime nav iespējkapitāla finansēts jaunuzņēmums un attīstībā iegulda pašu nopelnīto naudu, nevis investoru līdzekļus. “Mēs strādājam ar naudu, ko paši nopelnām, bet mums ir jāsacenšas ar uzņēmumiem, kam ir simtiem miljonu dolāru investoru kapitāls. Šādos apstākļos konkurēt ir grūtāk, bet nav neiespējami. Tāpēc mēs esam ļoti lepni, ka mūsu risinājums spēj konkurēt ar lielajām kompānijām visā pasaulē. Tas kārtējo reizi pierāda, ka latvieši ar neatlaidību un centību var diezgan labi konkurēt ar pasaules spēlētājiem,” norāda U. Briedis. ASV tirgus veido aptuveni 35 % no DeskTime apgrozījuma.`+
`<dd></dd><h3 class="post-subtitle">Bez tipiskas pārdošanas komandas</h3>`+
`<dd></dd>DeskTime nav klasiskas pārdošanas komandas, kas nodarbotos ar t. s. auksto zvanu veikšanu un sūtītu e-pastus potenciālajiem klientiem. “Būtu sarežģīti šo produktu pārdot, vienkārši zvanot uzņēmumiem, kas par to neko nav dzirdējuši,” teic U. Briedis. `+
`<dd></dd>Produkts ir veidots tā, lai potenciālajam klientam viss būtu skaidrs no mājaslapā pieejamās informācijas. Taču uzņēmumam ir komanda, kas nodarbojas ar jauno klientu konsultēšanu par produkta izmantošanu. “Amerikas tirgum ir raksturīgs tas, ka viņi paši ļoti labi visu apgūst. Viņiem patīk lasīt, viņi iedziļinās. Mums ir ļoti plašas biežāk uzdoto jautājumu sadaļas. Mums ir arī apmācību video, kurus cilvēki skatās, jo viņi saprot, ka viņiem pašiem produkts būs jālieto. Tikai tad, ja kaut kas nav saprotams, viņi piesakās uz telefonisku konsultāciju,” saka U. Briedis. Visbiežāk uzņēmuma komanda sazvanās ar lielajiem klientiem, kuriem pirms vienošanās par sadarbību ir specifiski jautājumi, piemēram, par drošību.`+
`<dd></dd>DeskTime gadījumā ļoti labi strādā reklāma no mutes mutē – klienti, kuri ir apmierināti ar produktu, ir vislabākie tā reklamētāji. “Tāpat mēs mērķtiecīgi strādājam ar atsauksmju lapām, piemēram, G2 un Capterra, kur mēs esam ļoti labās pozīcijās,” viņš teic.`+
`<dd></dd><h3 class="post-subtitle">Dzimtā vieta – internets</h3>`+
`<dd></dd>“Mūsu dzimtā vieta ir internets,” saka D. Dubovskis. Uzņēmums izmanto Facebook un Instagram reklāmas, ar kurām izdodas ieinteresēt daudz cilvēku, kā arī lieto influenceru mārketingu. `+
`<dd></dd><div class="quote-style">“Mēs izmantojam digitālās reklāmas, lai cilvēku ieinteresētu par mūsu zīmolu un aizvestu viņu līdz mūsu mājaslapai. Taču mēs arī saprotam, ka viņš uzreiz neveiks pirkumu. Cilvēks gribēs dzirdēt kādu atsauksmi un redzēt kādu demonstrāciju. Tad klients mūsu produktu meklēs YouTube. Tāpēc ir svarīgi, lai viņš uzreiz redz prestižus iekārtu apskates kanālus, kas uzreiz radīs uzticamību,” stāsta D. Dubovskis.</div>`+
`<dd></dd>Uzņēmums potenciālo klientu segmentēšanai sociālajos medijos pieiet konservatīvi un skatās uz esošajiem klientiem un viņiem līdzīgajiem, kā arī cilvēkiem, kuri seko konkurentu zīmoliem.`+
`<dd></dd><h3 class="post-subtitle">Aktīvi iegulda digitālajā reklāmā</h3>`+
`<dd></dd>DeskTime vismaz 20 % no gada apgrozījuma iegulda digitālajā reklāmā. “Tā kā mēs esam digitāls uzņēmums, mums ir viegli izmērīt ieguldījumus. Investējot Google reklāmā un meklētājprogrammu optimizācijā, mēs uzreiz redzam ieguldījumu apjomu un varam izrēķināt, cik klientu šādām investīcijām vajadzētu atvest. Sasniegumu vērtēšanai mēs esam piesaistījuši ārvalstu aģentūru, kas specializējusies tieši biznesa digitālo risinājumu reklāmā,” atklāj U. Briedis.`+
`<dd></dd>Reklāma ASV ir dārga, tāpēc ir svarīga meklētājprogrammu optimizācija un satura radīšana. DeskTime gadījumā labi strādā kvalitatīvs bloga saturs. Taču U. Briedis teic, ka nevar uzrakstīt vienu bloga rakstu un cerēt, ka tam būs fantastiski panākumi. Tāpēc DeskTime blogā ir pieci līdz seši jauni raksti mēnesī. “Nesen ar vienu no mūsu bloga rakstiem padalījās Canva valdes loceklis, kurš agrāk strādāja Apple. Tajā brīdī bija milzīgs mājaslapas apmeklējums, kas atveda arī jaunus klientus,” stāsta U. Briedis.`+
`<dd></dd>Viņš iesaka ieguldīt labā meklētājprogrammu optimizācijā. “Protams, pašos pamatos produktam ir jābūt labam – bez tā meklētājprogrammu optimizācija nepalīdzēs,” uzsver U. Briedis. Tāpat ir svarīgi piedomāt pie atslēgvārdiem un tā, lai tie būtu atšķirīgi no konkurentu izmantotajiem.`+
`<dd></dd><h3 class="post-subtitle">Rūpīgi jāsagatavojas</h3>`+
`<dd></dd>I. Slokenbergs uzsver sagatavošanās nozīmi – ir jābūt informētam par savu nozari, ASV reģionālajiem tirgiem, cenām un izmaksām, nozares spēlētājiem, organizācijām, pasākumiem utt. “Internets un digitālie rīki dod jebkuram Latvijas uzņēmumam iespējas daudz ko izpētīt par eksporta iespējām, iespējamiem klientiem un pircējiem jau pirms pirmā soļa speršanas,” viņš norāda. `+
`<dd></dd>Tāpat I. Slokenbergs iesaka izpētīt konkrētās nozares asociācijas un rīkotās izstādes un iepazīties ar tām, jo arī tās ir labs veids, kā sagatavoties ceļam uz ASV tirgu. Taču pēc izpētes un biznesa plāna sagatavošanas ir jāsāk ieguldīt līdzekļus – uzņēmējiem regulāri jābrauc uz ASV, jātiekas ar sadarbības partneriem un iespējamiem klientiem, jāapmeklē izstādes, jāveido paziņu tīkls. “Galvenais ir domāt par ilgtermiņa attiecību veidošanu. Lai radītu uzticību un izveidotu attiecības, ir būtiski regulāri tikties, iet pusdienās un uz pasākumiem, piedalīties konferencēs, semināros, izstādēs – katru gadu. Tas nepieciešams, jo daudzu konkurentu piedāvājumu priekšā ASV pircējs dos priekšroku tam pārdevējam, ar kuru viņam būs izveidojušās labas personiskās un uz uzticību balstītas attiecības,” viņš norāda.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america6+`"></div>`+
`<dd></dd>I. Slokenbergs iesaka izmantot valsts atbalsta instrumentus un atbalstu, ko sniedz dažādas organizācijas – Latvijas Investīciju un attīstības aģentūra, kam ir pārstāvji Sanfrancisko un Bostonā, eksportētāju asociācija The Red Jackets, Latvijas Tirdzniecības un rūpniecības kamera, Amerikas Tirdzniecības palāta Latvijā, Latvijas goda konsulu tīkls ASV. “Katra no šīm organizācijām ir atsevišķs informācijas avots par ASV tirgu, un katrai ir sava specifika,” viņš uzsver. `+
`<dd></dd>Savukārt Latvijas Amerikas Tirdzniecības kamera rīko konferenci Spotlight Latvia, kas notiks šī gada 22. septembrī Bostonā un pulcēs daudz augsta līmeņa Latvijas un ASV uzņēmēju un profesionāļu.`+
`<dd></dd><h3 class="post-subtitle">Par juridiskajiem jautājumiem jādomā no pirmās dienas</h3>`+
`<dd></dd>I. Slokenbergs uzsver, ka no pirmās dienas ir jādomā par juridiskajiem jautājumiem, t. i., kā orientēties sarežģītajā ASV juridiskajā sistēmā un aizsargāt savas intereses. “Viena lieta ir eksportēt no Latvijas uzņēmuma, bet pavisam cita – pieņemt lēmumu dibināt atsevišķu kompāniju ASV, kas tur veiks uzņēmējdarbību. Uzņēmumam ASV ir vairākas priekšrocības, un dažkārt tas ir obligāti, piemēram, ja nepieciešams atvērt ASV bankas kontu vai nodarbināt ASV darbiniekus,” viņš skaidro. `+
`<dd></dd><div class="quote-style">Latvijas uzņēmēji mēdz dibināt kompāniju ASV vienīgi tādēļ, ka amerikāņiem ir ērtāk un pazīstamāk veikt darījumus ar vietējo uzņēmumu ar adresi un bankas kontu ASV, nevis Latvijā. </div>`+
`<dd></dd>To apstiprina Gamechanger Audio pieredze. “Amerikā uzņēmēji ir pieraduši, ka viss notiek iekšzemē un viņiem nav jāsaskaras ar importa procedūrām,” saka D. Dubovskis. Uzņēmumam ir māsasuzņēmums ASV, kas ir Gamechanger Audio pārstāvis Amazon un oficiālais importētājs tiem klientiem, kuriem ir juridiski svarīgi pirkt iekšzemē.`+
`<dd></dd>“Ja tiek pieņemts lēmums dibināt ASV uzņēmumu, ir jāsaprot, ka uzņēmumu korporatīvā vide, pārvaldība un uzņēmumu īpašumtiesību strukturizēšana šajā tirgū atšķiras no tā, kā ir pierasts Latvijā. Šiem jautājumiem ir jāpieiet uzmanīgi, vislabāk ar ASV advokāta palīdzību – it sevišķi, ja uzņēmumam būs vairāki īpašnieki, tostarp kāds vietējais partneris. Tāpat ir krasas atšķirības darba likumos, līgumu nosacījumos u. c. ASV nevar iet ar priekšstatu, ka juridiski visu varēs sakārtot tāpat, kā ierasts Latvijā. Un noteikti neiesaku censties pašiem visu kārtot bez vietējiem advokātiem un nodokļu konsultantiem, lai ietaupītu līdzekļus, jo tā var “iebraukt auzās”,” saka I. Slokenbergs.`+
`<dd></dd>Sagatavošanās ASV tirgum pieprasa arī aizsargāt intelektuālo īpašumu – patentus, komercnoslēpumus, autortiesības un preču zīmes. Tāpat ir svarīgi strādāt atbilstoši ASV federālajam un štatu nodokļu režīmiem, lai izvairītos no nevajadzīgiem riskiem, it sevišķi pārrobežu nodokļu atbilstības kontekstā.`+
`<dd></dd><h3 class="post-subtitle">Iespēja ekonomikas čempioniem</h3>`+
`<dd></dd>I. Slokenbergs šajā tālajā tirgū iesaka būt pacietīgiem un negaidīt tūlītējas sekmes. Viņš aicina atrast atbilstošu nišu plašajā ASV tirgū, veidot ilgtermiņa, uz uzticamību  balstītas attiecības ar ASV partneriem. “Latvija vienmēr būs maza, salīdzinot ar ASV, bet tas nenozīmē, ka Latvija nevar radīt pasaules ekonomikas čempionus,” uzskata I. Slokenbergs. `+
`<dd></dd>Kā piemēru viņš min Printful, kas ir Latvijas pirmais “vienradzis” – jaunuzņēmums, kura vērtība investīciju piesaistes rezultātā ir sasniegusi vienu miljardu ASV dolāru. ASV investors Bregal Sagemount Latvijā dibinātajā uzņēmumā 2021. gadā investēja 130 miljonu dolāru. “Katrs šāds gadījums stiprinās mūsu saites ar ASV tirgu un var radīt jaunas iespējas Latvijas uzņēmējiem, savukārt ciešāka ekonomiska sadarbība vēl vairāk veicinās stratēģiskās drošības saites ar ASV. Šāda palielināta sadarbība ir obligāts priekšnosacījums, kas nodrošinās nākotnes iespējas Latvijas iedzīvotājiem un sniegs latviešiem pārliecību turpināt dzīvot, strādāt un radīt Latvijā, nevis citur,” spriež I. Slokenbergs.`+

	support_by_liaa,



	exp_review_8_name: "Pieredzes stāsti eksportā uz Dienvidkoreju",
	exp_review_8_company:"SPH Engineering, Kronus, Ette Tete",
	exp_review_8_designation: "SPH Engineering, Kronus, Ette Tete",
	exp_review_8_excerpt: `Lai gan Dienvidkorejas tirgus ir tāls un vairumam Latvijas uzņēmumu vēl svešs, abu valstu savstarpējās tirdzniecības apjoms 10 gadu laikā ir pieckāršojies, sasniedzot teju 130 miljonus eiro. Šī eksotiskā tirgus pazinēji sadarbības ar Dienvidkoreju nākotni redz pozitīvā gaismā ar lielu potenciālu gan palielināt apjomus, gan dažādot eksportēto preču veidus.`,
	exp_review_8_title:"Vilinošais Dienvidkorejas tirgus, kam neder pasteļtoņi",
	exp_review_8_text:

`<dd></dd>Lai gan Dienvidkorejas tirgus ir tāls un vairumam Latvijas uzņēmumu vēl svešs, abu valstu savstarpējās tirdzniecības apjoms 10 gadu laikā ir pieckāršojies, sasniedzot teju 130 miljonus eiro. Šī eksotiskā tirgus pazinēji sadarbības ar Dienvidkoreju nākotni redz pozitīvā gaismā ar lielu potenciālu gan palielināt apjomus, gan dažādot eksportēto preču veidus.`+
`<dd></dd>2022. gadā Dienvidkoreja bija Latvijas 40. nozīmīgākais eksporta un 42. nozīmīgākais importa partneris. “Šis ir viens no Latvijai svarīgākajiem tālajiem tirgiem. To pierāda arī Latvijas ārējā ekonomiskā pārstāvniecība Dienvidkorejā, jo neviens neizdomāja tāpat vien šeit atvērt biroju. Latvijas Investīciju un attīstības aģentūra veica uzņēmēju aptauju, un šī tirgus svarīgums ir uz datiem balstīts secinājums,” saka Latvijas ārējās ekonomiskās pārstāvniecības Dienvidkorejā vadītājs Mārtiņš Baumanis.`+
`<dd></dd>Viņš Dienvidkoreju pielīdzina vidēji turīgas Rietumeiropas valsts izmēra tirgum, piemēram, Vācijai vai Francijai. Ignorēt tik nozīmīgu uzņēmējdarbības iespēju nav prāta darbs. “Ja uzņēmums jau stabili eksportē uz Eiropu un tam ir nepieciešama izaugsme, šis ir viens no pirmajiem tirgiem, kur sevi izaicināt,” viņš uzskata.`+
`<dd></dd><h3 class="post-subtitle">Tirgus pieprasa kvalitāti</h3>`+
`<dd></dd>“Valsts ir attīstīta, cilvēki interesējas par jaunām precēm. Dzīves kvalitātes uzlabošana Dienvidkorejā ir tēma, par kuru cilvēki domā arvien vairāk. Dienvidkoreja ir piedzīvojusi ekonomiskās attīstības izrāvienu. Vietējiem iedzīvotājiem ir lielas darba spējas, bet šobrīd cilvēki sāk domāt par to, kā dzīvot labāk un kā līdzsvarot mājas un darba dzīvi, nevis kā nopelnīt vairāk naudas,” saka M. Baumanis.`+
`<dd></dd><div class="quote-style">Ņemot vērā, ka pircējiem Dienvidkorejā ir svarīga dzīves kvalitāte, arī precēm ir jāatbilst augstām prasībām. “Dienvidkorejā nav grūti nopirkt Ķīnas preces, taču viņiem ir svarīga kvalitāte. Tāpēc te ir pieprasījums pēc dzīves kvalitāti uzlabojošām lietām un bērnu precēm, kam vietējie iedzīvotāji tērē diezgan daudz naudas. </div>`+
`<dd></dd>Piemēram, Dienvidkoreja ir viens no svarīgākajiem eksporta tirgiem Latvijas bērnu grāmatām. Arī jebkura augstākās klases lieta ar stāstu Dienvidkorejā tiks novērtēta,” viņš spriež. Savukārt rūpniecisko produktu jomā Dienvidkorejā labi klājas “Latvijas Finierim”, kas sadarbojas ar Dienvidkorejas kuģu būvētājiem. M. Baumanis spriež, ka šai sadarbībai ir attīstības potenciāls.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea1+`"></div>`+
`<dd></dd>“Dienvidkoreja tehnoloģiju ziņā ir ļoti attīstīts tirgus. Viņi ļoti daudz investē tehnoloģijās un interesējas par citu valstu tehnoloģiju uzņēmumiem. Man patīk, ka Dienvidkorejas tirgus nestāv uz vietas. Viņi visu laiku domā un attīstās. Mums ir laba sinerģija ar partneriem, jo gan mēs, gan viņi vēlas būt pirmrindnieki un visu laiku domājam par tehnoloģisko attīstību,” saka dronu tehnoloģiju uzņēmuma “SPH Engineering” pārdošanas komandas vadītāja Madara Mazkalne.`+
`<dd></dd><h3 class="post-subtitle">Pilnīgi cits mārketings</h3>`+
`<dd></dd>M. Baumanis norāda, ka partneri Dienvidkorejā diezgan bieži vēlas palīdzību mārketinga jautājumos. Tāpēc nevajadzētu iedomāties, ka pietiks aizsūtīt preci un partneri paši nodarbosies ar tās pārdošanu. “Dzīvē tā nenotiek. Viņi bieži vien sagaida finansiālu atbalstu vai atlaidi no preces cenas, lai varētu pielāgot produkta mārketingu Dienvidkorejas tirgum. Pretējā gadījumā partneri preci novietos zemākajā plauktā, kā rezultātā to iztirgos par puscenu, bet nākamā pasūtījuma nebūs. Turpretī Latvijas ražotājam šis tirgus ir zudis,” viņš stāsta.`+
`<dd></dd><div class="quote-style">Lai sāktu sadarbību, ir svarīgi parādīt savu labo gribu tā, lai otra puse jūt interesi ilgtermiņa sadarbības veidošanai.</div>`+
`<dd></dd>Tāpēc pirmajam preču sūtījumam viņš iesaka piemērot atlaidi, piemēram, 10 % apmērā, vai arī piedāvāt ko citu īpašu. “Viņi to sagaida, jo paši tā rīkojas,” zina teikt M. Baumanis.`+
`<dd></dd>Bērnu mēbeļu zīmola “Ette Tete” dibinātājas Lindas Riekstiņas-Šnores novērojumi liecina, ka partneri Dienvidkorejā nav ārkārtīgi prasīgi uz milzīgām atlaidēm – sadarbība ir loģiski pamatota un vērsta uz ilgtermiņu.`+
`<dd></dd>Mārketinga aktivitātes Dienvidkorejā “Ette Tete” ir atstājis partnera ziņā, jo gaumes atšķirības ir ievērojamas. “Piemēram, nekur citur mūsu preču personalizācijā nav bijis tik daudz sirsniņu. Ja mēs šajā tirgū piedāvātu mums tipiskos pasteļtoņus, mēs tur pazustu. Tāpēc mārketinga aktivitātes ir atstātas partnera ziņā,” saka L. Riekstiņa-Šnore.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea2+`"></div>`+
`<dd></dd>Vienlaikus šajā tirgū ir svarīga personīgā iesaiste uzņēmējdarbībā. Piemēram, kad partneris bija saņēmis pirmās paletes un gatavojās sākt tirdzniecību, tas lūdza, lai “Ette Tete” uzfilmē īsu video, kurā viņi demonstrē savu sajūsmu par ieiešanu Dienvidkorejas tirgū. “Viņiem ir svarīgs stāsts, ka mēs kā ģimene radījām šos produktus un esam ļoti priecīgi, ka tie būs arī Dienvidkorejas ģimenēs. Tas ir ļoti personīgi,” viņa stāsta.`+
`<dd></dd><h3 class="post-subtitle">Sadarbības uzsākšana var prasīt ilgu laiku</h3>`+
`<dd></dd>“Sadarbība ar korejiešiem veidojas ilgi. Viss notiek salīdzinoši lēni, tāpēc ir vajadzīga pacietība un liela vēlme ienākt šajā tirgū. Šeit ir Latvijas uzņēmumu veiksmes stāsti, bet tie ir prasījuši divus, trīs gadus ilgus pūliņus, piemēram, regulāru dalību izstādēs un komunikāciju ar sadarbības partneriem. Ir jābūt pietiekami lielam gribasspēkam, lai ieietu Dienvidkorejas tirgū,” saka M. Baumanis.`+
`<dd></dd>Savukārt L. Riekstiņa-Šnore uzskata, ka daudz kas ir atkarīgs no tā, kāds cilvēks ir sadarbības partnera pusē. “Ette Tete” salīdzinoši ātri izdevās vienoties par līgumu un viss notika diezgan raiti. “Mums joprojām šad tad notiek diskusijas par produktu un tā pielāgojumiem, jo viņiem ir nedaudz savādāka izpratne par to, kas ir kvalitatīvs produkts. Krāsa ir pietiekami bieza, lai aizsargātu koku, bet mums ir svarīgi, lai var redzēt koka struktūru. Savukārt viņiem tas nav tik nozīmīgi,” piebilst L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">Jāņem vērā hierarhijas spēks</h3>`+
`<dd></dd>M. Baumanis norāda, ka dienvidkorejieši no ārzemniekiem nesagaida zināšanas par vietējās kultūras īpatnībām un tradīcijām. Taču obligāti jāatceras pasniegt vizītkarti ar abām rokām. “Ja tev dod vizītkarti, tā uzreiz ir jāizlasa un jāsaprot, ar ko tu esi saticies. Šī ir cieņas izrādīšana, un tā ir ļoti svarīga,” viņš uzsver.`+
`<dd></dd>M. Mazkalne piebilst, ka Dienvidkorejā ir izteikta hierarhija, tāpēc vienmēr ir jāsaprot, ar kādu cilvēku partnera uzņēmumā ir komunikācija un cik augstu viņš ir korporatīvajā hierarhijā. No tā var secināt, cik ātri virzīsies sadarbība.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea3+`"></div>`+
`<dd></dd>Latvijas ārējās ekonomiskās pārstāvniecības Dienvidkorejā vadītājs min vēl citas kultūras atšķirības. Šajā zemē nevienu vārdu neraksta ar sarkanu krāsu, it īpaši cilvēka vārdu. “Sarunas laikā ir jābūt pilnīgi fokusētam uz sarunu biedru. Pat pierakstu veikšana telefonā tiks uztverta negatīvi. Ļoti svarīga ir arī ātra atgriezeniskā saite, un pēc tikšanās uzreiz tajā pašā dienā vajadzētu uzrakstīt e-pasta ziņu ar pateicību par tikšanos, pirmajām veiktajām darbībām un pat piedāvāt laiku nākamajai tikšanās reizei,” iesaka M. Baumanis.`+
`<dd></dd><h3 class="post-subtitle">Uzņēmējdarbība var sākties arī nejauši</h3>`+
`<dd></dd>L. Riekstiņa-Šnore atzīst, ka Dienvidkorejā nonāca nejauši: cilvēks, kurš nodarbojas ar importu, bija nopircis “Ette Tete” mēbeli un pats izrādīja interesi sadarboties. “Ette Tete” ar partneri sāka sadarboties 2020. gadā, un 2022. gadā uzņēmējdarbība Dienvidkorejā jau veidoja 5 % no apgrozījuma. “Es domāju, ka tur vēl ir ļoti liels potenciāls, jo šobrīd mēs vēl tirgojam salīdzinoši mazus apjomus. Tur vēl ir, kur augt un augt,” saka L. Riekstiņa-Šnore.`+
`<dd></dd>Arī “SPH Engineering” Dienvidkorejas partneris proaktīvi izrādīja interesi sadarboties. “Taču mēs bijām izdarījuši ļoti labu mājasdarbu – mums bija kvalitatīvs produkts,” saka M. Mazkalne. Tagad uzņēmumā ir darbinieks, kurš koncentrējas uz pārdošanu Āzijā, tai skaitā Dienvidkorejā, kas tagad ir “SPH Engineering” trešais lielākais eksporta tirgus pēc Ziemeļamerikas un Kanādas. Klienti galvenokārt izmanto Latvijā radīto programmatūru gan dronu maršrutu plānošanai un automatizēšanai, gan dronu šoviem, bet ir bijuši arī individuāli pielāgoti projekti. “Dienvidkorejā ir vieni no iespaidīgākajiem dronu šoviem, kādi pasaulē tiek rīkoti. Vietējais Dienvidkorejas rekords ir šovs, kurā Vecgada vakarā lidoja 1500 dronu. Un mēs varam lepoties, ka to pamatā ir mūsu tehnoloģijas,” saka M. Mazkalne.`+
`<dd></dd>Viņa pozitīvi raugās uz uzņēmējdarbības attīstību Dienvidkorejas tirgū: “Protams, mēs gribam paplašināt darbību Dienvidkorejā un visā Āzijas un Klusā okeāna reģionā.”`+
`<dd></dd><h3 class="post-subtitle">“Kronus” eksports Korejā veido 2 līdz 5 % no apgrozījuma</h3>`+
`<dd></dd>Kokrūpniecības nozares uzņēmuma “Kronus” pārdošanas direktors ASV, Āzijā un Klusā okeāna reģionā Aleksejs Volcenkovs ir novērojis, ka Dienvidkorejā cilvēkiem ļoti patīk koks, taču tur nav tādu mežsaimniecības tradīciju kā Latvijā. Tāpēc liela daļa kokrūpniecības produktu valstī tiek importēta, un “Kronus” saskata lielu potenciālu šajā tirgū.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea4+`"></div>`+
`<dd></dd>Šobrīd Dienvidkoreja veido 2 līdz 5 % no “Kronus” apgrozījuma un ar vairāku miljonu eiro apgrozījumu ir starp 15 nozīmīgākajiem eksporta partneriem. Uzņēmums uz šo zemi eksportē gan koka iepakojumu, gan preces dārzam, piemēram, augstās kastes puķu vai dārzeņu audzēšanai. Tieši dārza produktu kategorijā “Kronus” saskata lielu potenciālu Dienvidkorejā.`+
`<dd></dd><h3 class="post-subtitle">Nevar aizmirst par sertifikāciju</h3>`+
`<dd></dd>Viens no lielākajiem izaicinājumiem ir sertifikācija. “Ja kādam ir Amerikas vai Eiropas sertifikāts, tas ir labi mārketingam, bet Korejai tas tāpat nederēs. Tāpēc vispirms jāiepazīstas ar regulējumu un citām prasībām, turklāt jāņem vērā, ka Dienvidkorejas standarti ir augstāki pat par Eiropas Savienībā pieņemtajiem. Dažām iestādēm ir mājaslapa angļu valodā, bet citām nav – es varu palīdzēt ar tirgus izpēti,” norāda M. Baumanis.`+
`<dd></dd>Arī L. Riekstiņa-Šnore norāda, ka visiem produktiem ir jāatbilst Dienvidkorejas iekšējiem standartiem. “Ette Tete” nācās testēt savu produkciju, bet viss atbilda vajadzīgajiem standartiem.`+
`<dd></dd><h3 class="post-subtitle">Viss notiek klātienē</h3>`+
`<dd></dd>M. Baumanis atzīst, ka Dienvidkorejā ir savi izaicinājumi un īpatnības. Viens no lielākajiem izaicinājumiem ir attālums. `+
`<dd></dd><div class="quote-style">“Nauda, ko uzņēmums iztērēs ceļam no Latvijas uz Dienvidkoreju, ir minimāls ieguldījums, lai varētu sekmīgi nodibināt attiecības. Korejieši arī pandēmijas laikā nepiekrita attālinātai sadarbībai – viņu kultūrā tā vienkārši nav pieņemts. Viņi domā, ka tad, ja tu neesi darbā, bet gan mājās, tu, visticamāk, atpūties un nestrādā. Tāpēc te viss notiek klātienē,” saka M. Baumanis.</div>`+
`<dd></dd>Tāpēc attiecību dibināšanā klātienes efekts ir nenovērtējams. “Tas pierādās arī manā darbā. Es varu aizsūtīt e-pasta ziņu korejiešu vai angļu valodā, bet gandrīz nekad nevarēšu saprast, vai tam sekos kādas darbības. Taču, satiekoties klātienē, ir pilnīgi cita lieta. Var gadīties, ka tajā pašā dienā pēc tikšanās viņi uzreiz pateiks, ko ir izdarījuši un ko varam plānot tālāk,” pieredzē dalās M. Baumanis.`+
`<dd></dd>Arī M. Mazkalne teic, ka dienvidkorejiešiem ir vajadzīga personīga saziņa. Tāpēc uzņēmuma pārstāvji brauc ciemos pie partneriem. “Jārēķinās, ka tā nebūs viena tikšanās. Viņiem ir jānotic gan uzņēmumam, gan tā pārstāvjiem,” saka M. Mazkalne.`+
`<dd></dd>Tāpat šajā tirgū ir liela cieņa pret kaimiņiem un apkārtējiem cilvēkiem. “Ette Tete” sūta savu produkciju izjauktā veidā – pircējam pašam ir jāsaliek preces. Vienam no uzņēmuma produktiem ir 72 tapiņas, kuras jāiesit rāmī. Partneris norādīja, ka darīt to ar āmuru ir pārāk skaļi un lūdza šo produktu piegādāt jau gatavā veidā. “Tajā brīdī es pat nezināju, ko atbildēt, jo produktu sūtīšana jau saliktā veidā ir ļoti dārga, jo tie aizņem daudz vairāk vietas,” stāsta L. Riekstiņa-Šnore. Tāpat Dienvidkorejā parasti ir ļoti mazi dzīvokļi, tāpēc vispirktākie ir mazi un kompakti produkti.`+
`<dd></dd><h3 class="post-subtitle">Valodas zināšanām ir liela nozīme</h3>`+
`<dd></dd>“Tādās valstīs kā Ķīna, Dienvidkoreja un Japāna ir ļoti būtiski izprast kultūru. Darbs Āzijā prasa zināšanas, pietāti, sapratni, cieņu, kā arī izpratni par to, kā viņi uztver informāciju. Mūsu pārdošanas speciāliste 10 gadus ir dzīvojusi Ķīnā, tāpēc viņai sarunas ar partneriem Āzijā norit veiksmīgi. Ja kādā brīdī pēkšņi “pieslēdzas” cits kolēģis, atbildes no partneriem jāgaida daudz ilgāk,” teic M. Mazkalne. Viņa piebilst, ka viens no pārpratumu cēloņiem ir valodas barjera. Uzņēmums Dienvidkorejā veiksmīgi strādā jau vairākus gadus, bet tikai nesen lokalizēja savu programmatūru korejiešu valodā. “Tas uzreiz būtiski palielināja viņu ieinteresētību nomainīt un tiešām lietot mūsu programmatūru. Jā, viņi prot angļu valodu, bet, ja uzņēmums ir nolēmis, ka Dienvidkoreja būs tam svarīgs tirgus, ir vajadzīgs cilvēks, kurš spēs runāt šajā valodā,” teic M. Mazkalne. `+
`<dd></dd>Arī M. Baumanis norāda, ka attiecības veidosies veiksmīgāk un attīstīsies ātrāk, ja uzņēmums ar klientiem spēs runāt korejiešu valodā. `+
`<dd></dd><div class="quote-style">“Valoda tuvina kultūrai un palīdz labāk saprast, kāpēc daudzas lietas notiek tieši tā un ne citādi. Mēģināt runāt viņu valodā ir milzīgas priekšrocības salīdzinājumā ar konkurentiem,” viņš uzsver.</div>`+
`<dd></dd>Arī A. Volcenkovs min kultūras atšķirības, īpaši izceļot pārtiku. “Dienvidkorejas apmeklējums latviešiem varētu būt izaicinājums: korejieši ēd daudz jūras produktu, turklāt bieži vien svaigus. Tas mūsu vidū nav īpaši populāri.”`+
`<dd></dd>Tāpat darījumu pārrunas ir diezgan sarežģītas, jo ir grūti nolasīt korejiešu emocijas. “Varētu teikt, ka viņiem vienmēr ir neitrāla sejas izteiksme, gluži kā spēlējot pokeru. Tāpēc darījumu pārrunas ir daudz grūtākas, salīdzinot ar, piemēram, Vāciju, Dāniju vai Franciju, kur pēc sejas izteiksmes vismaz nedaudz var nojaust otras puses domas un emocijas,” norāda A. Volcenkovs.`+
`<dd></dd><h3 class="post-subtitle">Darījumu un personīgās attiecības sajaucas</h3>`+
`<dd></dd>Kā vienu no tirgus īpatnībām M. Baumanis izceļ to, ka šajā valstī darba un privātā dzīve nav tik ļoti nodalītas kā citur. “Ja uzņēmējam ir Dienvidkorejas partnera WhatsApp numurs, pastāv liela iespēja, ka viņi sazināsies ārpus darba laika, runās par ģimeni un sūtīs viens otram bērnu bildes. Dienvidkorejieši mēdz sapludināt robežas starp darba un privāto dzīvi. Dažkārt tas latviešiem var likties nepieņemami,” viņš norāda.`+
`<dd></dd>L. Riekstiņai-Šnorei bija grūti pierast, ka ar cilvēku, kuru viņa nekad savā mūžā nav satikusi klātienē, veidojas tik personīgas attiecības. Piemēram, uzņēmums savam partnerim aizsūtīja Ziemassvētku dāvanu un pretī saņēma viņa bērnu bildes, kā viņi ar to spēlējas. “Mūsu bērni piedzima ļoti līdzīgā laikā. Viņš atsūtīja priecīgu e-pasta ziņu ar jaundzimušā bildi, bet man radās jautājums – vai man tagad jāatbild ar to pašu? Mēs neesam pieraduši pie tādas komunikācijas. Mēs partneriem nesakām, ka mums pasaulē ir nācis bērns. Visbiežāk viņi nemaz nenojauš, kas notiek manā privātajā dzīvē. Taču Dienvidkorejā ģimenes un darba dzīve ir nedalāma,” stāsta L. Riekstiņa-Šnore.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea5+`"></div>`+
`<dd></dd>Arī A. Volcenkovs izceļ to, ka uzņēmējiem Dienvidkorejā patīk veidot personīgas attiecības ar darījumu partneriem. Parasti darba pārrunas, kas notiek pa dienu, turpinās ar vakariņām un dzērieniem, lai iepazītos tuvāk. “Tas palīdz veidot attiecības. Visi mūsu labākie darījumu kontakti ir veidojušies tieši vakariņu laikā neformālās sarunās,” viņš norāda.`+
`<dd></dd>L. Riekstiņai-Šnorei ir radies iespaids, ka tad, kad piegādātājs iegūst partnera uzticību un pierāda sevi, šajā tirgū piedod vairāk nekā citās valstīs. “Ja kaut kas nav sanācis, bet tiek risināts, klienti saglabā lojalitāti un turpina strādāt kopā,” spriež L. Riekstiņa-Šnore. Arī A. Volcenkovs spriež, ka korejieši ir ļoti uzticami partneri. Tāpat viņi ļoti lielu vērību pievērš detaļām, kas viņu kvalitātes standartus padara daudz augstākus nekā ierasts Eiropā. `+
`<dd></dd><h3 class="post-subtitle">Turpina iesākto sadarbību</h3>`+
`<dd></dd>2018. gadā notika Valsts prezidenta Raimonda Vējoņa vizīte Dienvidkorejā, kas bija pirmā augstākā līmeņa Latvijas valsts amatpersonas vizīte šajā reģionā. Šīs vizītes laikā tika parakstīts saprašanās memorands par savstarpējo ekonomisko attiecību veicināšanu, starptautiskās tirdzniecības stiprināšanu un sadarbības iespējām tehnoloģiju pārneses un jaunuzņēmumu attīstības jomā.`+
`<dd></dd>Savukārt šogad tika parakstīts saprašanās memorands par sadarbību jaunuzņēmumu un inovāciju jomā. Tas paredz abpusēju atbalstu jaunuzņēmumu tīkla paplašināšanai, attīstībai un ienākšanai Latvijas un Dienvidkorejas tirgū, kā arī sadarbību ilgtspējīgas inovācijas, jauno tehnoloģiju, viedās mobilitātes un digitalizācijas jomā. Vizītes laikā tika parakstīts arī Latvijas Investīciju un attīstības aģentūras un Korejas investīciju organizācijas “Eugene Capital and Securities” saprašanās memorands par sadarbību investīciju piesaistes jomā, kā arī Latvijas Ķīmijas un farmācijas uzņēmēju asociācijas un Korejas Biotehnoloģiju nozares organizācijas saprašanās memorands par sadarbību biofarmācijas nozarē un veselības aprūpes sistēmā.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea6+`"></div>`+
`<dd></dd>Tikšanās laikā abas puses pārrunāja sadarbības iespējas augsto tehnoloģiju projektos un pētījumu programmās, tai skaitā biomedicīnas un biotehnoloģiju un Latvijas Viedās specializācijas stratēģijas jomās, kā arī kosmosa nozarē, kur Latvijai ir labi attīstīta infrastruktūra, modernās zinātnes iespējas un tehnoloģijas.`+
`<dd></dd>Latvijas ārējā ekonomiskā pārstāvniecība Dienvidkorejā Latvijas uzņēmumiem sniedz konsultācijas par Dienvidkoreju. “Mēs visu laiku runājam par Latviju: piedalāmies izstādēs, organizējam nacionālos stendus, prezentējam preces, piesaistām vietējos konsultantus. Mēs darām visu, ko mēs te uz vietas varam izdarīt. Ja sestdienas vakarā jābrauc satikties ar korejiešu potenciālo klientu, tad es to daru. Tas ir mans darbs,” saka M. Baumanis.`+
`<dd></dd><h3 class="post-subtitle">Nākotnes potenciāls ir liels</h3>`+
`<dd></dd>“Dienvidkoreja ir viens no Latvijas stratēģiskajiem partneriem gan investīciju piesaistes, gan eksporta jomā, tādēļ vēlamies attīstīt mūsu sadarbību,” teic Latvijas Investīciju un attīstības aģentūras direktora vietniece investīciju un enerģētikas jautājumos Laura Štrovalde. Arī M. Baumanis un A. Volcenkovs Latvijas uzņēmumiem saskata labas uzņēmējdarbības iespējas Dienvidkorejā un saistībā ar nākotni ir noskaņoti pozitīvi: “Nākotnei nav neviena cita virziena kā vien uz augšu,” teic M. Baumanis.`+

	support_by_liaa,



	exp_review_9_name: "Pieredzes stāsti eksportā uz Izraēlu",
	exp_review_9_company:"Primekss, Light Guide Optics International",
	exp_review_9_designation: "Primekss, Light Guide Optics International",
	exp_review_9_excerpt: `Izraēlu mēdz dēvēt par izgudrotāju un jaunuzņēmumu nāciju. Tāpēc tiem Latvijas uzņēmumiem, kuri apsver eksportēšanu uz šo tirgu, jābūt gataviem piedāvāt pasaules līmeņa inovācijas, tehnoloģijas un kvalitāti.`,
	exp_review_9_title:"Valsts, kur visi ir izgudrotāji — Izraēla",
	exp_review_9_text:

`<dd></dd>Izraēlu mēdz dēvēt par izgudrotāju un jaunuzņēmumu nāciju. Tāpēc tiem Latvijas uzņēmumiem, kuri apsver eksportēšanu uz šo tirgu, jābūt gataviem piedāvāt pasaules līmeņa inovācijas, tehnoloģijas un kvalitāti.`+
`<dd></dd>“Mūsu valstu ekonomiskajai sadarbībai ir milzu neizmantots potenciāls, un mums ir visas iespējas attīstīt savstarpēji izdevīgus projektus. Izraēla ir maza valsts (Vidzemes lielumā!), bet inovāciju un patentu daudzuma ziņā tā ir viena no pasaules līderēm. Tā vien šķiet, ka šeit visi ir izgudrotāji — mājsaimniecei ir patents trauku mazgāšanas sūklim, bet kukaiņu pētnieks ir izstrādājis robotu, kas sajūt cilvēka smaržu zemestrīces gruvešos,” stāsta Latvijas Investīciju un attīstības aģentūras inovāciju un tehnoloģiju pārstāvniecības Izraēlā vadītāja Irina Rubinčika.`+
`<dd></dd>Optiskās šķiedras ražotāja Light Guide Optics International vadītājs Daumants Pfafrods norāda, ka Izraēlā gandrīz 40 % no visa iekšzemes kopprodukta veido augsto tehnoloģiju uzņēmumi. Tāpēc inovatīviem uzņēmumiem ir vērtīgi atrast sadarbības partneri Izraēlā. Arī betona tehnoloģiju uzņēmuma Primekss īpašnieks Jānis Ošlejs spriež, ka uzņēmumam nav vērts skatīties Izraēlas virzienā, ja tas nestrādā ar inovācijām vai neražo kvalitatīvus produktus. `+
`<dd></dd>“Zemo tehnoloģiju ēra ir beigusies. Piemēram, katram lopiņam Izraēlā uzreiz pēc piedzimšanas tiek veikta DNS analīze, lai precīzi zinātu tā veselības stāvokli un plānotu ganāmpulka ekonomisko izdevīgumu, nākotnē realizējot gaļu vai pienu. `+
`<dd></dd><div class="quote-style">Datu analīze Latvijas spēcīgajam informācijas un komunikācijas tehnoloģiju sektoram ir pa spēkam, lai tieši šajā jomā mēs varētu nākt klajā ar inovatīviem risinājumiem, kurus varam eksportēt pasaulē! </div>`+
`<dd></dd>Mums ir laba augsne gan tehnoloģiju eksportam, gan investīcijām mūsu spilgtajos jaunuzņēmumos,” saka I. Rubinčika.`+
`<dd></dd><h3 class="post-subtitle">Ar lielu potenciālu</h3>`+
`<dd></dd>2022. gadā Latvijas kopējais preču un pakalpojumu tirdzniecības apgrozījums ar Izraēlu bija 116,1 miljons eiro, ierindojot Izraēlu 45. vietā starp Latvijas ārējās tirdzniecības partnerēm. Kopējais preču un pakalpojumu eksports uz Izraēlu bija 88,4 miljoni eiro, kas veido 0,3 % no Latvijas kopējā eksporta, savukārt preču un pakalpojumu imports — 27,7 miljoni eiro, t. i., 0,1 % no Latvijas importa. Salīdzinājumā ar 2021. gadu eksports ir pieaudzis par 62 %, bet imports — par 11 %. 2022. gadā Izraēla bija Latvijas 33. nozīmīgākā eksporta un 48. nozīmīgākā importa partnere. `+
`<dd></dd>“Izraēla joprojām nav Latvijas nozīmīgāko eksporta tirgu sarakstā. Taču, ja uzņēmumam izdodas pārvarēt visas tirgū ieiešanas barjeras un iekļūt Izraēlā, tad tam ir nodrošināta nozīmīga un stabila peļņa,” saka I. Rubinčika.`+
`<dd></dd>Viņa piebilst, ka tad, ja uzņēmuma produkts ir pārstāvēts Izraēlā, šis tirgus var kalpot kā vārti uz plašāku pasauli. “Izraēla tradicionāli ir ļoti cieši saistīta ar Ameriku. Ja uzņēmums strādā Izraēlā, tad ieiešana Amerikas tirgū varētu būt vieglāka. Izraēla ir arī ceļš uz Āziju un Indiju,” norāda I. Rubinčika.`+
`<dd></dd><h3 class="post-subtitle">Ģeogrāfiski un politiski sarežģīts tirgus</h3>`+
`<dd></dd>“Izraēla ir viens no sarežģītākajiem punktiem pasaulē — gan ģeogrāfiski, gan politiski. Izraēla ir norobežota no visas pasaules, to ieskauj valstis, ar kurām tai nav diplomātisko attiecību, ir kara stāvoklis vai nav mierizlīguma,” saka I. Rubinčika. Arī D. Pfafrods izceļ, ka drošības pasākumi, ko veic iebraucot un izbraucot no šīs valsts, atšķiras no citur pasaulē ierastajiem. Šā iemesla dēļ loģistika uz Izraēlu ir diezgan sarežģīta — preces valstī nonāk ar jūras konteineriem vai lidmašīnām.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel1+`"></div>`+
`<dd></dd>Taču, neskatoties uz to, valsts ekonomika strauji attīstās. “Izraēlas tirgus ir strauji augošs. Valsts ekonomika ir ļoti spēcīga, tāpēc visiem iesaku turp doties,” saka J. Ošlejs. Dažādos reitingos Izraēlas ekonomika ieņem no ceturtās līdz astotajai vietai starp stiprākajām ekonomikām līdzās Kanādai un ASV. Tāpēc ir loģiski, ka šis tirgus ir arī Latvijas uzņēmumu interesēs,” stāsta I. Rubinčika.`+
`<dd></dd><h3 class="post-subtitle">Importa reforma atvieglo eksportu</h3>`+
`<dd></dd>Vaicāts par izaicinājumiem Izraēlas tirgū, D. Pfafrods teic, ka ir akurāti jāsakārto muitošanas un deklarāciju jautājumi. Tā kā uzņēmums tirgo medicīnas produktu, kam ir dažādi starptautiski sertifikāti, lielu problēmu nav bijis.`+
`<dd></dd>I. Rubinčika stāsta, ka 2023. gada janvārī Izraēla nāca klāja ar importa reformu un valsts nolēma pilnīgi atzīt Eiropas Savienības ražošanas un kontroles standartus. Tas nozīmē, ka daudzi produkti vairs nebūs atsevišķi jāreģistrē Izraēlas kontroles iestādēs un nebūs jāiegūst GMP labas ražošanas prakses sertifikāts. Izņēmums ir piena un gaļas produkti. Visu pārējo preču ievešanai pietiek ar pašdeklarāciju tiešsaistes sistēmā, importētājam apliecinot, ka tas ieved produktus, kuri ražoti atbilstīgi Eiropas Savienības standartiem.`+
`<dd></dd>“Diemžēl kosmētikas produkcijai šie vienkāršotie noteikumi ir aizkavējušies,” saka I. Rubinčika.`+
`<dd></dd><h3 class="post-subtitle">Novērtē kvalitatīvu pārtiku</h3>`+
`<dd></dd>I. Rubinčika Izraēlā labas iespējas saskata Latvijas pārtikas produktiem. “Piemēram, šeit ir liels ogu — arī žāvētu un ātri sasaldētu — deficīts. 50 grami Argentīnas melleņu Izraēlā maksā 3 eiro. Lai cik tas būtu dīvaini, trūkst arī kvalitatīva saldējuma. Lietuvas ražotāji jau ir iegājuši šajā nišā, bet tajā vēl ir potenciāls,” viņa stāsta.`+
`<dd></dd>Lai gan Izraēlā visās jomās tiek atbalstīti vietējie ražotāji, jau vairākus gadus Latvijas produkti Izraēlā ir labi pārstāvēti, piemēram, Latvijas šprotes, Laimas saldumi, Latvijas balzams, Cēsu alus, Cesvaines sieri.`+
`<dd></dd><h3 class="post-subtitle">Iespējas ir arī finanšu nozarei</h3>`+
`<dd></dd>I. Rubinčika vairākas reizes ir saskārusies ar interesi par Latvijas mēbelēm un gaismekļiem. Arī tekstila ražotājiem ir biznesa iespējas šajā tirgū. Lielu potenciālu viņa redz optiskajai šķiedrai, elektronikai un aizsardzības nozares uzņēmumiem.`+
`<dd></dd><div class="quote-style">“Izraēla ir ieinteresēta ražošanas automatizācijā, lietu interneta, klimata tehnoloģiju, viedās enerģētikas un atkritumu pārstrādes jomā, kā arī lauksaimniecības un pārtikas tehnoloģiju attīstībā,” teic I. Rubinčika.</div>`+
`<dd></dd>Vēl viņa saskata potenciālu finanšu nozarē. “Latvija var piedāvāt Izraēlas jaunuzņēmumiem finanšu tehnoloģiju licences jauno finanšu produktu attīstībai. Īpaši aktuāli tas ir šobrīd, kad politiskās situācijas dēļ no Izraēlas aizplūst finanšu kapitāls. Mūsu bankas varētu Izraēlā dibināt pārstāvniecības, lai būtu šajā naudas virpulī un uzklausītu potenciālo klientu vajadzības finanšu jomā,” spriež I. Rubinčika.`+
`<dd></dd><h3 class="post-subtitle">Eksports 20 miljonu eiro apmērā</h3>`+
`<dd></dd>Viens no lielākajiem Latvijas eksportētājiem Izraēlā ir Līvānu optiskās šķiedras ražotājs Light Guide Optics International, kura apgrozījums šajā tirgū veido vairāk nekā 20 miljonus eiro gadā. Vienlaikus uzņēmuma partneri strādā globālā tirgū un produkti, kas paliek Izraēlā, esot neliela daļa. “Tāpēc Izraēlas ekonomiskā attīstība vai politiskā virtuve uz mums tik lielu iespaidu neatstāj. Mūs vairāk ietekmē globālie notikumi — piegādes ķēžu problēmas, valūtas kursa svārstības,” atklāj D. Pfafrods.`+
`<dd></dd>Līdz šim Light Guide Optics International uz Izraēlu eksportēja produktus lāzeriekārtām. Šobrīd uzņēmuma visstraujāk augošais bizness ir katetru ražošana artēriju ārstniecībai. Arī šajā jomā uzņēmums plāno attīstīt sadarbību ar Izraēlu. “Pašlaik tas ir ļoti, ļoti strauji augošs bizness, kas jau veido vairāk nekā 20 % no mūsu apgrozījuma, un tuvāko gadu laikā turpinās palielināties,” saka D. Pfafrods.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel2+`"></div>`+
`<dd></dd>Uzņēmuma saikne ar Izraēlu ir sena. 2000. gadu sākumā D. Pfafrods strādāja uzņēmumā Anda Optec, kas ražoja šķiedras uroloģijas lāzeriem. 2004. uzņēmumā notika lielas pārmaiņas — Latvijas puses dalībnieki atstāja uzņēmumu un nodibināja Z Light, kas vēlāk kļuva par Light Guide Optics International. Uzņēmums nolēma no jauna veidot attiecības ar partneriem Izraēlā. “Tas nebija tik vienkārši. Sadarbības veidošana aizņēma diezgan ilgu laiku un pierādīja, ka uzņēmējdarbības vide atšķiras no Latvijā ierastās. Mēs atradām konsultantu kompāniju, kas bija ar mieru mums palīdzēt ieiet Izraēlas tirgū, un tādā veidā mēs atkal atjaunojām sadarbību un parādījām, uz ko esam spējīgi. Pēc trīs gadus ilga darba 2007. gadā mēs atsākām nopietni strādāt Izraēlā,” stāsta D. Pfafrods.`+
`<dd></dd>Uzņēmumam Light Guide Optics International Izraēlā palīdzēja attīstīties vietējais partneris, kas saņem komisijas maksu par veiksmīgiem darījumiem. “Ņemot vērā izraēļu prasmi kaulēties, mums pārrunās neveicas tik labi,” saka D. Pfafrods.`+
`<dd></dd><h3 class="post-subtitle">Seko klientam</h3>`+
`<dd></dd>Arī J. Ošlejs spriež, ka ir Izraēlā ir nepieciešams uzņēmuma pārstāvis, kas labi pārzina gan vietējo kultūru, gan uzņēmumu un tā produktu. “Izraēlā noteikti ir vajadzīgs kāds no Latvijas, kas saprot, kā latvieši domā, un var iztulkot latviešiem, ko domā Izraēlas puses pārstāvji,” viņš teic.`+
`<dd></dd>Primekss uz Izraēlu eksportē jau 12 gadus. Uzņēmums šajā tirgū nonāca savā ziņā neplānoti. Primekss ilgtermiņa klientam IKEA vajadzēja uzbūvēt noliktavu Izraēlas pilsētā Netanjā. “Mēs sekojam klientiem. Ja mums ir klients, kas attīstās kādā jaunā tirgū, piemēram IKEA, tad mēs tam vienkārši sekojam,” saka J. Ošlejs. Uzņēmumam ienākot Izraēlā, par to ieinteresējās arī citas kompānijas. Tagad Primekss eksports šajā tirgū veido 8 % no tā apgrozījuma.`+
`<dd></dd><h3 class="post-subtitle">Ieiet tirgū nav ne lēts, ne ātrs process</h3>`+
`<dd></dd>Vaicāta, kā labāk sagatavoties, domājot par eksportu uz Izraēlu, I. Rubinčika brīdina, ka vajadzētu būt gatavam ievērojamām izmaksām. Ir jārēķinās ar izdevumiem par muitas speciālistu un juristu konsultācijām, salīdzinoši dārgu jūras un gaisa loģistiku, kā arī ar regulāriem komandējuma izdevumiem, lai varētu tikties ar partneriem klātienē. Taču tas atmaksāsies, jo Izraēlā ir laba pirktspēja. 10 miljoni iedzīvotāju šeit pelna tik, cik citās valstīs 50 miljoni. Un tirgus visu laiku ir izsalcis pēc jauniem produktiem,” viņa saka.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel3+`"></div>`+
`<dd></dd>I. Rubinčika iesaka uzņēmējiem nebaidīties no iespējamiem šķēršļiem, piemēram, ja nesaņem atbildes uz e-pastiem. Tāpēc viņa rekomendē atbraukt un iepazīties ar potenciālajiem partneriem klātienē. “Jā, Izraēla ir īpatnēja, tāpēc es silti iesaku vispirms atbraukt un iepazīt to. Arī, lai izveidotu biznesa sakarus, iesaku vispirms satikties klātienē. Tiešsaistes tikšanās, e-pasti un zvani var palīdzēt tikai pašā sākumā. Pēc tam ļoti svarīga ir tiešā saskarsme,” saka I. Rubinčika. `+
`<dd></dd><h3 class="post-subtitle">Strīdi ir dabiska uzņēmējdarbības sarunu daļa</h3>`+
`<dd></dd>“Uzņēmējdarbības kultūra Izraēlā man ļoti patīk un darbojos tur ar prieku,” saka J. Ošlejs. Uzņēmējdarbības kultūru atšķirības viņš pamanīja jau pirmajā reizē, kad bija aizbraucis uz Izraēlu un notika saruna par līgumu. Tiekoties ar partneriem celtnieku vagoniņā, lai vienotos par pēdējām detaļām, saruna izvērtās ļoti asa. Uzņēmējs bija pārliecināts, ka nekāda darījuma nebūs, bet saruna noslēdzās pozitīvi.`+
`<dd></dd>“Strīds, pat ļoti ass, ir dabiska biznesa sarunu daļa. Tas, ka kādam ir ļoti spēcīga argumentācija nenozīmē, ka viņam nepatīk tas, ko mēs darām. Tas vienkārši ir veids, kā viņi sarunājas,” stāsta J. Ošlejs.`+
`<dd></dd>Arī I. Rubinčika iesaka nekautrēties uzstāt uz savu viedokli: “Izraēlā mierīgi var kaulēties un iestāties par savu uzņēmējdarbības interešu aizstāvību. Ja otra puse to nedarīs, izraēļiem liksies, ka kaut kas nav kārtībā. Viņi rēķinās, ka uzņēmums aizsargās un aizstāvēs savas intereses, tāpēc pat gaida kaulēšanos.”`+
`<dd></dd>Gan J. Ošlejs, gan D. Pfafrods izceļ, ka Izraēlā bez personīgiem sakariem un savstarpējas uzticības ir grūti izveidot lietišķas attiecības. “Kad attiecības jau ir izveidojušās un bizness veiksmīgi attīstās, personīgie kontakti varbūt vairs nav tik svarīgi. Taču mēs viens pie otra joprojām diezgan bieži braucam. Arī pandēmijas laikā notika regulāras tikšanās,” teic D. Pfafrods.`+
`<dd></dd>I. Rubinčika piebilst, ka Izraēlā nedēļa sākas ar svētdienu un tā ir darba diena. Savukārt darba nedēļa beidzas ceturtdienā. “Piektdienās partnerus nevajadzētu traucēt,” viņa teic. Ebreju kultūrā un reliģijā ir aizliegta cūkgaļa, tāpēc šo produktu Izraēlā nevar ievest, un tos nevajadzētu piedāvāt partneriem, kad tie viesojas Latvijā.`+
`<dd></dd><h3 class="post-subtitle">Radoši, tieši un ātri</h3>`+
`<dd></dd>I. Rubinčika piebilst, ka Izraēlā cilvēki ir dabiski un atvērti, viņi neievēro mākslīgus etiķetes noteikumus. “Izraēļiem ir raksturīga ātra reakcija — bieži vien atbilde tiks sniegta tālruņa lietotnē WhatsApp uzreiz stundu pēc sarunas, nevis e-pastā. To pašu viņi gaida no uzņēmējdarbības partneriem — ir jāspēj ātri reaģēt uz sadarbības piedāvājumiem,” viņa saka.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel4+`"></div>`+
`<dd></dd>Izraēlā cilvēki ir radoši un spontāni, viņiem mēdz šķist, ka latvieši domā pārāk ierobežoti. Tāpēc J. Ošlejs iesaka meklēt līdzsvaru starp izraēļu radošumu un latviešu sistemātiskumu. Ja viens vai otrs ir pārsvarā, tiek izjaukts līdzsvars. “Kad mums bija kādas problēmas šajā tirgū, tās vairāk bija saistītas ar to, ka nebija līdzsvara starp latviešu sistemātiskumu un izraēļu radošumu. Tādos brīžos mēs veltījām vairāk pūļu, lai pavadītu laiku viens ar otru un nonāktu pie līdzīgas izpratnes par lietām,” viņš dalās pieredzē.`+
`<dd></dd>Jārēķinās arī ar viņu tiešumu un konkrētību. I. Rubinčika zina teikt, ka Izraēlas partneri vēlas zināt precīzas detaļas par piedāvāto produktu un cenas piedāvājumu, nevis uzņēmuma prezentāciju par misiju un vīziju.`+
`<dd></dd>D. Pfafrods piebilst, ka ir jābūt pacietīgiem — Izraēlas tirgū nekas nenotiek uzreiz. Pamazām ir jārada par sevi iespaids kā par stabilu partneru. “Nebūs tā, ka šodien aizbraucu un uzreiz noslēdzu līgumu uz vairākiem gadiem. Viss notiek ļoti lēnām, soli pa solītim. Taču, kad jau ir panākta vienošanās, tad tā ir sadarbība uz ļoti ilgu laiku,” viņš teic.`+
`<dd></dd><h3 class="post-subtitle">Sadarbosies inovāciju un tehnoloģiju jomā</h3>`+
`<dd></dd>Kopš pagājušā gada Latvijas Investīciju un attīstības aģentūrai ir pārstāvniecība arī Izraēlā. I. Rubinčika konsultē Latvijas uzņēmējus par Izraēlas tirgu, kā arī palīdz ar sakaru veidošanu. Otrs viņas uzdevums ir pārņemt Izraēlas inovāciju pieredzi un piemērot to Latvijā, lai palīdzētu Latvijas uzņēmumiem strādāt efektīvāk. “Mans mērķis ir attīstīt inovācijas Latvijā, iedvesmojot mūs ar Izraēlas piemēru, kā arī piesaistīt investīcijas viedās specializācijas jomās,” saka I. Rubinčika.`+
`<dd></dd>2023. gada vasarā Latvijas Investīciju un attīstības aģentūra un Izraēlas Inovāciju pārvalde parakstīja sadarbības līgumu, lai veicinātu divpusējus projektus inovāciju un tehnoloģiju attīstības jomā. “Jau sākumā bija skaidrs, ka politiskās un vēsturiskās situācijas dēļ ar Izraēlu vispirms ir jānoslēdz sadarbības līgums, lai apliecinātu, ka Latvija ir Izraēlas draugu vidū. Šis sadarbības līgums “legalizē” mūsu attiecības ar Izraēlu, un tas ir ļoti svarīgs panākums. Tagad mēs varam attīstīt divpusējos projektus pētniecībā, inovācijās un zinātniski ietilpīgā ražošanā,” saka I. Rubinčika.`+
`<dd></dd><div class="quote-style">Izraēlā ir aktuāls atvērto inovāciju princips, kā rezultātā pētnieki un ražotāji ir gatavi dalīties ar saviem sasniegumiem. Protams, viņu intelektuālais īpašums ir aizsargāts un ir jāmaksā par licences tiesībām. I. Rubinčika Izraēlu salīdzina ar inovāciju lielveikalu, kur var nopirkt gatavu risinājumu saviem izaicinājumiem.</div>`+
`<dd></dd>“Aicinu Latvijas uzņēmējus padomāt, kas tiem traucē strādāt efektīvāk. Eiropas inovāciju un tehnoloģiju institūta Izraēlas zināšanu un inovāciju kopiena EIT Hub Israel ir īpaša atbalsta programma, kas līdzfinansē šādu tehnoloģiju meklēšanu Izraēlā. Izraēla mērķtiecīgi apgūst Eiropas tirgu, investē uzņēmumos, dibina pētniecības centrus un aktīvi piedalās zinātniskajos konsorcijos, kurus finansē Eiropas Savienības fondi, tāpēc katrs jauns partneris Eiropā Izraēlai ir zelta vērts,” viņa norāda.`+

	support_by_liaa,


	exp_review_10_name: "Pieredzes stāsti eksportā uz Lielbritāniju",
	exp_review_10_company:"Aerones, Krassky, Sēlijas Mežs",
	exp_review_10_designation: "Aerones, Krassky, Sēlijas Mežs",
	exp_review_10_excerpt: `Lai gan Lielbritānijas izstāšanās no Eiropas Savienības teju nevienam uzņēmumam par labu nav nākusi, tas joprojām ir vilinošs tirgus. Šobrīd tajā jau ilgstoši vislabāk klājas kokapstrādes uzņēmumiem, taču nākotnē eksperti saskata potenciālu arī informācijas tehnoloģiju un aizsardzības nozares uzņēmumiem.`,
	exp_review_10_title:"Liels un dārgs tirgus: Lielbritānija",
	exp_review_10_text:

`<dd></dd>Lai gan Lielbritānijas izstāšanās no Eiropas Savienības teju nevienam uzņēmumam par labu nav nākusi, tas joprojām ir vilinošs tirgus. Šobrīd tajā jau ilgstoši vislabāk klājas kokapstrādes uzņēmumiem, taču nākotnē eksperti saskata potenciālu arī informācijas tehnoloģiju un aizsardzības nozares uzņēmumiem.`+
`<dd></dd>"Lielbritānija Latvijai ir svarīgs tirgus, jo īpaši kokapstrādes uzņēmumiem. Mūsu uzņēmumi šo uzskata par savu mājas tirgu, un arī visi britu iepircēji zina par Latviju. Šīs nozares Latvijas uzņēmumu apgrozījums Lielbritānijā ir vairāki simti miljoni eiro gadā," saka Latvijas Investīciju un attīstības aģentūras pārstāvniecības Lielbritānijā vadītājs Ivars Žukovskis. `+
`<dd></dd>2023. gada trešajā ceturksnī Latvijas koksnes un tās izstrādājumu eksporta vērtība uz Lielbritāniju bija 425 miljoni eiro. "Domāju, ka līdz gada beigām koksnes produktu eksporta vērtība sasniegs pusmiljardu eiro," viņš spriež. `+
`<dd></dd><h3 class="post-subtitle">Liela valsts prasa lielus apjomus</h3>`+
`<dd></dd>Interjera salona Krassky īpašnieka un vadītāja Aigara Zelmeņa pieredze rāda, ka Lielbritānijas tirgū patērētājs var atļauties maksāt vairāk par īpašu pieredzi, labu servisu un unikālu produktu. Turpretī I. Žukovskis norāda, ka Lielbritānijā bieži vien pats būtiskākais ir konkurētspējīga cena, nevis unikāls piedāvājums. "Lielbritānija ir liela, un daudzi Latvijas uzņēmumi vienkārši nevar saražot tik daudz, cik šis tirgus prasa. Mēs esam relatīvi maza ekonomika, un mūsu lielie uzņēmumi nereti ir kā ārzemju mazie vai vidējie uzņēmumi. Līdz ar to jāsaprot, ka bieži vien var būt vienkārši neiespējami piegādāt konkrētus produktus, piemēram, Tesco ķēdei," viņš teic. `+
`<dd></dd>Lai gan pārtikas ražotājiem Lielbritānija var šķist vilinošs tirgus, arī šajā jomā jāņem vērā, ka lielās veikalu ķēdes vēlētos pasūtīt tiešām lielus apjomus, kas ne visiem uzņēmumiem ir pa spēkam. "Sainsbury's ķēdē esmu redzējis Laimas produkciju – tāda līmeņa uzņēmumiem tas ir pa spēkam, bet mazākiem dalībniekiem tas būtu ļoti liels izaicinājums," saka I. Žukovskis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk1+`"></div>`+
`<dd></dd>Viņš uzsver, ka katrs Lielbritānijas reģions ir atsevišķs tirgus – Londona, Velsa, Ziemeļīrija utt. "Tie visi ir ģeogrāfiski un kultūras ziņā reģioni. Tāpat Lielbritānija nav tikai briti vai angļi. Piemēram, Londonā ir daudz austrumeiropiešu, tai skaitā latviešu, baltiešu, poļu utt., un ir speciāli veikali, kas pārdod šai mērķauditorijai paredzētu produkciju. Piemēram, jau izsenis šādos veikalos ir Canelle Bakery produkcija, šogad tai ir pievienojušās arī Milzu! pārslas," stāsta I. Žukovskis. `+
`<dd></dd><h3 class="post-subtitle">Komerciāli nobriedis tirgus</h3>`+
`<dd></dd>A. Zelmenis atgādina, ka Lielbritānija ir dārga valsts visās nozīmēs. "Kad es atceros, cik augstu cenu mēs maksājām par salona telpu nomu, man slikti paliek. Pārdošanas apjoma ziņā bija labi rezultāti, bet visu peļņu noēd augstais atalgojums, nomas maksa un nekustamā īpašuma nodokļi," viņš teic. `+
`<dd></dd><div class="quote-style">Tāpat Lielbritānija komerciāli ir ļoti nobriedis tirgus. Tas ietekmē to, kādas preces un pakalpojumi ir pieejami tirgū. </div>`+
`<dd></dd>"Tur visam ir sava specializācija. Piemēram, Latvijā paklāju ieklāšanu objektā mēs veicam ar savu montāžas komandu, bet Lielbritānijā ir viena komanda, kura drīkst nest tikai paklāju, un ir otra komanda, kura drīkst pārvietot mēbeles – katrai no tām ir savs apdrošināšanas segums. Proti, tam uzņēmumam, kura pārstāvji nes un izklāj paklāju, apdrošināšanas segumā nav paredzēts segt izmaksas par saskrāpēto grīdu, kas var rasties pie mēbeļu pārvietošanas," skaidro A. Zelmenis. `+
`<dd></dd><h3 class="post-subtitle">Apzināti izvēlas mazākus sadarbības partnerus</h3>`+
`<dd></dd>Lielbritānija veido aptuveni 10 % no dārza mājiņu ražotāja “Sēlijas mežs” apgrozījuma. Iepriekš uzņēmums strādāja ar Franciju, sadarbojoties ar turienes lielajām veikalu ķēdēm. "Lielveikalu ķēdes vienmēr noteica, kādu produktu un par kādu cenu tās pirks. Tāpēc vienā brīdī sapratām, ka mēs gribam strādāt nedaudz citādāk – ar mazākiem partneriem, kuru pasūtījumi nav tik lieli, ka pieprasījums ir apjomīgāks nekā mūsu spējas to izpildīt," saka “Sēlijas mežs” valdes loceklis Ivars Dričs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk2+`"></div>`+
`<dd></dd>Lielbritānijā uzņēmums sadarbojas ar vairākiem mazākiem klientiem. Šāda pieeja palīdz sarunu ceļā panākt labvēlīgākus noteikumus, kaut vai par valūtu kursa svārstībām. "Arī Lielbritānijā mums visi līgumi ir noslēgti eiro, līdz ar to valūtas kursa svārstības ir klienta, nevis mūsu risks. Ar lieliem partneriem nebūtu iespējams šādi strādāt, jo viņi neielaižas liekās diskusijās," stāsta I. Dričs. `+
`<dd></dd>Šādā modelī “Sēlijas mežs” strādā jau vairāk nekā piecus gadus un pagaidām neredz vajadzību atgriezties pie sadarbības ar lielajiem vairumtirgotājiem vai veikalu ķēdēm. `+
`<dd></dd><div class="quote-style">"Darbā ar lielām veikalu ķēdēm ir viena ļoti būtiska nianse, kas sadarbību padara diezgan saspringtu. Proti, viņi izsludina iepirkumu. Ja uzņēmums uzvar, tas arī veiks preču piegādi iepirkuma perioda laikā. Taču nekad nav zināms, vai uzvarēsi nākamajā iepirkumā. </div>`+
`<dd></dd>Protams, svārstības ir arī sadarbībā ar mazajiem tirgotājiem, bet tās ir prognozējamākas. Tāpat, ražojot lielajiem klientiem, mēs konkrētas preces varam ražot tikai viņiem," skaidro I. Dričs. `+
`<dd></dd><h3 class="post-subtitle">Klienti ir prasīgi</h3>`+
`<dd></dd>I. Dričs stāsta, ka klienti Lielbritānijā ir ļoti prasīgi: "Ja viņi ir pasūtījuši produktus, kas nav no "lētā gala", viņi grib, lai viss būtu perfekti. Tāpēc ir svarīgi būtiskās produktu sastāvdaļās netaupīt uz kvalitāti, jo pēc tam būs mazāk problēmu pēcpārdošanas apkalpošanā. Ir lētāk ražot kvalitatīvu produktu, nevis pēc tam kaut ko labot, sūtīt un mainīt detaļas. Turklāt jāņem vērā, ka sūtīt preces uz Lielbritāniju ir ļoti dārgi." `+
`<dd></dd> “Sēlijas mežs” sadarbojas ar diasporas pārstāvi, kas ir kļuvis par uzņēmuma partneri. "Strādājot ar diasporas pārstāvi, varam iegūt detalizētāku informāciju par pārdošanas procesu, gan viņa pieredzi šajā tirgū, jo lielveikalu ķēdes un lielie iepircēji ļoti maz dalās ar šādu informāciju. Tas ir viņu trumpis," saka I. Dričs. `+
`<dd></dd><h3 class="post-subtitle">Jābūt īstajā vietā</h3>`+
`<dd></dd>Kad Krassky jau bija salons Rīgā un Maskavā, daudzi teica, ka vajadzētu atvērt salonu arī Londonā. "Šo impulsu rezultātā mēs sākām pētīt tirgu un sapratām, ka tur ir brīva niša. Vislielākais potenciāls bija tieši dizaina paklājiem," stāsta A. Zelmenis. `+
`<dd></dd>Atvērt salonu Londonā nebija viegli. Projekta realizācija ievilkās par gadu – sākotnēji bija plānots atvērt salonu jau 2012. gadā, bet tas notika gadu vēlāk – 2013. gadā. Uzņēmumam bija izaicinoši atrast piemērotas telpas salonam, jo Londonā liela nozīme ir atrašanās vietai. Krassky mērķauditorija ir cilvēki ar augstiem ienākumiem, kā arī interjera dizaineri. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk3+`"></div>`+
`<dd></dd>"Londonai ir raksturīgi, ka ar nišas produktu ir jātrāpa īstajā vietā. Mūsu auditorijas gadījumā mēs "drīkstējām" skatīties telpas tikai Mayfair, Belgravia un Chelsea rajonos. Taču šajās apkaimēs ir tikai burtiski trīs vai četras ielas, kur ir vērts atrasties – ja neesi īstajā vietā, tevi vienkārši nepamana," skaidro A. Zelmenis. `+
`<dd></dd>Vēl viens izaicinājums saistās ar to, ka nekustamā īpašumu īpašniekiem pieder veseli kvartāli, un viņi ļoti rūpīgi skatās, kā nomnieks iederas konkrētā kvartāla koncepcijā. Piemēram, Krassky zaudēja konkursā par labām telpām, jo saimnieka mērķis bija attīstīt luksusa modes kvartālu. Pēc kāda laika tur jau bija Chanel veikals. `+
`<dd></dd><h3 class="post-subtitle">Vispirms adrese, tad uzņēmuma reģistrācija</h3>`+
`<dd></dd>Uzņēmuma atvēršanai Lielbritānijā Krassky nolīga juristu. Sarežģītākais visā procesā bija bankas konta atvēršana, jo divas bankas bez paskaidrojumiem atteica. Izrādījās, ka tolaik Latvija bija "pelēkajā" sarakstā. Taču ar Lielbritānijas grāmatveža atbalstu, kas iepazīstināja ar savu privātbaņķieri, Krassky sekmīgi ieguva bankas kontu. `+
`<dd></dd><div class="quote-style">"Lai varētu sākt kaut ko darīt Lielbritānijā, uzņēmuma pārstāvim ir vajadzīga vietējā adrese un apdrošināšanas numurs. Pirms mēs varējām nodibināt uzņēmumu, mums bija jādabū adrese, pat ja mēs tur nedzīvojam.</div>`+
`<dd></dd>Iespējams, pa šiem gadiem kaut kas ir mainījies," pieļauj A. Zelmenis.`+
`<dd></dd><h3 class="post-subtitle">Jāpārzina vietējās manieres</h3>`+
`<dd></dd>"Attiecību veidošanai ir ļoti liela nozīme. Ikdienā viņi ir ļoti jauki, smaidīgi, pieklājīgi. Taču, lai laistu sev tuvāk, ir milzīga nozīme tam, cik bieži esat tikušies, vai ir kopīgās intereses, vērtības un mērķi. Kad viņi ir sapratuši, ka tu esi "savējais", viņi tevi iesaka tālāk un atveras daudzas durvis," saka A. Zelmenis. `+
`<dd></dd>Krassky mērķauditorija ir turīgi cilvēki. Pilnvērtīgai attiecību veidošanai ar šādiem cilvēkiem ir jāpārzina vietējās manieres. "Piemēram, uzaicinājums uz kafiju ir ikdienišķs, bet mirklī, kad tevi uzaicina uz tēju pie kāda mājās, tas ir jāvērtē augstu. Arī uzaicinājums vakariņās ir kaut kas tāds, kam uzņemošā puse parasti ļoti gatavojas, īpaši, ja tas notiek pie viņiem mājās," teic A. Zelmenis. Šo atšķirību izpratne ir īpaši svarīgi, jo viņa pieredze liecina, ka Lielbritānijā viss balstās uz savstarpējām rekomendācijām. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk4+`"></div>`+
`<dd></dd>Arī robotizētas vēja turbīnu tehniskās apkopes un inspekcijas uzņēmuma “Aerones” vecākais biznesa attīstības vadītājs Kaspars Litavnieks pirms biznesa uzsākšanas Lielbritānijā iesaka visu izpētīt un izpildīt savu “mājasdarbu”, nevis akli sūtīt uz nepazīstamu cilvēku e-pastiem informāciju par savu preci vai pakalpojumu. "Vispirms ir jāsaprot vajadzība pēc konkrētā produkta vai pakalpojuma šajā tirgū, un tad var sākt pētīt, kuri ir īstie cilvēki, ar ko vajag iepazīties un sākt sazināties," viņš iesaka. `+
`<dd></dd>Tāpēc “Aerones” mērķtiecīgi dodas uz dažādām nozares izstādēm un konferencēm. "Katram tirgum, katrai valstij ir sava specifika, tāpēc mēs mērķtiecīgi uzrunājam konkrētā tirgus dalībniekus," saka K. Litavnieks. `+
`<dd></dd><h3 class="post-subtitle">Attiecības prasa ieguldījumu</h3>`+
`<dd></dd>A. Zelmenis uzsver, ka Lielbritānijā bieži vien bez personīga ieteikuma pēc Google Search metodes nekur tālu nevar tikt. Arī I. Žukovskis apgalvo, ka vienkārši klauvēties pie kāda uzņēmuma durvīm ir liela avantūra. Tā vietā ir rūpīgi un pacietīgi jākaldina labas attiecības un jāveido kontaktu tīkls: `+
`<dd></dd><div class="quote-style">"Bieži vien uzņēmējiem šķiet, ka tad, ja viņi kaut ko ir izdarījuši Anglijas dienvidos, to pašu varēs atkārtot citā reģionā. Taču tas ir nepareizs pieņēmums. Katrā reģionā ir citi izplatītāji, kas cits citu nepazīst. </div>`+
`<dd></dd>Taču cilvēki pazīst savus bijušos kolēģus vai regbija komandas biedrus – izaicinošākais ir saprast, kā iekļūt šajā "burbulī"," viņš saka. `+
`<dd></dd>Tāpēc arī K. Litavnieks uzsver, ka ir jāpievērš uzmanība attiecību veidošanai: "Lai veidotos ilglaicīga sadarbība, ir jāveido personīgas un cilvēcīgas attiecības un tās jāauklē. Tāpat ir svarīgi dzert alu ar klientiem, britu kultūrā tas ir nozīmīgi." `+
`<dd></dd><h3 class="post-subtitle">Par Brexit neviens nepriecājas</h3>`+
`<dd></dd>I. Žukovskis salīdzina, ka pēc Brexit ir notikusi atgriešanās pagātnē: atkal ir muita, no Eiropas Savienības atšķirīga sertifikācija. "Tās ir galvassāpes visiem. Es vēl neesmu saticis nevienu britu uzņēmēju, kas teiktu, ka izstāšanās no Eiropas Savienības ir nesusi kādu labumu. Taču par to nevajag baidīties – Latvijas eksportētāji ilgstoši ir darbojušies tirgos, kas ir vēl eksotiskāki nekā šobrīd Lielbritānija. Pareizie partneri palīdzēs ar to visu tikt galā," viņš iedrošina. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk5+`"></div>`+
`<dd></dd>Piemēram, “Aerones” sadarbību ar klientiem Lielbritānijā sāka jau pēc Lielbritānijas izstāšanās no Eiropas Savienības. "Ir jūtama atšķirība, kā mēs strādājam Lielbritānijā un pārējā Eiropā. Visa dokumentācija un birokrātija Lielbritānijā ir daudz sarežģītāka," saka K. Litavnieks. `+
`<dd></dd>I. Dričs piebilst, ka pēc izstāšanās no Eiropas Savienības transports uz Lielbritāniju ir kļuvis nedaudz dārgāks, jo ir lielāka birokrātija uz robežas. Tāpat ir citādi jāmarķē paletes, jo Lielbritānija tagad skaitās trešā valsts. "Mēs to visu darām un strādājam tālāk," viņš saka. `+
`<dd></dd><h3 class="post-subtitle">Brexit un Covid-19 pieliek punktu</h3>`+
`<dd></dd>A. Zelmenis atzīst, ka Lielbritānijas izstāšanās no Eiropas Savienības spēcīgi ietekmēja Krassky darbību šajā tirgū. Pēc Brexit Londona sāka zaudēt savu pievilcību ārvalstu kapitāla acīs. Arvien vairāk kompāniju sāka pārcelt savus centrus, piemēram, uz Nīderlandi, Vāciju vai Franciju, kas ir turpat pāri Lamanšam. "Prom sāka plūst gan kapitāls, gan cilvēki, jo palikt varēja tie, kuri tur bija nodzīvojuši piecus gadus un varēja saņemt patstāvīgās uzturēšanās atļauju," saka A. Zelmenis. `+
`<dd></dd>Un tad nāca Covid-19, kas situāciju padarīja vēl dramatiskāku. "Ja mums būtu bijuši spēcīgāki pārdošanas rezultāti vai vairāk entuziasma pilna pārdošanas komanda, iespējams, mēs turpinātu biznesu Londonā. Taču ar Brexit un Covid-19 saistītajām grūtībām arī man pašam pazuda motivācija un mēs nolēmām slēgt salonu Londonā," atklāj A. Zelmenis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk6+`"></div>`+
`<dd></dd>Papildu izaicinājums bija tas, kā cilvēki sevi reklamē. Tas izpaužas gan darba intervijās, gan citās dzīves situācijās. Tāpēc viņš iesaka skatīties tikai un vienīgi uz rezultātiem un vērtēt kritiski, ja, piemēram, partneris vai darbinieks sola, ka nākamajā mēnesī darījums noslēgsies, bet tas nav izdevies arī pēc trim mēnešiem un katru reizi ir kāds jauns attaisnojums. "Cilvēki Lielbritānijā kolosāli prot pasniegt procesā esošas lietas kā jau paveiktus darbus," novērojis A. Zelmenis.`+
`<dd></dd>Līdzīga pieredze ir K. Litavniekam: "Briti ir jātur pie vārda, jo bieži ir dažādi solījumi, un, ja kādu laiku nepievērsīsi komunikācijai uzmanību, atkal viss būs jāsāk no sākuma."`+
`<dd></dd><h3 class="post-subtitle">Potenciāls ir liels </h3>`+
`<dd></dd>"Lielbritānija mums ir ļoti ciešs sabiedrotais – gan politiski, gan drošības ziņā. Ņemot vērā to, ka mēs varam piegādāt produktus, kas ir 100 % ražoti Eiropas Savienībā un NATO teritorijā, Latvija un Lielbritānija var diezgan organiski sadarboties. Drošības un aizsardzības nozare noteikti ir tā joma, kur mēs varam sadarboties vēl vairāk. Arī IT risinājumiem ir liels potenciāls. Nav nekādu ierobežojumu – viss ir izdarāms. Vienīgi jāpiestrādā pie valsts zīmola," saka I. Žukovskis.`+

	support_by_liaa,


	exp_review_11_name: "Pieredzes stāsti eksportā uz Nīderlandi",
	exp_review_11_company:"STENDERS, Lat Eko Food, Baltic Candles",
	exp_review_11_designation: "STENDERS, Lat Eko Food, Baltic Candles",
	exp_review_11_excerpt: `Nīderlandieši jau izsenis bijuši izcili tirgotāji, tāpēc eksports uz šo valsti nav pats vieglākais. Taču tieša komunikācija, horizontāla hierarhija un ģeogrāfiskais tuvums vilina ne vienu vien Latvijas uzņēmēju.`,
	exp_review_11_title:"Nebūs viegli pārdot tirgotājiem ar gadsimtiem ilgu pieredzi",
	exp_review_11_text:

`<dd></dd>Nīderlandieši jau izsenis bijuši izcili tirgotāji, tāpēc eksports uz šo valsti nav pats vieglākais. Taču tieša komunikācija, horizontāla hierarhija un ģeogrāfiskais tuvums vilina ne vienu vien Latvijas uzņēmēju. `+
`<dd></dd>"Nīderlandes un Latvijas ekonomiskās attiecības ir daudz senākas nekā daudziem šķiet. Eksports uz Nīderlandi Latvijai vienmēr ir bijis būtisks. Man prieks, ka arī tagad mēs esam labās attiecībās," saka Latvijas Investīciju un attīstības aģentūras (LIAA) ekonomiskās pārstāvniecības Nīderlandē vadītājs Jēkabs Groskaufmanis. Tam ir vairāki iemesli: augošs tirgus, laba pirktspēja, teju 18 miljoni iedzīvotāju teritorijā, kas ir par trešdaļu mazāka par Latviju. Turklāt pārstāvniecība Nīderlandē darbojas jau teju 20 gadu, jau ilgstoši atbalstot Latvijas eksportētājus. "Nīderlande ir un pārskatāmā nākotnē noteikti būs ļoti būtisks Latvijas eksporta tirgus," viņš norāda. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands1+`"></div>`+
`<dd></dd>Latvija vairāk importē nekā eksportē uz Nīderlandi. Tajā pašā laikā Latvijā bieži notiek importēto produktu pārstrāde, lai tos pēc tam eksportētu tālāk. "No vienas puses, Nīderlande ir neatkarīgs tirgus, bet, no otras puses, tas ir loģistikas centrs ar vēsturiski ļoti spēcīgajām ostām. Jau gadsimtiem tā ir viena no valsts ekonomikas atslēgām," saka J. Groskaufmanis. `+
`<dd></dd><h3 class="post-subtitle">Vislabāk Nīderlandē veicas kokmateriālu eksportētājiem</h3>`+
`<dd></dd>Latvijas eksports uz Nīderlandi ir diezgan diversificēts. Taču šajā tirgū vislabāk veicas Latvijas eksportspējīgākajiem kokmateriālu ražošanas uzņēmumiem. `+
`<dd></dd><div class="quote-style">"Nīderlandei un Latvijai ir gadsimtiem senas saiknes. Nīderlandē būvēja kuģus un mastos lika Latvijas koku – tie bija t.s. Rīgas masti. Man ir prieks redzēt, kā pa šiem gadsimtiem šī tēma ir saglabājusies, un mēs esam spējuši kāpināt eksporta sarežģītību. Mēs vairs neeksportējam tikai kokmateriālus, kas aizvien, protams, ir liels eksporta apjoms, bet arī apstrādātu koku, tai skaitā mēbeles, nesošās konstrukcijas un pat ēkas," stāsta J. Groskaufmanis.</div>`+
`<dd></dd>Latvija eksportē gan agrīnākas vērtību ķēdes produktus, piemēram, lielu apjomu graudu un sēklu, gan pārtikas produktus ar augstu pievienoto vērtību. "Mēs uz Nīderlandi eksportējam pat sierus, kas liecina, ka Latvija ir spējīga pārtikas ražotāja," viņš teic. `+
`<dd></dd>Pēdējos gados eksportētāju vidū parādās aizvien vairāk sarežģītas elektronikas, sakaru un dažādu optisko iekārtu ražotāji. Interese par Latviju aug arī Nīderlandes investoriem un riska kapitāla fondiem. "Agrāk viņi varbūt skatījās uz Rietumeiropu un Ziemeļeiropu, bet tagad pievērš uzmanību arī Baltijai. Arī TechChill turpmāk varēs satikt Nīderlandes riska kapitāla fondus, kurus interesē Latvijas jaunuzņēmumi un zinātniski ietilpīgi tehnoloģiju uzņēmumi," zina teikt J. Groskaufmanis. `+
`<dd></dd><h3 class="post-subtitle">Eksportu kaldina izstādēs</h3>`+
`<dd></dd>Pārtikas ražotāja SIA Lat Eko Food eksports uz Nīderlandi ir cītīgs izstāžu apmeklēšanas rezultāts. "Piedalāmies gan LIAA, gan citu organizāciju veidotās un atbalstītās izstādēs. Mums tas ir viens no galvenajiem veidiem, kā atrast eksporta sadarbības partnerus," saka Lat Eko Food valdes priekšsēdētāja, īpašniece Egija Martinsone. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands2+`"></div>`+
`<dd></dd>Pirmo reizi ar tagadējo partneri Nīderlandē Lat Eko Food iepazinās izstādē 2018. gadā. Aptuveni divus gadus ilga sarunas, testēšana, dokumentu apmaiņa, vizītes un kvalitātes auditi. "Tas bija diezgan garš process, bet tagad jau trīs gadus stabili strādājam ar Nīderlandi," teic E. Martinsone. `+
`<dd></dd>Lat Eko Food Nīderlandē ir divi klienti – izplatītājs un lielveikalu ķēde, kam uzņēmums ražo privātā zīmola produkciju. `+
`<dd></dd><div class="quote-style">"Privātā zīmola produktiem ir milzīgs spēks. Tas ir labi atpazīstams vietējais zīmols. Mūsu zīmols viņiem ir svešs un nezināms. Iepazīstināt cilvēkus ar jaunu zīmolu ir ilgu gadu darbs. Visātrākie un lielākie eksporta apjomi, vismaz pārtikas nozarē, ir iespējami tikai ar privāto zīmolu," spriež E. Martinsone.</div>`+
`<dd></dd>Lat Eko Food eksportē jau 10 gadus, tāpēc viņa zina, ka abiem sadarbības modeļiem ir savi plusi. "Jaunradītam zīmolam nav viegli iekarot pasauli. Ir jāiegulda ārkārtīgi milzīgas finanses mārketingā, lai to padarītu atpazīstamu pasaulē. Mēs jau redzam, cik grūti ir Latvijā popularizēt savus zīmolus, bet eksporta valstīs tas ir vēl sarežģītāk. Man šķiet utopiski domāt, ka mēs ar savu vārdu ātri iekarosim visu pasauli. Jā, mēs varam būt visā pasaulē pa drusciņai, bet tas prasa daudzu gadu darbu. Tāpēc mēs esam izvēlējušies dalīt risku divās daļās un strādāt gan ar privātajām preču zīmēm, gan paši ar savu zīmolu," stāsta E. Martinsone.`+
`<dd></dd>Un tam ir rezultāts: Nīderlande veido aptuveni 15 % no Lat Eko Food apgrozījuma. Paredzams, ka 2023. gada noslēgumā izdosies pietuvoties 20 %. Absolūtos skaitļos tas ir aptuveni pusotrs līdz divi miljoni eiro. `+
`<dd></dd><h3 class="post-subtitle">Strādā tieši ar lielveikaliem</h3>`+
`<dd></dd>Sveču ražotājs SIA Baltic Candles ar lielveikalu ķēdēm cenšas strādāt bez starpniekiem, jo šobrīd lielveikali pāriet uz šādu modeli, lai būtu droši, ka iegūst produkciju par labāko cenu. Tāpat uzņēmums sadarbojas ar vairumtirgotājiem, kas izplata produkciju mazajiem veikaliņiem. `+
`<dd></dd>"Mēs ražojam produkciju lielos apjomos un diemžēl nespējam paši piegādāt sveces katram mazajam veikaliņam. Līdz ar to mums ir arī partneri, kas izplata mūsu produkciju. Tāpat mums ir aģenti, bet mēs pievēršam uzmanību, lai viņu darbs nepārklātos ar mūsu esošo klientu tīklu," saka Baltic Candles pārdošanas vadītājs Raivis Stols. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands3+`"></div>`+
`<dd></dd>Baltic Candles kā savu priekšrocību saskata to, ka spēj piedāvāt gan garās, gan tējas sveces, bez kā nav iedomājams neviens lielveikals, gan arī dažādas tematiskās sveces par godu katriem svētkiem. "Mēs spējam nodrošināt pilnu preču sortimentu, lai mūsu klienti varētu sekmīgi tirgot sveces," uzsver R. Stols.`+
`<dd></dd>Nīderlande veido 5 līdz 8 % no Baltic Candles apgrozījuma. Lai diversificētu riskus, uzņēmums piedomā pie tā, lai neviena no eksporta valstīm neveidotu vairāk par 15 līdz 20 % no apgrozījuma. `+
`<dd></dd>R. Stols ir novērojis, ka Nīderlandē, tāpat kā visā Rietumeiropā, šobrīd valda recesija. Viņš cer, ka tas ir īstermiņa process. "Taču sveces ir labs produkts arī dažādās krīzēs, jo tad cilvēki nepērk jaunas automašīnas, mēbeles un nedodas dārgos ceļojumos, bet turpina tērēt naudu par svecēm, kas nav pats dārgākais produkts," saka R. Stols. `+
`<dd></dd><h3 class="post-subtitle">Atslēga: pareizais partneris</h3>`+
`<dd></dd>Vannas un ķermeņa kopšanas kosmētikas ražotāja SIA “STENDERS” izpilddirektore Kristīne Grapmane vērtē, ka Nīderlandē ir efektīva nodokļu sistēma, atvērta ekonomika un augsts izglītības līmenis, kas veicina uzņēmējdarbības attīstību. Ērtu preču transportu nodrošina labi attīstīta loģistikas infrastruktūra. `+
`<dd></dd><div class="quote-style">"Viens no veiksmes kritērijiem ir atrast pareizo partneri," viņa uzsver. Partnerim ir jāpazīst Nīderlandes tirgus, ir vajadzīga pieredze mazumtirdzniecībā, vēlme attīstīties, kā arī zīmola un uzņēmuma vērtību izpratne. </div>`+
`<dd></dd> “STENDERS” partneris Nīderlandē zīmolu uzrunāja pats. Viņš no sava dēla, kurš studē Latvijā, saņēma dāvanā uzņēmuma produktus. Vēlāk viņš apmeklēja uzņēmuma veikalus un izrādīja interesi sadarboties. `+
`<dd></dd><h3 class="post-subtitle">Piesātināts tirgus</h3>`+
`<dd></dd>"Šis nav viegls tirgus, kurā strādāt. Taču tas ir interesants, visnotaļ piesātināts ar dažāda veida veikaliem un ķēdēm. Nīderlandieši ir diezgan labi tirgotāji, vieni no labākajiem tirgotājiem pasaulē uzreiz aiz amerikāņiem. Līdz ar to viņi paši aktīvi tirgojas savā valstī ar produkciju no Tālajiem Austrumiem un citām valstīm," teic R. Stols. `+
`<dd></dd>E. Martinsone pirktspēju raksturo kā pietiekami labu: "Valsts ir augstā labklājības līmenī, un viņiem interesē labi, kvalitatīvi ražotāji. Vienlaikus Nīderlande ir liels un ļoti piesātināts tirgus. Visa kā ir gana, viņi īpaši nealkst pēc nekā jauna. Tāpēc ieinteresēšana ir diezgan sarežģīta un ilga. Taču līdzīgi ir gandrīz jebkurā eksporta tirgū – nekur mūs negaida atplestām rokām. Iekļūt kādā tirgū prasa pacietīgu darbu." `+
`<dd></dd><h3 class="post-subtitle">Konkurence ir liela</h3>`+
`<dd></dd>"Nīderlandieši no jebkura piegādātāja sagaida funkcionalitāti par labu cenu. Lai gan valstī ir augsta pirktspēja, viņiem statuss nav tik svarīgs: vidēja līmeņa vadītāji uz biroju ripos ar savu simt eiro vērto riteni. Šis ir ļoti iekārojams tirgus ļoti daudzu valstu ražotājiem, tāpēc arī konkurence ir milzīga," saka J. Groskaufmanis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands4+`"></div>`+
`<dd></dd>Arī K. Grapmane teic, ka Nīderlandē kosmētikas tirgū ir milzīga konkurence, bet pircēji ir atvērti arī jauniem zīmoliem un produktiem. "Attīstība, vismaz mūsu gadījumā, ir lēna. Protams, kopējā ekonomiskā situācija šobrīd attīstībai nepalīdz," viņa secina. `+
`<dd></dd>E. Martinsone skaidro: "Katram eksporta produktam ir jāatrod kaut kas, ar ko tas ir labāks par to, kas jau stāv lielveikala plauktā. Lai plauktā nonāktu viens produkts, kādam citam no tā ir jāiziet." `+
`<dd></dd><h3 class="post-subtitle">Pievērš uzmanību cenai</h3>`+
`<dd></dd>"Nīderlandieši kopumā ir ļoti taupīgi. Viņi vairākas reizes apdomās, pirms veiks pirkumu. Kārtīgi visu izlasīs un iedziļināsies, kur produkti ir ražoti un kas zināms par ražotāju. Nīderlandieši iepērkas pārdomāti un izsvērti," vērtē K. Grapmane. `+
`<dd></dd>Arī E. Martinsone norāda, ka Nīderlandē liela nozīme ir cenai. Vienlaikus jābūt arī augstai kvalitātei. "Nīderlandiešiem interesē cena. Šajā tirgū nav viegli, jo ir ļoti daudz dalībnieku," saka R. Stols. `+
`<dd></dd><h3 class="post-subtitle">Cieņā efektivitāte</h3>`+
`<dd></dd>J. Groskaufmanis izceļ, ka nīderlandiešiem cieņā ir efektivitāte un plānošana. "Jebkura tikšanās un komunikācija ir efektīva, tai ir jānotiek laikā, kavēšana nav pieņemama. Un, ja tikšanās laikā viss ātri tiek izrunāts, atlikusī stunda nav jāaizpilda ar tukšām sarunām. Viņi vienkārši ķeras pie nākamā darba," viņš stāsta. `+
`<dd></dd><div class="quote-style">Arī R. Stols ir ievērojis, ka nīderlandiešiem patīk, ka viss, ko apsola, notiek laikā un tiek izdarīts perfekti.</div>`+ 
`<dd></dd>"Sadarbības noteikumi ir diezgan sarežģīti, varētu pat teikt – cimperlīgi. Ja būs kāda problēma ar kvalitāti, viņi nebaidīsies uz to norādīt. Šajā tirgū patērētājiem ir tendence sūdzēties un pieprasīt no ražotāja vai tirgotāja kompensāciju. Nevienā citā valstī tik izteikti to neesam novērojuši. Tāpēc jārēķinās, ka tad, ja kaut kas nebūs perfekti, viņi norādīs uz katru sīkumu un pieprasīs savas tiesības," norāda E. Martinsone. `+
`<dd></dd><h3 class="post-subtitle">Novērtē tiešumu un atklātību</h3>`+
`<dd></dd>J. Groskaufmanis iesaka būt pēc iespējas godīgiem un pārredzamiem, kā arī nebūt pārsteigtiem par viņu tiešumu. Reizēm tas latviešiem var šķist rupji, taču tā tas nekad neesot domāts. `+
`<dd></dd>"Nīderlandē uzņēmēji novērtē tiešumu un atklātību. Biznesa kultūrā ir atklāts dialogu veidošanas process, kurā tiek uzklausītas dažādas idejas un ierosinājumi, vienlaikus augstu vērtē efektīvas darba metodes un racionālu pieeju. Salīdzinot ar citiem tirgiem, Nīderlandes biznesa kultūra ir neformāla un veicina inovācijas," novērojusi K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands5+`"></div>`+
`<dd></dd>J. Groskaufmanis piebilst, ka Nīderlandē hierarhija ir horizontāla. Lai gan arī eksistē "tu" un "jūs" formas, "jūs" no valodas sāk izzust. "Tas izpaužas arī biznesā – vadītāji uzņēmumos ir pieejami. Uzrunājot uzņēmumus un vēloties sazināties ar kādu konkrētu iepirkumu speciālistu vai iepircēju, nav jākautrējas viņu uzrunāt vai lūgt kolēģi pārsūtīt e-pastu konkrētajam cilvēkam. Un ar to jārēķinās arī no viņu puses," viņš teic. `+
`<dd></dd><h3 class="post-subtitle">Jānāk ar skaidru plānu</h3>`+
`<dd></dd>"Nīderlandieši ar tirdzniecību ir nodarbojušies gadsimtiem ilgi un viņiem ir piegādātāji no visas pasaules. Tāpēc šajā tirgū ir jānāk ar skaidru plānu. Attiecīgi ir svarīgi jau pirmajā saziņā būt konkrētam un pateikt, ko vēlies un ko piedāvā, lai otra puse saprastu, kāpēc būtu jāturpina komunikācija," skaidro J. Groskaufmanis. `+
`<dd></dd>Tāpēc eksperts iesaka izpētīt, kādus apjomus uzņēmums spēj saražot un cik ātri preci varēs piegādāt. "LIAA pārstāvniecība Nīderlandē ir gatava uzņēmumiem palīdzēt sagatavoties, ja ir interese par šo tirgu," viņš sola. `+
`<dd></dd>K. Grapmane iesaka detalizēti izpētīt vietējo tirgus situāciju, konkurentus un izprast klientu vajadzības. "Veiksmīgai sadarbībai jācenšas iedziļināties vietējā biznesa kultūrā, kurā augstu tiek vērtēta uzņēmējdarbības ētika un cieša sadarbība ar partneriem," viņa teic. `+
`<dd></dd>Savukārt E. Martinsone iesaka aizbraukt un kārtīgi izpētīt tirgu: izstaigāt lielveikalus, pavērot produktus, cenas, zīmolus: "Vislabāk tirgu var saprast, aizbraucot un iejūtoties turienes sabiedrībā un vidē. Internetā noteikti to tik labi nevar izjust kā uz vietas." `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands6+`"></div>`+
`<dd></dd>Pirms 15 gadiem, izvēloties šo tirgu, Baltic Candles to darīja mērķtiecīgi. "Sākumā mēs izanalizējam tirgu: kādi spēlētāji ir šajā tirgū, kas ir mūsu galvenie konkurenti? Kad izvēlējāmies klientus, mēs pie viņiem mērķtiecīgi gājām ar konkrētu produkciju un bijām pārliecināti, ka mēs viņus varētu ieinteresēt," saka R. Stols. `+
`<dd></dd>Izvēloties eksporta tirgu, Baltic Candles analizē patērēto sveču apjoma kilogramos uz vienu iedzīvotāju. "Skandināvu valstīs sveču dedzināšanas kultūra ir ļoti augstā līmenī, bet Nīderlandes tirgus ir nākamais sveču ražotājiem interesantais tirgus kā top5 pēc sveču dedzināšanas apjomiem," saka R. Stols. Tāpat Nīderlandē dzīvo vairāk nekā 17,5 miljoni cilvēku, kas ir aptuveni tikpat, cik visā Skandināvijā kopā. `+
`<dd></dd><h3 class="post-subtitle">Ar lielu potenciālu</h3>`+
`<dd></dd>STENDERS saskata lielu potenciālu biznesa attīstībai Nīderlandē, īpaši visā Beniluksa valstu reģionā – Nīderlandē, Beļģijā un Luksemburgā. "Nīderlandieši ir ieinteresēti augstas kvalitātes un inovatīvos produktos, kas raksturo labu potenciālu un iespējas mūsu attīstībai. Taču tas prasīs laiku. Zīmolam ir jāiegūst atpazīstamība," saka K. Grapmane. Nīderlandi uzņēmums redz kā daļu no Beniluksa valstu tirgus, kas ir pietiekami liels un interesants Eiropas reģions. `+
`<dd></dd>J. Groskaufmanis brīdina nesajaukt nīderlandiešus ar flāmiem vai beļģiem kopumā: "Viņu vidū ir veselīga konkurence, līdzīgi kā latviešiem ar lietuviešiem un igauņiem." `+
`<dd></dd>Viņš piebilst, ka daudziem nīderlandiešiem ir pārsteigums, ka Rīga ir vien divu stundu lidojuma attālumā, ka Latvijai ir sava aviokompānija un citas lietas, kas mums šķiet pašsaprotamas. "Man prieks par katru reizi, kad pēc investoru vizītes vai tirdzniecības misijas Latvijā mainās un tiek salauzti stereotipi, un viņi atzīst, ka Latvija ir Ziemeļeiropa nevis Austrumeiropa," saka J. Groskaufmanis.`+

	support_by_liaa,



	exp_review_12_name: "Pieredzes stāsti eksportā uz Japānu",
	exp_review_12_company:"Printful, Azeron, Zabbix",
	exp_review_12_designation: "Printful, Azeron, Zabbix",
	exp_review_12_excerpt: `Šobrīd Japāna ir 43. lielākais Latvijas ārējās tirdzniecības partneris. Pagrieziena punkts Latvijas un Japānas ekonomiskajās attiecībās varētu būt 2025. gadā gaidāmā izstāde Expo.`,
	exp_review_12_title:"Ar partneriem Japānā būs jābrauc paspiest roku klātienē",
	exp_review_12_text:

	`<dd></dd>Šobrīd Japāna ir 43. lielākais Latvijas ārējās tirdzniecības partneris. Pagrieziena punkts Latvijas un Japānas ekonomiskajās attiecībās varētu būt 2025. gadā gaidāmā izstāde Expo. `+
	`<dd></dd>"Ņemot vērā valsts ekonomisko attīstību un vērtības, Japāna Latvijas uzņēmējiem ir potenciāls tirgus. Turklāt šī ir ļoti liela valsts ar 127 miljoniem iedzīvotāju," stāsta Expo 2025 paviljona direktore Lāsma Līdaka. `+
	`<dd></dd>Šobrīd Japāna ir Latvijas lielākais eksporta tirdzniecības partneris Āzijā. 2022. gadā Latvijas kopējais tirdzniecības apgrozījums ar Japānu bija 123 miljoni eiro, kas ierindoja Japānu 43. vietā Latvijas ārējās tirdzniecības partneru vidū. Eksports uz Japānu bija 86 miljoni eiro, bet imports ir 37 miljoni eiro. Lielākais potenciāls šajā tirgū ir informācijas un komunikācijas tehnoloģijām, jaunākajām tehnoloģijām un unikāliem risinājumiem. `+
	`<dd></dd>"Japāna ir ļoti konservatīvs tirgus. Ir sarežģīti tajā iekļūt un strādāt. Taču vienlaikus šis tirgus ir ļoti brīnišķīgs – tur valdošā uzticība, godprātība, paļaušanās un struktūra ir ļoti pateicīga biznesam," saka informācijas tehnoloģiju uzņēmuma Zabbix mārketinga vadītāja Zane Lasmane. `+
	`<dd></dd><h3 class="post-subtitle">Pirmais solis – kultūras iepazīšana</h3>`+
	`<dd></dd>L. Līdaka uzsver, ka uzņēmumam ir jābūt ļoti gatavam Japānas tirgum vēl pirms ieiešanas tajā. "Pats galvenais – ir jābūt spēcīgam, konkurētspējīgam produktam vai pakalpojumam. Pirms doties uz Japānu, katram uzņēmumam ir rūpīgi jāveic tirgus priekšizpēte, jāsaprot pozicionēšana, kas ir tā produkta vai pakalpojuma priekšrocības. Galvenais ir labi sagatavots piedāvājums. Tāpēc pirmais solis Japānā ir tirgus izpratne, un paralēli – kultūras un biznesa attiecību iepazīšana. Kultūras izpratne šajā tirgū ir ļoti, ļoti svarīga," viņa teic. Z. Lasmane izceļ, ka Japāna nav standarta tirgus, tāpēc eiropiešiem ir jāveic ļoti lieli sagatavošanās darbi, lai būtu gatavi sākt strādāt ar Japānu. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan1+`"></div>`+
	`<dd></dd>Apdrukas un šūšanas ārpakalpojumu uzņēmuma Printful starptautiskās cenu noteikšanas nodaļas vadītājs Dāvis Vasiļevskis izceļ kādu it kā sīkumu: ir ļoti svarīgi pareizi apmainīties ar vizītkartēm. "Vizītkarti nevar tā vienkārši kaut kur ielikt un aiziet tālāk savās gaitās. Tas ir vesels process," viņš uzsver. Arī Z. Lasmane norāda, ka vizītkartei un tās pasniegšanas rituālam ir liela nozīme. `+
	`<dd></dd><div class="quote-style">"Visi rituāli, piemēram, kā mēs uzrunājam cilvēkus un kā notiek sadarbība, Japānā ir ļoti svarīgi. Tajos ir lielas atšķirības, tāpēc ieteicams lasīt grāmatas par japāņu kultūru un biznesa vidi," iesaka Z. Lasmane. </div>`+
	`<dd></dd>D. Vasiļevska pieredze liecina, ka Japānā ir ļoti izteikta hierarhija. "Ir jārespektē vietējā kultūra. Jāņem vērā, ka tikšanās laikā pie galda ir klāt visu iesaistīto pozīciju cilvēki. Parasti sāk runāt jaunākais vai zemāka ranga profesionālis, un tikai pašās beigās vadītājs pasaka kādu vārdu, bet reizēm pat nepasaka neko," viņš stāsta. `+
	`<dd></dd><h3 class="post-subtitle">Valodas nezināšana var būt liels klupšanas akmens</h3>`+
	`<dd></dd>"Ir jāņem vērā, ka ne visi Japānā runā angļu valodā. Iespējams, būs vajadzīgs tulks, lai sarunas notiktu produktīvāk. Japāņu valoda var būt klupšanas akmens," brīdina L. Līdaka. Tam piekrīt D. Vasiļevskis: "Japānā angliski runā ļoti maz cilvēku. Daļa varbūt runā, bet to dara nelabprāt. Tāpēc visām sarunām jābūt japāniski." `+
	`<dd></dd>Datorspēļu klaviatūru ražotāja “Azeron” komandā neviens nepārzina japāņu valodu, taču uzņēmums ir sagatavojis produktu aprakstu tulkojumus. "Japāņi diezgan slikti zina angļu valodu. Bez tulkota satura ir visnotaļ grūti viņus sasniegt, jo viņi vienkārši nesaprot, ko mēs pārdodam, un Google Translate ne vienmēr ir precīzs," saka “Azeron” līdzdibinātājs Jānis Kūlbārdis. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan2+`"></div>`+
	`<dd></dd>Reiz, tulkojot tekstus uz japāņu valodu, “Azeron” to uzticēja tulkošanas uzņēmumam Rīgā, kurā apgalvoja, ka viņiem ir tulks, kam japāņu valoda ir dzimtā. Kad “Azeron” savā Discord kontā palūdza kopienai pārbaudīt, vai tulkojumi ir pareizi, izrādījās, ka teksts, kas angļu valodā ir "Keypad for lefties" jeb kontrolieris kreiļiem, ir iztulkots burtiski kā "produkts kreisajiem". "Tas mums lika saprast, ka, strādājot ar valodām, kurās paši nerunājam, ir jābūt divkārt uzmanīgiem," teic J. Kūlbārdis. `+
	`<dd></dd>Šobrīd japāņu valodā ir tikai “Azeron” produktu apraksti Amazon tirdzniecības platformā. Uzņēmumā saprot, ka šo situāciju vajadzētu uzlabot, bet līdz šim nav izdevies atrast nevienu cilvēku ar japāņu valodas zināšanām. "Nav jēgas samaksāt aģentūrai 10 tūkstošus eiro par tulkojumiem, ja mēs pēc tam nevaram cilvēkus apkalpot japāņu valodā. Mums vajag cilvēku, kurš var ar viņiem sazināties. Japāna ir tik tāls un nesaprotams tirgus, ka cilvēkam, kurš tajā mūs pārstāv, ir ļoti liela nozīme," uzsver J. Kūlbārdis. `+
	`<dd></dd><h3 class="post-subtitle">Sadarbības uzsākšana prasa ilgu laiku</h3>`+
	`<dd></dd>"Sadarbības sākšana ar partneri Japānā mums prasīju vairāk nekā divus gadus. Viena no mūsu kļūdām bija tā, ka mēs pietiekami bieži nebijām uz vietas un netikāmies ar partneri klātienē," saka D. Vasiļevskis. Viņš salīdzina: lai noskaidrotu jautājumu par viena miljona eiro lēmumu, Eiropā vai ASV pietiek uzrakstīt e-pastu vai piezvanīt. Turpretī Japānā viss notiek daudz lēnāk. "Tur ir ļoti svarīga satikšanās aci pret aci. Japānis var pateikt "jā", bet tas ne vienmēr nozīmēs "jā". Pat ja e-pastā ir rakstiska vienošanās, tas nenozīmē, ka tā arī notiks. Arī mūsu gadījumā lietas uz priekšu virzījās ļoti lēni – līdz brīdim, kad aizbraucām un paspiedām roku," pieredzē dalās D. Vasiļevskis. `+
	`<dd></dd>Arī Z. Lasmane iesaka rēķināties, ka attiecību veidošana Japānā prasīs ilgu laiku. "Viņiem ir svarīgas ilgstošas partnerattiecības. Ja pēc pāris gadiem parādās konkurents, kas nāk ar daudz zemākām cenām, japāņi uzreiz neskries pie šī konkurenta. Viņi kārtīgi pārbauda partnerus, lai sadarbība būtu ilgstoša," viņa norāda. `+
	`<dd></dd><h3 class="post-subtitle">Izvairās no konfliktiem</h3>`+
	`<dd></dd>"Japāņi ļoti skatās uz godaprātu. Viņi sagaida godprātīgu rīcību gan no mums, gan paši no sevis. Ja kāds kļūdas, šī kļūda tiek atzīta, par to atvainojas un savlaicīgi informē par gaidāmo," saka Z. Lasmane. Viņa ir novērojusi, ka japāņi izvairās no konflikta un viņi ir gatavi darīt visu, lai tas nenotiktu. Tieši tāpēc viņi tik rūpīgi izvērtē, vai partneris ir uzticams un godprātīgs. Tas palīdz izvairīties no iespējamiem konfliktiem. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan3+`"></div>`+
	`<dd></dd>Z. Lasmane piebilst, ka Japānā lēmumu pieņem vienprātīgi. "Viņi vēlas panākt, lai visas puses piedalās lēmumu pieņemšanā. Ja visas puses konkrēto lēmumu ir pieņēmušas un par to vienojušās, pēc tam nav iespējas rasties konfliktam, un, ja tiešām ir kāda nesaprašanās, viņi to uztver ļoti nopietni. Ikdienas komunikācijā ar Japānas filiāli ir bijis tā, ka mēs kādu jautājumu esam pārpratuši vai kāds ir bijis atvaļinājumā un mēs laikus neesam saņēmuši atbildi. Viņi vienmēr atvainojas, izskaidrojas. Japāņiem ir ļoti svarīgi, lai mēs paliekam uz draudzīgas savstarpējās uzticības nots," teic Z. Lasmane. `+
	`<dd></dd><h3 class="post-subtitle">Daļa no plašāka reģiona</h3>`+
	`<dd></dd>Printful uz Japānu skatās kā plašāku reģionu, nevis tikai vienu valsti. "Mēs uz to skatāmies kā daļu no Āzijas reģiona, kas e-komercijā ir ļoti attīstīts. No Japānas mēs apgādājam arī Dienvidkoreju, Filipīnas, Singapūru un visu Dienvidaustrumāzijas reģionu," stāsta D. Vasiļevskis. `+
	`<dd></dd>Par attīstību Japānā Printful sāka domāt 2017. gadā. Uzņēmums 2018. gadā piedalījās pirmajā vizītē Japānā kopā ar LIAA, bet tikai 2022. gadā aktīvi sāka darboties šajā tirgū. `+
	`<dd></dd>Izvēloties jaunu tirgu, Printful skatās uz to, cik tirgū ir iedzīvotāju, kādi ir vidējie e-komercijas tēriņi uz vienu cilvēku, cik attīstīta ir iepirkšanās tiešsaistē un kāda ir sabiedrības vēlme pēc personalizētām lietām. `+
	`<dd></dd><div class="quote-style">"Protams, Japānā ir kultūras atšķirības, bet fundamentāli e-komercijas biznesam šis tirgus ir ļoti labvēlīgs. Japānā uzņēmumi aug un eksperimentē ar dažādām idejām un biznesa modeļiem," viņš saka. </div>`+
	`<dd></dd>Daudzās valstīs Printful ir savas ražotnes, bet Japānā uzņēmums sadarbojas ar ražošanas partneri. "Šis mums joprojām ir eksperimentāls tirgus, kur nav tā kā ASV, kur mēs zinām visu par to, kā strādā bizness. Tāpēc mums Japānā ir partneris, kurš labi pārzina tirgu un kultūras atšķirības – kaut vai tādas ikdienišķas lietas, kā runāt ar Japānas biznesa partneriem," saka D. Vasiļevskis. `+
	`<dd></dd>Analizējot dažādus scenārijus, uzņēmumam šāds sadarbības modelis šķita vispievilcīgākais. Viens no plusiem ir investīcijas – lai atvērtu ražotni, jāiegulda ievērojams finansējums. Otrs pluss – sadarbība ar partneri Printful ļauj darboties elastīgāk un drošāk testēt tirgu, lai saprastu, kas strādā un kas nestrādā. "Mēs joprojām taustāmies un meklējam pareizās atbildes. Taču mēs šim tirgum noteikti dosim iespēju, jo visi makro rādītāji ir apmierinoši un mums ir bijis labs progress," teic D. Vasiļevskis. `+
	`<dd></dd><h3 class="post-subtitle">Sadarbojas ar vietējo partneri</h3>`+
	`<dd></dd>Zabbix ir viens no ļoti retajiem Latvijas uzņēmumiem ar filiāli Japānā – iespējams, pat vienīgais. Filiāle tur ir jau 11 gadus, bet stāsts sākās vēl agrāk, kad uzņēmuma dibinātājam Aleksejam Vladiševam atrakstīja no liela Japānas telekomunikāciju uzņēmuma NTT Communications. Viņi meklēja monitoringa programmatūru, ko izmantot, sniedzot pakalpojumus citiem. Pēc vairākkārtējas sarakstes, produkta pārbaudes un testēšanas viņi pieteicās vizītē uz Latviju. Pagāja vēl aptuveni gads, kura laikā Latvijas pusei šķita, ka sadarbība vēl nav sākusies. "Viņi vēlāk mums stāstīja, ka viņi bija braukuši arī pie konkurējošiem uzņēmumiem uz Vāciju un citur Eiropā, lai saprastu, kuru monitoringa instrumentu viņi izvēlēsies. Tāpat viņiem ir svarīgi pārbaudīt partnerus, produktu un saprast, vai var uzticēties," stāsta Z. Lasmane. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan4+`"></div>`+
	`<dd></dd>NTT Communications kļuva par Zabbix pirmo partneri, kas atvēra uzņēmuma filiāli Japānā. Uzņēmuma pieredze liecina, ka Japānā bez vietējās pārstāvniecības nav iespējams attīstīties. Viņa neslēpj, ka ir daudz un dažādu barjeru, kuras uzņēmumam no Latvijas nav saprotamas mentalitātes, kultūru atšķirības un arī valodas dēļ. `+
	`<dd></dd>"Šobrīd Zabbix monitorings Japānā ir kā de facto standarts. Ja kādam Japānā pajautā, kādu monitoringa sistēmu tas izmanto, ir gandrīz tikai viena atbilde: Zabbix," apgalvo Z. Lasmane. `+
	`<dd></dd><h3 class="post-subtitle">Uzticas vietējiem</h3>`+
	`<dd></dd>"Atšķirībā no citām mūsu filiālēm Japānā, mēs ar klientiem nestrādājam tieši – visa komunikācija un darījumi notiek caur mūsu Japānas partneriem. Tā ir kultūras un biznesa iezīme: viņi ļoti uzticas vietējiem pakalpojumu sniedzējiem, it īpaši IT nozarē. Tāpēc mums Japānā ir filiāle, kur strādā vietējie darbinieki, kuri sniedz atbalstu mūsu partneriem šajā tirgū," stāsta Z. Lasmane. `+
	`<dd></dd>Visā pasaulē izmanto Zabbix programmatūru, ko var lejuplādēt jebkurš, bet Japānā ir arī taustāmi produkti – Zabbix serveri un Zabbix ierīces. "Partneriem radās ideja, ka programmatūru būtu vieglāk tirgot un attīstīt, ja būtu arī ierīces. Partneri izveidoja un izstrādāja šīs ierīces ar ārpakalpojumu sniedzējiem–ražotājiem. Nekur citur mēs Zabbix ierīces nepārdodam," stāsta Z. Lasmane. `+
	`<dd></dd><h3 class="post-subtitle">Nestandarta eksporta stāsts</h3>`+
	`<dd></dd> “Azeron” ir nestandarta eksportētāja pieredzes stāsts. "Jau kopš dibināšanas mūs interesē visi tirgi, kur saprot angļu valodu un uz kurieni FedEx veic piegādes. Ir dabiski sanācis, ka jau no pirmās dienas mums vienmēr ir bijuši klienti no Japānas. Jau produkta izlaišanas dienā, kad Japānā bija trīs naktī, mums bija pirmie pasūtījumi. Es šo gadījumu ļoti labi atceros, jo ir jābūt pietiekami trakam, lai naktī sēdētu pie datora un gaidītu iespēju nopirkt tieši mūsu klaviatūru," saka J. Kūlbārdis. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan5+`"></div>`+
	`<dd></dd>Lai gan “Azeron” lielākais tirgus ir Amerika, kas veido 60 % no apgrozījuma, neviens cits tirgus bez Japānas neveido vairāk kā 5 % no apgrozījuma. Pirmajos gados Japāna veidoja 1–3% no “Azeron” kopējā apgrozījuma. Viss mainījās pirms pāris gadiem, kad uzņēmums izlēma piedāvāt savu produktu Japānas “Amazon” – pārdošanas apjomi sāka palielināties. `+
	`<dd></dd>Šobrīd bizness Japānā jau veido ap 13 % no apgrozījuma. Līdz šim labākie rezultāti ir populāram saturam platformā X, influenceru stāstiem par to, kā viņi izmanto “Azeron” produktu, un Japānas “Amazon”. `+
	`<dd></dd>"Japānā cilvēki ļoti iespaidojas no tā, ko lieto viņu elki, draugi, kaimiņi. Ir vajadzīga šāda uzticama rekomendācija. Lai kā negribētos to atzīt, mēs nemākam atrast pareizo atslēgu, kā četrkāršot apgrozījumu šajā tirgū. Taču Japāna ir tirgus, kur mēs saskatām vislielāko potenciālu," saka J. Kūlbārdis. `+
	`<dd></dd><h3 class="post-subtitle">Iespēja veicināt valsts atpazīstamību</h3>`+
	`<dd></dd>Pašlaik Japānā tikai 11 % iedzīvotāju zina, ka ir tāda valsts Latvija, liecina Latvijas Investīciju un attīstības aģentūras pētījums. Tā, protams, ir ļoti neliela daļa. `+
	`<dd></dd><div class="quote-style">"Āzijā cilvēki ir ļoti piesardzīgi un nevēlas sadarboties ar nepazīstamiem, nezināmiem vai nepārbaudītiem partneriem. Tāpēc Latvijai ir jāstrādā pie atpazīstamības veicināšanas, kas, ņemot vērā kultūras īpatnības, ir gana izaicinoši. Japānas pilsētā Osakā 2025. gadā notiks pasaules Expo, kas var palīdzēt veicināt arī tūristu skaita pieaugumu un ekonomiskās sadarbības potenciālu. Ir gudri jādomā, kā to īstenot," saka L. Līdaka. </div>`+
	`<dd></dd>To, ka šādām izstādēm ir pozitīvi rezultāti, apliecina nesenais Expo Apvienotajos Arābu Emirātos. Izstādē piedalījās 443 Latvijas uzņēmumi, kuri nodibināja 3182 jaunus biznesa kontaktus un noslēdza 35 līgumus. Salīdzinot ar 2021. gadu, eksports uz Apvienotajiem Arābu Emirātiem 2022. gadā pieauga par 52 %. Papildu tiešajiem ieguvumiem no sadarbības ar Apvienotajiem Arābu Emirātiem, tika stiprinātas saites arī ar citām reģiona valstīm, īpaši ar Saūda Arābiju, kas ir nozīmīgākais ekonomiskais spēks Tuvo Austrumu reģionā. Tāpēc tiek sagaidīts, ka pēc Expo 2025 Latvijas eksports uz Japānu 2028. gadā varētu pieaugt par 50 miljoniem eiro, savukārt Japānas izcelsmes investīcijas – par 30 miljoniem eiro. `+
	`<dd></dd><h3 class="post-subtitle">Kopīgs Baltijas reģiona paviljons</h3>`+
	`<dd></dd>Šoreiz Latvija kopā ar Lietuvu veido kopīgu paviljonu, reklamējot visu Baltijas reģionu. "Šajās tālajās valstīs uz mums skatās kā uz vienu reģionu, līdzīgi kā uz Skandināvijas valstīm – un arī Zviedrija, Dānija, Norvēģija, Islande un Somija veido kopīgu Ziemeļvalstu paviljonu," saka L. Līdaka. Ideja par kopīgu paviljonu pieder japāņiem, kuri ieteica šādu pieeju. Viņi Baltijas valstis redz kā vienu reģionu un aicināja piedalīties visām trīs valstīm kopā, uzticot Latvijai vadošās valsts godu šajā apvienībā. "Kopā ar lietuviešiem mēs meklēsim vienotu stāstu, kas raksturo abas valstis," saka L. Līdaka. Igaunija 2025. gada Expo nepiedalīsies. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan6+`"></div>`+
	`<dd></dd>Biznesa programma un aktivitātes, kurās uzņēmēji varēs piedalīties, vēl ir izstrādes posmā. To plānots pabeigt 2024. gada sākumā. Taču jau tagad ir zināms, ka plānotas izstādes, tirdzniecības misijas, biznesa forumi un semināri. "Mēs gribētu dzirdēt gan nozaru asociāciju, gan dažādu uzņēmējdarbības veicinošu organizāciju, kā arī pašu uzņēmumu viedokli. Mēs veidosim arī seminārus un mēģināsim saprast uzņēmumu interesi par šo tirgu," saka L. Līdaka. `+
	`<dd></dd>Viņa atgādina, ka pats galvenais ir spēcīgs un konkurētspējīgs produkts vai pakalpojums. Katram uzņēmumam ir jāveic savs mājasdarbs: jāveic tirgus priekšizpēte, jāsaprot sava produkta pozicionējums, unikālās konkurētspējas priekšrocības un izaugsmes stratēģija, ko L. Līdaka iesaka izstrādāt kopā ar partneri. "Galvenais ir labi sagatavots piedāvājums, biznesa kultūras aspekti jau ir sekundārs faktors. Lai gan Japāna šķiet tāls un sarežģīts tirgus valodas un kultūras dēļ, šis ir tirgus ar lielu nākotnes potenciālu. Ne velti Latvijas Investīciju un attīstības aģentūras pārstāvniecība Japānā darbojas jau kopš 2008. gada un turpina atbalstīt Latvijas uzņēmumus šajā tirgū," viņa papildina. `+
	

	support_by_liaa,

	
	review_1_name: "Jānis Hermanis",
	review_1_designation: "LDDK, finanšu un nodokļu eksperts",
	review_1_text:
		"Latvijas eksporta īpatsvars jau 10 gadu garumā ir bijis aptuveni 60% no IKP, tomēr tam vajadzētu būt vēl lielākam un virzīties vismaz līdz abu Baltijas kaimiņvalstu līmenim, kurās tas pārsniedz 70%.",
	review_1_excerpt:
		"Latvijas eksporta īpatsvars jau 10 gadu garumā ir bijis aptuveni 60% no IKP, tomēr tam vajadzētu būt vēl lielākam un virzīties vismaz līdz abu Baltijas kaimiņvalstu līmenim, kurās tas pārsniedz 70%.",
	
	review_2_name: "Jānis Ošlejs",
	review_2_designation: "Primekss, valdes priekšsēdētājs",
	review_2_text:
		"Reālais  efektīvais  valūtas  kurss  rāda, ka Latvijā ražoto preču un pakalpojumu pašizmaksa aug straujāk, kā mūsu tirdzniecības partneriem. Daļēji tas notiek tādēļ, ka mūsu algas un cenas aug straujāk, kā citviet.",
	review_2_excerpt:
		"Reālais  efektīvais  valūtas  kurss  rāda, ka Latvijā ražoto preču un pakalpojumu pašizmaksa aug straujāk, kā mūsu tirdzniecības partneriem. Daļēji tas notiek tādēļ, ka mūsu algas un cenas aug straujāk, kā citviet.",
	
	
		"Augstākās izglītības Sektors": "Augstākās izglītības Sektors",
    "Others": "Pārējie",
	"Previous": "Iepriekšējie",

    "ES-27": "ES-27",
    "Eksports/IKP EE": "Eksports/IKP EE",
    "Eksporta prognoze": "Eksporta prognoze",
    "Eksports/IKP LT": "Eksports/IKP LT",
    "Eksports/IKP LV": "Eksports/IKP LV",
    "Eksportē uz 3. valstīm": "Eksportē uz 3. valstīm",
    "Eksportē uz ES": "Eksportē uz ES",
    "Igaunija": "Igaunija",
    "Izmaiņa pret iepriekšējā gada periodu": "Izmaiņa pret iepriekšējā gada periodu",
    "Kopā": "Kopā",
    "Kopējais Finansējums Zinātniski Pētnieciskajam Darbam": "Kopējais Finansējums Zinātniski Pētnieciskajam Darbam",
    "Kopējais eksports": "Kopējais eksports",
    "LV NAP2021-27 mērķi": "LV NAP2021-27 mērķi",
    "LV_NAP": "LV_NAP",
    "Latvija": "Latvija",
    "Lietuva": "Lietuva",
    "NIP 2014-2020": "NIP 2014-2020",
    "NIP 2020-2027": "NIP 2020-2027",
    "Neeksports": "Neeksports",
    "Nodokļi kopā": "Nodokļi kopā",
    "Pakalpojumu Eksports": "Pakalpojumu Eksports",
    "Pakalpojumu eksports": "Pakalpojumu eksports",
    "Papildus eirozonas eksports": "Papildus eirozonas eksports",
    "Pasaules pieprasījums": "Pasaules pieprasījums",
    "Pieaugošs Eksports": "Pieaugošs Eksports",
    "Preču eksports": "Preču eksports",
    "Preču un pakalpojumu eksports": "Preču un pakalpojumu eksports",
    "REER": "REER",
    "Samazinošs Eksports": "Samazinošs Eksports",
    "Uzņēmējdarbības Sektors": "Uzņēmējdarbības Sektors",
    "Valsts Sektors": "Valsts Sektors",
	"Eksportētāju pienesums tautsaimniecībā": "Eksportētāju pienesums tautsaimniecībā",
	"Eksportētāju finanšu veselība": "Eksportētāju finanšu veselība",
	"Produktivitāte un inovācijas": "Produktivitāte un inovācijas",
	"Latvijas eksports 2021. gadā": "Latvijas eksports 2021. gadā",

	"Gads": "Gads",
	"Mēnesis": "Mēnesis",
	"Gadi": "Gadi",
	"Mēneši": "Mēneši",


	"Ceturksnis": "Ceturksnis",
	"Viss periods": "Viss periods",
	"Daļējs periods": "Daļējs periods",

	"Atgriezties": "Atgriezties",
	"Attālināties": "Attālināties",

	"Latvijas Eksporta Barometrs": "Latvijas Eksporta Barometrs",
	"NIP - nacionālās industriālās politikas pamatnostādnes": "NIP - nacionālās industriālās politikas pamatnostādnes",

	"Apgrozījums uz 1 darbinieku (mediāna) pa gadiem": "Apgrozījums uz 1 darbinieku (mediāna) pa gadiem",
	"Rentablitāte (aktīvu atdeves) mediāna pa gadiem": "Rentablitāte (aktīvu atdeves) mediāna pa gadiem",
	"Mediānas peļņa pēc nodokļiem pa gadiem": "Mediānas peļņa pēc nodokļiem pa gadiem",
	"Peļņa pēc nodokļiem uz 1 darbinieku (mediāna) pa gadiem": "Peļņa pēc nodokļiem uz 1 darbinieku (mediāna) pa gadiem",
	"Bruto peļņas robeža (mediāna) pa gadiem": "Bruto peļņas robeža (mediāna) pa gadiem",

	"Latvijas ārpus-eirozonas reālais eksports (gada pieauguma tempi, %, devumi, pp.)": "Latvijas ārpus-eirozonas reālais eksports (gada pieauguma tempi, %, devumi, pp.)",

	"Veidotāji":"Veidotāji"



}

const translationsEn = {
	btn_home: "Go to Export Analysis",
	btn_analyse: "Analyse",
	btn_read_more: "Read more",
	btn_more_reviews: "View all",
	home: "Home",
	project_title: "Latvian Export Barometer",
	home_block_1:
		"Export Barometer has been created for various purposes and is aimed at a wide target audience that includes public administration, businesses and the society at large. Export Barometer illustrates the overall situation of Latvia’s export in an interactive way. Export is a keystone of the development of our country. Thanks to export, national economies’ experience growth and development, however, at this point Latvia is still behind Lithuania and Estonia in terms of export development, despite the huge effort to regularly boost it.",
	home_block_2:
		"The study analysed all Latvian exporters of goods and services whose export volumes exceed EUR 1 million. The barometer has been developed based on the last three years’ worth of data to allow for a comparison of trends over the course of several years, and in particular, in the light of the impact caused by the pandemic on the development of exports in general.",
	contribution_pagetitle: "Exporters’ Contribution to National Economy",
	contribution_tab_1: "Number of exporters",
	contribution_tab_1_fs: "Number of exporters with the export volume exceeding EUR 1 million",
	contribution_tab_1_text_bold: "Daiga Kiopa — Member of the Board, Lursoft: ",
	contribution_tab_1_text:
		"Statistics show that those exporting companies whose annual export volumes exceed EUR 1 million, have managed to increase their exports to third countries over the last three years, whereas the number of exporters to EU countries has slightly decreased in 2020. That, in turn, leads to the conclusion that exporters who are exporting their products and services to third countries should pay more attention and allocate larger resources both for the assessment of trade risks and business partners.",

	contribution_tab_2: "Number of employees",
	contribution_tab_2_text_bold: "Daiga Kiopa — Member of the Board, Lursoft: ",
	contribution_tab_2_text:
		"Th comparison of changes in the number of employees over the last two years in Latvian exporting companies and non- exporting companies (volumes exceeding EUR 1 million) reveal that the percentage of the number of employees in the non-exporting companies over the last year has decreased more rapidly than in the exporting companies. Th assessment of the average change in the number of employees in the company, in turn, leads to the conclusion that in 2020 this indicator has increased for service exporters compared to the previous year, whereas the average number of employees in the company’s exporting goods has slightly decreased. One of the explanations for such change is more intense implementation of automated production processes in the companies.",
	contribution_tab_2_1: "Total number of employees",
	contribution_tab_2_1_fs: "Total number of employees (acc. to the State Revenue Service data)",
	contribution_tab_2_2: "Average number of employees in the company",
	contribution_tab_2_2_fs: "Average number of employees per year",

	contribution_tab_3: "Tax amount",
	contribution_tab_3_text_bold: "Daiga Kiopa — Member of the Board, Lursoft: ",
	contribution_tab_3_text:
		"Th comparison of the number of exporting and non-exporting companies shows that the prevalence of non-exporting companies in the given group of companies exceeds 70%. In contrast, the assessment of the tax paid by exporting companies reveals that their contribution to annual tax varies between 45-50%. It means that despite the smaller number of exporters, their contribution to the national economy, including tax paid, is considerably higher than that of the non-exporting companies. ",
	contribution_tab_3_1: "Total tax amount",
	contribution_tab_3_1_fs: "Total tax amount by years",
	contribution_tab_3_2: "Tax amount per company",
	contribution_tab_3_2_fs: "Number of exporters with the export volume exceeding EUR 1 million",
	contribution_tab_3_2_text_bold: "Daiga Kiopa — Member of the Board, Lursoft: ",
	contribution_tab_3_2_text:
		"Statistics show that the companies exporting services have paid more tax both per company and per employee in comparison with the commodity exporters.",
	contribution_tab_3_3: "Tax per employee",
	contribution_tab_3_3_fs: "Tax paid to the state budget per employee by years",
	contribution_tab_3_3_text_bold: "Daiga Kiopa — Member of the Board, Lursoft: ",
	contribution_tab_3_3_text:
		"It is noteworthy that a more detailed analysis of the tax paid by companies suggests that exporting companies generally pay higher national insurance contributions per employee — this is a figure that is directly proportional to the gross wage and indirectly reflects both operational efficiency and social guarantees of employees. This means that remuneration levels in exporting companies are higher than those in non-exporting businesses. These figures show that people working for the exporting companies can afford higher spending in the domestic market, which in turn also directly promotes the local economic activity. ",

	contribution_tab_4: "NACE",

	financial_health_pagetitle: "Exporters’ Financial Wellbeing",
	fh_tab_1: "Operational volumnes",
	fh_tab_1_1: "Turnover per employee",
	fh_tab_1_1_text_bold:
		"Martins Tiknuss — Chairman of the Board, Latvian Exporters Association (LEA) “The Red Jackets”",
	fh_tab_1_1_text:
		"For us to be able to compete with our product prices in the global market and to improve the exporting companies’ capabilities to develop production facilities and obtain funding from banks or capital funds more easily, we need to improve the entrepreneurs’ skill to raise the added value and reduce its prime cost. This means providing support for purchasing assets and investing in raising their operational efficiency. In addition, adjusting the cost base to the regional level, so that the cost of energy resources would be on the same level and the tax burden would be comparable to that of other competitors.",
	fh_tab_1_2: "Profitability (return on assets)",
	fh_tab_2: "Profitability",
	fh_tab_2_1: "Profit after tax",
	fh_tab_2_1_text_bold:
		"Martins Tiknuss — Chairman of the Board, Latvian Exporters Association (LEA) “The Red Jackets”",
	fh_tab_2_1_text:
		'Mārtiņš Tiknuss - Latvijas Eksportētāju asociācijas "The Red Jackets", valdes priekšsēdētājs: Lai mēs spētu konkurēt pasaules tirgos ar produktu cenu un veicinātu eksportējošo uzņēmumu spēju attīstīt ražotnes un vieglāk piesaistīt finansējumu no bankām vai kapitāla fondiem mums ir jāveicina uzņēmēju spēja audzēt pievienoto vērtību un samazināt tās pašizmaksu, kas nozīmē sniegt atbalstu aktīvu iegādē un investīcijām darba ražības palielināšanā, kā arī strādāt pie pamata izmaksu pozīciju pielāgošanas reģiona līmenim, lai energoresursi būtu vienā cenu līmenī, lai nodokļu slogs būtu salīdzināms ar konkurentiem.',
	fh_tab_2_2: "Profit after tax per employee",
	fh_tab_2_3: "Gross profit margin",

	productivity_pagetitle: "Productivity and Innovations",
	prod_tab_1: "Real export",
	prod_tab_1_1: "Total real export",
	prod_tab_1_1_fs: "Total real export in Latvia (annual growth rate, %, contribution, pp)",
	prod_tab_1_1_text_bold: "Janis Oslejs — Chairman of the Board, Primekss:",
	prod_tab_1_1_text:
		"The real export data suggests that an increase in Latvian exports over the last few years was mainly stimulated by the rapid growth of global demand. However, at the same time this was also impeded by the raising prime cost of Latvian goods and services. While as a result of the COVID-19 crisis the total global demand remains almost stagnant; Latvian entrepreneurs will have to put in extra effort to boost their exports. To ensure growth in a situation when the external environment is not quite favourable, it is necessary to increase the government support for investments in equipment, digitalisation, and R&D.",
	prod_tab_1_2: "Real export outside the euro area ",
	prod_tab_2: "Research and development (R&D) figures",
	prod_tab_2_1: "Expenditure for scientific research",
	prod_tab_2_1_fs: "Expenditure for scientific research per sector",
	prod_tab_2_1_text_bold: "Janis Hermanis — Finance and Tax Expert, Employers' Confederation of Latvia (LDDK):",
	prod_tab_2_1_text:
		"Expenditure for scientific research in Latvia over the last few years has been gradually increasing. However, this increase almost equals the GDP growth rate, hence, the ratio of these investments and GDP does not reach the same level as in other countries. Half of the total investment in Latvia goes to the higher education sector, 1/3 to the business sector and around 1/6 – to the public sector. Compared to other countries, Latvia is lagging behind in terms of investments, mainly in the business sector where expenditure should be several times higher.",
	prod_tab_2_2: "Funding",
	prod_tab_2_2_fs: "Scientific staff employed, % of the total number of employees (full-time), TOTAL",
	prod_tab_2_2_text_bold: "Janis Hermanis — Finance and Tax Expert, Employers' Confederation of Latvia (LDDK):",
	prod_tab_2_2_text:
		"Scientific research in Latvia has been underfinanced for a very long time. It has been fluctuating between 0.4% and 0.7% of GDP, which is considerably less than in other EU countries (incl. Estonia and Lithuania), and also fails to meet the strategic goals set by the National Development Plan. The ratio of funding in Latvia (% of GDP) is far behind other Baltic states in respect of all sectors, however, this difference is mainly due to the under-supported business sector. It should be noted that the actual figures regarding funding of R&D might be higher than reflected by the statistical data because entrepreneurs have mentioned several times that providing such statistical data creates a considerable administrative burden. It means that the process of accounting investments in R&D should be simplified and made more user-friendly to respondents.",
	prod_tab_2_3: "Employed personnel",
	prod_tab_2_3_fs: "Scientific staff employed, % of the total number of employees (full-time), TOTAL",
	prod_tab_2_3_text_bold: "Janis Hermanis — Finance and Tax Expert, Employers' Confederation of Latvia (LDDK):",
	prod_tab_2_3_text:
		"The ratio of personnel employed for scientific research reaches 0.7% of the total number of employees. It is almost twice as low as the average in EU countries and 1/3 less than in Estonia and Lithuania. The figures in Latvia are also lower than those envisaged in the NDP, whereby in 2027 this should reach 1%, which is the current level in Estonia and Lithuania. The ratio of scientific research personnel in the public administration and higher education sectors in Latvia is similar to other Baltic countries in all sectors. The major difference between Latvia and other countries can be observed in the business sector, where staff numbers should be at least three times higher to reach the level of Estonia and Lithuania. Here too, the actual figures of Latvia might be better if the statistical accounting process were simplified for the companies involved in R&D.",

	company_interviews_pagetitle: "Company Interviews",
	comp_tab_1: "Exporter stories",
	comp_tab_1_title: "Exporter stories",
	comp_tab_2: "Experience in export markets",
	comp_tab_2_title: "Experience in export markets",

	review_0_name: "Martins Tiknuss",
	review_0_designation: "Chairman of the Board, Latvian Exporters Association (LEA) “The Red Jackets",
	review_0_text:
		"The aim of the NIP: to increase export volumes to EUR 22 billion in 2023 and to EUR 27 billion in 2027. The present growth of exports enables us to forecast that Latvia will even exceed this goal in 2027.",
	review_0_excerpt:
		"The aim of the NIP: to increase export volumes to EUR 22 billion in 2023 and to EUR 27 billion in 2027. The present growth of exports enables us to forecast that Latvia will even exceed this goal in 2027.",
	
	
	review_1_name: "Janis Hermanis",
	review_1_designation: "Finance and Tax Expert, Employers' Confederation of Latvia (LDDK)",
	review_1_text:
		"The ratio of Latvian export has stayed in the range of 60% of GDP for the last 10 years already, however, it should be even higher and closer to at least the level of the other two neighbouring Baltic countries where it exceeds 70%. ",
	review_1_excerpt:
		"The ratio of Latvian export has stayed in the range of 60% of GDP for the last 10 years already, however, it should be even higher and closer to at least the level of the other two neighbouring Baltic countries where it exceeds 70%. ",
	
	review_2_name: "Janis Oslejs",
	review_2_designation: "Chairman of the Board, Primekss",
	review_2_text:
		" The real effective exchange rate suggests that the prime cost of goods and services produced in Latvia is growing more rapidly than for our trade partners. This can be partly attributed to the faster increase of our wages and prices than elsewhere.",
	review_2_excerpt:
		" The real effective exchange rate suggests that the prime cost of goods and services produced in Latvia is growing more rapidly than for our trade partners. This can be partly attributed to the faster increase of our wages and prices than elsewhere.",

	review_3_name: "Linda Riekstiņa-Šnore",
	review_3_company: "“Ette Tete”",
	review_3_website: "http://www.ettetete.com",
	review_3_designation: "Ette Tete (www.ettetete.com) founder, Member of the Board",
	review_3_title:"You can’t sit and wait for export partners to find you",
	review_3_excerpt:
		`Today, children’s furniture maker Ette Tete mainly exports to the Netherlands, Belgium, Germany. Switzerland, Italy and South Korea. The company is working on adapting its products to comply with US standards and to begin its active search for partners in the American market next year.`
	,
	review_3_text:
`<dd></dd>Today, children’s furniture maker Ette Tete mainly exports to the Netherlands, Belgium, Germany. Switzerland, Italy and South Korea. The company is working on adapting its products to comply with US standards and to begin its active search for partners in the American market next year.`+
`<dd></dd>The Ette Tete story began in 2016 when a growing family needed children’s furniture. Their production was launched with the help of the LEADER project within which the company received 70% co-funding for purchasing CNC machines. After that, Ette Tete joined the Latvian Investment and Development Agency’s (LIAA) Business Incubator.`+
`<dd></dd><div class="quote-style">“We didn’t miss a single opportunity offered by the Incubator and absorbed all the information, including how to use Instagram, price products, etc. the best we could. I think it enabled us to avoid many beginners’ mistakes and focus on developing and growing our business. I can compare the support of the LEADER project to sowing seeds and the incubation programme to watering them, which paved the way for us to grow and flourish now. Although we would have been able to develop and grow without that support, it would have definitely been a much slower and likely more painful process,” said the Ette Tete founder Linda Riekstiņa-Šnore.</div>`+
`<dd></dd>Talking about the state support, she also noted the LIAA co-funding of product certification, which can now reach up to 90%. In Europe, certification is relatively easy, as a single certificate is valid in many countries. But, for example, when the UK decided to leave the EU, we had to get a new certificate and that meant additional costs. Certification of our products for the South Korean market amounted to EUR 6,500, but LIAA co-funding helped us reduce this cost significantly’, added L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">Looking overseas from day one</h3>`+
`<dd></dd>From the very beginning, we planned our sales with a view to exporting. The company began offering its products on e-commerce platforms, such as Etsy and Amazon, which made them available to international buyers. The first market where we sent Ette Tete products was the US. Our export markets expanded organically until potential foreign distributors and retailers started to come to us. Our first cooperation agreement was signed with a partner in Italy. “Then we decided that we should not work only through e-commerce platforms anymore. If there was interest from potential partners in addition to end buyers, we had to become more active and look for steady international cooperation partners,” said L. Riekstiņa-Šnore.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+linda1+`"></div>`+
`<dd></dd>She believes that a key to the success of the company is, first and foremost, their products, which in the first five years of their business led the way because no one else was offering anything like it. Secondly, it’s customer service for both e-commerce buyers and international partners. “We do everything to make sure that cooperation with us as a manufacturer is nice and smooth,” added L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">Export should be proactive</h3>`+
`<dd></dd>E-commerce platforms remain one of our customer engagement strategies. We have learned that active participation in e-commerce improves brand visibility and awareness. And it means more customers.  `+
`<dd></dd><div class="quote-style">“However, today, we can no longer sit and wait for a partner to find us. We have to be very active ourselves. We are working hard in this respect, and now, we have a dedicated employee for this task. We visit many different trade missions, participate in trade fairs and actively search for new partners,” said L. Riekstiņa-Šnore.</div>`+
`<dd></dd>For example, Ette Tete was a part of the national stand of the Kind + Jugend exhibition of goods for children and infants, Spielwarenmesse and a trade mission to Japan. We also received state support to participate in these events. “You should never expect to come home from a trade fair with a bunch of new customers. That’s not how it works and you cannot count on that. A trip to a trade fair may be enough for one market, but in another, you will have to find a partner yourself. You have to try different ways to see which one will work in each specific market,” explained L. Riekstiņa-Šnore.`+
`<dd></dd><h3 class="post-subtitle">99% of the products are sold outside Latvia</h3>`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+linda2+`"></div>`+
`<dd></dd>Founded in 2016, now Ette Tete has reached turnover of EUR 2.5 million and employs 30 people. More than 99% of sales are generated by exports. Latvian-made children’s furniture can be bought in many countries, but the company has permanent partners in 12 countries, including the Netherlands and Germany. The company’s main export markets are Belgium, Germany and South Korea. “Our top export destinations are constantly changing. It depends on our business partners, i.e. the location with the strongest and the most ambitious partner will rise to the top. For example, Belgium is one of our top three most significant export markets, although not so long ago it was not even close to it,” said L. Riekstiņa-Šnore.`+
`<dd></dd>In future, the entrepreneur hopes to boost their exports, but it also depends on external circumstances. Although Ette Tete has not had any significant partners in Russia or Ukraine, and the war has not affected the company directly, its impact can be felt indirectly through the rising prices of raw materials. Both local and imported raw materials have become more expensive, and inflation will bring further adjustments to their plans.`+
`<dd></dd><div class="quote-style">“We continue working to bring our products into compliance with US standards. We hope to achieve this by the end of the year and begin our active search for partners in the US market next year,” said L. Riekstiņa-Šnore.</div>`+
`<dd></dd><h3 class="post-subtitle">Know your product and your customer</h3>`+
`<dd></dd>L.Riekstiņa-Šnore said that when thinking about expanding export markets, you should carefully analyse the country in question, i.e. how big the market is and whether it is worth looking for new partners there.  For Ette Tete, their products sell best in developed countries where consumers have money to spend. In developing countries, where price is more important, Latvian furniture maker’s products would have a more difficult time. “There is no point in developing these export channels as the return will not be sufficient,” she pointed out.`+
`<dd></dd>That is why the entrepreneur would recommend exporting companies to think about their product and how it is being sold. To think about strategy, i.e. whether to look for as many partners as possible or fewer, but more loyal partners. And whether those should be larger distributors or small shops.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+linda3+`"></div>`+
`<dd></dd>L.Riekstiņa-Šnore’s experience shows that sometimes several small, but loyal partners are worth much more than numerous uninvolved partners.  “Occasionally, we realised that there were multiple partners on the market who were in fierce competition with each other. It affected the product and the partners would become dissatisfied and complain to us. Competition is vital to promote growth, but when it is too fierce it can harm the brand,” said the entrepreneur.`+

		support_by_liaa,

	review_4_name: "Robert Dlohi",
	review_4_company: "“PERUZA”",
	review_4_website: "https://www.peruza.com",
	review_4_designation: "Board Chairman of PERUZA (https://peruza.com)",
	review_4_excerpt: "Though PERUZA has developed and exported industrial equipment for the food sector for more than 30 years, only during the last 10 years has it adopted a systematic approach. And the results are quite impressive: currently 80% of its turnover is exported.",
	review_4_title:"More systematic exports during the last decade",
	review_4_text: 

	`<dd></dd>Though PERUZA has developed and exported industrial equipment for the food sector for more than 30 years, only during the last 10 years has it adopted a systematic approach. And the results are quite impressive: currently 80% of its turnover is exported.`+
	`<dd></dd>The company has several lines of business: development and manufacture of fish processing equipment, e-commerce, packaging logistics solutions, and robotization, automation, computer vision and AI. “Historically, the company produced equipment to process small fish. It allowed us to gain extensive experience to take the next step and start producing equipment for larger fish,” says Roberts Dlohi, the Chairman of the Board of PERUZA.`+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+roberts1+`"></div>`+
	`<dd></dd>In 2021, our turnover reached EUR 5.5 million, and the average share of exports was as high as 80%. France and Russia become two of the first export markets as early as 1993. In cooperation with French partners and a colleague from Astrakhan, a sturgeon roe production facility was developed. Supported by good feedback, the company also succeeded in implementing projects in other countries. During the first 15 years, we found new customers thanks to good recommendations from our clients.`+
	`<dd></dd><h3 class="post-subtitle">Focus on the new business reality</h3>`+
	`<dd></dd>Only during the last decade did PERUZA shift its focus onto export marketing activities. During this period of time, the company structure was radically changed, and that also applies to its vision.`+
	`<dd></dd><div class="quote-style">R. Dlohi is a strong believer in Einstein’s idea, “It would be unreasonable to do the same and expect a different result. If we want to reach the next level, we need a new mindset, approach, structure, and procedures. We want to grow, thus, we need a vision to understand the required change that would enable our success,” claims R. Dlohi.</div>`+
	`<dd></dd>The company has been able to change its focus from the old model when customers looked for someone to implement their idea to an active and focussed search for project opportunities.`+
	`<dd></dd>Currently, PERUZA exports to many countries, and export destinations keep changing. R. Dlohi claims that this is how their business works: customers build their assets that need to serve for years. As a result, customers who have succeeded in that buy new equipment only after many years. “We are aware of that and know that everything can be very different when we enter new markets: language, time zone, business and communication models, and raw materials. We understand that and realise that we need to keep learning new things,” explains R. Dlohi.`+
	`<dd></dd>We have exported to Canada, Japan, and the Philippines, but this year the top destination will be Iceland as we signed an export agreement worth EUR 4.3 million this summer. This amounts to 78% of our turnover in 2021. PERUZA actively exports to the Faroe Islands and also tries to penetrate the Scandinavian market, in particular Norway and Finland. This year, export will also be affected by the Russian war in Ukraine. In February, we planned to sign an export agreement worth EUR 2.5 million with a large Russian company, but due to the war it became impossible.`+
	`<dd></dd><h3 class="post-subtitle">Each new market should be carefully analysed</h3>`+
	`<dd></dd>“Currently, we actively develop our export markets with all available means and tools, e.g. by developing digital marketing and relying on the support and co-financing provided by the Latvian Investment and Development Agency (LIAA) for various activities, as well as the services of the export advisory company Gateway&Partners to attract new customers,” claims R. Dlohi.`+
	`<dd></dd>He emphasises that before entering new export markets, which product could be successful in specific countries should be assessed. The company bases its decisions on available information, previous experience and understanding of the markets to formulate an informed hypothesis about the next possible export destination. PERUZA analyses which markets are important and reachable during the respective period, and focusses on them. “However, customers able to pay for our products in the respective market is the most important factor,” adds R. Dlohi. In regards to salmon, one has to start in Norway and then proceed to Chile. We also assess which markets have the greatest potential for consignment logistics and process robotisation.`+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+roberts2+`"></div>`+
	`<dd></dd><h3 class="post-subtitle">Times and marketing strategies change</h3>`+
	`<dd></dd>Though in our case marketing and personal contact with customers during trade shows is very important, we also actively focus on digital marketing: online content and search output optimisation.`+
	`<dd></dd><div class="quote-style">“Gradually, we see the fruit of our labour. For example, we have started receiving requests on our website that were non-existent before,” claims R. Dlohi.</div>`+
	`<dd></dd>He underlines that all of these activities: trade shows, online presence and use of other marketing channels remind our customers that there is a trusted partner PERUZA with great products. “Nowadays, our team has a totally different approach than 10 years ago, and this development is necessary and logical for our growth,” concludes R. Dlohi.`+
	`<dd></dd><h3 class="post-subtitle">State aid helps in reducing costs</h3>`+
	`<dd></dd>PERUZA has cooperated with LIAA during several projects. Some time ago this agency administered product development projects that the company used. PERUZA has developed several new equipment concepts and prototypes for the processing of fish. Some products have become really successful such as the de-gutting line for small fish that is able to clean 1000 sprats or Baltic herrings per minute. However, some products had a great start that was followed by the discovery of significant limitations resulting in suspended sales. R. Dlohi explains that abilities and limitations of new equipment often are discovered only after some time and larger processing quantities. However, the failures have also helped in developing new and better equipment. “LIAA support was valuable as it reduced the risk of error, but errors lead to development,” emphasises the entrepreneur.`+
	`<dd></dd>Currently, we cooperate with LIAA in an international competitiveness project to participate in various trade fairs and study visits. Seafood Expo Global/Seafood Processing Global in Barcelona, Spain is one of the largest and most important fishery trade shows in the world. We have participated in it for 15 years already.`+
	`<dd></dd><div class="quote-style">To participate in such fairs, one has to pay approx. EUR 40-50,000; however, thanks to LIAA, we can reduce these costs. This autumn, we used the opportunity to participate in the visit of Ilze Indriksone, the Minister of Economics, in Japan organised by LIAA. The agency knew that Japan was one of our export destinations, as a result, we received information about this visit in due time and were able to register for it,” explains R. Dlohi.</div>`+
	`<dd></dd>PERUZA also cooperates with several competence centres to develop new products. For example, this allowed us to develop a quince processing and drying line to produce candied fruit; a home care bed for bed-ridden patients controlled by a computer, and computer vision and AI solutions for waste sorting. “When we develop something new, we have to be sure that it will work, and in this regard competence centres provide the best support in Latvia. While for projects that require large financing we cooperate with Altum as a source of financing,” states R. Dlohi.`+
	`<dd></dd>He also highlights the Export Innovation Award organised by LIAA and the Ministry of Economics, the Latvian Association of Exporters the Red Jackets and Altum for many years. “It allows us to commend those that have succeeded and that we can be proud of locally and internationally. It is always nice when someone claps you on the shoulder and recognises your achievements,” says R. Dlohi. In 2021, PERUZA received the 2nd prize in the Knowledge-based Innovation category.`+
	`<dd></dd><h3 class="post-subtitle">Like in football</h3>`+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+roberts3+`"></div>`+
	`<dd></dd>R.Dlohi believes that Latvian exports develop like in football that has a system to nurture champions: everything starts with courtyard teams, followed by school teams, semi-professional youth teams, competitions, and the Champions League. “The more professional the athlete, the more targeted and specific the assistance, tools and instruments. At the beginning, there is an opportunity to play on a good pitch, but later there are coaches, massage therapy, diet, and training camps. This is how the best candidates are selected and promoted. In this system, everyone knows what and how to proceed to develop and grow to become global champions,” he explains. R. Dlohi believes that Latvian companies with varied maturity levels have an opportunity to receive the appropriate tools and instruments that they may require. Public bodies enable everyone to become a champion if you have the motivation and willingness to work hard. “I do not think that there is too much red tape. Bureaucracy is needed to prevent misappropriation,” adds the entrepreneur.`+
	
support_by_liaa_en

	,
	review_5_name: "Toms Auškāps",
	review_5_company:"JSC “BALTICOVO”",
	review_5_website: "http://www.balticovo.lv",
	review_5_designation: "JSC “BALTICOVO” (www.balticovo.lv), Communications and Development Director, Board Member",
	review_5_excerpt: "BALTICOVO is the largest producer of eggs and egg products in Northern Europe. The company employs more than 300 people, who raise poultry and produce eggs, sort and package eggs, as well as produce various egg products on a daily basis.",
	review_5_title:"Latvian egg products are exported as far as New Zealand",
	review_5_text:
	`<dd></dd>The largest egg and egg product producer AS Balticovo’s hens lay about 700 million eggs a year. 70% are exported to more than 20 countries worldwide, including New Zealand.`+
	`<dd></dd>In 2021, Balticovo turnover reached 62.4 million euro. Talking about future plans of the company, Toms Auškāps, Balticovo Communication and Development Director, noted that the company sold its products in the internal EU market and its competitive edge depended on how successfully the company was able to use the exceptional opportunities of the cornerstone of the European Union, i.e. free movement of goods, in the common market.`+
	`<dd></dd><div class="quote-style">‘If we keep developing and growing, it will be thanks to exports,’ stated T. Auškāps with confidence. </div>`+
	`<dd></dd>He believes that every company wishing to export its products needs to understand the term ‘export capability’ and whether the company has time, resources and quality products for foreign markets. ‘It is very important to do this groundwork by thinking about and analysing the situation very carefully. Export is a targeted and intended effort which goes hand in hand with market research. It is certainly best to invest more time in target market research before starting exports. It will help to avoid unnecessary failures and tying up funds and to protect investments. Careful preparation is needed so as not to become a victim or an object of ridicule,’ warned T. Auškāps.`+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+toms1+`"></div>`+
	`<dd></dd><h3 class="post-subtitle">The largest export market is the Nordic countries</h3>`+
	`<dd></dd>Balticovo history began 50 years ago. The first steps into foreign markets were taken in the nearest neighbouring countries, although, as we know today, such exports only really developed after 2010. ‘This was a great time of development for us when egg processing facilities for making liquid eggs and boiled egg products were established. With the development of these product ranges, we ventured outside our domestic Latvian market into new markets in Sweden, Denmark and Czechia,’ said T. Auškāps. These markets are also the company’s main export destinations at the moment. Balticovo also exports its products to Lithuania and Estonia, but views them more like a domestic market. They also export to Poland, Scandinavia, Germany and other countries.`+
	`<dd></dd><div class="quote-style">‘We are a world level player. We cannot fall behind our foreign competitors. If in some countries support programmes are more generous, local players get the better of us there. </div>`+
	`<dd></dd>For instance, in the German and Czech markets, we compete with the Poles. When Polish companies get any subsidies or VAT reliefs, we instantly feel it. That is why it would be very good to have an organisation in Latvia to monitor the availability of any support programmes in other countries. It would help acquire new information to prevent us falling behind foreign companies and to remain competitive in the international market. It is especially important for large Latvian exporters because they are the most sensitive to any shifts in the market,’ said T. Auškāps.`+
	`<dd></dd>He also highlighted that other countries took very good care of their companies by providing good conditions for their stable existence and operations within their countries. This helps protect the market from ‘intruders’. ‘Outside our domestic market, Balticovo competitors almost always wish we left their markets. At the same time, our customers, i.e. retail chains, wholesalers and distributors, expect lower prices, the same level of service and higher quality from us than those of their local producers. This is how harsh the context of our product sales in the common EU market is. So it is important to find strong partners ready to work together in the long term and face any side effects created by soft state protectionism in each country,’ said T. Auškāps.`+
	`<dd></dd><h3 class="post-subtitle">Getting established in a new market may require as long as four years</h3>`+
	`<dd></dd>Balticovo exports its products to 20 countries. Getting established in a new market is a relatively slow and lengthy process and three or four years or even more time may pass from the initial idea to the first exported products. This lengthy and complicated process is exactly why any Balticovo decision to enter new markets is based on a thorough market analysis. We take into account both our previous market experience and detailed market research based on information about the self-sufficiency of the market and the how many eggs and egg products are consumed in the country.`+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+toms2+`"></div>`+
	`<dd></dd>‘New markets in Europe certainly require less time, but outside Europe, it is a longer and more complicated process including working with the food and veterinary authorities of the target countries, acquiring food certificates, auditing, aligning requirements, etc.’ explains T. Auškāps. For example, when we were planning exports to Hong Kong, our production facility audit took place four years after the first steps towards developing that market. In contrast, exporting to the New Zealand market ‘only’ took 12 months. New Zealand has strict import controls of various goods based on a number of criteria, such as ‘community protection’, ozone layer protection, toxic substance control and consumer protection. It a very extensive and legitimate set of requirements to protect New Zealand market and contain any potential threats to the local economy. For these reasons, New Zealand has a very strict quarantine regime on imported products in place to protect its agricultural and forestry sector from contamination from pests or diseases.`+
	`<dd></dd><div class="quote-style">‘We succeeded in the New Zealand market, mainly because we found a competent, experienced and, most importantly, motivated importer, which was able to coordinate documents between the responsible body in New Zealand and the Latvian Food and Veterinary Service,’ revealed T. Auškāps.</div>`+
	`<dd></dd>He believes that Latvia should continue focusing on exports outside the EU and investment in other markets, such as Australia, New Zealand, Japan, Korea and Southeast Asia. Although getting started in these geographically remote locations requires a lot of time and resources, they also offer great opportunities for growth. First consultations and other necessary support to get going in those markets can be provided by the international economic representative offices of the Latvian Investment and Development Agency. Outside Europe, their representative offices are in the US, Canada, Japan, UAE, South Korea and China and next year, another one will open in Australia.`+
	`<dd></dd><h3 class="post-subtitle">Any available support should be used</h3>`+
	`<dd></dd>T. Auškāps highly recommends using all the available support options.  Especially if you are a small or a medium-sized company. ‘These opportunities should be used well. You should consult experienced exporters and seek their advice. Find a mentor among them. Don’t be afraid to ask questions, just make sure that the questions are the right ones. I don’t know a single large company which would not be willing to help with advice or share their experience,’ he assured.`+
	`<dd></dd>Balticovo board member, T. Auškāps, knows that as far as investments in export are concerned, no prospective return or future success is guaranteed. ‘Export is not a fairy tale, because hard work does not necessarily bring results. It requires a great deal of patience. The farther away your export destination is, the more complicated it gets. The more factors affect the process, the more variables there are. Long distance also complicates things as the company does not really have any hands-on control. This is exactly why it is necessary to use the tools and support mechanisms provided both by LIAA and other organisations for the development of export, i.e. direct networking events, trade fairs and expos and personal meetings. Any support, be it co-financing of travel expenses, trade show fees or accommodation, is very important,’ said T. Auškāps.`+
	`<dd></dd>When Balticovo became a large business, its support opportunities dwindled. However, T. Auškāps believes that until 2016, the Latvian Investment and Development Agency had been an indispensable partner in facilitating the company’s export business. It provided significant financial support to participate in various industry fairs abroad. ‘Trade fairs are one of the essential platforms to maintain relationship with existing customers, to meet with company representatives who are not yet our customers but we have negotiations under way, to present our new products and to learn about the competitors’ news and product ranges. It is a great place to meet foreign customers and see which way the market is going. Every other year, we participate in the SIAL trade show in Paris or Anuga Cologne, as well as Gulfood in Dubai,’ said T. Auškāps.`+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+toms3+`"></div>`+

	support_by_liaa,

	review_6_name: "Jānis Kūlbārdis",
	review_6_company:"“AZERON”",
	review_6_website: "http://www.azeron.eu",
	review_6_designation: "Co-founder of “AZERON” (www.azeron.eu)",
	review_6_excerpt: "AZERON is a technology company which deals with the production of globally innovative and unique video game controllers. Such a controller is the dream of many gamers, because it makes playing video games much easier by replacing the traditional keyboard.",
	review_6_title:"Close to 100% of turnover exported without any foreign business trips",
	review_6_text:
	`<dd></dd>Except manufacture of various devices, the business of gaming controllers Azeron is 100% digital: the customers get to know our products online and order them there. Due to this, Azeron, a company that has been in the market for just a few years and exports almost 100% of its 2.5 million EUR turnover, has never had any foreign business trips to meet potential customers. `+
	`<dd></dd><div class="quote-style">“The key to our success is our outstanding products and an excellent team. If either were missing, we would probably not be where we are now. Furthermore, it is important not to be afraid and to look for what you need,” says Jānis Kūlbārdis, the co-founder of Azeron. </div> `+
	`<dd></dd><h3 class="post-subtitle">Helps in playing computer games </h3> `+
	`<dd></dd>Azeron produces gaming controllers that facilitate gaming and replace regular keyboards: though they look like a mouse, they actually function as a keyboard. The development of the prototype started in 2012 in a small house in the countryside in the Ventspils region. Over the course of time, the necessary professionals – a 3D modeller, a programmer  and a  production and business process manager – joined Imants Daigins, who had come up with the initial idea, to transform the idea into a unique and visually appealing product. “To publicise this product, we reached out to several influencers, until one of them: YouTube content creator Beaks that was popular with gamers responded. At that time, he had half a million followers. When Beaks received the prototype, he published his review on his YouTube account. Within one month, this video had been viewed by four million people, and we started receiving hundreds of letters from potential customers each day. Thus, we founded a company and started to develop production capacities to satisfy the growing demand,” tells J. Kūlbārdis. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janis1+`"></div>`+
	`<dd></dd><h3 class="post-subtitle">Incubator helps to develop for less </h3> `+
	`<dd></dd>Even before the founding of Azeron, we submitted documents for participation in the pre-incubation programme of the LIAA Ventspils Business Incubator. When the company was founded, we joined the incubator, and we are still a part of it. “We are very satisfied and grateful for the possibility to develop the company in the incubator. It is possible that we would have built the company without an incubator, but the support that we have received so far has allowed us to develop for much less and much more efficiently,” claims J. Kūlbārdis. `+
	`<dd></dd><div class="quote-style">In the incubator, all members have access to training and advice, as well as subsidies and co-financing for various purposes, e.g. accounting, rent, marketing, etc. He states that the incubator environment is very friendly, welcoming and flexible.  </div>  `+
	`<dd></dd>Kūlbārdis remembers a case when he called the head of the incubator at 7 p.m. to ask if she knew someone who could help with a specific issue. She needed just little a while to find the necessary contacts in the LIAA network. “It is hard to tell in which stage of development we would have been without the incubator, but one thing is clear: we would not have been able to invest so much in our development just by ourselves. Every euro that we have invested has generated impressive returns,” underlines J. Kūlbārdis. `+
	`<dd></dd>He believes that an individual approach to each participant and their needs would be a valuable improvement in the incubator. For example, in the incubator, Azeron can receive support for IT services, accounting, marketing services, consultancy and other services if provided by external providers. However, if any of these functions are performed internally, these costs are not covered. “It can be interpreted as lack of support to hire employees and create new jobs. So far, we have relied on an outsourced accounting company for accounting services during the programme. We just started doing the accounting internally, and according to our initial estimates, our overhead has decreased by 50% that equals the amount of the LIAA grants. Currently, we are working on the automatisation of the accounting system; once it is in place, we will become much more productive. In the end, it turns out that it is less expensive to do it ourselves without any support,” explains J. Kūlbārdis. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janis2+`"></div>`+
	`<dd></dd><h3 class="post-subtitle">60% of exports to the US </h3> `+
	`<dd></dd>Manufacturing and administration of Azeron takes place from Ventspils, while the sales take place on its website and on the Amazon platform. The controller can be purchased by any person anywhere in the world where FedEx and DHL can deliver. “We export almost 100% of our products, but no member of our team has ever been on a business trip outside Latvia,” reveals J. Kūlbārdis. `+
	`<dd></dd>Our top export destinations are the USA, Canada, Great Britain, France, Germany, Australia and Japan. Just 0.1% of our products stay in the Latvian market. Historically, the USA has been the largest market, and it still maintains this position with 60% of the turnover. `+
	`<dd></dd>The company has significantly grown in the Japanese market: in just a year, the share of turnover has increased from 2–3% to 10%. According to J. Kūlbārdis, this growth can be attributed to several factors. Azeron cooperates with the largest global manufacturer of mechanical switches, Omron, based in Japan. This manufacturer has written and talked about the Latvian partner in its channels. Also, information about Azeron was available during the Latvian Days organised by LIAA in Tokyo, and we received positive feedback from customers. “We also saw a rise in sales when we started trading on the Japanese Amazon site. People started to talk and write about us and, accordingly, to buy more controllers. Generally, our customers started to take an interest in us very organically and have never stopped,” explains J. Kūlbārdis. `+
	`<dd></dd><div class="quote-style">In export markets, our company does not have any local partners, wholesalers or retailers. “We do not need them. From the very beginning, our Marketing Division has focussed on promoting our products and finding new customers online. We spend a large share of our budget on marketing: we have digital marketing, cooperation with influencers and even a Discord server in the gaming environment, where thousands of people can speak with each other,” tells J. Kūlbārdis. </div> `+
	`<dd></dd>The company also does not need to participate in trade fairs. “First, our customers can easily be reached online. Second, we can achieve much better sales results by investing the same amount in digital marketing,” believes J. Kūlbārdis. `+
	`<dd></dd><h3 class="post-subtitle">Longer and more expensive deliveries </h3> `+
	`<dd></dd>Many companies have been affected by the Russian war in Ukraine. J. Kūlbārdis claims that Azeron sales results have barely been affected by the closed markets and that the war has had almost no effect on the sales results. However, it has affected deliveries. A large share of raw materials come from Asia by air. Currently, a flight from Hing Kong to Paris-CDG takes five hours more than before. “It means that, first, the delivery takes more time, and second, that it is more expensive: the flight is longer and thus incurs higher fuel costs. The delivery became more expensive at the beginning of Covid-19 pandemic due to tighter restrictions and requirements in various countries. However, after the start of the war, the prices have grown even more, eventually affecting the customers,” explains J. Kūlbārdis.`+
	`<dd></dd>This is one of the circumstances that make him recommend choosing  partners carefully, making sure that they are willing to grow together instead of those that just issue invoices. For example, the Latvian team of FedEx noticed Azeron’s potential early on and offered the same price as they would to large customers. FedEx also offered free advice on various markets, provided useful contacts and even helped to develop safe packaging, while other carriers did not even want to speak with us. Such partners are difficult to find. However, it is very important to try to do that,” advises J. Kūlbārdis. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janis3+`"></div>`+

	support_by_liaa,

	review_7_name: "Kristīne Grapmane",
	review_7_company:"SIA „STENDERS”",
	review_7_website: "http://www.stenders-cosmetics.com",
	review_7_designation: "SIA „STENDERS” (www.stenders-cosmetics.com), Chief Operating Officer",
	review_7_excerpt: `The last few years have proven that exports should be developed in multiple geographical directions. As a result, Stenders, the producer of bath rituals products, is currently focused on strengthening its current export markets and entering new markets in Europe, the US, Canada and even Australia. `,
	review_7_title:"Stenders bath rituals enjoyed even in Australia",
	review_7_text:

`<dd></dd>The last few years have proven that exports should be developed in multiple geographical directions. As a result, Stenders, the producer of bath rituals products, is currently focused on strengthening its current export markets and entering new markets in Europe, the US, Canada and even Australia. `+
`<dd></dd><div class="quote-style">“Exporting will succeed if you understand your values and the unique features of your products. With this knowledge, you just need to forge ahead as each product has its niche and market. Latvia is a very good market to test products. However, to develop further, you need to export. It is worth using all export support mechanisms that are currently available in a smart and well-considered way. Growth lies abroad,” underlines Kristīne Grapmane, the Executive Director of Stenders. </div>`+
`<dd></dd><h3 class="post-subtitle">Alone or with franchise partners </h3>`+
`<dd></dd>In export markets, cooperation with Stenders can be initiated by two parties: either the company can develop a new market alone, or a partner from a specific country can approach it. Entry into a new market is usually preceded by a thorough market study, analyses of the economic situation, product niche and competitors. “There are competitors that we monitor more closely, and depending on their activities, we try to decide which markets are the most promising,” explains K. Grapmane. `+
`<dd></dd><div class="quote-style">Furthermore, it is important to study the structure of sales channels, i.e. whether it is better to enter a new market as a wholesaler or to start a franchise. “Some markets in the cosmetics sector are more decisive, e.g. Germany. If you succeed in Germany, it will most probably be easier to launch your products in France, Switzerland and elsewhere,” she says. </div>`+
`<dd></dd>Both an internal team and external contractors, such as the export advisory company Gateway&Partners, study markets and find new partners for the company. “When we find new partners, we gain new resources, capacities and insights,” tells K. Grapmane. `+
`<dd></dd>To develop its exports, Stenders participates in international exhibitions. This year, the company has made the decision to participate in one of the largest global cosmetics fairs, Cosmoprof 2023, that takes place in Bologna, Italy. `+
`<dd></dd><h3 class="post-subtitle">70% of turnover exported</h3>`+
`<dd></dd>70% of Stenders turnover is exported. “China still is our largest export market. However, due to the very strict Covid-19 safeguards and restrictions, this market has recently shrunk. Since the start of the pandemic, China has enforced its zero-tolerance policy: no case of infection is acceptable; the cities are still periodically placed under lockdown, and there are very strict trade and import restrictions. These factors increase uncertainty in the market considerably,” explains K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristine1+`"></div>`+
`<dd></dd>Stenders has ceased its cooperation with Russian partners as a result of the Russian war in Ukraine. To mitigate geopolitical risks, this year we have focussed on development in other markets. In the Middle East, good cooperation with our Jordanian franchise partner has resulted in new stores and wholesale channels. Iraq and Qatar have also joined our export markets. This year, Stenders has also opened its first store in Australia. In Europe, we have just made the first few steps: our franchise partner in the Netherlands has made good progress, and work is ongoing in Germany. “New markets always require large investments, research, patience and willingness to take risks. To reach your targets faster, it is important to define priorities and focus on a particular market,” claims K. Grapmane. `+
`<dd></dd><h3 class="post-subtitle">Challenge: find and develop human resources </h3>`+
`<dd></dd>Besides geopolitical risks, human resources are also a challenge. “For us to export, our employees should be well educated and be able to develop and follow changes and the latest trends. It is a challenge to find and retain real professionals,” underlines K. Grapmane. `+
`<dd></dd>To stimulate employee learning and development, in 2022 the company has reviewed its human resources development strategy that is a part of the sustainability and welfare programme of Stenders. Currently, the company is using the LIAA Mini MBA training programme “Innovation Management”, as well as considering other opportunities to develop our company according to agile principles. “Development of our employees is an important criterion, thus, we will continue to use the available support to develop human resources,” add K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristine2+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Large companies also require support </h3>`+
`<dd></dd>Small and medium-sized companies have access to an extensive range of instruments. While large producers that have more than 250 employees, such as Stenders, have very limited access to support. “New markets always require large investments, research, patience and willingness to take risks. If state support programmes for export were available, it could be developed even faster. Currently, only companies are developing exports depending on their capacity. Thus, we actively support the idea of providing more support and a larger export toolset to larger companies,” states K. Grapmane. `+
`<dd></dd>As to state aid, the process is quite bureaucratic, e.g. preparing all the documentation required for LIAA training. “We understand that due to our corporate structure, the bureaucratic process is longer and more complicated. Though it is needed, it would make sense to complete it only once so that during future projects, this procedure would take less time and be clearer. Thus, the activities of the Latvian Exporters Association to develop the Green Corridor are really important as they would result in shorter processes in areas important to exporters,” adds K. Grapmane. `+
`<dd></dd>Still, over the course of time, cooperation with public bodies has improved. “The officials are more forthcoming than before, and they are more eager to help. LIAA really tries to understand problems, help in solving them and provide comments as needed. I know that I have the necessary contacts, and I can always call and will be helped. I really appreciate the assistance provided by LIAA: advice, explanations and situational assessments,” says K. Grapmane. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristine3+`"></div>`+

	support_by_liaa,

	review_8_name: "Jānis Ošlejs",
	review_8_company:"SIA „Primekss”",
	review_8_website: "http://www.primekss.com",
	review_8_designation: "SIA „Primekss” (www.primekss.com), CEO",
	review_8_excerpt: `According to Jānis Ošlejs, CEO of the industrial concrete flooring contractor Primekss, the key to successful exports is developing your business niche where your products have a competitive advantage. This is the approach Primekss is using and it expects to reach sales of EUR 90 million, 95% of which are exports, in 2022.`,
	review_8_title:"In exports, finding your niche is essential",
	review_8_text:

`<dd></dd>According to Jānis Ošlejs, CEO of the industrial concrete flooring contractor Primekss, the key to successful exports is developing your business niche where your products have a competitive advantage. This is the approach Primekss is using and it expects to reach sales of EUR 90 million, 95% of which are exports, in 2022.`+
`<dd></dd><div class="quote-style">Based on his own experience, Ošlejs recommends thoughtfully developing products for customers who will be most interested in your offer, since they are the ones who will be willing to buy or prepared to pay more.</div>`+
`<dd></dd>This, in turn, opens up broader development possibilities for the company. That is why the ability to focus and not to spread yourself too thin over multiple export markets with too many customers is important.`+
`<dd></dd><h3 class="post-subtitle">From Scandinavia to India</h3>`+
`<dd></dd>“We realised fairly quickly that the Latvian market is small and growth opportunities are limited. So back in 2002, we turned to exports. We started with our neighbouring countries, Lithuania and Estonia,” said Ošlejs. Later, when Latvia joined the EU and many opportunities opened in the single European market, the company began exporting to Sweden. Then the rest of Scandinavia and other countries followed gradually. Currently, Scandinavia is home to our largest export markets, but we also export to Asia, India, Israel and other European counties, including the UK. The USA and even South Africa are also important export destinations. Before Russia’s invasion of Ukraine, we were also working with Russia and Belarus, but now these markets are closed to us.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janisO1+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Follow your customers</h3>`+
`<dd></dd>Before entering a new export market, Primekss analyses the competitiveness of its products there. Of course, it is easier to start with neighbouring markets that are easy to travel to and where cultural differences are minor. Exporting from Latvia to Lithuania is far easier than to Australia. However, the most important aspects are competitiveness and following customer needs.`+
`<dd></dd><div class="quote-style">“Our strategy is to follow our customers. We have many large customers, such as IKEA, DHL and Volvo. Where these companies go, we go,” explained Ošlejs.</div>`+
`<dd></dd>Primekss consciously chose to develop exports to the US, which is a large market with great growth opportunities. “We realised that it was definitely worth going there and we are actively working on that destination,” said Ošlejs.`+
`<dd></dd>Scandinavia is close enough geographically and a reachable market for the company to be able to do concrete work for industrial flooring, building frames and foundations using its own resources, but in the US it works with large American concrete work contractors who sell and apply Primekss technologies there.`+
`<dd></dd><h3 class="post-subtitle">It is high time to invest in development</h3>`+
`<dd></dd>“It’s no secret that Latvian export volume is critically low. In comparison with our neighbours, both processing industry and service sector exports are low,” said Ošlejs. He explained that, historically, the amount of bank loans and other industry financing has been significantly lower than in neighbouring countries while production costs were higher. Therefore, local industrial companies have lower income and their development is slower.`+
`<dd></dd>The entrepreneur believes that Latvia’s big mistake was focusing too much on transit and bank sector development at the expense of the development of industrial and service export companies. “Now is the time to improve the situation and this is the task for today’s government. Otherwise, we will slide into stagnation,” said Ošlejs, adding that exporting companies are the ones who can achieve unlimited sales volume, i.e. unlimited income which can be used to pay high salaries and increase the number of well-paid jobs. “That’s why today, all countries which have used exports as the foundation of their development are wealthy and prosperous, while countries that support those working in the local market quickly become an oligarch’s paradise because in these countries there is money to be made just by liaising with politicians. With exports, a product is either good or not. There are no schemes or corruption there,” said Ošlejs.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janisO2+`"></div>`+
`<dd></dd>He thinks that, in order to catch up with Lithuania and Estonia in terms of living standards, investments in the development of exporting companies should be much faster and much more active. Bank loans, capital and support from European funds available to businesses for investment in equipment and innovation are not sufficient. “To achieve growth, we need to increase available financing much, much faster. So I urge the government to increase the funding available to exporters at least threefold. It is the bare minimum necessary to overtake Lithuania and Estonia,” said Ošlejs.`+
`<dd></dd><h3 class="post-subtitle">Fluctuations bring growth opportunities</h3>`+
`<dd></dd>“Today, the geopolitical situation and environment are highly volatile. For example, many Latvian businesses have been buying raw materials in Russia at good prices, but these are not available anymore. The existing alternatives are more expensive so production costs go up and profits go down. To continue production, existing production processes should be changed. This means buying new equipment and developing new, better selling products requiring investment in science and development. Regrouping requires investment,” said Ošlejs.`+
`<dd></dd><div class="quote-style">In changing times, manufacturers and service providers face many challenges, but at the same time these are also opportunities. The businessman is confident that every time the economy is volatile, it creates great opportunities for rapid development.</div>`+
`<dd></dd>“In every crisis, there are losers, but there are also winners – those who make money from a crisis. For example, now, energy prices are very high, so many households are big losers while energy producers and traders are the winners. Small shops go bust, shop displays in Rīga are empty, but major online retailer sales are growing fast. It is difficult to make products in China and impossible in Russia, so Europe has become the place to do it,” said Ošlejs. He believes that businesses and state leaders should ask themselves: How has the world changed? Customers should be the ones benefiting from the change and the strategic support of the state should be focused on that. During both the Covid-19 pandemic and Russian crisis, Primekss grew and developed rapidly by focusing specifically on its growing market segments,” said Ošlejs.`+
`<dd></dd><h3 class="post-subtitle">State support is useful</h3>`+
`<dd></dd>“Throughout our existence, we have received a lot of support from various Latvian organisations to get where we are now, to be able to export to almost every corner of the world, to become one of the top 20 large companies with the highest average salary and to increase the number of employees and pay taxes,” said Ošlejs.`+
`<dd></dd>The company has grown using various state support instruments, such as Latvian Investment and Development Agency (LIAA) support for technology development and the purchase of equipment, Altum Capital Fund support, returning Altum-supported venture capital investments with profit and state support of science and technological development within the Smart Material Competence Centre. State support also helped Primekss to participate in the world’s largest construction industry exhibition, Big 5, in Dubai and various construction exhibitions and fairs in Scandinavia, Germany, USA, etc. “Co-funding of participation in fairs is a very good tool in the support arsenal. We use it a lot,” said Ošlejs. He was also part of several trade missions organised by LIAA. Experience shows that it is especially valuable in markets where the executive branch of the state has more say in things.`+
`<dd></dd>“The work of LIAA coordinators abroad should definitely be praised. We have successfully used their support to develop our export markets. We are very grateful to LIAA representative offices for their unfailing detailed support in various regions. For example, in UAE, LIAA representative Inga Ulmane helped us with communication to deal with local bureaucracy issues. State support is also very good and useful when it comes to visas. It is a great example of how the Latvian state can work very well and support local manufacturers and exporters. I think that LIAA CEO Kaspars Rožkalns and his team do very good and important work,” added Ošlejs.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+janisO3+`"></div>`+

	support_by_liaa,

	review_9_name: "Katrīna Allikas",
	review_9_company:"SIA „Sonarworks”",
	review_9_website: "http://www.sonarworks.com",
	review_9_designation: "SIA „Sonarworks” (www.sonarworks.com), Chief Marketing Officer",
	review_9_excerpt: `Today, products of Sonarworks, a Latvian digital sound calibration start-up, are available all over the world, except Russia and Belarus. Even on the islands of Fiji, musicians can buy Sonarworks products from the company’s website or distributors and have them delivered there. `,
	review_9_title:"Sonarworks technologies are used by Grammy sound engineers worldwide",
	review_9_text:

`<dd></dd>Today, products of Sonarworks, a Latvian digital sound calibration start-up, are available all over the world, except Russia and Belarus. Even on the islands of Fiji, musicians can buy Sonarworks products from the company’s website or distributors and have them delivered there. `+
`<dd></dd>Katrīna Allikas, chief marketing officer at Sonarworks, believes that the key to success in exports is the products. The company has two products: one is SoundID Reference", which is a sound and speaker calibration software enabling audio engineers and producers to achieve the necessary reference sound. It is used by more than 100,000 recording studios worldwide, including 50 Grammy-winning sound engineers who produce and mix music for global stars such as Lady Gaga, Coldplay, Madonna, Adele, etc. The company has been successfully working in this area for ten years, but in the last three years it has turned its attention to the listener market, licensing its SoundID technology to other companies in America and China enabling them to improve their products. This year, at the world’s largest technology fair CES (Consumer Electronics Show) in Las Vegas, Sonarworks  received an award for its second generation SoundID and for integration with Grell earbuds. The company had similar achievements last year when its SoundID solution received an innovation award as an independent technology, while HELM Audio earbud production won an award for SoundID integration. `+
`<dd></dd><div class="quote-style">From its very beginnings, the company’s business has been based on clear principles that the product should solve a significant task in the industry and be user friendly. “We have put a lot of work into the product design and usability improvement because we understand that our product should help creators of music to get to their creative work more quickly”, she stated.  </div>`+
`<dd></dd>Streamlined logistics and continuous product improvement are as important as the product itself to ensure swift payments, quick deliveries and a strong team which can help customers with any questions or doubts. “And, above all, the team. We invested a lot of time to recruit new colleagues to our team. It is in line with our ambition and the company’s values, as we believe that we can achieve our goals only as a united team”, said Ms Allikas. `+
`<dd></dd><h3 class="post-subtitle">The largest market is the US </h3>`+
`<dd></dd>Exports made up 100% of Sonarworks EUR 3.85 million turnover in 2021. The largest market is the US with 40% of sales. “America is the largest music production market. Since early days of the company, we have been going there to meet with Grammy-winning audio engineers, and we understand how our product addresses a significant problem in the industry”, explained K. Allikas. Over there, music production has been developing more quickly and at a larger scale than Latvia, so our customers there have a better understanding of the problems Sonarworks can solve and its true value. She admits that in Latvia, things haven’t been that easy, as the local market is very limited and does not fully understand the benefits of calibration. `+
`<dd></dd><div class="quote-style">“We have attained a good understanding of what our largest markets are, and today, we are more focused on raising awareness of our product in those countries”, said Ms. Allikas. </div>`+
`<dd></dd>After Russia’s attack on Ukraine, Sonarworks stopped all sales and deliveries to Russia and Belarus, including cutting ties with suppliers continuing to work with Russia. “We are continuing to deliver to Ukraine, only now we’ve had to switch from FedEx to Latvijas Pasts. It has increased delivery time, but the main thing is that we can provide the service and continue supporting the Ukrainian economy,” said K. Allikas.`+
`<dd></dd><h3 class="post-subtitle">First, market potential is analysed  </h3>`+
`<dd></dd>When considering entering new markets, Sonarworks is mainly guided by the market’s potential and, of course, its size and paying ability. The company relies on the information available through Google Analytics, product data and user interviews. It buys targeted market research and requests data from its distribution partners. Ms Allikas explained that the professional audio market is difficult to grasp because there is no publicly available research on the market size and potential.`+
`<dd></dd><div class="quote-style">“In our experience, every company in this industry tries to find its own approach to assess their market potential. We get help from our distribution partners who share important information about regional markets and their development”, she revealed.</div>`+
`<dd></dd>The main indices to look at and consider before starting to export to a new market are: a specific product’s potential in that market; the ability to pay; and competition. In addition, country-specific aspects, such as payment options, language barrier or company’s ability to work with local influencers should be taken into account. For example, in China, social media platforms are completely different, and working there requires significant resources and strategy.`+
`<dd></dd><h3 class="post-subtitle">Use support of colleagues and government </h3>`+
`<dd></dd>K. Allikas recommends any company considering going into exports to do their homework carefully. She recommends applying to organisations with relevant experience, such as the Latvian Investments and Development Agency, the Latvian Exporters Association “The Red Jackets”, the Ministry of Economics, the Ministry of Foreign Affairs, the European business support network in Latvia, Altum, etc. It is also important to ask other companies in the industry for advice. “Latvian companies are doing well in external markets, so it wouldn’t be difficult to find companies in the industry that could share their experience. Latvia’s wonderful advantage is its size and close ties in the business environment”, noted K. Allikas adding that both information and financial support is available from government and private companies and foundations. That is why she recommends taking time to study all the options. “Latvian society is very responsive; you just need to define your goal and the necessary support clearly”, she said. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+katrina1+`"></div>`+
`<dd></dd>Sonarworks is financed by venture capital so both the investor support and LIAA and Altum financing were important. “Every year, we try to participate in at least a couple of industry events to meet our users and hear what they think is important for further development of our product, to meet with our distribution partners and understand what kind of support they need to expand our regional business and to learn from the market to understand trends, innovations and how our product can be a part of the future of the industry. LIAA financing to cover costs of visiting fairs has been especially important”, K. Allikas said. `+
`<dd></dd><h3 class="post-subtitle">Relations are the precondition of development </h3>`+
`<dd></dd><div class="quote-style">“Working in a start-up, you have to overcome one obstacle after another. Initially, one of the biggest challenges was to build a distribution network and find reliable partners who understood the market better than we did”, said K. Allikas. These challenges were overcome through expanding the team and building connections, as well as going to America to meet partners and get to know our target audience in order to understand the best way to present the product. </div>`+
`<dd></dd>“Business development in any market is continuous work because in the era of digital development target audience changes. Therefore, not to lose your place in the market, it is important to understand its needs and the place of your products in its development cycle”, noted K. Allikas. You should also keep in mind that your partners’ employees responsible for your product promotion on the market may change, so visiting industry events and partners and training and education are very important. During the Covid-19 pandemic, Sonarworks experienced a drop in sales due to employee turnover in its partner companies, which meant that all the work had to be restarted almost from scratch. “So we understand that building and strengthening ties is one of the fundamental conditions of successful business development”, said K. Allikas. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+katrina2+`"></div>`+

	support_by_liaa,

	review_10_name: "Romāns Sidorovs",
	review_10_company:"SIA „GroGlass”",
	review_10_website: "http://www.groglass.com",
	review_10_designation: "SIA „GroGlass” (www.groglass.com), member of the board",
	review_10_excerpt: `Groglass is one of the few companies in the world making glass with anti-reflective coating which is used for framing and preserving works of art, as well as for numerous other technical applications. The technology developed by the company can also ensure 99% UV protection and less than 1% light reflection, achieving exceptional picture clarity. `,
	review_10_title:"Latvia can make transparent glass",
	review_10_text:

`<dd></dd>Groglass is one of the few companies in the world making glass with anti-reflective coating which is used for framing and preserving works of art, as well as for numerous other technical applications. The technology developed by the company can also ensure 99% UV protection and less than 1% light reflection, achieving exceptional picture clarity. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans1+`"></div>`+
`<dd></dd>The beginnings of the nanotechnology company Groglass are connected with the Latvian research centre Sidrabe, which invented a vacuum spraying technology for glass. Sidrabe investors, which after the collapse of the Soviet Union began investing in various technology and science intensive companies, decided to develop and commercialise the promising technology. So Groglass was founded as a subsidiary company. “There is a piece of equipment from Sidrabe at our production facility with a ‘Made in Latvia’ label, and we are proud to be using this high-tech device made here in Latvia in our daily work”, said Romāns Sidorovs, Groglass Member of the Board. `+
`<dd></dd>Last year, Groglass’ turnover was EUR 23 million while operating profit exceeded EUR 8 million. “Every year, we grow by another 10 to 15%”, he said. The company has two production facilities in Rīga with about 200 employees. `+
`<dd></dd><h3 class="post-subtitle">Product development takes seven years </h3>`+
`<dd></dd>The main Groglass product is invisible glass. Unlike ordinary glass, this material has anti-reflective and UV-protection properties, which are important for framing shops, large art galleries and museums and other customers which use the company’s products for technical applications, such as electronic displays and refrigerated display cabinets.  `+
`<dd></dd><div class="quote-style">“There is only one other company in the work that can make high-quality ‘invisible’ acryl. It took over seven years to develop this product. We faced many obstacles, but we believed in what we were doing, and together with our investor we managed to complete the development of the product”, said R. Sidorovs. </div>`+
`<dd></dd>Groglass actively works with framing shops. The company also supplies glass to manufacturers of refrigerated display cabinets for food retailers. “We also make glass for electronic devices which can be used, for example, as information displays in airport and railway stations, advertising displays or museum displays”, explained R. Sidorovs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans2+`"></div>`+
`<dd></dd>Groglass mainly works with corporate customers, but recently the company has also turned to the consumer segment. He added that currently, this business brings only a small portion of the company’s turnover, but the company is looking into its options and is planning to expand its cooperation with consumers in the framing business in the future. `+
`<dd></dd><h3 class="post-subtitle">Exporting from day one </h3>`+
`<dd></dd>Since its first day in business, Groglass has been exporting almost all of its product range. “Since 2010, we have been exporting almost 100%”, said R. Sidorovs. The first country where the company sent its products for greenhouses was the Netherlands. In search of potential partners, the first customers who decided to give the Latvian-made solution a try came from the Netherlands. Since that time, the number of countries that the company exports to has reached 45. The largest export market is Europe followed by the US, China and Australia. `+
`<dd></dd><div class="quote-style">“Competition in the export market is fierce. We can continue working and growing because we have chosen our niche and focus, i.e. our invisible glass. It may not be a very large niche globally, but we are the best in the world in it and we can provide the best products and customer services. It enables us to keep our market share”, said R. Sidorovs. </div>`+
`<dd></dd>The company may expand to multiple segments where competition there is much stiffer, but there, the company is unlikely to ever reach this good a position even investing heavily in marketing. That is why the company is focusing on its existing niche.`+
`<dd></dd>Commercially, Russia’s attack on Ukraine has not affected Groglass’ business, as the share of the Russian and Ukrainian markets in the total turnover was less than 0.5%. However, the war did affect electricity and gas prices. Glass is the main raw material used by the company and it’s made using gas, the price of which has skyrocketed. The company has also been affected by rising electricity prices. “However, we can adapt by increasing the efficiency of our production facilities and compensating at least some of the costs by raising prices. Our customers understand”, said R. Sidorovs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans3+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Ability to adapt to changing conditions </h3>`+
`<dd></dd>Groglass’ DNA contains two crucial components, i.e. the ability to adapt and to adhere to proven values.  `+
`<dd></dd><div class="quote-style">“We are a market leader in almost all of our segments, but at the same time we have competitors whose turnovers are in the billions. Our turnover is about EUR 25 million, which in itself means that we have a better ability to adapt to changes. Without that we wouldn’t be able to export almost 100% of our products and remain in the market leader's position”, noted R. Sidorovs. </div>`+
`<dd></dd>In addition to being flexible and able to adapt to circumstances, the company also sticks to proven values. Of course, if something does not work, Groglass’ team makes changes and looks for new methods. “If we believe that we can make a specific product, we are looking for ways to do it”, he said. `+
`<dd></dd><h3 class="post-subtitle">Plans for the third production facility are already underway </h3>`+
`<dd></dd>During its development, Groglass has used various state support instruments. The company follows what support programmes are available, mainly focusing on the support for participation in trade fairs, research projects, etc. The manufacturer also uses subsidies to cover research costs.  `+
`<dd></dd><div class="quote-style">“We use the Competence Centre programmes, as our research costs reach several million euro every year. There are also our long-term projects: the development of a single product may take as long as seven years. These are huge investments, but they enable us to create unique products”, explained R. Sidorovs. </div>`+
`<dd></dd>Groglass also relies on the state support of investments in technologies and equipment. For example, the company built its second production facility using the JRP project co-funding. “Now we are thinking about a third factory, which would require tens of millions of euros in investments. Currently, there are several support programmes going and we hope we’ll be able to receive state support for our new production facility”, said R. Sidorovs. `+
`<dd></dd>He positively views their cooperation with the Latvian Investments and Development Agency (LIAA), Central Financial and Contract Agency and the Competence Centre. “We are happy that LIAA is on the side of businesses and listens to us both at the officials and the top management levels. I hope it continues in the future, because it is vital for helping other companies as well to reach their export goals”, said R. Sidorovs. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+romans4+`"></div>`+

	support_by_liaa,

	review_11_name: "Žygimantas Surintas",
	review_11_company:"SIA „Smartlynx Airlines”",
	review_11_website: "http://www.smartlynx.aero",
	review_11_designation: "SIA „Smartlynx Airlines” (www.smartlynx.aero), CEO",
	review_11_excerpt: `Less than 3% of Latvian full-service aircraft plus crew (ACMI) charter and cargo operator SmartLynx's income comes from the local market. Facing a possible recession in the region the company is planning to increase income outside Europe by 10 to 15% by next year. `,
	review_11_title:"The world is big – don't do all the business locally",
	review_11_text:

`<dd></dd>Less than 3% of Latvian full-service aircraft plus crew (ACMI) charter and cargo operator SmartLynx's income comes from the local market. Facing a possible recession in the region the company is planning to increase income outside Europe by 10 to 15% by next year. `+
`<dd></dd>“I see new businesses always using this saying – first, we are going to trial and test the local market because it is cheaper and the risk is lower, and then we are going to start exporting and attempt to break into the international markets. My personal experience tells me that it is one of the biggest mistakes that you can make. Don't do all the business locally," emphasized the CEO of SmartLynx Žygimantas Surintas.`+
`<dd></dd><div class="quote-style">Startup companies in particular have limited funds and all their business success is driven by the size of the market and the demand. Maybe the product would be much more successful if the company allocated investments directly into export markets rather than spending the money on testing the products in the local market.  </div>`+
`<dd></dd>"You can be very, very smart and a very good company but if you focus only on the local market, you will never have a chance to grow. At the same time, you also need to earn the chance to grow, and start to be a serious and big exporter," he said. The SmartLynx CEO recommends measuring the capabilities to cooperate and compete in the big markets. The company needs to be prepared for this. It is one thing to see the numbers in the Excel sheet, but another to execute. “This is one of the biggest SmartLynx challenges as well. The global market has really high standards for quality and delivery so you have to be prepared for that. The international market only gives you one or two chances. So you cannot fail,” highlighted Ž. Surintas. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+zygimantas1+`"></div>`+
`<dd></dd><h3 class="post-subtitle">Looking outside the European Union </h3>`+
`<dd></dd>SmartLynx started full charter operations in 1992 as Latcharter Airlines. It was the first airline to provide package holidays from Riga; it was also equipped to offer sports, events, concerts, and governmental, military, and executive charter flights. “The company was initially created to serve only the local market, but it has changed. Now we are mainly focused on exports and operations in different international markets. In our turnover, Latvia represents less than 3%, and all the Baltic states together – 6 to 7% of the total turnover. We are purely focusing on the export markets,” said Ž. Surintas.  `+
`<dd></dd>The current number of airplanes in the fleet is 49. In the summer of 2023 there will be around 65 aircraft. From January till November 2022 SmartLynx operated more than 30 thousand flights and carried over 4 million passengers. Today, the total number of SmartLynx employees is 365. The current number of fixed-duration employees at SmartLynx in Latvia is 327 people, including sister companies and subsidiaries in Latvia. There are also 31 employees in Estonia, 27 in Malta, 6 in Lithuania and 1 in Germany.  `+
`<dd></dd><div class="quote-style">The reason SmartLynx is located in Latvia, is because in Riga, the aviation industry is the most developed compared to other Baltic states. Ž. Surintas emphasizes that in Latvia it is possible to get special education in this field ‒ here there are schools that prepare technicians, pilots as well as dispatchers.</div>`+
`<dd></dd>"There are several aviation companies based in Latvia, and specialists tend to switch their workplace, so they have comprehensive experience and they learn from each other. Also, there are lower personnel costs," he adds. `+
`<dd></dd><h3 class="post-subtitle">Biggest market ‒ United Kingdom </h3>`+
`<dd></dd>Now SmartLynx’s biggest market is the United Kingdom followed by the Benelux countries and Germany. An important part of the business is markets in the Middle East and Asia as well. “Africa is also a quite strong market for us. Currently, we operate 8 airplanes in Africa,” said Ž. Surintas. Canada is the new market for SmartLynx. The majority of our revenue comes from the European Union countries, but we are a very global company,” he added. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+zygimantas2+`"></div>`+
`<dd></dd>The consolidated turnover of SmartLynx this year will reach around 300 million euros. “We have the same fears about the economic recession in the EU. This is not a big secret, and I believe all companies over the next year will be taking this into account. Our company is trying to look at how to diversify our sales and reduce the proportion of income from the EU states and increase our business in North America and the Middle East markets. We would like to increase the share of revenue outside the EU by 10 to 15% by next year. This will help us to weather economic turbulent times,” said Ž. Surintas. `+
`<dd></dd><h3 class="post-subtitle">Fuel prices will affect demand </h3>`+
`<dd></dd>SmartLynx is also affected by Russia's war in Ukraine. Mainly because four of the company's cargo planes were financed by a company related to Russia. “Those planes have been affected the European and American sanctions, and we cannot operate those airplanes, they are all parked right now. This is a very significant hit to our business because four planes are 5% of our total fleet,” said Ž. Surintas. Also, one plane is stuck in Ukraine, and it is not possible to retrieve it because of the restrictions in Ukraine's airspace. “We are seeing more indirect hits to our business which is mainly affecting the internal demand and increase in the cost of living, which is directly connected to the war and economic situation,” he added. `+
`<dd></dd>Talking about fuel prices, Ž. Surintas said they are very important for the business – fuel prices mainly affect the end customers. And he is very realistic: nobody knows how demand will be affected due to the higher prices. `+
`<dd></dd><h3 class="post-subtitle">Looking to new markets very carefully </h3>`+
`<dd></dd><div class="quote-style">Regarding export strategy and decision-making process about entering new markets, Ž. Surintas believes all the decisions are made based on the same principles as in any other business. They are driven by the key data figures: the number of people, competitors, airplanes, the country's geography and traveling habits, and what kind of airplanes are used in the market. </div>`+
`<dd></dd>“Germany and the UK are the markets where people travel most frequently in Europe. It shows that there is demand all the time. But there is constantly high competition and high inner barriers as well. That's why we are always looking to markets which have economic and political stability. Also, the regulation is very important, because there are countries where there are limitations providing wet lease services,” said Ž. Surintas. For example, in some countries, wet lease is not allowed because of different kinds of restrictions set by the authorities. “Also, there are many small countries where we see the risk of defaults as too high, and we are also staying away from these markets. The risk factor is quite high, so when we explore new markets for our company, we need to be very careful,” he said. `+
`<dd></dd><h3 class="post-subtitle">The same principles </h3>`+
`<dd></dd>Because of the difficulties in the aviation industry people sometimes think that business is somehow different in this sector. But Ž. Surintas emphasized that all businesses are more or less based on the same principles. SmartLynx also needs to look for the markets which can contribute revenue to the company. The company also participates in exhibitions which is a very popular marketing tool. Another popular tool is brokerage. Historically more than 40% of business came through different brokers. `+
`<dd></dd>“Now when our company gets bigger, we will have more direct business with our end customers. Exhibitions and brokerage are the two key tools for our business,” said Ž. Surintas. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+zygimantas3+`"></div>`+

	support_by_liaa,

	review_12_name: "Kristīne Metuzāle",
	review_12_company:"SIA „Tilde”",
	review_12_website: "http://www.tilde.lv",
	review_12_designation: "SIA „Tilde” (www.tilde.lv) Senior Key Account Manager",
	review_12_excerpt: `Tilde has won the World Machine Translation competition four times. This competition brings together machine translation developers from all over the world. It is like the industry's annual Olympics, where the latest research-based machine translation technologies are evaluated. To continuously develop further, Tilde is already working on language technology research in the field of quantum computers. `,
	review_12_title:"Globalization drives demand for language technologies",
	review_12_text:

`<dd></dd>Tilde has won the World Machine Translation competition four times. This competition brings together machine translation developers from all over the world. It is like the industry's annual Olympics, where the latest research-based machine translation technologies are evaluated. To continuously develop further, Tilde is already working on language technology research in the field of quantum computers. `+
`<dd></dd><div class="quote-style">Tilde places strong emphasis on research and development. The company invests more than 22% of its turnover into research aimed at creating new technologies. “In this way, we can satisfy the ever growing needs of our customers and be among the leaders in the industry”, said Kristīne Metuzāle, Tilde’s Senior Key Account Manager. </div>`+
`<dd></dd> She believes that continuous investment in research and development has enabled the company both to remain strong today and to offer high-quality solutions tomorrow and beyond as technologies forge ahead. “For example, we are already working on language technology research in quantum computing. We want to understand now what will happen in this area in several years in order to be prepared for the future”, said Metuzāle. `+
`<dd></dd><h3 class="post-subtitle">Better work and communication </h3>`+
`<dd></dd>Tilde develops AI-based language technologies to help overcome various language barriers in communication between governments, businesses and other organizations. “In today’s communication, language technologies play a growing role because they allow us to communicate. For example, people can receive news from only one channel in the language they know. However, with language technologies, they can follow news in other languages that they do not understand. This means they can receive information from different channels and sources and understand what is true and what may be false. In the future, technologies will help us live in a democratic and better integrated world. We, of course, want to see Tilde being an important part of this world”, added Metuzāle. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristineM1+`"></div>`+
`<dd></dd>Technologies developed by the company help to make work more efficient, save time, create more efficient processes and expand export opportunities. “Growing international cooperation and mobility mean that businesses need more effective communication and customer service tools. For example, the demand for virtual assistants and machine translation solutions in international communication is growing all the time. This is why we closely follow our customers’ needs, as well as market and technology trends. We must always offer products that are best suited to the needs of our customers”, she said. `+
`<dd></dd>Metuzāle believes that voice technologies make working processes, e.g., conducting meetings and converting speech to text, more efficient for many organizations and state authorities. We all have many meetings, including online, which need to be minuted. Why shouldn’t we automate this process? There are people whose jobs require visiting places to observe things and then report in writing. Using voice solutions, these people can make voice recordings of their observations and when they return to their offices their recordings are already converted to text. For instance, with voice technologies, doctors will be able to create important notes for their patients or colleagues much more quickly and efficiently. `+
`<dd></dd><h3 class="post-subtitle">30 years of experience </h3>`+
`<dd></dd>Today, the company is working on artificial intelligence and mainly develops three areas of technology: machine translation, virtual assistants and voice recognition. “All these technologies are based on artificial intelligence”, said Metuzāle. `+
`<dd></dd><div class="quote-style">Tilde’s history began 30 years ago when the first computers imported to Latvia were not equipped for writing in Latvian. “Back then, to write in Latvian, you had to press a combination of keys to get your diacritic symbols. One of the symbols to be pressed was “~”. This is where Tilde’s name and logo came from”, she explained.  </div>`+
`<dd></dd>The company began working in the language technology industry to ensure the availability of Latvian symbols on computers and went on to create electronic dictionaries and other solutions. As times and technologies moved forward, we entered the artificial intelligence era. Today, Tilde has more than 150 employees in three offices. Its headquarters is in Riga and it has two other offices in Lithuania and Estonia. `+
`<dd></dd><h3 class="post-subtitle">Exports represent 59% of turnover </h3>`+
`<dd></dd>In 2021, Tilde’s turnover was EUR 6.7 million. “Our turnover continues to grow and the share of exports in sales is significant. Exports make up 59% of our total turnover”, said Metuzāle. The company’s rate of expansion to foreign markets is growing and it continues to work there with more focus and higher efficiency. `+
`<dd></dd>Tilde mainly works in Europe. “Europe has many language challenges as it has 24 official languages into which everything should be translated. However, there are 80 languages widely spoken in Europe. Many government authorities know that there are large groups of people who do not speak a local language or even another official EU language (e.g., refugees). These authorities need tools to communicate with such groups, for example, on social, healthcare or education matters”, said Metuzāle. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristineM2+`"></div>`+
`<dd></dd>The company has developed more than 90 language combinations in machine translation. At the customers’ request, machine translation solutions have also been developed for more exotic languages, such as Vietnamese, Korean, Thai, Mexican Spanish and other languages. The company has also adapted machine translation to specific customer industries, including medicine, law and other sectors. “European companies have business partners all over the world and they need tools to overcome language barriers and boost the efficiency of their internal and external communication”, she explained. `+
`<dd></dd><h3 class="post-subtitle">Exports built on a powerful product </h3>`+
`<dd></dd>Metuzāle said that Tilde’s export experience began with a powerful product, which was first offered in the Baltic states, where languages have a complicated structure. This experience enabled the company to expand to other foreign markets. She believes that the key to the company’s export success is its solutions with unique added value, which are different from all other solutions in the industry.  `+
`<dd></dd><div class="quote-style">“Our solutions work well to satisfy our customers’ needs. It gives us a strong position in the market. Language technology solutions are not immediately clear to everyone and their use in daily life is not self-evident, so we need to invest a lot of effort in education. It allows us to open many doors”, said Metuzāle. </div>`+
`<dd></dd>To open new export doors, Tilde goes to trade missions, participates in various events and cooperates with representative offices of the Latvian Investment and Development Agency (LIAA) abroad. “Currently, we are working with the LIAA office in Norway and we already have a number of potential partners”, said Metuzāle, adding that they were happy to see that Tilde’s reputation preceded them and businesses were approaching Tilde because they knew they needed the solutions that would bring them the largest added value. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+kristineM3+`"></div>`+

	support_by_liaa,








	exp_review_3_name: "Stories about export to Sweden",
	exp_review_3_company:"UPB, Putnu Fabrika Ķekava, Blindsave",
	exp_review_3_designation: "UPB, Putnu Fabrika Ķekava, Blindsave",
	exp_review_3_excerpt: `Sweden is among the five most important export markets for Latvia. Latvia mainly exports timber and timber products, metal and metal products, and building materials to Sweden. Engineering sciences and information technology services are also among the top trade sectors.`,
	exp_review_3_title:"Sweden: a close, stable and wealthy export market",
	exp_review_3_text:

`<dd></dd>Sweden is among the five most important export markets for Latvia. Latvia mainly exports timber and timber products, metal and metal products, and building materials to Sweden. Engineering sciences and information technology services are also among the top trade sectors.`+
`<dd></dd>Mārtiņš Tiknuss, Chair of the Board of the Latvian Exporters Association The Red Jackets, said that in 2022, Latvian exports of goods and services to Sweden reached 1.46 billion euros. The import of goods and services from Latvia accounts for 1.3% of Sweden’s total imports. “Latvian exports to Sweden still have a great growth potential,” he stated confidently. M. Tiknuss sees Sweden as an easily reachable, stable, predictable and sufficiently well-off country. At the same time, Swedish companies want to buy goods for less than it would cost Latvian companies to produce them.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_sweden1+`"></div>`+
`<dd></dd>According to V. Jēkabsons, Head of the Investment and Development Agency of Latvia’s representative office in Sweden, Swedish customers expect high quality for a low price from Latvian and Baltic contractors. They are often eager to start negotiations when they are interested in a product, but in terms of price, their expectations may be lower than it is physically possible. “It is important to understand whether we can enter that market with our prices at all. Latvian companies can make high quality products in small quantities rather than huge amounts. Although the Swedish market is not gigantic, Latvian businesses know how to adapt to it, and it is something that large competitors may not be willing to do. We are so small that the Swedish market is big enough for us,” M. Tiknuss thinks.`+
`<dd></dd><div class="quote-style">Andis Blinds, the owner of the floorball goalkeeper equipment BlindSave, emphasised Sweden’s high purchasing power: “With 10.4 million people, the Swedish market is sufficiently large and solvent”. </div>`+
`<dd></dd>At the same time, Dmitrijs Zolotarjovs, AS Putnu Fabrika Ķekava and SIA Lielzeltiņi Director for Scandinavian Export, highlighted that Swedes highly value loyalty, reliability, quick information exchange and transparency of business in their partners.`+
`<dd></dd>“Almost all Swedes, regardless of their age, speak English, and very well at that. All our contracts are mainly in English,” said Uģis Grīnbergs, Member of the Board of the industrial holding UPB. A. Blinds also noted that all Swedes spoke English and there was no problem whatsoever writing emails in English. Unlike, for example, Spain where his emails would be read only if they were in Spanish.`+
`<dd></dd><h3 class="post-subtitle">The closest practical market</h3>`+
`<dd></dd>“Pre-cast reinforced concrete structures are heavy and large, so their transportation generates significant costs, making the area where they can be sold at a profit very limited. So Sweden is the closest and the most practical choice for us. In addition, Swedes have already had experience with pre-cast reinforced concrete,” said U. Grīnbergs. This was the motivation behind UPB launching exports to Sweden back in 2005 offering metal structures and facade solutions of their own design and production in addition to pre-cast concrete products.`+
`<dd></dd><div class="quote-style"> In the first year, export sales reached a couple of million euros but now they have exceeded 100 million euros. Sweden is the largest export market for UPB accounting for 35 to 40% of the company’s turnover.</div>`+
`<dd></dd>D. Zolotarjovs also noted that geographically, Sweden is one of the closest export markets, which opens many opportunities for business development. “Local companies can cover only about 55 to 60% of poultry consumption. Therefore, 35 to 40% of the demand comes from import, which means a great market potential for us,” he added.`+
`<dd></dd>BlindSave exports its floorball goalkeeper equipment to Sweden as this sport is extremely popular there. Export to Sweden accounts for 35% of the company’s sales.`+
`<dd></dd><h3 class="post-subtitle">Orderly legal environment</h3>`+
`<dd></dd>According to U. Grīnbergs, the business and legal environment in Sweden is rather well organised, with a reasonable tax policy.  Describing business in Sweden, he mentioned UPB as an example – with a turnover of 100 million euros and about 20 projects a year, 200 to 300 installation specialists and foremen, project managers, sales managers and engineers work on a rotation basis and go there regularly while the company does not have a permanent office there. Considering that UPB has projects all over Sweden, it is easier to manage all the regions from Latvia.`+
`<dd></dd><div class="quote-style">“Everything is pretty well organised and standardised: regulations on business, taxes, contracts, construction, environmental and occupational safety, order and everything that happens on a construction site. I think it’s the same in all other sectors, too,” said U. Grīnbergs.</div>`+
`<dd></dd>He supposes that Swedes are among those rare nations with whom you can speak openly, and they appreciate it in others too. If there is a problem you can discuss it and look for a timely solution together. “The greatest advantage is that they get involved in seeking a solution rather than striking a pose like ‘here is your contract, you either get the job done or say goodbye to your money,” U. Grīnbergs explained, sharing his experience.`+
`<dd></dd><h3 class="post-subtitle">Decisions are taken together</h3>`+
`<dd></dd><div class="quote-style">“In Sweden, joint decision-making is prevalent indeed, i.e. they will all get involved, express their concerns, present their points and consider how the decision will affect the area of responsibility of a specific specialist. And only when all parties are in agreement will they go ahead with their joint decision. That is why the decision-making process in Sweden is slow,” M. Tiknuss said.</div>`+
`<dd></dd>U. Grīnbergs also noted that Swedes took their decisions in meetings, consulting each other, discussing the matter and trying to consider all risks before signing a contract or even issuing a proposal. Matters that may pose a risk to them and their partners are also discussed. He remembered one instance when a customer pointed out an item where the price offered by UPB was too low and asked to double check. “In other countries, you are unlikely to hear anything like that, and if we did forget to include one floor or anything else in our estimate they would accept the proposal and say nothing. However, Swedes often note it so that all risks are clear from the off and everything is understood,” said U. Grīnbergs. On the other hand, M. Tiknuss says it’s an exception which may only be true for long-term partners. “Starting work with a new partner, the majority of Swedes are not willing to waste time on educating them. If a partner senses that we don’t know anything, for example, we have given a proposal twice the price of their current partner, or half the price, a Swede would never say which way we have gone wrong. Neither will a Swede ever haggle. Exporters should know what and to whom to offer and with whom to compete. If a proposal is not within an adequate range, you will not get any advice or feedback,” M. Tiknuss commented on the initial cooperation stage.`+
`<dd></dd><h3 class="post-subtitle">You must be able to dispel any doubts</h3>`+
`<dd></dd>M. Tiknuss believes that reputation is very important for many Swedish companies. That is why companies willing to export to that market must deliver quality products on time. “To them, it is extremely important that nothing goes belly up because they risk their reputation and customers’ trust which they have spent years building and which can be lost in an instant. Every new supplier is a new risk. This should be taken into account by anyone thinking about exporting to Sweden, as it will be easier if the company can dispel any doubts and show that they can be trusted,” he explained.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_sweden2+`"></div>`+
`<dd></dd>In Sweden, Latvian companies have to compete with other exporters, not to mention local Swedish businesses. That is why M. Tiknuss urges people to get an understanding of the company’s other competitors in the market and analyse whether something being bought on the Swedish market at the moment is an adaptation of a product created by the world’s major players which Swedes are forced to buy. Possibly, Latvian players can improve the quality of the existing product by adapting it specifically for the Swedish market and selling it for a better price. “Our businesses can occupy a specific niche in an already existing category, finding ways to offer a higher quality product,” M. Tiknuss proposed.`+
`<dd></dd><h3 class="post-subtitle">Trust is gained slowly</h3>`+
`<dd></dd><div class="quote-style">“Starting business with Swedish companies is not a quick and easy process. It can take three years from your first meeting with them to their first order. However, if it has worked, it will be a partnership for years. That is why you need a lot of patience to work in that market”, D. Zolotarjovs advised.</div>`+
`<dd></dd>U. Grīnbergs also believes that Swedes’ trust is gained slowly. In 2005, UPB started with small projects in that market, and now major general contractors in Sweden rely on the Latvian company in their large-scale, complex projects in which UPB provides design, production, supply and construction services. “In 2016, the tallest building we built, Munksjotornet in Jönköping, was 16 storeys tall, but now we have completed a 36-storey high-rise, CityGate in Gothenburg. We have changed the cityscape of big Swedish cities with our high-rises,” U. Grīnbergs proudly noted.`+
`<dd></dd>Z. Zolotarjovs added that Swedes rarely change their suppliers when everything is going well.  M. Tiknuss also expertly said that, before changing their existing long-term supplier, Swedes would subject their potential partner to testing for a long time, so they rarely changed their old trusted partners.`+
`<dd></dd><div class="quote-style">“Swedes are rather consistent, they build their relationships slowly and for years to come, so the matter of reputation is a very important one. With a ‘cold’ approach, the result will come much more slowly than when you have been recommended by somebody from the company’s circle of trust. In such cases, you can get to decision-makers in a flash, especially if the customer is interested in what you have to offer,” V. Jēkabsons explained. </div>`+
`<dd></dd>In his experience, in Sweden, things move significantly more quickly through personal contacts, especially in the context of small and medium companies.`+
`<dd></dd><h3 class="post-subtitle">Compliance with a variety of requirements is a challenge</h3>`+
`<dd></dd>“Working in Sweden has more advantages than disadvantages. The most difficult thing for us now is to align the requirements of Swedish trade unions with Latvian and EU laws and regulations. For example, what the amount of paid leave should be, considering that all our employees are UPB employees in Latvia who work on sites in Sweden, Norway or the UK and each country has its own requirements,” U. Grīnbergs shared his experience.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_sweden3+`"></div>`+
`<dd></dd>In Sweden, you must have an agreement with a trade union, you simply can’t operate without it, and you must comply. UPB also has to comply with the requirements of trade unions which are stipulated in contracts based on local market traditions and regulations. The company has to juggle the different taxation, jurisdiction and legal requirements of multiple countries, and it is not an easy task. “This is what’s most difficult – you can read one thing in a book or an agreement, but in reality, and traditionally, they all do it a bit differently. On top of that, you may have something clearly stated on paper, but every region may interpret it in their own way. Therefore, you have to be in touch with trade unions all the time, discuss these matters, and before starting each project, you have to negotiate working hours, pay rates and other conditions,” U. Grīnbergs explained.`+
`<dd></dd><h3 class="post-subtitle">Peace helps prosperity</h3>`+
`<dd></dd>M. Tiknuss highlighted an important fact about Sweden – the country hasn’t had a war for more than 200 years. It enabled Swedish companies to create continuity and accumulate wealth. If a Latvian company starts cooperation with a Swedish company, there is a very good chance that it has been a family business for generations. “Swedish companies behave similarly to really rich people. First of all, they think about not losing what they have. And only then do they think about developing something and making more money. The Swedes do not need to pursue profit because it has accumulated over the years, having been handed over from generation to generation. In Eastern Europe, which has experienced war, it was simply impossible to continue business in areas affected by fighting. It was not like that for Sweden,” M. Tiknuss added.`+
`<dd></dd><h3 class="post-subtitle">There are still broad opportunities for cooperation</h3>`+
`<dd></dd>M. Tiknuss believes that Swedish market is very far from being conquered:  “In any sector or industry we may look at, Latvian companies have opportunities to find their niche and expand to Sweden”. He hopes that in the future, Latvia and Sweden will implement an innovation programme to work together on the development of new products. There are similar programmes with both Norway and Finland. “Such programmes facilitate strengthening the business relationship between the counties,” M. Tiknuss believes.`+
`<dd></dd>V. Jēkabsons is confident that Sweden will continue to be one of the most important trade partners for Latvia.  It is due to a similar mindset, geographical closeness and a good representation of Swedish investors in Latvia, as well as price differences, although those will inevitably disappear as time goes by. Despite the growing volume of Latvian exports to Sweden, he thinks that we should continue seeking ways to make good products at affordable prices. We also have to work on our country’s image. `+
`<dd></dd><div class="quote-style">“Many Swedes still don’t associate Latvia with things we want to sell. So it is important to prove with our actions that we are trustworthy. We should establish contacts, and marketing efforts would help. Information about the company and its product should be widely available. In addition, it is very important to consider sustainability matters, which in Sweden is a requirement in all sectors and at all levels,” he believes.</div>`+
`<dd></dd>If a company is thinking about exporting to Sweden, they can consult with Valters Jēkabsons, Head of the Investment and Development Agency of Latvia representative office in Sweden, or Laura Valtere, partner at Gateway & Partners.`+

	support_by_liaa,


	exp_review_4_name: "Stories about export to Germany",
	exp_review_4_company:"EKJU, Karavela, Labrains",
	exp_review_4_designation: "EKJU, Karavela, Labrains",
	exp_review_4_excerpt: `In 2022, Germany was the third largest export market for Latvian companies. The main exports are food, timber, metal and machine building products. Other sectors are also gradually joining their ranks, e.g. the chemical industry and IT services.`,
	exp_review_4_title:"Germany is a market for companies with patience and serious intentions",
	exp_review_4_text:

`<dd></dd>In 2022, Germany was the third largest export market for Latvian companies. The main exports are food, timber, metal and machine building products. Other sectors are also gradually joining their ranks, e.g. the chemical industry and IT services.`+
`<dd></dd>“The German market has always been important for Latvian businesses, and its role is increasing. Figures speak for themselves. Last year, Latvian companies exported goods worth just under 900 million euros to Germany,” said Līva Melbārzde, Head of PR of the German-Baltic Chamber of Commerce (AHK) in Estonia, Lithuania and Latvia.`+
`<dd></dd><h3 class="post-subtitle">Gaining trust takes time</h3>`+
`<dd></dd>“Although Germany is a very desirable market and many businesses would like to be present there, it requires a great deal of patience. `+
`<dd></dd><div class="quote-style">Germans will take a long time before they put their trust in a new partner. However, once you have gained their trust, you can be confident that it will be a long-term partnership,” L. Melbārzde says.</div>`+
`<dd></dd>She knowingly adds that sometimes Latvian companies which already successfully export to other countries realise that things are a bit different in Germany. “It is necessary to invest time in studying the German market and competition. You should think through your strategy down to the smallest details, including various regulations which apply to running a business in that country. It is a time-consuming process taking at least a year,” L. Melbārzde warns.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany1+`"></div>`+
`<dd></dd>In Germany, doing the same thing as in the US is recommended: choose one city or at least one federal state and begin exporting there. “Germany is diverse, and there are big differences between federal states in terms of mentality, business and economy. That is why it is definitely not a good idea to aim for the entire German market at once. It may simply be too big,” L. Melbārzde suggests.`+
`<dd></dd><h3 class="post-subtitle">Discipline on all levels</h3>`+
`<dd></dd>“Germany is a conservative market. It has a clear hierarchy, and, for example, it is expected that a director will talk with a director. Although it does not mean that a sales representative cannot build a good rapport with a director,” L. Melbārzde adds.`+
`<dd></dd>For the garden furniture manufacturer SIA EKJU, Germany is their third largest export market accounting for 14% of sales. The company’s Chairman of the Board Māris Danieks believes that all customers are unique, and their requirements are different, but the overall business environment in Germany is strict. `+
`<dd></dd><div class="quote-style">“Thinking about exporting to Germany, you must have discipline on all levels. All decisions should be thought through. Everything should be calculated: every gram, every centimetre, every milligram,” she believes. Requirements are stringent: the company must have insurance, due diligence done by the customer and product certification, which can often come from one specific German body. Moreover, this certification might not be valid for any other market. </div>`+
`<dd></dd>“The delivery system is very strict: the goods are to be delivered at the exact time and in the exact amount. If at 14:35 you must be at an unloading bay, it means that you must arrive exactly at that time. You won’t be served later because it will be someone else’s slot. Everything is orderly, everything is scheduled. Penalties for a short or late delivery are present in all markets, but in other countries these penalties may be waived. But not in Germany,” M. Danieks says adding that in any event you should expect payment after delivery and the payment term is long. For some SIA EKJU customers, the payment term is up to six months after delivery.`+
`<dd></dd>M. Danieks knows from experience that many customers will not place an order before they have seen your factory and done their due diligence: “They want to make sure that the company does manufacture the products and to see the site where it happens. This way, the customers try to mitigate their risks. For example, a Latvian company is supposed to have the capacity for a specific order. The partners will make sure that your operation is big enough for the required demand. Only then will negotiations begin.”`+
`<dd></dd><h3 class="post-subtitle">Draw up and read your contracts carefully</h3>`+
`<dd></dd>“Germans are people of principle. I have come to a conclusion that you should try to discuss all possible situations with them before signing a deal and include it all in your contract. Signing a contract, you have to have a backbone, to defend your interests,” Līga Brūniņa, CEO of the cosmetics manufacturer SIA Labrains, points out.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany2+`"></div>`+
`<dd></dd>M. Danieks agrees that in Germany, the wording of the contract is very important. SIA EKJU learned a hard lesson when they made a mistake and went too low with their price. On top of that, it was just before the Covid-19 pandemic which pushed the prices of raw materials up. Their partner did not agree that it was force majeure and they had to do what they had promised. “We got through it, but the lesson we learned was that if you have signed anything, there is no way back.`+
`<dd></dd><h3 class="post-subtitle">It is useful to speak German</h3>`+
`<dd></dd>“Aiming for the German market, it’s highly advisable to have knowledge of German. Of course, the majority of German entrepreneurs also speak English, but language plays a big role in gaining their trust. That is why you need to speak German for your first step. After that, you can discuss details in English, but to establish that contact and start a relationship, a German must know that their partner can communicate in German. It is also true for the website. It is good to have a German version of it,” L. Melbārzde recommends.`+
`<dd></dd><div class="quote-style">M. Danieks believes that it will be much more difficult to work in the German market without speaking German. “Of course, Germans speak English, but knowledge of German is a key to that market. Without it, there’s no point wasting time in Germany,” he notes.</div>`+
`<dd></dd>L. Brūniņa does not speak German. “But I know that it is necessary. Maybe in Berlin you can get away without it, but elsewhere, knowledge of German is very important” she concludes.`+
`<dd></dd><h3 class="post-subtitle">Talks over dinner are long</h3>`+
`<dd></dd>“If you are invited to a dinner, expect it to be a long affair. Germans like to have long and substantive conversations. You have to make time for such events,” L. Melbārzde notes. She says that Germans are open to socialising. So if you want to establish contacts, you should be ready to tell a story about your business, your country and yourself. “Often, when establishing contacts, it is important to them to know, for example, their partner’s marital status. They are not prejudiced in this respect, but usually conversations turn to life outside work,” L. Melbārzde explains.`+
`<dd></dd>Germany is a big country, and you should not assume that all German businesspeople are ethnic Germans. That is why she recommends not to bring any strong political or religious views to the table.`+
`<dd></dd><div class="quote-style">“Sometimes Germans’ view of Latvia is based on wrong ideas, so you shouldn’t be too critical of yourself and your country. It would be better to tell a positive story that we are interesting, modern and progressive. Normally they like stories of our digitalisation achievements, for example, in a state administration context. Unlike us, they have significantly more paperwork. In this respect, we are a step ahead of them, and Germans see us as an exciting example,” L. Melbārzde notes.</div>`+
`<dd></dd>If your conversation partner has a doctor’s degree, then you should address them as doctor. The only exception is when the person offers to switch to a more informal mode. “Normally these titles are important to them. Formal address is also important to Germans until both parties feel like switching to a more informal way,” L. Melbārzde points out knowingly.`+
`<dd></dd><h3 class="post-subtitle">Visit your customers regularly</h3>`+
`<dd></dd>The cosmetics company SIA Labrains makes 16 products and exports them all to Germany. Currently, the German market accounts for about 5% of sales. “We only started exporting in September 2022. It takes some time for the consumers to get to know our products, but we are already seeing repeat orders, and everything is moving forward. I will be happy if this year, our exports to Germany reach 15% of our sales. The volumes go up about 3% every month,” L. Brūniņa says.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany3+`"></div>`+
`<dd></dd>The company has an agent which works with retailers and ensures communication with partners. If SIA Labrains decides to start working with beauticians and dermatologists, it will most likely also need a distributor. Although the company has an agent in the German market, L. Brūniņa still goes there regularly. “The export of our products to Germany is developing rapidly so I go there at least once every two months. Only being there can I meet people, network and build contacts. When I am there for the second, third or fourth time, personal conversation start to emerge, and these people introduce me to other people. Exports require time,” she says.`+
`<dd></dd><h3 class="post-subtitle">Show and prove yourself at exhibitions</h3>`+
`<dd></dd>For many companies, exhibitions are the first step in conquering the German market. SIA Labrains has already participated in Vivaness Biofach (the largest natural cosmetics exhibition in Europe) in Germany twice. L. Brūniņas believes that this is the way to build contacts and establish partnerships. After many years in business, M. Danieks has noticed that the company has been being observed for a long time. “In the first year, only some foreigners came up to our stand to talk. Next year, Germans came to have a look, and a year after that, they sat down to talk,” he says.`+
`<dd></dd>The canned fish manufacturer SIA Karavela found its way to the German market by going to exhibitions. “We started exporting to Germany in 2009 when we went to the international exhibition Anuga. We simply went up to the stands of different companies which had similar canned goods and said: “Hello, We are from Latvia, and we would like to make some products for you.” However weird this may sound, some of them said – OK, we are interested,” Jānis Endele, SIA Karavela Director for Development, remembers their export beginnings. However, there is no one ultimate exhibition which will suit all businesses. That is why he recommends carefully selecting exhibitions to participate in.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany4+`"></div>`+
`<dd></dd>Latvian companies regularly participate in the Frankfurt Book Fair, which this year will take place from 18 to 22 October. This year, the Investment and Development Agency of Latvia will also run the Latvian national stand there, and businesses can apply for state support to be a part of it. Then there is another favourite, the children's furniture exhibition Kind + Jugend 2023, which will take place from 7 to 9 September. It will also have the Latvian national stand. Businesses can also choose to have their own stands or take a trip to explore the competition and industries. State support administered by the Investment and Development Agency of Latvia within the Programme for Promotion of International Competitiveness will be available for these activities until June.`+
`<dd></dd><h3 class="post-subtitle">Exports doubled in four years</h3>`+
`<dd></dd>In 2022, SIA Karavela sales in Germany were almost 14 million euros. They accounted for almost 20% of total sales. Every year, the German market consumes about 300 million cans of herring fillets in various sauces. This year, SIA Karavela will make about 10 million, or 3%, of those. The company aims to reach 10% of the market, i.e. 30 million cans.`+
`<dd></dd>In 2019, SIA Karavela acquired the third largest German fish product manufacturer Larsen Danish Seafood. Today, under the Larsen brand, the company is a market leader in three product groups: canned sprats, salmon and mussels. “Since 2019, we have doubled our exports to Germany. It allows us to forecast pragmatically that our task for the next four years will be to double our sales in that market yet again,” J. Endele said.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany5+`"></div>`+
`<dd></dd>J. Endele believes that the SIA Karavela example of entering the German market shows that the same can be done by other food companies, such as meat or dairy manufacturers. `+
`<dd></dd><h3 class="post-subtitle">There are several ways to the shop shelves</h3>`+
`<dd></dd>J. Endele explains that Latvian food companies have four possible areas for business in Germany:`+
`<ul><li>expat market;</li><li>own brands of the large retail chains;</li><li>working with distributors;</li><li>and working with specialised bio product buyers.</li></ul>`+
`<dd></dd><div class="quote-style">“Expats who buy products that they already know the taste of occupy a small share of the market, and this share shrinks every year. However, it is a first step to launch exports,” he notes.</div>`+
`<dd></dd>Via agents, SIA Karavela products are sold at Aldi and Lidl. “We are experts in making our products and the agent is an expert in sales. These are two different things. When our partner told us to do things differently, we learnt how to make a rational decision. Agents know better; if they have not dropped you yet, they know a thing or two about this business. Of course, it is not easy to accept the rules of the game when you think you know best yourself. We must trust our partner who knows the other side of the business,” J. Endele explains.`+
`<dd></dd><div class="quote-style">At the same time, an excellent buyer, in their own words, is someone who understands what consumers will want: “That is why a manufacturer should be able to tell a unique story. You won’t see your product on the shelves unless your story convinces the buyer.”</div>`+
`<dd></dd><h3 class="post-subtitle">Product development is complicated</h3>`+
`<dd></dd>“It is a myth that a company in Latvia can create a product which will be liked by Germans. In 2019, when we bought the German fish processing company Larsen Danish Seafood, we thought we would make this product and that product! To tell you the truth, none of them did well,” J. Endele admits. He explains that developing products for that market is complicated, it requires time and analysis. There are things that Germans have been eating all their lives. It includes various aspects, let alone the packaging. With the onset of the crisis and surging prices, SIA Karavela thought that they could keep the price of the canned herring the same, but reduce the size. “We invested a lot of time and resources, but in the end Germans still didn’t accept it. They would compare prices of a 200 gram can and a 125 gram can, and if the price of the small can was the same as the big one, they would be prepared to try it. Normally small size has a higher price and Germans do not want a more expensive product,” J. Endele says. However, he is not quitting: “Even if it didn’t work, you have to look for innovations and products with added value all the time.”`+
`<dd></dd>M. Danieks adds that the quality and design requirements of customers in Germany are much higher than, for example, in France or the UK. “Germans are more demanding; they want to have top quality. German customers want a classic, but high-quality product,” he explains.`+
`<dd></dd><h3 class="post-subtitle">Explore store shelves</h3>`+
`<dd></dd>J. Endele recommends that other entrepreneurs go to Germany and explore the stores first. It would only take a day to see the stores of the main players in the market. He encourages entrepreneurs to do some shelf reading, i.e. to take pictures of the store shelves with a specific product category and buy all the competitors’ products in their segment. If there are no similar products, there is no point in wasting time and trying to offer something new. Back home, taste all the products and analyse the offer: packaging, price, taste, marketing.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_germany6+`"></div>`+
`<dd></dd>The next step is approaching potential partners. However in Germany, same as in other markets, the existing suppliers have an advantage. “In Germany, buyers are conservative. If they are satisfied, they will not be willing to change their existing suppliers. And, unless the manufacturer has caused some problems, and if the price is the same, the existing supplier will stay where it is. If a retailer is not thinking about changing a supplier, it is difficult to get on their shelves,” J. Endele says knowingly.`+
`<dd></dd><h3 class="post-subtitle">A step closer to exports: joining an industry association</h3>`+
`<dd></dd>L. Melbārzde adds that Germans like all kinds of associations, societies, clusters and interest groups. So she recommends Latvian companies planning to export to Germany to join their trade association. “It is rather typical for the German business environment to join such organisations, including chambers of commerce and industry. It is a certain advantage for members of our German-Baltic Chamber of Commerce which works in more than 90 countries worldwide. When Germans see that a company is a member there, they accept it as their own,” L. Melbārzde says.`+
`<dd></dd>M. Danieks is confident that Latvian companies have a huge business potential in Germany: “We just have to work to conquer that market.”`+

	support_by_liaa,


	exp_review_5_name: "Stories about export to Norway",
	exp_review_5_company:"HansaMatrix, Tenax Panel, Dores Fabrika",
	exp_review_5_designation: "HansaMatrix, Tenax Panel, Dores Fabrika",
	exp_review_5_excerpt: `Norway is the fifth largest country in Europe and its name means “a way to the North”. For Latvians, this Nordic land is mainly associated with Vikings, fjords and trolls, but economic ties between Latvia and Norway are not insignificant and growing stronger.`,
	exp_review_5_title:"Latvian export road to the North. Norway still has lots of potential",
	exp_review_5_text:

`<dd></dd>Norway is the fifth largest country in Europe and its name means “a way to the North”. For Latvians, this Nordic land is mainly associated with Vikings, fjords and trolls, but economic ties between Latvia and Norway are not insignificant and growing stronger.`+
`<dd></dd>Until recently, Norwegians have known about Latvian companies in our traditionally strongest sectors, such as construction and timber, best. However, lately our products with high added value have gained greater recognition. `+
`<dd></dd><div class="quote-style">“We can see that a strong, growing economic relationship between the countries also brings about more joint research and scientific projects. A number of Latvian companies are already working with Norway’s independent research organisation SINTEF, which is the largest in Europe”, said Evita Nedzvecka, Head of the Investment and Development Agency of Latvia (LIAA) Representative Office in Norway. </div>`+
`<dd></dd><h3 class="post-subtitle">One of the fastest growing export markets</h3>`+
`<dd></dd>“Interest from Latvian companies in exporting opportunities in Norway and Nordic countries is growing rapidly. In addition to geographic proximity, it is driven by a similar communication and business culture”, believes Austris Keišs, Deputy Head of the LIAA Representative Office in Norway. Jānis Sams, CEO of the electronic systems and component manufacturer AS HansaMatrix, also thinks that a similar mind-set makes business with partners in Norway more successful than, for example, in Italy or Spain. “We tried exporting to those countries too, but their way of thinking meant that work did not move as fast as we were used to. Their temperament is completely different and we realised that we were doing better with Scandinavian entrepreneurs”, he revealed. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway1+`"></div>`+
`<dd></dd>Physical distance is important in hi-tech industries because it allows dealing with matters in person, e.g. a customer may visit a production facility in the morning and be back home by dinner. “The online environment is very convenient, but there are things you cannot do over a video call”, Sams said. Keišs added: “Geographic location means good logistics options, quicker deliveries, lower risks and more competitive costs. Of course, let’s not forget that in Latvia we have the largest airport in the Baltic states and a flight from Rīga to Oslo takes just ninety minutes”.`+
`<dd></dd><h3 class="post-subtitle">Timber, metal, books</h3>`+
`<dd></dd>Timber and timber products account for 23% and metal products for 17% of total Latvian exports to Norway. Nedzvecka explained that Norway has imported many construction materials because construction is one of the sectors the government uses to heat up the economy. The large number of government construction contracts in Norway is a significant driver of Latvian exports to that country. `+
`<dd></dd><div class="quote-style">Zaiga Fogelmane quotes one of her customers: “Every Norwegian has a boat in the sea, a summer house in the mountains and a tractor which needs a shed”. She points out that, currently, the Norwegian market is active with steady demand in the industrial construction sector.</div>`+
`<dd></dd>At the same time, if you go inside any bookshop in Norway and pick up any book, the odds are good that it has been printed in Latvia. “Printing services account for 7% of Latvian exports to Norway”, Keišs added.`+
`<dd></dd><h3 class="post-subtitle">Latvian products are used in the construction of major buildings</h3>`+
`<dd></dd>For the heat insulation material manufacturer SIA Tenax Panel, their first projects in Norway were in 2008. “After we did some projects in private homes, we knew what products were suitable for that market, i.e. sandwich panels and strip foundation systems, as well as  building foundation systems made by Tenapors, a company within Tenax Group,” said Fogelmane, Head of Export Sales at SIA Tenax Panel.`+
`<dd></dd>Today, the company’s main products for Norway are sandwich panels for walls, ceilings and roofs and parts made by the company. The company also provides engineering services, including technical drawings, design and consulting. Another Tenax Group company, Tenax Install, provides installation, project management and engineering services.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway2+`"></div>`+
`<dd></dd>Norway’s share of Tenax Panel sales in the Scandinavian market is about 30%. The company’s biggest success story in Norway is a supply contract for sandwich panels with mineral wool for Lagunen shopping centre in Bergen, which in 2018 was the largest in Norway. The company’s products were also used for the then largest logistics centre Europris in Moss. “This year, we are supplying products for two buildings in the logistics park Drøbak with a total area of 21 thousand square metres. We are proud that we can supply such large-scale projects”, Fogelmane said.`+
`<dd></dd><h3 class="post-subtitle">Exporting food is a challenge</h3>`+
`<dd></dd>“We must remember that Norway is not a favourable market for all sectors. As an example, I can name food, which is a strategic industry in Norway, where the local government uses various tools to support local producers, including making things difficult for external players trying to enter the market”, Nedzvecka explained. `+
`<dd></dd><div class="quote-style">For example, the list of importers in the cheese category in Norway is rather short: it features companies which have bought import quotas at an auction and can import products without import duties. For others, import duties may reach 277% of the price for one kilo of cheese, which makes the imported product too expensive and far less competitive than local products. </div>`+
`<dd></dd>That is why food exports to Norway only amount to 3%. Moreover, they are dominated by a few companies – Atlas Premium, which buys fish from Norway, processes it and sends back, and Orkla, which has production facilities in Latvia. `+
`<dd></dd>“If a food company is looking into export opportunities in the Nordic countries, Norway is definitely not the best market to start, as the number of steps and the degree of difficulty are higher than, for example, in neighbouring Sweden or Finland where food exporters are doing much better”, Nedzvecka added.`+
`<dd></dd><h3 class="post-subtitle">Norway is not the EU</h3>`+
`<dd></dd>“As Norway is not in the European Union, many industries will involve an additional bureaucratic load. Moreover, many sectors in Norway are protected to support local businesses”, Nedzvecka said.`+
`<dd></dd>Sams added that Norway is not in the eurozone either and exchange rate fluctuations sometimes bring unexpected losses.  “However, it’s not something we can influence or control. It’s like that in all countries with other currencies”, he said. Sams also urges companies not to forget about currency conversion rates and recommends considering the option of using one currency, e.g. the euro, when signing the contract. “Then you won’t have any unnecessary surprises. Sometimes they may be good, sometimes not. And when they are not, business will always pay”, he said.`+
`<dd></dd><h3 class="post-subtitle">Speaking the language is a useful bonus</h3>`+
`<dd></dd>“Norway is a coveted export market not only for Latvian companies, but also for businesses from many other countries. Because Norwegians use language as one of the initial selection filters for potential partners, often those who can speak with them in their own language will have an advantage. In Latvia, interest in Norwegian, Swedish and other Scandinavian languages is growing, which also opens up broader economic cooperation opportunities”, Keišs concluded. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway3+`"></div>`+
`<dd></dd>Sams says that locals make the best representatives:  “If a local person represents a foreign business, it strengthens trust”. HansaMatrix has a sales agent in Norway. He has been working for just over a year and it is hard to say for the moment what has been accomplished in terms of business results. However, Sams believes it is important not only that the sales agent is local, but that they can also represent the company on a technological level. “An agent cannot be just a salesperson, because the more technological the business is, the better their understanding of the product being sold there should be. The first impression made by our agent is our business card. If they are competent in technical matters, our chance of success is higher”, Sams said.`+
`<dd></dd><h3 class="post-subtitle">Respect for privacy</h3>`+
`<dd></dd>Nedzvecka notes that matters related to time off, employees’ mental health and work-life balance are very important to Norwegians.  Achievements in work and business are also important, but for Norwegians, physical activity, hobbies, travelling and time spent with their families are just as meaningful. `+
`<dd></dd>“In my experience, I have observed many times that, during business negotiations or dinners, Norwegian entrepreneurs talk about their hobbies and things they are excited about outside work as this can provide a fresh perspective on a solution to a problem. The Norwegians appreciate it very much when, in addition to complying with the contract conditions, their potential foreign partners see time off and healthy work-life balance as values. That is why pestering and calling after business hours or at weekends is definitely not the best way to communicate in Norway”, Nedzvecka explained.`+
`<dd></dd><h3 class="post-subtitle">Democratic approach to all matters</h3>`+
`<dd></dd>Nedzvecka says that entering the Norwegian market requires a lot of resources from exporters – in terms of both money and time.  You should also remember that your initial plan of action is likely to be delayed because in Norway, decision-making takes a long time. This is due to their democratic approach to all matters, i.e. all decisions are made jointly rather than individually, and, of course, that takes time. “That is why businesses should arm themselves with patience”, Nedzvecka recommended. `+
`<dd></dd>At the same time, Sams believes that Norwegians are more decisive than their Nordic neighbours. If Norwegians consider a solution reasonable, the decision may be taken much faster than in neighbouring countries.`+
`<dd></dd><h3 class="post-subtitle">Building a relationship will not be easy</h3>`+
`<dd></dd>“In Norway, one of the keys to achievement is creating a relationship based on trust. In general, Norwegians are very reserved when it comes to new contacts or friendships, so you should remember that finding new partners and building relationships takes longer in Norway”, Nedzvecka added. Fogelmane also thinks that the business environment in Norway is not an open one. “You have to be able to build relationships and get into their circle of trust”, she said. SIA Tenax Panel Head of Export Sales also notes that in business, Norwegians do not like remote communication or business trips twice a year – presence is important. It helps to reduce the distance between business partners.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway4+`"></div>`+
`<dd></dd>Sams explains that trust is gained through work, i.e. when you keep a promise you get credits which add up.  If you don’t do what you have promised, you will have a hard time building a business relationship. “First impressions are powerful. If you gain trust at the beginning, after that your relationship will develop much better”, she added. Jānis Lisenko, CEO of the wooden house manufacturer SIA Dores Fabrika, also notes that one key to a long-term partnership is to keep promises. It is especially important when working with a new partner. `+
`<dd></dd>Sams recommends being realistic about your abilities. Otherwise, you can waste a lot of energy.  “However silly it may sound, big ambitions should be reasonable. You cannot just decide to double your sales tomorrow, you have to sit down and calculate the cost. If you have no investors to back this up with capital, you will have to work with your own funding and then your growth has to be sustainable. That is why a company with a turnover of 20 or 30 million cannot offer partnership to a company whose turnover is worth half a billion. It would be a struggle for both parties. Often entrepreneurs get so carried away with their growth, favourite product and geniality that they forget these basics”, Sams added.`+
`<dd></dd><h3 class="post-subtitle">Like hand in glove</h3>`+
`<dd></dd>HansaMatrix supplies three different products groups to Norway. They include 3D cameras made and installed by the company, which are used in robotic vision solutions. In the aquaculture sector, the Latvian company supplies underwater cameras, winches, control units, cables and power supply units which make up a system for monitoring and controlling processes at fish farms in fjords. The third group is power management. In this sector, HansaMatrix’s customer is a company whose business is to identify power management errors.`+
`<dd></dd>Currently, exports to Norway account for about 25% of HansaMatrix turnover. However, this can change very quickly because there are customers that may be inactive for a year and then place a huge order. For example, a Norwegian company has recently acquired the right to enter the Saudi Arabia electricity market and suddenly they required three times as many products. “So, today, exports to Norway may be 25%, but next year they might be 50% or 0%”, Sams explained. However, to avoid suspending business in a bad year, HansaMatrix is building long-term relationships with its partners. When, for example, a drop in sales is expected, often both partners discuss their options together.`+
`<dd></dd><div class="quote-style">“We work with our customer hand in glove – no company which has a steady long-term relationship wants to lose a partner. Normally, they are as interested in our relationship as we are. That’s why we work together through thick and thin. We sit down together and figure out what we can do for the business”, Sams said.</div>`+
`<dd></dd><h3 class="post-subtitle">There is great potential</h3>`+
`<dd></dd>“The Norwegian market is not an easy one. The country is very different, with its own standards, requirements and nationalism”, Lisenko said. In his experience, Norwegians are not too open to imported products. “That is why you have to go there and talk. They want guarantees that everything will meet their expectations. Currently, we are actively seeking partners in Norway, specifically in construction of industrially manufactured wooden frame houses. It’s not easy and Latvia is not always a word they want to hear in Scandinavia. We had many predecessors there whose work brought disrepute to our country and trust was lost. So, we go to our potential partners and show, tell and try to convince them that things aren’t as bad as they think”, Lisenko explained.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway5+`"></div>`+
`<dd></dd>Dores Fabrika experience shows that to enter the Norwegian market, a company should comply with various construction regulations and standards and be registered in multiple registers. Lisenko says that Norwegians ask about previous experience in Norway. If there are references from any existing customers or partners, things go easier. Partners also value your experience at home and in other countries. In the case of Dores Fabrika, more than 10 years of experience and 1,000 completed projects are an advantage. “Looking at the map, our working locations are all over the world. We have delivered our houses to New Zealand and Europe”, Lisenko said.`+
`<dd></dd>Today, Norway does not contribute a large share to Dores Fabrika turnover. “Nonetheless, it is a target market we are looking at and where we see a lot of potential. We still have plenty of work ahead of us”, he admitted. To find new partners, the company is working both on its own and with LIAA and Gateway&Partners.`+
`<dd></dd><h3 class="post-subtitle">Support in gaining new export contracts</h3>`+
`<dd></dd>Nedzvecka says that LIAA representative offices both in Norway and other countries offer a range of support activities to facilitate and help Latvian companies find new partners leading to new export contracts.  “The representative office can offer very practical help to businesses, such as lists of contacts and market reviews, support in arranging supplier meetings, informative events and market reviews. Other important factors are the representative office's network of contacts, knowledge and market insights in a specific country which may help companies to understand their export prospects better”, the representative in Norway added.`+
`<dd></dd><h3 class="post-subtitle">2.2% of total Latvian exports go to Norway</h3>`+
`<dd></dd>In 2022, total sales of Latvian goods and services in Norway amounted to EUR 815.6 million. This puts Norway in 19th place among Latvian foreign trade partners. Total exports of goods and services to Norway was EUR 631.9 million, or 2.2% of total Latvian exports. Imports of goods and services reached EUR 183.7 million, which represents 0.6% of Latvian imports. In comparison with 2021, exports grew by 33% and imports by 41%. In 2022, Norway was the 13th largest export and 27th largest import partner for Latvia.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_norway6+`"></div>`+
`<dd></dd>At the end of 2022, Norway was the 9th largest foreign investor in companies registered in Latvia. The total amount invested in 242 companies employing more than 6,000 people was EUR 304 million. The largest companies with Norwegian capital in Latvia are Linstow, Orkla, DNB, Moller Baltic Import, Visma, Narvesen, Circle K, Atea, Zalaris and Norwegian.`+
`<dd></dd>In future, in addition to sectors which are the backbone of Latvian exports, such as timer processing, metal processing and manufacturing of industrial goods, Nedzvecka sees great export potential for those companies that invest in research and innovations to create sustainable products and services with high added value which can successfully compete on international markets. “The future belongs to enterprising, innovative and green companies”, she believes.`+

	support_by_liaa,


	exp_review_6_name: "Stories about export to Australia",
	exp_review_6_company:"VALPRO, Laflora, Grindeks",
	exp_review_6_designation: "VALPRO, Laflora, Grindeks",
	exp_review_6_excerpt: `These days, distance is no longer considered an obstacle and a market with 26 million consumers also beckons Latvian manufacturers who see great trade potential and people’s openness and preparedness to cooperate.`,
	exp_review_6_title:"Destination Australia. An export market 14,000 kilometres away",
	exp_review_6_text:

`<dd></dd>These days, distance is no longer considered an obstacle and a market with 26 million consumers also beckons Latvian manufacturers who see great trade potential and people’s openness and preparedness to cooperate.`+
`<dd></dd>The European Union is Australia’s third largest trading partner and the volume of trade between Australia and Latvia is also showing a growing trend. Two years ago, the Latvian Embassy in Australia was opened and at the beginning of this year we finally received an economic representation of our country on that continent. “With the opening of the Embassy and the forthcoming free trade agreements, the economic representative office was a logical next step that shows the importance of this market for Latvia”, said Sabīne Kazaka, Head of the Investment and Development Agency of Latvia (LIAA) Representative Office in Australia. The free trade agreement has been discussed since 2018 and its signing would mean lower tariffs on various export goods. This step is expected to boost interest in Europe. “We can already see a growing interest in Latvian solutions, so the presence of Latvian representatives is significant and valuable,” says Kazaka.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia1+`"></div>`+
`<dd></dd>In 2022, total sales of Latvian goods and services in Australia amounted to EUR 81.7 million. Australia is in the 50th place among Latvia’s foreign trade partners. The export of goods and services to Australia was EUR 52.5 million, or 0.2% of total Latvian exports. At the same time, imports reached EUR 29.2 million. “In comparison with 2021, last year exports grew by 31% and imports by 11%. The trend is upward, and it is clear that with the opening of the Embassy and the representative office the exports will definitely grow”, Kazaka predicts.`+
`<dd></dd><h3 class="post-subtitle">Today, traditional industries are in the lead</h3>`+
`<dd></dd>The three largest groups of Latvian goods exported to Australia are timber and timber products, machinery, tools and electrical equipment, and various minerals, such as peat. Export to Australia accounts for about 1% of sales of the peat producer SIA Laflora and in recent years the demand has been growing. Irina Sintniece, Head of Exports at SIA Laflora, explains this growth by limited offer from other companies and the volatility of the peat market. “Peat substrate is great for growing almost anything, but considering a very different climate, water availability, soil composition and local crops in the Baltics and in Australia, we offer them a raw material, i.e. fractionated peat without additives, so that they can add the necessary mineral fertiliser and offer a product which meets the needs of the local market”, Sintniece explained.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia2+`"></div>`+
`<dd></dd>Kazaka points out that the timber deficit in Australia has opened more opportunities for Latvian companies.  Last year, there were multiple building projects which initially used Australian and New Zealand timber, but halfway into the projects, the lack of the products and high prices forced them to turn to European timber as well. Considering differences in climate, soil and humidity, combining such products is not always straightforward or even possible, so Australian companies are looking into getting all their timber from one country or region. “Currently, I am working with several Australian companies which are planning to buy all timber for their projects from Europe and possibly from Latvia. Interest in Latvian timber is really great, as evidenced by the Australian companies’ visit to Latvia at the end of June”, said Kazaka.`+
`<dd></dd><h3 class="post-subtitle">A large market with 26 million people</h3>`+
`<dd></dd>“Australia has a population of 26 million and in the past 20 years it has grown by five million”, Juris Hmeļņickis, Chair of the Board of the pharmaceutical company AS Grindeks, noted. A growing population means an aging society, and sooner or later they will need drugs. For many years, Grindeks has been working with corporate customers but last year saw an opportunity to start offering its product to the end consumer. “We will create a portfolio of 50 to 60 products which we can offer our patients straight away. We have chosen Australia as our strategic market where we will offer a wider range of drugs rather than just a few products as before”, says Hmelnickis.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia3+`"></div>`+
`<dd></dd>Kazaka adds that the market is large, but competition is fierce indeed.  Nonetheless, she believes that quality goods and services with high added value have great potential. The main thing is to offer a competitive price. “As a result of the Covid-19 pandemic, container prices skyrocketed and many Latvian companies were forced to stop their deliveries for a while, but now the costs are getting close to what they were before the pandemic”, Kazaka says. `+
`<dd></dd><h3 class="post-subtitle">The product should be prepared for a long journey</h3>`+
`<dd></dd>It takes two or sometimes even three months for a container to reach Australia by sea. “Freights are subjected to temperature fluctuations and humidity so the companies need to ensure that the product will be exactly as it should be when it arrives at its destination”, Kazaka notes.`+
`<dd></dd>Mārtiņš Orskis, Head of Sales of the manufacturer of petrol cans SIA VALPRO, explained that very salty sea water can affect quality of the products. That is why, before accepting your first order, you must know whether this type of delivery is suitable and what packaging will work best”, he added.`+
`<dd></dd><div class="quote-style">He recommends discussing the delivery forecasts with the customer in due time to avoid unpleasant surprises.  At the same time, Sinniece recommends keeping in mind the delivery cost fluctuations, as logistics is a significant part thereof and affects the price of the product. </div>`+
`<dd></dd>“Freight services to Australia are not cheap, so everyone is trying to keep the costs down. We send large freight by sea, which of course takes longer. However, if two or three pallets are ordered, we will fly them there”, says J. Hmelnickis.`+
`<dd></dd>M. Orskis reminds us that Australia has its own dollar.  That is why he thinks it is better to get a partner to agree to payments in euros. “Another thing to discuss is a time zone for communication and meetings”, said Orskis.`+
`<dd></dd><h3 class="post-subtitle">No need to be afraid of certification</h3>`+
`<dd></dd>Orskis says that almost everywhere in the world imported goods are to be certified, as is also the case in Australia.  “All goods must be certified to meet their requirements. The huge difference from other countries is that Australia sets the bar very high and simply does not allow imports of any low-quality products”, he said. It has certain advantages. For example, in one of the tests, VALPRO petrol cans were dropped to the ground from a height, and it appeared that sometimes the lids opened. Seeking a solution for this problem, the company came up with a special latch to secure the lid. Australians and Latvians patented this solution together. “Thus, thanks to the Australian high standards we have made a valuable improvement to our product”, shared his experience Orskis. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia4+`"></div>`+
`<dd></dd>Kazaka also urges fellow entrepreneurs not to be afraid of certification. Of course, this process requires effort and time, but the rules are clear: “You just have to be patient.” For Grindex, the journey from drug registration to marketing takes from eighteen months to two years over there. Now, Australia accounts for about 1% of the company’s sales. “We export to more than 100 countries. Of course, we have markets with higher export figures, but we are happy about each steady and regular sales channel. We are looking to increase our turnover in Australia significantly”, Hmelnickis says. He adds that while in Australia, you can also work on exports to New Zealand. That is to say that if the products are already registered in Australia, it gives the company more credibility in the eyes of the New Zealand authorities and its will be easier to enter that market. “We are not focusing on New Zealand at the moment, but I think that in the long run, it may become another export country with steady sales”, he says.`+
`<dd></dd><h3 class="post-subtitle">A country the size of a continent with multiple time zones</h3>`+
`<dd></dd>“When a country occupies a whole continent and has six states with their own constitutions, parliaments and even climate, it is obvious that demand will vary region from region”, says Kazaka.`+
`<dd></dd><div class="quote-style">It’s winter in Australia now, and snow and winter fun are only a four-hour drive away from Melbourne while just a two-hour flight separates you from enjoying sun and 20+ degrees. </div>`+
`<dd></dd>Temperature differences also mean different wildlife. “For example, Adelaide in South Australia is a home to the white ant, which is the most devastating wood termite. Although timber frame houses are very common in Australia, there are no big market opportunities for timber frame, terrace or fence manufacturers in that region”, Kazaka explains.`+
`<dd></dd><h3 class="post-subtitle">Almost every Australian owns a petrol can made in Latvia</h3>`+
`<dd></dd>VALPRO’s history in Australia is a long one. “The factory where VALPRO currently operates has been manufacturing fire-fighting equipment and petrol cans since the last century. Even back then, petrol cans were making their way to Australia. During Atmoda years, Australians sought their old partners, as they wanted to get the quality they already knew. So, in 1992, together with Australians, Latvians privatised the factory and established the joint venture A/S Valpro Corp. Currently, Australia contributes about 5% to VALPRO overall sales.`+
`<dd></dd>The company's classic 20-litre petrol can is owned by pretty much every Australian. “One thing is for sure: our capacity is impressive. However, if we keep offering the same thing, sooner or later we will fill the market to the brim. That is why we have developed new products. We also have 2, 5 and 10 litre cans, as well as various accessories and pouring spouts for cars”, Orskis says.`+
`<dd></dd>Although Australia is not the most densely populated country, people have to travel long distances. Camping is popular there and it has very developed agriculture – and both need petrol backup. “So, the demand for our products is great”, he added.`+
`<dd></dd><h3 class="post-subtitle">Partners are the key to the market</h3>`+
`<dd></dd>S. Kazaka points out that partners are often used for a successful market entry and provide information about business culture and human resources in the target market while also ensuring a presence effect.  “Here, same as in other countries, trust is built on meeting in person. That is why, for a successful start of business here, I recommend coming and meeting people in person rather than just working through business partners”, she pointed out. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia5+`"></div>`+
`<dd></dd>M. Orskis also believes that you should come and meet with your partners in person to establish contacts and build a trusting relationship. “Emails are a great, but it is always worthwhile to meet your partners in person. Australia has a large enough Latvian diaspora, the LIAA representative office and the embassy, and you should use these contacts. They are responsive and want Latvian companies to succeed in this market”, Hmelnickis says.`+
`<dd></dd>I. Sintniece recommends finding a reliable partner who can deal with problems and find compromises.  “For example, once we received a claim from the client that termites were found in pallets, and they had already started to damage the timber. Sawmills at our latitude do not have termites, so we had to visit our delivery partners. After a careful assessment of the situation, it turned out that peat from containers had been unloaded in the warehouse and remained there for months resulting in termite infestation of the pallets. Australia is way too far away to risk working with a financially unstable company or to increase sales too quickly”, he shared his experience.`+
`<dd></dd><h3 class="post-subtitle">Open but on their own terms</h3>`+
`<dd></dd>“As any other market, Australia has its own peculiarities. The business environment is different, but you can see that Australians work with companies in Europe a lot and are open to cooperation with Latvia”, Kazaka says. Hmelnickis believes that Australia offers steady sales, a stable currency and clear rules of the game. “There are strict rules to follow when coming to the Australian market. I got the impression that they are very pedantic – everything is planned, and everything is in its place. I like it, and I can see that it is possible to build a steady and growing business there in the long term”, he said, adding that people are open in that market. Everyone whom Hmelnickis has met are immigrants: they either came to Australia decades ago or were born there, but their parents were immigrants. “The state is tolerant to incomers. You can even feel it on the streets”, he says.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_australia6+`"></div>`+
`<dd></dd>Kazaka lives and works in Melbourne and describes the community there as international and a very open one.  “Australian indigenous peoples are aborigines who currently account for about 3% of the population. Almost everyone I’ve met have parents or grandparents who had come to Australia, so the notion of moving to the other side of the world is not that strange to them and they are very welcoming”, Kazaka noted.`+
`<dd></dd><h3 class="post-subtitle">Understanding and ready to do business</h3>`+
`<dd></dd>According to Orsky’s observations, Australians are understanding, ready to cooperate and open to various solutions.  For example, there is different information inscribed on VALPRO petrol cans, and it takes a lot of time to put it there. When the manufacturer simply asked its partners if it was really necessary, it turned out that it was not. He concludes that partners in Australia are focused on cooperation and finding a solution together. Orski compares: “In Germany, everything is very punctual, strict and to the letter of the contract. It’s not like that in Australia where partners are ready to discuss everything. It is important to remember it in daily communication with Australians.”`+
`<dd></dd>The company has been in communication with its partners for 30 years now. People in the partner company change from time to time, but the friendly relationship continues. “If you listen to a conversation between my colleague and our Australian partner, you will hear that they first discuss how the family is doing and only then get to the questions about an order. It is important to build a close relationship and maintain a friendly attitude. I believe that is one of the keys to our success in Australia”, Orskis is confident. S. Kazaka’s experience also shows that a close relationship is the key to a long-term cooperation, but you cannot overstep the boundaries where a relationship leads to a breach of contract.`+
`<dd></dd><h3 class="post-subtitle">Answers will not always come quickly</h3>`+
`<dd></dd>Although English does not have the status of the official language in Australia, de facto it is used like one, and Latvian companies have no problem communicating with their partners in that market. Hmelnickis notes that Australian English is easy to understand and cannot be compared, for example, with Scots who also speak English, but their accent is much more difficult to understand. `+
`<dd></dd>Kazaka points out that you don’t come across the Australian accent in the business environment a lot, but often pronunciation and even words may be different.  Communication with Australian companies is a bit slower than to what we are used in Latvia, and you have to remember that with the vacation season, the business environment slows down a bit from mid-December to mid-January. Kazaka does not recommend planning any agreement signing, visits or business meetings for that period.`+
`<dd></dd><div class="quote-style">“After visits of Latvian entrepreneurs to Australia and Australian businesses visiting Latvia, I can say that decision-making can be quick when both parties have clear, acceptable rules and a good rapport. In some instances, we were waiting for a response from an Australian company for a long time, but as soon as we offered a face-to-face meeting we received their answer immediately, which goes to show how important face-to-face meetings are”, Kazaka noted.</div>`+
`<dd></dd><h3 class="post-subtitle">Opportunities not to be missed</h3>`+
`<dd></dd>“Even one successful project gives a great growth potential in the Australian market, as previous experience is highly valued and shows that long distance is not a problem for the company”, Kazaka says. That is why she urges entrepreneurs to narrow down the target audience a bit and work out the profile of their cooperation partner in more detail. “Everything is based on the market and demand research, so the LIAA representative offices are a great starting point for exploring a new export market. Every country has something that you need to know before establishing any cooperation to ensure a more successful market entry. We also work with large companies on a daily basis helping them to increase their market share or launch new products or services in the market, so the main thing is to know your abilities and use them”, Kazaka said. She sees great potential in the information and communication technologies and the digital sector as a whole.`+
`<dd></dd><div class="quote-style">“Currently, Latvian technology solutions are being used in Australia and have been for a couple of months already. In addition to a greater interest in the existing solutions, I have also seen potential for other companies and digital services”, Kazaka noted. She also points out that in Australia, Internet service is unstable and there are many areas without coverage.</div>`+
`<dd></dd>Ministers’ visits and a trade mission are planned for this year, as well as visits of entrepreneurs and investors from both countries. “First Latvian companies have already been received in Australia and two more visits are planned within a month. There is also an apparent interest from investors. I am currently working on a visit to Latvia in September. In my opinion, the number of visits even before the trade mission is a great indicator of the interest of businesses and the importance of the market and, hopefully, it will also convince other Latvians that distance is not an obstacle”, Kazaka says. `+

	support_by_liaa,



	exp_review_7_name: "Stories about export to the United States of America",
	exp_review_7_company:"DeskTime, Gamechanger Audio, Giraffe360",
	exp_review_7_designation: "DeskTime, Gamechanger Audio, Giraffe360",
	exp_review_7_excerpt: `Everybody has heard that the US is the land of opportunity. Although is it a huge country geographically, with significant differences from state to state, fierce competition and legally complicated market, many Latvian companies are ready to face and overcome this challenge and prove that nothing is impossible.`,
	exp_review_7_title:"USA, the Land of Opportunity",
	exp_review_7_text:

`<dd></dd>Everybody has heard that the US is the land of opportunity. Although is it a huge country geographically, with significant differences from state to state, fierce competition and legally complicated market, many Latvian companies are ready to face and overcome this challenge and prove that nothing is impossible.`+
`<dd></dd>Ivars Slokenbergs, member of the board of Latvian American Chamber of Commerce and a founder of the New York based law firm Slokenbergs PLLC, says that the US market has always been important to Latvian business, but for some reason, entrepreneurs have not fully used it and for many it has been inaccessible for various practical reasons. Why? `+
`<dd></dd><div class="quote-style">Firstly, the sheer distance is a challenge in itself. Secondly, running business in the US is expensive as you have to invest substantial amount to grow in that market. Thirdly, with its large geographical area, significant regional differences and fierce competition, the US is a very challenging market. On top of that, it has complex legal regulation. </div>`+
`<dd></dd>“Historically, Latvian businesses have always done better investing money and effort in the Baltics, other neighbouring countries and Eastern Hemisphere markets not really looking to North America,” Slokenbergs says.`+
`<dd></dd>However, times change, and, according to his observations, in the last 10 years the belief that the US market is out of reach has changed. “Latvia came out of the global crisis relatively scot-free realigning its existing export market, improving efficiency and looking for new markets where Latvian goods and services would be competitive. We can count the US as one of these new markets as, despite the distance, high costs and complexity, the dynamic and huge US market rich in opportunities can no longer be ignored. Latvian manufacturers understand that conquering the European market is not enough, and America offers extensive opportunities for growth,” Slokenbergs believes.`+
`<dd></dd><h3 class="post-subtitle">Important foreign trade partner</h3>`+
`<dd></dd>In 2022, Latvia’s total goods and services trade volume with the US was 1.5 billion euros, which puts the US in the 11th place among Latvia’s foreign trade partners. Of this amount, exports of goods and services to the US was one billion euros, which was 3.6% of the total Latvian exports making the US Latvia’s 9th largest export partner.`+
`<dd></dd><div class="quote-style">Timber and timber products (34%), machines, mechanisms and electrical equipment (24%), optical devices and equipment (8%), chemical industry products (7%), food industry products (6%) and building materials (4%) made up most of the Latvian exports to the US. </div>`+
`<dd></dd>“The range of Latvian products exported to the US is very broad, from timber and optical instruments to food,” Slokenbergs says.`+
`<dd></dd>Together, USA and Europe form the largest bilateral economic community and in 2021, the total US-Europe trade volume was 1.2 trillion euros. “Direct investments between Europe and the US are equally impressive with the US investments in European Union exceeding the US investments in the Asia-Pacific Region four times while the total European Union investments in the US exceeds the total investments of the EU members to India and China tenfold,” Slokenbergs notes. He is confident that there are also opportunities for Latvian businesses in this massive flow of trade and investments.`+
`<dd></dd><h3 class="post-subtitle">Start-ups affect export trends</h3>`+
`<dd></dd>In Latvia, the technology start-up sector has grown in the past ten years. These companies aim at the global market since their conception because local consumers are not enough for technological solutions they offer. “Moreover, the US is the largest start-up market in the world, both in terms of technology consumer numbers and possibilities of finding investors. That is why most start-ups are planning to enter the US market right from day one,” Slokenbergs explains.`+
`<dd></dd>He adds, however, that the US is an important market not just for start-ups, and any exporter can strike gold there. With the population of just under 340 million people and generally high purchasing power, the USA generates 24% of the gross global product. “They have a structured and predictable business environment with countless opportunities where everybody can find their niche. Of course, export should be planned carefully, analysing and identifying the best entry point to the market, investing funds and finding reliable partners and advisors, including lawyers, accounting and tax advisors and other professionals,” Slokenbergs recommends.`+
`<dd></dd><h3 class="post-subtitle">Large and diverse market</h3>`+
`<dd></dd>Slokenbergs explains that the US is a federation with 50 states, each with its own legislation, and is almost 10 million square kilometres in area.  Miks Opelts, a co-founder and CEO of the property technology start-up Giraffe360, believes that the size of America is what makes its market so appealing, because if you can gain a foothold in the market with 339 million people, the yield will be proportionally higher than in any single European country. “When I came there first, a friend told me that it is a free country, and I have that feeling too. America is very dynamic,” Opelts says. At the moment, the US make up 20% of the Giraffe360 sales, but it is the company’s fastest growing market. He expects that in 18 to 24 months, America will be generating the largest share of the company’s turnover. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america1+`"></div>`+
`<dd></dd>Large size means that you have to deal with different business environments and cultures in the market. “New York, Silicon Valley, Texas, Middle America and the South are different both geographically and culturally. In addition, it is very different when you work with large global corporations based in the US, which tend be slow and bureaucratic, and smaller businesses with which everything happens faster and with fewer formalities. You cannot understand it by watching Hollywood TV shows which have little in common with reality. You have to experience the US business culture first hand”, Slokenbergs notes.`+
`<dd></dd><h3 class="post-subtitle">Cultural differences are not huge</h3>`+
`<dd></dd>“It may seem that the US and Latvia would be like day and night in terms of cultural differences. However, with our product, the differences aren’t that big. In America, they look at the time spent by employees and the productive hours rather than whether an employee is sitting at the computer clicking away at that particular moment,” explains Uģis Briedis, Head of Business Development for the desktop time tracking tool DeskTime.`+
`<dd></dd>Opelts also believes that, in comparison with Europe, there are fewer culture-specific restrictions in the US. For example, Sweden, France, UK and Germany all have their cultural specifics which have to be taken into account. However, America is a free and unchained market focused on commerce and speed. “America is less focused on the past, history and culture. They are more interested in how they can make money out of a product,” he notes.`+
`<dd></dd><h3 class="post-subtitle">USA is a costly market</h3>`+
`<dd></dd>Opelts believes that there are much fewer barriers in the US market than in Europe.  “One big barrier is money. America is a very costly market, from employee pay to office rent. European costs pale in comparison with the US, even if you talk about Switzerland,” he added. In his experience, any costs to which you are used in Latvia should be multiplied by four or even ten in the US. That is why he believes that you cannot start in that market carefree. To enter the US market, you have to have a strong investor support or do it on the back of success in other export markets. “With my experience in the UK and Germany, I feel much more comfortable in the US, and now also we have capital available to take root,” Opelts says.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america2+`"></div>`+
`<dd></dd>Briedis also recommends accumulating experience and money in Europe and then invest in America: “The US market looks very tempting. However, if you can afford it, I recommend to build your export muscle in Europe first and only then go to America. Business in Europe is relatively less costly and when Europe starts generating steady profit, you can spend it in the US. It was a time when we also wanted to venture into America just like that, and got burned.”`+
`<dd></dd><h3 class="post-subtitle">Start with the US</h3>`+
`<dd></dd>Although experts warn against choosing the US as your first export market, the experience of the music technology company Gamechanger Audio is quite the opposite. “With one in twenty people having it in their home and one in thirty playing it pretty much regularly, the guitar is a national musical instrument in the US. There are even special rules allowing people to take a guitar on board airplanes and prohibiting airlines from checking guitars in as luggage if their owners object. That is why we started our business with America straight away, and in English,” Didzis Dubovskis, Gamechanger Audio co-founder says.`+
`<dd></dd>Company started is business with a presale. Gamechanger Audio announced its first product, guitar pedal Plus Pedal, offering to book it in advance and pay half the price. “It was like a crowdfunding campaign on our website. That’s how we got our first real customers,” Dubovskis explains.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america3+`"></div>`+
`<dd></dd>Now Gamechanger Audio products are used by many global music stars. Among them are Aerosmith guitarist, Rolling Stones bassist and guitarists playing in Roger Waters, Nick Cave and other musicians' bands. Working together with the White Stripes founder Jack White, Gamechanger Audio has developed a product to suit his specific wishes.`+
`<dd></dd><h3 class="post-subtitle">No need for distributors</h3>`+
`<dd></dd>Dubovskis says that the world is changing: if before it would have been unthinkable to start in that market without an intermediary, now it is possible.  “In our niche, a distributor buys a product for half the end product price, but a store for a third or even a quarter. We could not offer large production capacity straight away as we were not sure how many people would buy our products, and we could not think of the best way to sell our products. That is why we distributed our products ourselves in the US and worked with the stores. Now, our products are available in more than 100 stores across America. About 80% of our products are sold in stores and 20% online on our website.`+
`<dd></dd>Online store helps build a customer base and website sales give an important figure which store owners want to see. If you can show a convincing number to your potential partner, you will have a better partnership. “Our strategy was to build a small customer base ourselves and to move on to larger customers, i.e. music stores, from that. It was a cautious low-budget strategy. If we were to start over now and had more money available, maybe we would do it differently, with more of a ‘bang’,” Dubovskis admits.`+
`<dd></dd><h3 class="post-subtitle">Exporting from day one</h3>`+
`<dd></dd>Giraffe360 does not think of the US as an export market. “We have customers in 30 countries and there are both Americans and Swiss among them. Most of our customers in Germany are of Turkish origin and of Latin American in the US. So thinking about the US as one market or customer is like shooting yourself in the foot. There is always a person on the other side, and they all have their own way of thinking and their own mind-set. That is why the approach should be very simple: what the customer wants and how we can do it,” Opelts believes.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america4+`"></div>`+
`<dd></dd>DeskTime owner's Draugiem Group philosophy is to go straight to exports. DeskTime has been offered internationally from day one, and it’s in English. We are also actively working on search engine optimisation and public relations to promote our product in foreign markets. As a result, the product has seamlessly made its way to the US,” Briedis says.`+
`<dd></dd><h3 class="post-subtitle">Business is managed from Latvia</h3>`+
`<dd></dd>DeskTime has half a million users worldwide. “We work with the entire globe from our office in Torņkalns. It can be done from here, from Latvia, there’s nothing impossible about that,” Briedis notes. In its other export markets, DeskTime works with partners, but the US is an exception and business there is managed from Latvia. `+
`<dd></dd>“Everybody is here and information exchange happens very quickly, which is a great advantage both for us and other companies of the group. If there are changes to make, we are not slowed down by time zones. Of course, as the company grows, we are thinking of moving some operations to the US, but for now we are good here in Latvia,” Briedis says. Considering the time difference between Latvia and the US, DeskTime tech support works in shifts.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america5+`"></div>`+
`<dd></dd>Gamechanger Audio also runs its business from Latvia. “We benefit from the US taxation system where import duties and customs clearance requirements start from 800 US dollars. So we can send our 400-dollar product with a 29-euro courier service from Rīga to arrive in America in two days’ time,” Dubovskis reveals.`+
`<dd></dd><h3 class="post-subtitle">No shortage of competition</h3>`+
`<dd></dd>Speaking about challenges, Briedis admits that DeskTime is neither the first nor the only company offering employee time tracking software in the US market. “Since the Covid-19 pandemic, the time tracking market segment has become very saturated. Our competition has quadrupled. The entire world has started to develop time tracking solutions, some better, some worse, but we have kept our place in the top 10.`+
`<dd></dd>Another challenge is that DeskTime is not a venture capital funded start-up and invests its own, not some investors’ money in the development. “We are working with the money we earn ourselves, but we have to compete with the companies which have access to hundreds of millions of investors’ capital. It is harder to compete in such circumstances, but it’s not impossible. That is why we are very proud that our solution can compete with large companies worldwide. Yet again it proves that with their persistence and hard work Latvians can compete with global players on pretty much equal terms,” Briedis points out. The US market generates about 35% of DeskTime sales.`+
`<dd></dd><h3 class="post-subtitle">Not your typical sales team</h3>`+
`<dd></dd>DeskTime does not have a classic sales team which would make cold calls and send emails to potential customers. “It would be difficult to sell this product just by calling businesses which do not know the first thing about it”, Briedis says. `+
`<dd></dd>The product has been designed in such a way so that potential customers would understand everything from information on our website. However, we have a team which advises new customer on how to use the product. “It is typical for the American market that they learn things themselves very well. They like reading, they like to dig deep. We have a very extensive FAQ section. We have video tutorials which people watch because they know that they will have to use the product themselves. Any only when they don’t understand something would they book a support call,” Briedis says. Mainly the team call large customers which have specific questions to ask, e.g. about security, before closing a deal.`+
`<dd></dd>For DeskTime, a word of mouth works very well with the customers who are satisfied with the product being their best promoters. “We are also focusing on review sites, e.g. G2 and Capterra, where we have a very good rating,” he says.`+
`<dd></dd><h3 class="post-subtitle">Internet is our home</h3>`+
`<dd></dd>“My home is the Internet,” Dubovskis says. The company uses Facebook and Instagram ads, which work well to get people interested, and influencer marketing. `+
`<dd></dd><div class="quote-style">“We use digital advertising to make people curious about our brand and lead them to our website. However, we understand that they will not make a purchase straight away. People want to see a review and a demo first. So they will search our product on YouTube. That’s why it is important that they see popular device review channels straight away, and it generates trust,” Dubovskis explains.</div>`+
`<dd></dd>The company takes a conservative approach to its potential customer segmentation on social media and looks at its competition and their followers, as well as people that follow their competitors’ brands.`+
`<dd></dd><h3 class="post-subtitle">Actively invest in digital advertising</h3>`+
`<dd></dd>DeskTime directs at least 20% of its annual sales to digital advertising. “As a digital company, we have no problem measuring investments. Investing in Google advertising and search engine optimisation, we can see the amount of investment straight away and calculate how many customers we need to recruit with these. We have hired a foreign agency specialising specifically in digital solution advertising to measure our achievements,” Briedis reveals.`+
`<dd></dd>Advertising in the US is expensive, so search engine optimisation and content generation are important. For DeskTime, quality blog content works well. However, Briedis points out that you cannot write one blog post and hope that it will work wonders. That is why there are five to six new posts on DeskTime blog every month. “Recently, a Canva board member who at some point worked for Apple joined us in one of our blog posts. We had a huge surge in visitors on our website then which brought us new customers,” Briedis says.`+
`<dd></dd>He recommends investing in search engine optimisation. “Of course, your product must be good to begin with, otherwise no search engine optimisation can help,” he noted. It is also important to think about keywords to make them different from the ones used by competition.`+
`<dd></dd><h3 class="post-subtitle">Prepare carefully</h3>`+
`<dd></dd>Slokenbergs emphasises the importance of preparation: you have to be informed about your sector, US regional markets, prices and costs, industry players, organisations, events, etc.  “The Internet and digital tools enable any Latvian company explore their export options and potential customers and buyers even before making the first step,” he points out. `+
`<dd></dd>He also recommends exploring and getting to know specific industry associations and exhibitions, which is also a good way to prepare for a journey to the US market. After completing research and getting your business plan ready, begin investing; go to the US regularly to meet with partners and potential customers, to attend events and to network. “Looking to establish long-term relationship is crucial. In order to build trust and relationships, you have to meet people, have lunches, attend events and participate in conferences, seminars and exhibitions regularly, every year. It is necessary because from all the sellers in the market, the US buyer will prefer the one they have a personal and trusted relationship with,” he points out.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_america6+`"></div>`+
`<dd></dd>Slokenbergs recommends using support instruments offered by the government and various organisations, such as Investment and Development Agency of Latvia, which has a representative office in San Francisco and Boston, Latvian Exporters Association The Red Jackets, Latvian Chamber of Commerce and Industry, American Chamber of Commerce in Latvia and Honorary Consul of Latvia network in the US.  “Each of these organisations is its own source of information about the US market and each has its own specifics,” he says. `+
`<dd></dd>American Chamber of Commerce in Latvia will hold the Spotlight Latvia conference which will take place on 22 September in Boston and gather many high-profile entrepreneurs and professionals from Latvia and the US.`+
`<dd></dd><h3 class="post-subtitle">Think about legal matters from day one</h3>`+
`<dd></dd>Slokenbergs believes that it is crucial to think about legal matters from day one, i.e. how to navigate the complex US legal system and to protect your interests.  “One thing is to export something from a Latvian company, and a completely different thing is to establish a separate company in the US to do business there. Having a US company has many advantages and often is a necessity, for example if you want to open a US bank account or employ people there,” he explains. `+
`<dd></dd><div class="quote-style">Latvian entrepreneurs tend to open companies in the US only because Americans find it more convenient to work with a business with a local address and a US bank account rather than a Latvia-based company. </div>`+
`<dd></dd>Gamechanger Audio experience is the same. “US businesses are used to the fact that everything is happening inside the country, and they don’t have to deal with any import formalities,” Dubovskis says. The company has a sister company in the US which represents Gamechanger Audio on Amazon and is the official importer for the customers who would only buy from inside the country.`+
`<dd></dd>“If you decide to open a US company, you must understand that the corporate environment, governance and company property structure there is very different from what we are used to in Latvia. These matters require careful consideration, best with the help of a US lawyer, especially if the company will have multiple owners, including a local partner. Moreover, there are major differences in labour laws, contract conditions, etc. You cannot go to the US thinking that you will deal with legal matters same way as in Latvia. And I definitely would not recommend trying to save money and sort everything out yourself without local lawyers and tax consultants, because it can go very, very wrong,” Slokenbergs says.`+
`<dd></dd>Preparations for the US market will also involve protection of intellectual property, i.e. patents, commercial secrets, copyrights, trade marks, etc. It is also important to comply with the US federal and state tax regimens to avoid unnecessary risks, especially in terms of cross-border taxation.`+
`<dd></dd><h3 class="post-subtitle">Opportunities of economy champions</h3>`+
`<dd></dd>Slokenbergs recommends being patient and not to expect quick results in that market.  He recommends finding a suitable niche in the US market and to build long-term, trusted relationships with the American partners. “Latvia will always be small in comparison with the US, but it does not mean that Latvia cannot create global economy champions”, Slokenbergs believes. `+
`<dd></dd>As one example he mentions Printful, which is the first Latvian unicorn, a start-up whose value after finding investors has reached one billion US dollars. In 2021, the US investor Bregal Sagemount invested 130 million US dollars in the Latvian company. “Every such case will strengthen our ties with the US market and bring about new opportunities for Latvian businesses and closer economic partnership will further boost our strategic security ties with the US. Such growing cooperation is a precondition for ensuring future opportunities for Latvian people and will give Latvians confidence to keep living, working and creating in Latvia rather than anywhere else,” Slokenbergs believes.`+

	support_by_liaa,


	exp_review_8_name: "Stories about export to South Korea",
	exp_review_8_company:"SPH Engineering, Kronus, Ette Tete",
	exp_review_8_designation: "SPH Engineering, Kronus, Ette Tete",
	exp_review_8_excerpt: `Although the South Korean market seems distant and strange to most Latvian companies, over the last 10 years the trade volume between the two countries has grown fivefold, reaching almost 130 million euros. Those who know this exotic market well, see the future of cooperation with South Korea in a positive light with great potential for both increasing trade volumes and diversifying export products.`,
	exp_review_8_title:"Tempting South Korean market which has no taste for pastel shades",
	exp_review_8_text:

`<dd></dd>Although the South Korean market seems distant and strange to most Latvian companies, over the last 10 years the trade volume between the two countries has grown fivefold, reaching almost 130 million euros. Those who know this exotic market well, see the future of cooperation with South Korea in a positive light with great potential for both increasing trade volumes and diversifying export products.`+
`<dd></dd>In 2022, South Korea was Latvia’s 40th largest export and 42nd largest import partner. “This is one of the most important remote markets for Latvia. Representative Office of Latvia in South Korea is a proof of it, as nobody has thought of opening an office here just like that. The Investment and Development Agency of Latvia has conducted a survey of entrepreneurs, and the importance of this market is a conclusion based on data”, says Mārtiņš Baumanis, Head of the Representative Office in South Korea.`+
`<dd></dd>He compares South Korea with a market of a wealthy Western European country, such as Germany or France. And to ignore a business opportunity this big would be madness. “If a company already has a steady export to Europe and needs to grow, this is one of the first markets to invite yourself to”, he believes.`+
`<dd></dd><h3 class="post-subtitle">The market demands quality</h3>`+
`<dd></dd>“The country is well-developed, and people seek new products. Improving the quality of life in South Korea is something people are increasingly thinking about. South Korea has gone through an economic leap. People do work hard here, but now they are starting to think about how to have a better life and find balance between home and work rather than how to earn more money”, Baumanis says.`+
`<dd></dd><div class="quote-style">Considering that buyers in South Korea value quality of life, products must also meet their high expectations. “In South Korea, Chinese products are widely available, but quality is an important factor for Koreans. This is the reason of the high demand for things that improve quality of life and for children’s goods on which the locals spend quite a lot of money. </div>`+
`<dd></dd>For example, South Korea is one of the most important export markets for Latvian children’s books. Moreover, any premium item with a story will be appreciated in South Korea”, he adds. In the field of industrial products, Latvijas Finieris is doing well in South Korea partnering with shipbuilding companies there. Baumanis believes that this cooperation has a potential for the development. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea1+`"></div>`+
`<dd></dd>“South Korea is a highly developed market in terms of technologies. They invest heavily in high-tech and look at technology companies from other countries. I like that the South Korean market is always on the move. They are thinking and developing non-stop. We have a good synergy with our partners because we both want to be at the forefront and think about driving technologies forward all the time”, says Madara Mazkalne, Sales Director at the drone technology development company SPH Engineering.`+
`<dd></dd><h3 class="post-subtitle">Completely different marketing</h3>`+
`<dd></dd>Baumanis notes that South Korean partners often want assistance with marketing.  So you should not think that you can just dispatch the goods and your partners will be selling them all by themselves. “It’s not how it works in real life. They would often expect some financial support or a discount for adapting the product marketing to the South Korean market. Otherwise, they will place your products on the bottom shelf and subsequently sell them at half price, and the next order will not follow. And the market is gone for that Latvian manufacturer”, he explains.`+
`<dd></dd><div class="quote-style">To start a cooperation, it is important to show your good will so that the other party feels that you are interested in a long-term cooperation. </div>`+
`<dd></dd>That is why he recommends offering a discount for the first order, say 10%, or some other special incentive. “They expect it because they do it themselves”, Baumanis shares his knowledge.`+
`<dd></dd>In the experience of Linda Riekstiņa-Šnore, the founder of the children’s furniture brand Ette Tete, South Korean partners are not overdemanding in terms of discounts: usually the cooperation is based on logic and with long-term outlook.`+
`<dd></dd>Ette Tete has left all marketing activities in South Korea to its partner because of significant differences in tastes. For example, nowhere else has the product personalisation come with so many cute hearts. “If we went in this market with our usual pastel palette, we would be lost there. So, we have left marketing to our partner”, Riekstiņa-Šnore explains. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea2+`"></div>`+
`<dd></dd>At the same time, personal involvement in business is important in this market. For example, when their partner received its first delivery and was preparing for sales, they asked Ette Tete to make a short video showing how excited the brand was about entering the South Korean market. “For them, a story is important of how we as a family created these products and that we are very happy about South Korean families who will also have them now. It is very personal”, she adds.`+
`<dd></dd><h3 class="post-subtitle">Cooperation may take a long time to take off</h3>`+
`<dd></dd>“Building relationships with Koreans is a long process. Everything happens somewhat slowly, so you have to have patience and a strong desire to enter that market. There are stories of success for Latvian businesses there, but they have required two or three years of effort, such as regular participation in exhibitions and communication with partners. Your desire to enter the South Korean market has to be strong”, Baumanis says.`+
`<dd></dd>On the other hand, Riekstiņa-Šnore believes that a lot of things depend on what kind of person is on your partner’s side. Ette Tete managed to get a contract relatively quickly and everything went on pretty smoothly. “We still have discussions about the product and its adjustments from time to time because they have a slightly different idea of what a quality product is. The paint layer is thick enough to protect the wood, but we still want to see the texture of the wood. It does not matter that much to them”, Riekstiņa-Šnore adds.`+
`<dd></dd><h3 class="post-subtitle">The power of hierarchy should be taken into account</h3>`+
`<dd></dd>Baumanis notes that South Koreans do not expect the foreigners to know their local culture or traditions.  However, you must remember to give your business card with both hands. “If you are given a business card, you must read it straight away and understand whom you have met. It is a sign of respect and it is very important”, he points out.`+
`<dd></dd>Mazkalne adds that South Korea has a clear hierarchy, so you must always understand who your point of contact is in your partner’s company and how high they are on the corporate ladder.  From that you can get an idea how fast things will be moving along.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea3+`"></div>`+
`<dd></dd>The head of the Latvian Representative Office in South Korea mentions some other cultural differences. Over there, nothing is written in red ink, especially people’s names. “During a conversation you must be 100% focused on the person you are talking with. Even making notes on your phone will be viewed negatively. Prompt feedback is also very important, and after a meeting, on the same day, you should write an email saying thank you for the meeting, the first steps taken and even propose time of the next meeting”, suggests Baumanis.`+
`<dd></dd><h3 class="post-subtitle">Business may also begin unexpectedly</h3>`+
`<dd></dd>Riekstiņa-Šnore admits that they came to South Korea by chance: someone working in imports bought a piece of Ette Tete furniture and then approached the company.  Ette Tete started working with their partner in 2020 and already in 2022 South Korea accounted for 5% of their sales. “I think there is still a huge potential left, because our trade volumes currently are relatively small. We can grow and then grow again there”, Riekstiņa-Šnore adds.`+
`<dd></dd>The South Korean partner of SPH Engineering has also showed its interest in cooperation proactively. “However, we did our homework very well and had a quality product”, Mazkalne says. Now the company has a dedicated employee focused on sales in Asia, including South Korea which for SPH Engineering has become the third largest export market after North America and Canada. Customers mostly use the software developed in Latvia both for drone flight planning and automation and for drone shows, but there have also been some custom made projects. “South Korea has the most impressive drone shows in the world. The local South Korean record belongs to a show where 1500 drones were up in the air on New Year’s Eve. And we can be proud that they are based on our technologies”, Mazkalne says.`+
`<dd></dd>She views the development prospects in the South Korean market positively: “Of course, we want to expand our business in South Korea and the entire Asia-Pacific region.”`+
`<dd></dd><h3 class="post-subtitle">Kronus exports to Korea account for 2 to 5% of sales</h3>`+
`<dd></dd>According to Aleksejs Volcenkovs, Kronus Sales Director in Asia-Pacific and Americas, people in South Korea love wood, but they don’t have the same forestry tradition as in Latvia. That is why a large share of timber industry products are imported to the country, and therefore Kronus sees a great potential for themselves in that market.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea4+`"></div>`+
`<dd></dd>With South Korea currently accounting for 2 to 5% of Kronus turnover, it is in the top 15 export destinations for the company. The company exports both wooden packaging and gardening products, such as high boxes for growing flowers or vegetables. It is in the gardening product category where Kronus sees great potential for themselves in South Korea.`+
`<dd></dd><h3 class="post-subtitle">Don’t forget about certification</h3>`+
`<dd></dd>Certification is one of the biggest challenges. “If you have an American or a European certificate, it’s great for marketing, but it won’t do in Korea anyway. So, the first step should be to find out the regulations and other requirements keeping in mind that South Korean standards are stricter than those of the European Union. Some authorities have a website in English, others don’t, but I can help with market research”, Baumanis says.`+
`<dd></dd>Riekstiņa-Šnore also notes that all products must comply with South Korean domestic standards. Ette Tete had to test their products, but everything complied with the relevant standards.`+
`<dd></dd><h3 class="post-subtitle">Everything happens in person</h3>`+
`<dd></dd>Baumanis admits that South Korea has its own challenges and peculiarities.  One of the biggest challenges is the distance. `+
`<dd></dd><div class="quote-style">“The money spent by a company on the journey from Latvia to South Korea is a bare minimum you need to invest to have a chance of establishing a relationship. Even during the pandemic Koreans did not switch to remote work, it’s simply not in their culture. They think that if you are not in the office, but at home, then most likely you are relaxing rather than working. That is why everything here is done in person”, Baumanis adds.</div>`+
`<dd></dd>And that is why personal presence when building relationships is invaluable. “In my work, I see proof of it all the time. I can send an email in Korean or English, but hardly ever can I tell whether any action will follow. But when we meet in person, it's totally a different thing. Sometimes, after a meeting, on the same day, they will tell you what they have already done and what we can plan further”, Baumanis shares his experience.`+
`<dd></dd>Mazkalne also says that the South Koreans need personal contact. That is why representatives of the company come to visit their partners. “And you should remember that it won’t be just one meeting. They need to build trust to both the company and its representatives”, Mazkalne adds.`+
`<dd></dd>Also, it is a market where people have great respect for their neighbours and those around them. Ette Tete sends its products disassembled and the buyer himself needs to put the product together. One of the products has 72 pins to be driven into a frame. The partner said that doing it with a hammer was too noisy and asked for this product to be delivered assembled. “At that moment, I didn’t even know what to say, because sending assembled products is very expensive as they take up more space”, Riekstiņa-Šnore says. In addition, tiny apartments are typical for South Korea so small and space-saving products sell the best.`+
`<dd></dd><h3 class="post-subtitle">Speaking the language is very important</h3>`+
`<dd></dd>“In countries like China, South Korea and Japan, understanding their culture is very important. Working in Asia requires knowledge, esteem, insight, respect and understanding of how they perceive information. Our sales specialist has lived in China for 10 years and her negotiations with our partners in Asia go very well. If at some point another colleague suddenly joins in, the response from the partners will come much slower”, Mazkalne says. She adds that the language barrier is one of the causes of misunderstandings. The company has been successfully working in South Korea for several years, but only recently has localised its software in Korean. “It has immediately increased their interest in replacing and actually using our software. Yes, they speak English, but if a company has marked South Korea as an important target market, they would need someone who speak the language”, Mazkalne adds. `+
`<dd></dd>Baumanis also points out that a relationship will be established easier and develop faster if the company can communicate with its customers in Korean. `+
`<dd></dd><div class="quote-style">“Language brings you closer to the culture and helps you get a better understanding why many things happen like that and not in any other way. Trying to speak their language is a huge advantage over a competitor”, he notes.</div>`+
`<dd></dd>Volcenkovs also mentions cultural differences, especially food. “A visit to South Korea may be a challenge for a Latvian as Koreans eat a lot of seafood, and often raw. It is not especially popular among us.”`+
`<dd></dd>Business negotiations are also quite difficult because it is hard to read emotions of the Koreans. “I would say that they always have that neutral facial expression, you know, a poker face. That is why business negotiations are much more difficult in comparison with how they are, for example, in Germany, Denmark or France, where you can deduce at least some of the thoughts and emotions of the other party from their facial expressions”, Volcenkovs says.`+
`<dd></dd><h3 class="post-subtitle">Business and personal relationships mix together</h3>`+
`<dd></dd>According to Baumanis, one of the peculiarities of that market is that business and private aspects of life are not as separated as elsewhere. “If an entrepreneur has a South Korean partner's WhatsApp number, there is a great chance that they will contact each other outside working hours, talk about their families and send each other pictures of their kids. South Koreans tend to erode the boundaries between work and private life. Sometimes it may seem unacceptable to Latvians”, he adds.`+
`<dd></dd>Riekstiņa-Šnore also had a hard time getting used to the close relationship with somebody she has never met in person.  For example, the company sent a Christmas gift to its partner and in return received pictures of the partners’ children playing with it. “Our children were born at about the same time. He sent me a happy email with a picture of his newborn, but I was wondering whether I had to respond with the same. We are not used to such communication. We do not tell our partner that we’ve had a child. Most of the time they have no idea of what's happening in my private life. But in South Korea, family and business life is indivisible”, Riekstiņa-Šnore says.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea5+`"></div>`+
`<dd></dd>Volcenkovs also notes that entrepreneurs in South Korea like to develop personal relationships with their business partners. Usually, business talks during the day are followed by dinner and drinks to get to know each other better. “It helps to build relationships. All our best business contacts have been established during dinners over informal conversations”, he adds.`+
`<dd></dd>Riekstiņa-Šnore shares her impression that after a supplier has built their partners’ trust and proven themselves, they may get away with more in that market than elsewhere in the world.  “If something’s gone wrong, but is being dealt with, your customers will remain loyal and continue working with you”, Riekstiņa-Šnore believes.  Volcenkovs also says that Koreans are very reliable partners. They also pay close attention to detail, and it makes their quality standards much stricter than European ones. `+
`<dd></dd><h3 class="post-subtitle">Continue with the cooperation</h3>`+
`<dd></dd>In 2018, President of Latvia Raimonds Vējonis visited South Korea and it was the first visit of the Latvian highest state official to the region. During the visit, a Memorandum of Understanding was signed to promote mutual economic relations, strengthen international trade and cooperation opportunities in the area of technology transfer and start-up development. `+
`<dd></dd>This year, a Memorandum of Understanding for the cooperation in the field of start-ups and innovations was signed. It provides mutual support for the start-up network expansion, development and entry into the Latvian and South Korean markets, and cooperation in sustainable innovation, new technology, smart mobility and digitalisation spheres. During the visit, the Memorandum of Understanding between the Latvian Development and Investment Agency and the Korean investment organisation Eugene Capital and Securities for cooperation in the area of investment attraction, and a Memorandum of Understanding between the Association of the Latvian Chemical and Pharmaceutical Industry and the Korea Biotechnology Industry Organisation for cooperation in the biopharmaceutical industry and healthcare were signed.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_skorea6+`"></div>`+
`<dd></dd>During the meeting, the parties discussed cooperation opportunities in high-technology projects and research programmes, including in the areas of biomedicine, biotechnology and the Smart Specialisation Strategy of Latvia, as well as in the aerospace sector where Latvia has a developed infrastructure, and modern science and technologies.`+
`<dd></dd>Representative Office of Latvia in South Korea offers Latvian businesses consultations in matters related to South Korea. “We talk about Latvia all the time: we participate in exhibitions, we create national stands, we present products, we bring in local consultants. Everything that we can do here locally, we do. If I have to meet a Korean potential client on a Saturday night, I do that. It’s my job”," Baumanis says. `+
`<dd></dd><h3 class="post-subtitle">The potential is huge</h3>`+
`<dd></dd>“South Korea is one of Latvia’s strategic partners both in terms of investment attraction and exports, so we want to expand our cooperation”, says Laura Štrovalde, Deputy Director General in charge of investment and energy at the Investment and Development Agency of Latvia. Baumanis and Volcenkovs also see good business opportunities for Latvian companies in South Korea and feel positive about the future: “The future has no other direction but up”, M. Baumanis says.`+

	support_by_liaa,



	exp_review_9_name: "Stories about export to Israel",
	exp_review_9_company:"Primekss, Light Guide Optics International",
	exp_review_9_designation: "Primekss, Light Guide Optics International",
	exp_review_9_excerpt: `Israel is often called the nation of inventors and start-ups. That is why Latvian companies considering exporting to that market should be prepared to offer world-class innovations, technologies and quality.`,
	exp_review_9_title:"Israel, a land where everybody is an inventor ",
	exp_review_9_text:

`<dd></dd>Israel is often called the nation of inventors and start-ups. That is why Latvian companies considering exporting to that market should be prepared to offer world-class innovations, technologies and quality.`+
`<dd></dd>“We have huge yet unused potential for economic cooperation and we have everything we need to run mutually beneficial projects. Israel is a small country (the size of Vidzeme!), but in terms of the number of innovations and patents it’s one of the global leaders. Seems like everybody is an inventor there: a housemaker holding a patent for a cleaning sponge or a beetle researcher developing a robot which can sense smell of a human buried under earthquake rubble”, says Irina Rubinčika, Head of the Latvian Development and Investment Agency’s Office for Innovation and Technology in Israel.`+
`<dd></dd>Daumants Pfafrods, CEO of the optic fibre manufacturer Light Guide Optics International notes that 40% of the domestic GDP is generated by high-tech companies. That is why innovation companies would benefit from finding a business partner in Israel. Jānis Ošlejs, the owner of the concrete technology company Primekss also believes that for the companies which do not care for innovations or making high-quality products it is pointless to look at Israel. `+
`<dd></dd>“The low-tech era is over. For example, in Israel, as soon as a livestock animal is born, a DNA test is done to determine its state of health and the expected livestock profitability as a milk or meat producing animal.`+
`<dd></dd><div class="quote-style">Latvia’s strong IT and communication sector can take care of data analysis and it is one area in where we can generate innovations to export to the world. </div>`+
`<dd></dd>We have a good foundation both for technology exports and investments in our brilliant start-ups”, Rubinčika says.`+
`<dd></dd><h3 class="post-subtitle">A great potential</h3>`+
`<dd></dd>In 2022, total goods and services trade between Latvia Israel reached EUR 116.1 million placing Israel in the 45th spot among Latvia’s foreign trade partners. Total exports of gods and services to Israel were EUR 88.4 million, which is 0.3% of total Latvian exports, while imports of goods and services were EUR 27.7 million accounting for 0.1% of Latvian imports. In comparison with 2021, exports grew by 62% and imports by 11%. In 2022, Israel was the 33rd largest export and 48th largest import partner for Latvia. `+
`<dd></dd>“Israel is not among Latvia’s main export markets yet. However, if a company overcomes all the obstacles associated with entering a new market and gets to Israel, it will be sure to generate a substantial, steady profit there”, Rubinčika says.`+
`<dd></dd>She adds that if a company’s product is present in Israel, that market can serve as a gate to the wider world. “Israel has had close ties with the US traditionally. If a company works in Israel, entering the US market may come more easily. It is also a gateway to Asia and India”, Rubinčika notes.`+
`<dd></dd><h3 class="post-subtitle">A difficult market, both geographically and politically</h3>`+
`<dd></dd>“Israel is one of the most difficult places in the world, both in terms of geography and politics. It is separated from the rest of the world being surrounded by hostile states with which it does not have diplomatic relations or peace treaties”, she explains. Daumants Pfafrods also notes that security measures when entering or leaving the country are unlike anywhere in the world. It makes logistics matters with Israel rather difficult and goods come there in sea containers or by air. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel1+`"></div>`+
`<dd></dd>Despite all that, its economy is developing rapidly. “The Israeli market is growing fast. It has very strong economy and I would recommend everybody going there”, Ošlejs says. Different ratings place Israel in the top four to top eight spots among the strongest economies similarly to Canada and the US. “So it’s only logical that that market is attractive to Latvian businesses”, Rubinčika adds. `+
`<dd></dd><h3 class="post-subtitle">Import reform makes exporting easier</h3>`+
`<dd></dd>When asked about challenges in the Israeli market, Pfafrods says that customs and declaration formalities require special attention. As his company sells a medical product which has multiple certificates, he has never had any serious problems. `+
`<dd></dd>Rubinčika said that in January 2023, Israel rolled out an imports reform fully accepting the EU manufacturing and quality standards.  It means that many products no longer require separate registration with the Israeli supervisory authorities and businesses do not have to acquire separate GMP certificates. The only exception is meat and dairy. All other goods require self-declaration in an online system where the importer confirms that their goods have been manufactured to the European Union standards. `+
`<dd></dd>“Unfortunately, these simplified rules for cosmetic products have been delayed”, Rubinčika says.`+
`<dd></dd><h3 class="post-subtitle">Quality food is always welcome</h3>`+
`<dd></dd>In Israel, Rubinčika sees good opportunities for Latvian food companies.  “For example, there is a huge shortage of berries, both dried and quick frozen. In Israel, 50 grams of Argentinian blueberries would cost you three euros. However odd it may sound, there is also not enough high-quality ice-cream there. Lithuanian manufacturers have already settled in this niche, but there is still potential”, she explains.`+
`<dd></dd>Although Israel supports its own manufacturers in all sectors, Latvian products have been well represented in Israel for some time, for example Latvian sprats, Laima chocolate, Latvijas Balzams, Cēsu beer, Cesvaines cheese.`+
`<dd></dd><h3 class="post-subtitle">Financial sector also offers opportunities</h3>`+
`<dd></dd>Rubinčika has seen interest towards Latvian furniture and lighting fixtures many times.  Textile manufacturers also have business opportunities in that market. She sees great potential for optic fibre, electronics and security companies there. `+
`<dd></dd><div class="quote-style">“Israel is interested in process automation, Internet of things, climate technologies, smart energy and waste processing as well as development of agricultural and food technologies”, Rubinčika explains.</div>`+
`<dd></dd>According to her, there is also potential in the financial sector. “Latvia can offer FinTech licences to Israeli start-ups for them to develop new products. It is especially relevant now, when capital is fleeing the country due to the current political situation. Our banks could open their representative offices there to be inside of this money whirlpool and hear the needs of the customers in the financial sector”, Rubinčika notes.`+
`<dd></dd><h3 class="post-subtitle">Exports amount to 20 million euros</h3>`+
`<dd></dd>One of the largest Latvian exporters to Israel is the optic fibre manufacturer from Līvāni Light Guide Optics International whose sales in that market exceed 20 million euros annually. At the same time, the company’s partners work in the global market and the share of the products coming to Israel is small. “That is why Israeli economic development or political workings do not impact us that much. We are more affected by global events, such as supply chain disruptions or exchange rate fluctuations”, Pfafrods reveals.`+
`<dd></dd>Until now, Light Guide Optics International has exported products for laser machines to Israel. Now, however, its most rapidly growing business is making catheters for vascular treatments. The company also plans to expand its cooperation with Israel in this area. “Currently, this business is growing at lightning speed and has already reached 20% of our sales, and we expect it to continue growing in the coming years”, Pfafrods says.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel2+`"></div>`+
`<dd></dd>The company’s ties with Israel are longstanding. In the early 2000s, Pfafrods worked at Anda Optec, a company making fibre for urology lasers. In 2004, there was a big change in the company with the Latvian members leaving and founding Z Light, which later became Light Guide Optics International. The company decided to build its relationship with partners in Israel from scratch. “It wasn’t that easy. Establishing business relationships took quite a lot of time and showed us that business environment differed from what we were used to in Latvia. We found a consultancy company which agreed to help us enter the Israeli market, and that way we found new partners and showed what we could do. Three years later, in 2007, we started working seriously in Israel again”, Pfafrods explains.`+
`<dd></dd>A local partner helped Light Guide Optics International get things going charging a fee for every successful deal. “Considering the Israelis’ knack for haggling, we didn’t do that well in negotiations”, Pfafrods adds. `+
`<dd></dd><h3 class="post-subtitle">Follow the customer</h3>`+
`<dd></dd>J. Ošlejs also believes that a company needs a representative in Israel who knows both the local culture and the company and its products well. “In Israel, you definitely need someone from Latvia who understands how Latvians think and who can translate what people from Israeli counterparty think to Latvians. `+
`<dd></dd>Primekss has already been exporting to Israel for 12 years. The company entered the market somewhat spontaneously. IKEA, a long-term customer of Primekss, needed to build a warehouse in the Israeli city of Netanya. “We followed the customer. If we have a customer, such as IKEA, expanding in a new market, we simply follow it”, Ošlejs says. When the company came to Israel it was approached by other companies as well. Now Primekss generates 8% of its turnover in that market.`+
`<dd></dd><h3 class="post-subtitle">Entering market is neither cheap nor quick</h3>`+
`<dd></dd>When asked how to prepare when thinking about exporting to Israel, Rubinčika warns that significant costs should be reckoned with. There will be spending on customs and legal advice, comparatively expensive sea and air logistics as well as regular business trips to meet partners in person. “However, it will pay off, as Israel has high purchasing power. Here, 10 million people earn as much as 50 million people in other countries. And the market is always hungry for new products”, she adds.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel3+`"></div>`+
`<dd></dd>Rubinčika recommends not to be intimidated by possible obstacles, like, for example, getting no replies to emails.  That is why her advice would be to go there yourself and meet your potential partners in person. “Yes, Israel is different, so I highly recommend going there and getting to know it first. It is also best to meet personally first to establish business relationships. Online meetings, emails and calls can only help in the very beginning. After that, personal contacts are crucial”, Rubinčika explains. `+
`<dd></dd><h3 class="post-subtitle">Heated discussions are a natural part of negotiations</h3>`+
`<dd></dd>“I like Israeli business culture very much and I am happy to work there,” Ošlejs says. I noticed differences in our business culture straight away when I first came to Israel and had negotiations about an agreement. We met with partners in a construction site cabin to negotiate final details and the talks turned out to be very heated. He was sure that there would be no deal, but the negotiations concluded with a positive result. `+
`<dd></dd>“A discussion, even a very heated one, is a natural part of business negotiations. When somebody has very strong opinions about something, it does not mean they don’t like what we are doing. It is simply the way they negotiate”, he adds. `+
`<dd></dd>Rubinčika also recommends not to hesitate to defend your opinion: “In Israel, you can feel free to haggle and fight for your business interests. If the other party does not do that, Israelis would think that something’s not right there. They expect a company to protect and defend its interests so they would also expect haggling.”`+
`<dd></dd>Both Ošlejs and Pfafrods note that it is difficult to build any business relationship in Israel without personal contacts and mutual trust. “When the relationship has been established and business is going smoothly, personal contacts may no longer be that important. Nonetheless, we visit each other quite often. Even during the pandemic we had regular meetings”, Pfafrods says.`+
`<dd></dd>Rubinčika adds that in Israel Sunday is the first days of the week and it is a business day.  Thursday is the last day of the working week. “You shouldn’t bother your partners on Fridays”, she notes. Pork is a taboo in Jewish culture and religion and is banned from import to Israel. You should also avoid offering it to your partners when they visit Latvia.`+
`<dd></dd><h3 class="post-subtitle">Creative, direct and agile</h3>`+
`<dd></dd>Rubinčika adds that Israeli people are natural and open and do not follow any artificial etiquette rules.  “A quick response is typical for Israel: often you will receive an answer on your WhatsApp within an hour of negotiations rather than a formal email. They expect the same from their business partners, so you must be able to respond to partner’s proposals quickly”, she says.`+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_israel4+`"></div>`+
`<dd></dd>In Israel, people are creative and spontaneous, and they tend to think that Latvians are too reserved in the way they think. That is why Ošlejs recommends finding a balance between Israeli creative and Latvian systematic approaches. If either prevails, the balance is broken. “Whenever we had any problems in that market, they were caused by the lack of balance between our systematic approach and Israelis’ creative spirit. In such situation we made extra effort to spend more time with each other to achieve similar understanding of the tings”, he shares his experience.`+
`<dd></dd>The same way you must reckon with their directness and no-nonsense approach. Rubinčika knowingly explains that Israeli partners would want to know the exact details of the offered products and quote rather than see a company’s mission and vision presentation. `+
`<dd></dd>Pfafrods adds that you have to be patient too as nothing happens straight away in the Israeli market.  You have to work gradually to be perceived as a steady partner. “It won't be like I come and close a long-term deal all in one day. Everything is happening slowly, step by step. However, once you have a deal, your relationship will be for a very long time to come”, she adds.`+
`<dd></dd><h3 class="post-subtitle">Innovations and technologies are welcome</h3>`+
`<dd></dd>Last year, Latvian Development and Investment Agency opened its representative office in Israel. Rubinčika advises Latvian businesses about Israeli market and helps with networking. Her other task is to adopt Israeli innovation experience and apply it in Latvia to help Latvian companies work more efficiently. “My goal is to boost innovations in Latvia inspired by the example of Israel and to attract investment to our smart technology sector”, Rubinčika says.`+
`<dd></dd>In summer 2023, the Latvian Development and Investment Agency and the Israel Innovation Authority signed a cooperation agreement to promote mutual projects for innovation and technology development. “It has been clear from the very beginning that, considering the political and historical background, we should sign a cooperation agreement to confirm that Latvia is a friend of Israel. This agreement ‘legalises’ our relationship with Israel, and it is a very important achievement. Now we can work on bilateral projects in research, innovation and science-intensive technologies”, Rubinčika explains.`+
`<dd></dd><div class="quote-style">Israel applies an open innovation principle, so its researchers and manufacturers are prepared to share their achievements. Of course, their intellectual property rights are protected, and licencing fees would apply. Rubinčika compares Israel with a supermarket of innovations where everybody can buy a ready solution for their challenges. </div>`+
`<dd></dd>“I urge Latvian businesses to think what prevents them from working more effectively. EIT Hub Israel, an Israeli knowledge and innovation community created by the European Institute of Innovation and Technology, is a special support programme co-financing search for such technologies in Israel. Israel tenaciously works on conquering the European market, invests in companies, establishes research centres and actively participates in scientific consortiums funded by the European Union, so for Israel, every new European partner is like a gold nugget”, she says.`+

	support_by_liaa,




	exp_review_10_name: "Stories about export to United Kingdom",
	exp_review_10_company:"Aerones, Krassky, Sēlijas Mežs",
	exp_review_10_designation: "Aerones, Krassky, Sēlijas Mežs",
	exp_review_10_excerpt: `Although hardly any business has benefited from the UK leaving the European Union, it remains a tempting market. Timber processing companies have long been the best performing there, but experts also see potential for information technology and defence companies in the future. `,
	exp_review_10_title:"A large and expensive market: United Kingdom",
	exp_review_10_text:

`<dd></dd>Although hardly any business has benefited from the UK leaving the European Union, it remains a tempting market. Timber processing companies have long been the best performing there, but experts also see potential for information technology and defence companies in the future. `+
`<dd></dd> “The UK is an important market for Latvia, especially for timber processing businesses. Our companies consider it their home market and all British procurers know about Latvia. Latvian companies generate several hundred million euros in sales annually in this sector in the UK,” Ivars Žukovskis, Head of the Investment and Development Agency of Latvia Representative Office in the UK, says. `+
`<dd></dd>In the third quarter of 2023, Latvian exports of timber and timber products to the UK amounted to €425 million. “I think that by the end of the year, exports of timber products will reach half a billion euros,” he says. `+
`<dd></dd><h3 class="post-subtitle">A big country requires volumes</h3>`+
`<dd></dd>From his experience Aigars Zelmens, the owner and manager of interior designers Krassky, knows that in the UK market consumers can afford to pay more for a special experience, good service and a unique product. In contrast, Žukovskis notes that in the UK, it is often a competitive price that matters, not a unique offer. “The UK is a big country, and many Latvian companies simply cannot reach an output the market demands. We are a relatively small economy, and our big businesses are often like foreign SMEs. So you have to understand that often it can be simply impossible to supply specific products to, say, Tesco’s,” he says. `+
`<dd></dd>For example, while the UK may be an attractive market for food manufacturers, it is also important to remember that the big retail chains would want to order really large volumes, and not all companies can do that. “I have seen Laima products in Sainsbury’s. Companies of this scale can do it, but it would be a huge challenge for smaller players," Žukovskis says. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk1+`"></div>`+
`<dd></dd>He notes that each area of the UK is a market of its own, e.g. London, Wales, Northern Ireland, etc. “All these are geographically and culturally different areas. Similarly, Britain is not just British or English. For example, in London there are many Eastern Europeans, including Latvians, Baltics, Poles, etc., and there are special shops selling products for this target group. Canelle Bakery products for one have always been present in these stores, and this year, Milzu! cereals have joined the party,” Žukovskis says. `+
`<dd></dd><h3 class="post-subtitle">A commercially mature market</h3>`+
`<dd></dd>Zelmenis adds that the UK is an expensive country in every sense.  “When I think of the price we have paid to rent the showroom space, I feel sick. Sales were good, but all the profit was eaten up by high wages, rent and property taxes,” he says. `+
`<dd></dd><div class="quote-style">The UK is also a very mature market commercially. It affects what goods and services are available on the market. </div>`+
`<dd></dd> “There is a specialisation for everything. For example, in Latvia we install carpets on site with our own installation team, but in the UK there is one team which can only carry carpets and another team which can move furniture because each has its own insurance cover. It means that the company which brings and lays the carpet is not covered for the cost of floors scratched when moving furniture,” Zelmenis explains. `+
`<dd></dd><h3 class="post-subtitle">Go for smaller partners</h3>`+
`<dd></dd>The UK accounts for around 10% of the sales of Selijas Mežs, a manufacturer of the garden summer houses. Previously, the company worked with France, partnering with the big retail chains there. “Supermarket chains have always determined what product and at what price they will buy. So at some point we realised that we wanted to work in a slightly different way: with smaller partners whose orders are not so large that the demand is greater than our capacity,” Ivars Dričs, a member of the Board of the Sēlijas Mežs, says. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk2+`"></div>`+
`<dd></dd>In the UK, the company works with multiple smaller clients. This approach helps negotiate more favourable terms, even considering currency fluctuations. “In the UK, we also have all our contracts in euros, so exchange rate fluctuations are the client’s risk, not ours. It would be impossible to work like this with large partners, because they don’t get into any unnecessary discussions,” Dričs says. `+
`<dd></dd>Sēlijas Mežs has been using this model for more than five years and does not see any need to return to the large wholesalers or chains. `+
`<dd></dd><div class="quote-style"> “There is one very important nuance in working with large retail chains that makes cooperation quite stressful. Namely, they announce a tender. If the company wins, it will be the one supplying the goods for the contract duration. But you never know if you’ll win the next tender. </div>`+
`<dd></dd>Of course, there may also be all sorts of changes when working with small traders, but they are more predictable. In addition, when we work for large customers, we can make certain goods for them only,” explains Dričs. `+
`<dd></dd><h3 class="post-subtitle">Customers are demanding</h3>`+
`<dd></dd>Dričs says that consumers in the UK are very demanding:  “If they have ordered products which are not on the cheaper end, they want everything to be perfect. It is therefore important not to skimp on quality of essential product ingredients, as there will be fewer after-sales service problems. It is cheaper to make a quality product than to repair, send and change parts afterwards. And it is very expensive to send goods to the UK.” `+
`<dd></dd>Sēlijas Mežs works with a diaspora representative who has become a partner of the company. “Working with a diaspora representative, we can get more detailed information both about the sales process and his experience in this market, as supermarket chains and large buyers share very little of this information. It is their trump card,” Dričs says. `+
`<dd></dd><h3 class="post-subtitle">Be in the right place</h3>`+
`<dd></dd>When Krassky already had a showroom in Rīga and one in Moscow, many said that they should open one in London. “As a result of these stimuli, we began studying the market and realised that there was an free niche. The greatest potential was in designer carpets,” Zelmenis says. `+
`<dd></dd>Opening a showroom in London was not easy. The project was delayed by a year: the showroom was originally scheduled to open in 2012, but it happened a year later, in 2013. Finding suitable property for the showroom was a challenge, as location is important in London. Krassky’s target group is high-income earners and interior designers. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk3+`"></div>`+
`<dd></dd> “As a rule, in London, you have to hit the right spot with your niche product. With our target audience, we could only “afford” to see properties in Mayfair, Belgravia and Chelsea. But there are literally three or four streets in these neighbourhoods that are worth being in, because if you’re not in the right spot, you simply won’t get noticed,” Zelmenis explains. `+
`<dd></dd>Another challenge is that property owners own entire street blocks and look very carefully at how a tenant would fit into the concept of that particular street section. For example, Krassky lost a bid for a good property because the landlord wanted to develop an upmarket fashion street. After a while, a Chanel store opened there. `+
`<dd></dd><h3 class="post-subtitle">First the address, then the company registration</h3>`+
`<dd></dd>Krassky hired a lawyer to set up a company in the UK. The most difficult part was opening a bank account, as two banks rejected them without any explanation. It turned out that Latvia was on the grey list at the time. However, with the help of a British accountant who introduced them to his private banker, Krassky was able to get a bank account. `+
`<dd></dd><div class="quote-style"> “To start something in the UK, a company representative needs a local address and an insurance number. Before we could set up a business, we had to get an address, even if we didn’t live there. </div>`+
`<dd></dd>Perhaps something has changed over the years,” Zelmenis admits. `+
`<dd></dd><h3 class="post-subtitle">Know local manners</h3>`+
`<dd></dd> “Relationship building is very important. They are very nice, smiley and polite in a daily life. But to get any closer, how often you’ve met, whether you have any common interests, values and goals matter immensely. Once they have realised that you are one of their own, they will recommend you further and many doors will open,” Zelmenis says. `+
`<dd></dd>Krassky’s target audience is wealthy people. To have a meaningful relationship with such people, you need to know local manners. “For example, an invitation for a coffee is an everyday thing, but when you are invited for a tea at someone’s home, it is worth a lot. Invitation to dinner is also something that the receiving party usually prepares for, especially if it’s at their home," Zelmenis says. Understanding these differences is particularly important because, in his experience, everything in the UK is based on mutual recommendations. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk4+`"></div>`+
`<dd></dd>Kaspars Litavnieks, Senior Business Development Manager at Aerones, a robotic wind turbine maintenance and inspection company, also recommends doing your research and homework before starting a business in the UK, rather than blindly emailing strangers about your product or service. “First you need to understand the demand for the product or service in that market, and then you can start researching who would be the right people to meet and start communication,” he advises. `+
`<dd></dd>That is why Aerones travels to various industry exhibitions and conferences. “Every market, every country has its own specifics, so we approach players in a particular market,” Litavnieks says. `+
`<dd></dd><h3 class="post-subtitle">Relationships require investment</h3>`+
`<dd></dd>Zelmenis notes that in the UK, searching Google will often get you nowhere without a personal recommendation.  Žukovskis also says that cold calls are also a bit foolhardy. Instead, it is about carefully and patiently forging good relationships and networking: `+
`<dd></dd><div class="quote-style"> “Entrepreneurs often think that if they have done something in the South of England, they can repeat the same in another area. But this is not true. There are different distributors in each region who do not know each other. </div>`+
`<dd></dd>But people know their former colleagues or mates from their rugby team, and the challenge is to figure out how to get inside that bubble,” he says `+
`<dd></dd>That is why Litavnieks stresses the need to take relationship building seriously: “To establish lasting cooperation, you need to build personal and human relationships and nurture them. It’s also important to go for a beer with your customers, it’s important in British culture.” `+
`<dd></dd><h3 class="post-subtitle">Nobody is happy about Brexit</h3>`+
`<dd></dd>Žukovskis says that Brexit is like a return to the past: customs, certification different from the European Union.  “It’s a headache for everyone. I have yet to meet a single British entrepreneur who would say that leaving the European Union has been good. But not to worry, Latvian exporters have a long experience of working in the markets even more exotic than the UK today. The right partners will help you do it all,” he encourages. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk5+`"></div>`+
`<dd></dd>For example, Aerones started working with its customers in the UK after the UK left the European Union. “There is a clear difference in how we work in the UK and the rest of Europe. All the paperwork and bureaucracy in the UK is much more complex,” Litavnieks says. `+
`<dd></dd>Dričs adds that delivery to the UK has become slightly more expensive since leaving the EU due to more bureaucracy at the border.  Pallets also must be labelled differently, as the UK is now a third country. “We’re doing all that and keep working,” he says. `+
`<dd></dd><h3 class="post-subtitle">Brexit and Covid-19 brought things to an end</h3>`+
`<dd></dd>Zelmenis admits that the UK’s exit from the European Union had a strong impact on Krassky’s business in that market.  After Brexit, London started to lose its appeal to foreign capital. Increasing numbers of companies have started moving their headquarters to the Netherlands, Germany or France, which is just across the Channel. "”Both capital and people started to flee, because only those who had lived there for five years and could get a permanent residence permit could remain,” Zelmenis says. `+
`<dd></dd>And then came Covid-19, which made the situation even more dramatic. “If we had stronger sales results or a hungrier sales team, we might have kept our business in London. But with the problems brought by Brexit and Covid-19, I myself lost motivation and we decided to shut down the London showroom,” Zelmenis says. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_uk6+`"></div>`+
`<dd></dd>An additional challenge was how people advertised themselves. It can be seen in job interviews and other life situations alike. He therefore recommends looking only at results and keeping a critical eye when, for example, a partner or an employee promises to close a deal next month, but three months later it hasn’t happened and each time there is a new excuse. “People in the UK are extremely skilled at presenting things in progress as a done deal,” Zelmenis observed.`+
`<dd></dd>Litavnieks has similar experience: “You must hold the British to their word because there are often many promises, but if you lose sight of communication for a while, you will have to start all over again.”`+
`<dd></dd><h3 class="post-subtitle">The potential is great </h3>`+
`<dd></dd>“The UK is a very close partner for us, both politically and in terms of security. Considering that we can supply products which are 100% made in the EU and NATO territory, Latvia and the UK can work together quite naturally. The security and defence sector is certainly an area where we can do even more. IT solutions also have great potential. There are no limits, everything can be done. We just need to work more on our country image,” Žukovskis says.`+
	
	support_by_liaa,



	exp_review_11_name: "Stories about export to Netherlands",
	exp_review_11_company:"STENDERS, Lat Eko Food, Baltic Candles",
	exp_review_11_designation: "STENDERS, Lat Eko Food, Baltic Candles",
	exp_review_11_excerpt: `The Dutch have always been great merchants, so exporting there is not the easiest thing to do. However, direct communication, horizontal hierarchy and geographical proximity have attracted many a Latvian entrepreneur.`,
	exp_review_11_title:"Selling to merchants with centuries of experience won’t be easy",
	exp_review_11_text:

`<dd></dd>The Dutch have always been great merchants, so exporting there is not the easiest thing to do. However, direct communication, horizontal hierarchy and geographical proximity have attracted many a Latvian entrepreneur. `+
`<dd></dd> “The economic relationship between the Netherlands and Latvia is much older than many people think. Exports to the Netherlands have always been important for Latvia. I am glad that we are still on good terms,” says Jēkabs Groskaufmanis, Head of Representative Office of the Investment and Development Agency of Latvia (LIAA) in the Netherlands. There are many reasons for this: a growing market, high purchasing power and almost 18 million people in a territory 30% smaller than Latvia. In addition, the representative office in the Netherlands has been working for almost 20 years and has a long history of supporting Latvian exporters. “The Netherlands is and will be a very important export market for Latvia in the foreseeable future,” he points out. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands1+`"></div>`+
`<dd></dd>Latvia imports more than it exports to the Netherlands. At the same time, imported products are often processed in Latvia for further export. “On the one hand, the Netherlands is an independent market, but on the other hand it is a logistics hub with very good ports historically. For centuries, it has been one of the keys to a country’s economy,” Groskaufmanis says. `+
`<dd></dd><h3 class="post-subtitle">Timber exporters in the Netherlands do best</h3>`+
`<dd></dd>Latvia’s exports to the Netherlands are rather diversified. But it is Latvia’s most export-oriented timber companies that do best in this market. `+
`<dd></dd><div class="quote-style">“The Netherlands and Latvia have centuries-old ties. In the Netherlands, ships were built and Latvian wood was used for the masts, so-called Rīga masts.  I am pleased to see how, over the centuries, this story has lived on and we have been able to increase the range of exports. We no longer export just timber, which is, of course, still going strong, but also processed timber, including furniture, load-bearing structures and even buildings,” Groskaufmanis says. </div>`+
`<dd></dd>Latvia exports both primary value chain products, such as volume grains and seeds, and high value-added food products. “We even export cheese to the Netherlands, which shows that Latvia is a capable food producer,” he says. `+
`<dd></dd>In recent years, growing numbers of manufacturers of sophisticated electronic, communication and various optical equipment have been emerging as exporters. Dutch investors and venture capital funds are also showing growing interest in Latvia. “In the past, they might have looked at Western Europe and Northern Europe, but now they are also looking at the Baltics. TechChill will also be a platform to meet Dutch venture capital funds interested in Latvian start-ups and science-intensive technology companies,” Groskaufmanis says. `+
`<dd></dd><h3 class="post-subtitle">Exports are forged at trade fairs</h3>`+
`<dd></dd>The export of food manufacturer Lat Eko Food Ltd to the Netherlands is the result of their diligent exhibition attendance. “We participate in exhibitions organised and supported by the LIAA and other organisations. For us, this is one of the main ways to find export partners,” Egija Martinsone, the owner and Chairwoman of the Board of Lat Eko Food, says. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands2+`"></div>`+
`<dd></dd>Lat Eko Food first met its current partner in the Netherlands at a trade fair in 2018. It took about two years of negotiations, testing, document exchange, visits and quality audits. “It was quite a long process, but now we have been working with the Netherlands for three years,” Martinsone says. `+
`<dd></dd>Lat Eko Food has two customers in the Netherlands: a distributor and a supermarket chain for which it produces private label products. `+
`<dd></dd><div class="quote-style"> “Private label products have enormous power. It is a well-known local brand. Our brand is unfamiliar and unknown to them. Introducing a new brand to people takes years of work. The fastest and largest export volumes, at least in the food sector, are only possible with private labels,” Martinsone says. </div>`+
`<dd></dd>Lat Eko Food has been exporting for 10 years, so she knows that both cooperation models have their advantages. “It’s not easy for a newly created brand to take on the world. It takes enormous investments in marketing to make it visible in the world. We already know how difficult it is to promote our brands in Latvia, but it is even more difficult in export countries. It seems utopian to think that one will conquer the whole world in one day with their name. Yes, we can have little bits all over the world, but it will take many years of work. That is why we have chosen to split the risk and work with both private labels and our own brand,” Martinsone says. `+
`<dd></dd>And it has brought a result: The Netherlands accounts for around 15% of Lat Eko Food sales. It is expected to near 20% by the end of 2023. In absolute terms, this is around €1.5 to €2 million. `+
`<dd></dd><h3 class="post-subtitle">Work directly with supermarkets</h3>`+
`<dd></dd>The candle manufacturer SIA Baltic Candles tries to work with supermarket chains without intermediaries, as supermarkets are now switching to this model to make sure they get the best price for their products. The company also works with wholesalers who distribute products to small shops. `+
`<dd></dd> “We make large volumes and unfortunately we cannot deliver our candles to every small shop ourselves. So we have partners who distribute our products. We also have agents, but we make sure that their work does not overlap with our existing customer network,” Raivis Stols, Baltic Candles Sales Manager, says. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands3+`"></div>`+
`<dd></dd>Baltic Candles sees its advantage in being able to offer both long candles and tea lights, something no supermarket can do without, as well as a variety of themed candles to celebrate every occasion. “We can provide a full range of products for our customers to have good candle sales,” Stols says. `+
`<dd></dd>The Netherlands generates 5 to 8% of the Baltic Candles sales. To diversify risks, the company makes sure that no export country accounts for more than 15-20% of its sales. `+
`<dd></dd>According to Stols, the Netherlands, like the rest of Western Europe, is currently in recession.  He hopes it is a short-term phase. “But candles are also a good product in various crises, because then people don’t buy new cars, furniture or go on expensive trips, but continue to spend money on candles, which are not the most expensive things,” Stols says. `+
`<dd></dd><h3 class="post-subtitle">Key: the right partner</h3>`+
`<dd></dd> Kristīne Grapmane, CEO of the bath and body care product manufacturer STENDERS SIA, believes that the Netherlands has an efficient tax system, an open economy and a high level of education, which encourages business development. A well-developed logistics infrastructure ensures easy goods logistics. `+
`<dd></dd><div class="quote-style"> “One of the criteria for success is finding the right partner,” she says. The partner needs to know the Dutch market, have retail experience, a desire to grow and an understanding of the brand and company values. </div>`+
`<dd></dd>STENDERS was approached by a partner in the Netherlands. He got the company’s products as a gift from his son, who is studying in Latvia. Later, he visited the company’s stores and expressed interest in working with them. `+
`<dd></dd><h3 class="post-subtitle">A saturated market</h3>`+
`<dd></dd> “It is not an easy market to work in. But it’s exciting, filled with all kinds of shops and chains. The Dutch are pretty good traders, among the best in the world, just behind the Americans. So they themselves are actively marketing products from the Far East and elsewhere in their own country,” Stols says. `+
`<dd></dd>Martinsone describes their purchasing power as rather high: “The country is at a high level of prosperity and they are interested in good, quality manufacturers. At the same time, the Netherlands is a large and very saturated market. They seem to have enough of everything, they are particularly hungry for anything new. This makes getting people interested quite difficult and time-consuming. But it is the same in almost any export market: nowhere are we welcomed with open arms. It takes patience to enter a market.” `+
`<dd></dd><h3 class="post-subtitle">Competition is fierce</h3>`+
`<dd></dd> “The Dutch expect functionality at a good price from any supplier. Despite the country’s high purchasing power, status is not that important to them: middle managers will ride their €100 bicycle to the office. This is a very desirable market for manufacturers from many countries, so there is a lot of competition,” Groskaufmanis says. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands4+`"></div>`+
`<dd></dd>Grapmane also says that the Dutch cosmetics market is very competitive, but consumers are also open to new brands and products. “Development, at least in our case, is slow. Of course, the overall economic situation is not helping our development at the moment,” she concludes. `+
`<dd></dd>Martinsone explains:  “For every export product, you must to find something that makes it better than what is already on the shelf. For one product to go on the shelf, something else has to come off.” `+
`<dd></dd><h3 class="post-subtitle">Focus on price</h3>`+
`<dd></dd> “The Dutch are generally very frugal. They will think several times before making a purchase. They will read everything thoroughly and find out where the products are made and what is known about the maker. The Dutch shop thoughtfully and consciously,” Grapmane says. `+
`<dd></dd>Martinsone also notes that price is important in the Netherlands. At the same time, quality must be high. “The Dutch are interested in the price. It’s not easy in this market because there are so many players,” Stols says. `+
`<dd></dd><h3 class="post-subtitle">Efficiency is respected</h3>`+
`<dd></dd>Groskaufmanis highlights the Dutch value for efficiency and planning.  “Any meeting and communication must be efficient, it must be on time and delays are unacceptable. And if everything is quickly discussed during the meeting, the remaining hour does not get filled with any idle chit-chat. They just get to their next task,” he says. `+
`<dd></dd><div class="quote-style">Stols has also noticed that the Dutch like everything they promise to happen on time and to be done perfectly. </div>`+
`<dd></dd> “The terms of cooperation are quite complex, one could even say nitpicky. If there is any problem with quality, they won’t hesitate to point it out. In that market, consumers tend to complain and demand compensation from the manufacturer or seller. We have not seen this so much in any other country. So you have to take into account that if something is not perfect, they will bring every detail to light and demand their rights,” Martinsone says. `+
`<dd></dd><h3 class="post-subtitle">Directness and openness is appreciated</h3>`+
`<dd></dd>Groskaufmanis recommends being as honest and transparent as possible and not being surprised by their directness.  Sometimes it may seem rude to Latvians, but it is never meant that way. `+
`<dd></dd> “In the Netherlands, businesses value directness and openness. The business culture is one of open dialogue, listening to different ideas and suggestions, while valuing efficient working methods and a streamlined approach. Compared to other markets, the Dutch business culture is informal and innovation-friendly,” Grapmane shares her experience. `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands5+`"></div>`+
`<dd></dd>Groskaufmanis adds that the hierarchy in the Netherlands is horizontal.  Although there is a formal “you” and an informal “you”, the formal one is starting to disappear from the language. “It is also reflected in business and managers are accessible in the companies. When approaching the companies wishing to contact a specific procurement officer or buyer, do not be shy to speak to them or ask a colleague to forward an email to that person. And expect the same from them,” he says. `+
`<dd></dd><h3 class="post-subtitle">Come with a clear plan</h3>`+
`<dd></dd> “The Dutch have been trading for centuries and have suppliers from all over the world. That’s why you need to come with a clear plan. That’s why it is important to be specific from your very first communication and say what you want and what you offer, so that the other party understands why the communication should continue,” Groskaufmanis explains. `+
`<dd></dd>The expert recommends looking at the volumes the company can make and how quickly it can deliver the goods. “The LIAA office in the Netherlands is ready to help businesses prepare if they are interested in this market,” he promises. `+
`<dd></dd>Grapmane recommends doing a detailed survey of the local market, competitors and customers’ needs.  “Successful cooperation requires an effort to get into the local business culture where business ethics and close cooperation with partners are values highly,” she says. `+
`<dd></dd>Martinsone recommends going there and exploring the market thoroughly: to walk through the supermarkets, to look at the products, prices and brands: “The best way to understand a market is to go there and immerse yourself in society and environment. You certainly cannot experience it as well online as you can in the real setting.” `+
`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_netherlands6+`"></div>`+
`<dd></dd>When Baltic Candles chose that market 15 years ago, it was not by chance. “We started by analysing the market: what players are there, who our main competitors are. When we chose our customers, we approached them with specific products and were confident that we could interest them,” Stols says. `+
`<dd></dd>When choosing an export market, Baltic Candles analyses the candles consumption in kilograms per person. “Candle burning culture is very common in the Scandinavian countries and the Dutch market is the next market of interest for candle makers as top 5 in terms of candle burning volumes,” Stols says. The Netherlands is also home to more than 17.5 million people, roughly the same as the whole of Scandinavia. `+
`<dd></dd><h3 class="post-subtitle">A great potential</h3>`+
`<dd></dd>STENDERS sees great potential for business development in the Netherlands, especially in Benelux, i.e. the Netherlands, Belgium and Luxembourg. ”The Dutch are interested in high quality and innovative products, which means good potential and opportunities for our development. But it will take time. The brand needs to gain recognition,” Grapmane says. The company sees the Netherlands as part of the Benelux market, which is a sufficiently large and interesting European region. `+
`<dd></dd>Groskaufmanis warns against confusing the Dutch with the Flemish or the Belgians in general: “There is a healthy competition among them, same as Latvians have with Lithuanians and Estonians." `+
`<dd></dd>He adds that many Dutch people are surprised that Riga is only a two-hour flight away and has its own airline and other things which for us are something that goes without saying. “I am happy every time when after an investor’s visit or a trade mission to Latvia stereotype shatter and people see that Latvia is Northern Europe, not Eastern Europe,” Groskaufmanis says.`+

	support_by_liaa,



	exp_review_12_name: "Stories about export to Japan",
	exp_review_12_company:"Printful, Azeron, Zabbix",
	exp_review_12_designation: "Printful, Azeron, Zabbix",
	exp_review_12_excerpt: `Japan is currently Latvia’s 43rd largest foreign trade partner. The Expo in 2025 could be a turning point for the economic relations between Latvia and Japan.`,
	exp_review_12_title:"You must go to Japan to shake hands with your partners in person",
	exp_review_12_text:

	`<dd></dd>Japan is currently Latvia’s 43rd largest foreign trade partner. The Expo in 2025 could be a turning point for the economic relations between Latvia and Japan. `+
	`<dd></dd> “Considering the country’s economic development and values, Japan is a potential market for. And it is a very big country with the population of 127 million,” says Lāsma Līdaka, the Director of the Expo 2025 Pavilion. `+
	`<dd></dd>Japan is currently Latvia’s largest export trade partner in Asia. In 2022, total trade between Latvia and Japan reached EUR 123 million placing Japan in the 43rd spot among Latvia’s foreign trade partners. Exports to Japan amounted to EUR 86 million, while imports reached EUR 37 million. The information and communication technologies, latest technologies and unique solutions have the greatest potential in that market. `+
	`<dd></dd> “Japan is a very conservative market. It is difficult to enter and work in. However, that market is also wonderful - the trust, integrity, reliance and structure ruling there are very good for business,” Zane Lasmane, Marketing Manager at the IT company Zabbix, says. `+
	`<dd></dd><h3 class="post-subtitle">The first step is getting to know the culture</h3>`+
	`<dd></dd>Līdaka stresses that the company needs to be very well prepared for the Japanese market even before entering it.  “The most important thing is to have a strong, competitive product or service. Before going to Japan, every company needs to do a thorough market feasibility study, understand its positioning and identify advantages of its product or service. The key is a well prepared offer. That is why the first step in Japan is to get to understand the market while learning about its culture and business relationships. Understanding the culture is extremely important in this market," she says. Lasmane points out that Japan is not a standard market, and Europeans have to do a lot of preparation to be ready to start working with Japan. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan1+`"></div>`+
	`<dd></dd>Dāvis Vasilevskis, Head of International Pricing at Printful, a printing and sewing outsourcing company, highlights one seemingly small detail: it is very important to exchange business cards in the right manner. `+
	`<dd></dd><div class="quote-style">“You can’t just put your business card somewhere and get on your way. It’s a whole process,” he stresses. In addition, Lasmane notes that your business card and the ritual of presenting it are very important. “All the rituals, e.g. how we address people and how we interact, mean a lot in Japan. There are big differences there, so you’d better read up on Japanese culture and business environment,” Lasmane recommends. </div>`+
	`<dd></dd>In Vasilevsky’s experience, there is a very strong hierarchy in Japan.  “Local culture must be respected. You should know that people from all levels involved are there at the table during meetings. Usually, a person in a junior or lower-ranking position would speak first and only at the very end would the manager say a word or two, but sometimes not even that,” he says. `+
	`<dd></dd><h3 class="post-subtitle">Not knowing the language can be a big hurdle</h3>`+
	`<dd></dd> “You should know that not everyone in Japan speaks English. You may need an interpreter to make the negotiations more productive. The language may be a stumbling block,” Līdaka warns. Vasiļevskis agrees: “Very few people in Japan speak English. Some even may speak it, but they are reluctant to do so. That’s why all negotiations must be in Japanese.” `+
	`<dd></dd>Nobody on the Azeron gaming keyboard manufacturer’s team speaks Japanese, but the company has translated product descriptions. "The Japanese have a rather poor command of English. Without translated content it is difficult to reach them, because they simply can’t understand what we are selling and Google Translate is not always accurate,” Azeron co-founder Jānis Kūlbārdis says. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan2+`"></div>`+
	`<dd></dd>Once, Azeron gave its texts for translation to Japanese to a translation company in Rīga which claimed to have a native Japanese translator. When Azeron asked the community on Discord to check if the translations were correct, it turned out that the English text “keypad for lefties”, or a controller for left-handed, was translated verbatim as a “product for left-wingers”. “It made us realise that we have to be extra careful when working with languages we can’t speak,” Kūlbārdis says. `+
	`<dd></dd>Currently, only Azeron product descriptions on Amazon are available in Japanese. The company understands that this situation needs improving, but so far it has been unable to find a single person who would speak Japanese. “There is no point in paying an agency €10,000 for translations if we cannot provide customer service in Japanese after that. We need someone who can communicate with them. Japan is such a distant and strange market that the person who would represent us there is very important,” Kūlbārdis says. `+
	`<dd></dd><h3 class="post-subtitle">Establishing a business relationship takes a long time</h3>`+
	`<dd></dd> “It took us more than two years to start working with a partner in Japan. One of our mistakes was that we were not on the ground often enough and did not meet with our partner face-to-face,” Vasilevskis says. He makes a comparison: in Europe or the US, to get an answer to a question about a million euro decision, all you need to do is to write an email or make a phone call. In Japan, things happen much slower. “Face-to-face meetings are very important there. A Japanese may say yes, but it won’t always mean yes. Even if there is a written agreement in the email, it doesn’t mean it will happen that way. It was the same for us: things were moving very slowly until we went and shook hands," Vasilevskis says. `+
	`<dd></dd>Lasmane also advises to expect that building relationships in Japan will take a long time. “Lasting partnerships are important to them. If in a couple of years a competitor with much lower prices comes along, the Japanese will not run to them immediately. They check their partners thoroughly to make sure that the partnership will last,” she says. `+
	`<dd></dd><h3 class="post-subtitle">Avoid conflict</h3>`+
	`<dd></dd> “The Japanese take integrity very seriously. They expect good faith from us and from themselves alike. If someone makes a mistake, the mistake is acknowledged, apologies are made and the outcome is communicated in a timely manner," Lasmane says. She has observed that the Japanese avoid conflicts and are prepared to do whatever it takes to prevent them. That is why they look so carefully at whether a partner is trustworthy and honest. This helps avoid potential conflicts. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan3+`"></div>`+
	`<dd></dd>Lasmane adds that in Japan, the decision is unanimous.  “They want to achieve that all parties participate in the decision-making. If all parties have accepted and agreed on a particular decision, there is no room for a conflict afterwards. And if there really is a misunderstanding, they take it very seriously. In our daily communication with the Japanese branch, we sometimes misunderstood a question or someone was on holiday and we did not receive an answer in time. They always apologise and provide an explanation. For the Japanese, it is extremely important that we preserve our friendly mutual trust vibe,” Lasmane says. `+
	`<dd></dd><h3 class="post-subtitle">Part of a wider region</h3>`+
	`<dd></dd>Printful looks at Japan as a wider region, not just one country. “We see it as part of the Asian region, which has very developed e-commerce. From Japan, we also supply South Korea, the Philippines, Singapore and the entire South-East Asia region,” Vasilevski says. `+
	`<dd></dd>Printful started thinking about doing business in Japan in 2017. In 2018, the company had its first visit to Japan with the LIAA, but it was not until 2022 that it became active in that market. `+
	`<dd></dd>Choosing a new market, Printful looks at the population numbers there, the average e-commerce spend per person, popularity of online shopping and the consumers’ interest in personalised stuff. `+
	`<dd></dd><div class="quote-style"> “Of course, there are cultural differences in Japan, but fundamentally the market is very good for an e-commerce business. In Japan, companies grow and experiment with different ideas and business models,” he says. </div>`+
	`<dd></dd>Printful has its own production facilities in many countries, but in Japan it has a manufacturing partner. “This is still an experimental market for us, which is not like the US where know everything about how business works. That is why we have a partner in Japan who knows the market and the cultural differences, even if it’s the most ordinary thing like talking to Japanese business partners,” Vasilevskis says. `+
	`<dd></dd>Having analysed various scenarios, the company saw this business model as the best choice. One of the advantages is the matter of investments, since opening a production facility requires substantial financing. Another advantage is that working with a partner gives Printful more flexibility and allows testing the market safer to see what works and what doesn’t. “We are still feeling the water and searching for the right answers. But we will definitely give this market a go, because all macro indicators are OK and we have made good progress," Vasilevski says. `+
	`<dd></dd><h3 class="post-subtitle">Working with a local partner</h3>`+
	`<dd></dd>Zabbix is one of the very few Latvian companies with a branch in Japan, perhaps even the only one. The branch has been there for 11 years, but the story began even earlier when the company’s founder Aleksejs Vladiševs received a letter from NTT Communications, a large Japanese telecoms company. They were looking for monitoring software to use when they provide services to others. After extensive correspondences and product tests and trials, they came to Latvia. Another year or so passed during which the Latvian party felt that cooperation had not yet started. “They later told us that they had also visited our competitors in Germany and elsewhere in Europe to see which monitoring tool to choose. It’s also important for them to check partners and their product to see whether they are reliable,” Lasmane says. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan4+`"></div>`+
	`<dd></dd>NTT Communications became the first Zabbix partner to open a company’s branch in Japan. According to the company’s experience, growing in Japan without a local presence is impossible. She makes no secret of the fact that there are many different barriers which a Latvian company cannot understand due to the different mindset, culture and language. `+
	`<dd></dd> “Zabbix monitoring is now a de facto standard in Japan. If you ask someone in Japan what monitoring system they use, there is pretty much one answer: Zabbix,” Lasmane believes. `+
	`<dd></dd><h3 class="post-subtitle">Trust the locals</h3>`+
	`<dd></dd> “Unlike our other branches, we don’t work directly with our customers in Japan and all communication and business are done through our Japanese partners. It’s a cultural and business thing: they trust local service providers very much, especially in the IT sector. That’s why we have a branch in Japan with local staff to support our partners in that market,” Lasmane says. `+
	`<dd></dd>Zabbix software is used worldwide and can be downloaded by anyone, but there are also tangible products in Japan like Zabbix servers and Zabbix devices. “Our partners had an idea that it would be easier to market and develop the software if there were also devices. The partners designed and developed these devices using outsourced manufacturers. We don’t sell Zabbix hardware anywhere else," Lasmane says. `+
	`<dd></dd><h3 class="post-subtitle">An unusual export story</h3>`+
	`<dd></dd>Azeron is the story of an exporter extraordinaire. “Since our inception, we have been interested in all markets where English is spoken and where FedEx delivers. It’s only natural that we’ve always had customers from Japan from day one. Already the day of the product launch, when in was 3 AM in Japan, we already had our first orders. I remember it very well, because you have to be crazy enough to sit in front of your computer at night waiting for the opportunity to buy our keyboard,” Kūlbārdis says. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan5+`"></div>`+
	`<dd></dd>Although Azeron’s largest market is America, which accounts for 60% of its sales, no market other than Japan accounts for more than 5% of sales. In the first years, Japan generated 1-3% of Azeron’s total sales. That all changed a few years ago when the company decided to offer its product on Amazon Japan and sales started to go up. `+
	`<dd></dd>Today, business in Japan already accounts for around 13% of sales. Top performers so far include popular content on X, influencers’ stories about how they use Azeron products, and Amazon Japan. `+
	`<dd></dd> “In Japan, people are very influenced by what their idols, friends or neighbours use. A trustworthy recommendation is necessary. As much as we hate to admit it, we cannot find the right key to quadrupling our sales in that market. But Japan is the market where we see the most potential,” Kūlbārdis says. `+
	`<dd></dd><h3 class="post-subtitle">Opportunity to raise the country’s profile</h3>`+
	`<dd></dd>According to a survey by the Investment and Development Agency of Latvia, only 11% of people in Japan have heard about Latvia. It is, of course, a very small share. `+
	`<dd></dd><div class="quote-style"> “In Asia, people are very cautious and reluctant to work with anyone who is unfamiliar, unknown or untested. Therefore, Latvia needs to work on improving it’s visibility, which is quite a challenge considering all the cultural differences. In 2025, the World Expo will take place in Osaka, Japan, which can also help boost tourism and the potential for economic cooperation. We need to be wise about how we can make it happen,” Līdaka says. </div>`+
	`<dd></dd>The recent Expo in the United Arab Emirates is a proof that such exhibitions bring positive results. 443 Latvian companies participated in the fair establishing 3,182 new business contacts and signing 35 contracts. Exports to the United Arab Emirates in 2022 went up 52% compared to 2021. In addition to the direct benefits of cooperation with the United Arab Emirates, ties have been strengthened with other countries in the region, in particular Saudi Arabia, an economic powerhouse in the Middle East. Therefore, after Expo 2025, Latvian exports to Japan are expected to grow by EUR 50 million in 2028, while Japanese investments are expected to increase by EUR 30 million. `+
	`<dd></dd><h3 class="post-subtitle">Joint Baltic Region Pavilion</h3>`+
	`<dd></dd>This time, Latvia and Lithuania will have a joint pavilion promoting the entire Baltic region. “These distant countries see us as one region, a bit like the Scandinavian countries, and Sweden, Denmark, Norway, Iceland and Finland will also have a joint Nordic pavilion,” Līdaka says. The idea of a joint pavilion belongs to the Japanese who suggested this approach. They see the Baltic States as one region and invited all three countries to participate together giving Latvia the honour of leading the association. “Together with the Lithuanians, we will look for a common story which describes both countries,” Līdaka says. Estonia will not participate in Expo 2025. `+
	`<dd></dd><div class="post-img-container"><img class="post-img" src="`+exp_japan6+`"></div>`+
	`<dd></dd>The business programme and the activities for the participants are still being worked on. The work on the programme should be completed in early 2024. However, it is already known that there will be exhibitions, trade missions and business forums and seminars. “We would like to hear the views of industry associations, various business support organisations and businesses themselves. We will also hold seminars and try to understand the interest of companies in that market,” Līdaka says. `+
	`<dd></dd>She adds that a strong and competitive product or service is the key. Every company should do its homework: conduct a market feasibility study and understand its product positioning, its unique competitive advantages and its growth strategy, which Līdaka recommends working on together with a partner. “A well-prepared offer is the main thing, and the cultural aspects of the business are a secondary factor. Although Japan seems a distant and difficult market because of its language and culture, it is a market with great potential for the future. It is no coincidence that the Investment and Development Agency of Latvia has been working in Japan since 2008 and continues to support Latvian companies in that market,” she adds. `+
	

	support_by_liaa,


    "Augstākās izglītības Sektors": "Higher Education",
    "Others": "Others",
	"Previous": "Previous",

    "ES-27": "EU-27",
    "Eksports/IKP EE": "Export/GDP EE",
    "Eksporta prognoze": "Export Forecast",
    "Eksports/IKP LT": "Export/GDP LT",
    "Eksports/IKP LV": "Export GDP LV",
    "Eksportē uz 3. valstīm": "Export to third countries",
    "Eksportē uz ES": "Export to EU",
    "Igaunija": "Estonia",
    "Izmaiņa pret iepriekšējā gada periodu": "Change vs previous year's period",
    "Kopā": "Total",
    "Kopējais Finansējums Zinātniski Pētnieciskajam Darbam": "Total funding for Scientific R&D",
    "Kopējais eksports": "Total export",
    "LV NAP2021-27 mērķi": "LV NAP2021-27 goals",
    "LV_NAP": "LV_NAP",
    "Latvija": "Latvia",
    "Lietuva": "Lithuania",
    "NIP 2014-2020": "NIP 2014-2020",
    "NIP 2020-2027": "NIP 2020-2027",
    "Neeksports": "Non-export",
    "Nodokļi kopā": "Total taxes",
    "Pakalpojumu Eksports": "Export of Services",
    "Pakalpojumu eksports": "Export of services",
    "Papildus eirozonas eksports": "Additional EU export",
    "Pasaules pieprasījums": "World demand",
    "Pieaugošs Eksports": "Increasing Export",
    "Preču eksports": "Export of Goods",
    "Preču un pakalpojumu eksports": "Export of Goods and Services",
    "REER": "REER",
    "Samazinošs Eksports": "Decreasing Export",
    "Uzņēmējdarbības Sektors": "Business Sector",
    "Valsts Sektors": "Government Sector",
	"Eksportētāju pienesums tautsaimniecībā": "Exporters’ Contribution to National Economy",
	"Eksportētāju finanšu veselība": "Exporters’ Financial Wellbeing",
	"Produktivitāte un inovācijas": "Productivity and Innovations",
	"Latvijas eksports 2021. gadā": "Latvia’s Export in 2021",

	"Gads": "Year",
	"Mēnesis": "Month",

	"Gadi": "years",
	"Mēneši": "months",

	"Ceturksnis": "Quarter",

	"Viss periods": "All Data",
	"Daļējs periods": "Custom",

	"Atgriezties": "Back",
	"Attālināties": "Zoom Out",

	"Latvijas Eksporta Barometrs": "Latvian Export Barometer",
	"NIP - nacionālās industriālās politikas pamatnostādnes": "NIP - National Industrial Policy Guidelines",
	"Apgrozījums uz 1 darbinieku (mediāna) pa gadiem": "Turnover per employee (median) by years",

	"Rentablitāte (aktīvu atdeves) mediāna pa gadiem": "Cost-efficiency (Return on Assets) median by years",
	"Mediānas peļņa pēc nodokļiem pa gadiem": "Median profit after tax per year",
	"Peļņa pēc nodokļiem uz 1 darbinieku (mediāna) pa gadiem": "Profit after tax per employee (median) by years",
	"Bruto peļņas robeža (mediāna) pa gadiem": "Gross profit margin (median) per year", 

	"Latvijas ārpus-eirozonas reālais eksports (gada pieauguma tempi, %, devumi, pp.)": "Latvia’s real export outside the euro area (annual growth rate, %, contribution, pp)",

	"Veidotāji":"Developed by"
	

}

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: translationsEn },
		lt: { translation: translationsLt },
	},
	lang: localStorage.getItem("lang") || "lt",
	fallbackLng: localStorage.getItem("lang") || "lt",
	interpolation: { escapeValue: false },
})

const data = LoadData()



const App = () => {
	const { t } = useTranslation()


	return (
		<BrowserRouter>
			<ScrollToTop/>
			<Routes>
				<Route path="/" element={<Home t={t} />} />
				<Route path="/dashboard" element={<Dashboard t={t} />} />
				<Route index={false} path="/financial-health" element={<FinancialHealth t={t} />} />
				<Route
					index={false}
					path="/financial-health/financialhealth-1"
					element={<FinancialHealth t={t} tab={"financialhealth-1"} />}
				/>
				<Route
					index={false}
					path="/financial-health/financialhealth-2"
					element={<FinancialHealth t={t} tab={"financialhealth-2"} />}
				/>
				<Route index={false} path="/productivity" element={<Productivity t={t} />} />
				<Route
					index={false}
					path="/productivity/real-exports-1"
					element={<Productivity t={t} tab={"real-exports-1"} />}
				/>
				<Route
					index={false}
					path="/productivity/research-development-1"
					element={<Productivity t={t} tab={"research-development-1"} />}
				/>

				<Route index={false} path="/contribution" element={<Contribution t={t} />} />
				<Route
					index={false}
					path="/contribution/contribution-1"
					element={<Contribution t={t} tab={"contribution-1"} />}
				/>
				<Route
					index={false}
					path="/contribution/contribution-2"
					element={<Contribution t={t} tab={"contribution-2"} />}
				/>
				<Route
					index={false}
					path="/contribution/contribution-3"
					element={<Contribution t={t} tab={"contribution-3"} />}
				/>
				<Route index={false} path="/contribution/nace" element={<Contribution t={t} tab={"nace"} />} />

				<Route path="/company-interviews" element={<CompanyInterviews t={t} />} />
				<Route
					index={false}
					path="/company-interviews/exporter-stories"
					element={<CompanyInterviews t={t} tab={"exporter-stories"} />}
				/>
				<Route
					index={false}
					path="/company-interviews/export-markets"
					element={<CompanyInterviews t={t} tab={"export-markets"} />}
				/>
				<Route path="/review/:id" element={<SingleReview t={t} />} />
				<Route path="/exp-review/:id" element={<SingleReview t={t} />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
