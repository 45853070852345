import React from "react"

export default function Logo(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 115.694 72.817"
			{...props}
		>
			<defs>
				<clipPath id="clip-path">
					<rect id="Rectangle_242" data-name="Rectangle 242" width="115.694" height="72.817" fill="none" />
				</clipPath>
			</defs>
			<g id="Mask_Group_102" data-name="Mask Group 102" clipPath="url(#clip-path)">
				<path
					id="Path_461"
					data-name="Path 461"
					d="M179.787,37.9c-1.289,2.643-2.572,5.29-3.868,7.929-6.9,14.058-13.764,28.139-20.738,42.163-3.567,7.173-10.958,10.9-19.457,10.081-7.013-.676-13.749-6.533-15.838-13.612-2.841-9.632,2.185-19.015,10.657-23.031,15.957-7.564,31.737-15.5,47.593-23.278.444-.218.917-.375,1.377-.561l.273.309m-41.51,50.443a9.422,9.422,0,1,0-9.4-9.336,9.285,9.285,0,0,0,9.4,9.336"
					transform="translate(-80.369 -25.37)"
					fill="#cd163d"
				/>
				<path
					id="Path_462"
					data-name="Path 462"
					d="M89.167,9.5a39.764,39.764,0,0,0-4.131,2.148c-3.008,2.055-5.664,1.857-9.064.266-8.135-3.806-16.882-4.5-25.729-3.041-16.292,2.69-28.326,11.477-36.235,25.884a45.838,45.838,0,0,0-5.658,21.43,10.211,10.211,0,0,1-.414,3.022c-.693,2.056-2.374,2.909-4.649,2.561A3.808,3.808,0,0,1,0,57.93a73.528,73.528,0,0,1,.752-9.7A56.268,56.268,0,0,1,11.949,22.477a56.684,56.684,0,0,1,29.7-20.127c16.1-4.53,31.359-2.491,45.77,5.971.459.269.883.6,1.744,1.184"
					transform="translate(0 0)"
					fill="#c9c6c9"
				/>
				<path
					id="Path_463"
					data-name="Path 463"
					d="M308.628,66.012c1.873,3,3.891,5.926,5.591,9.024a56.2,56.2,0,0,1,6.847,26.713c.038,2.849-1.524,4.591-4.046,4.644-2.6.056-4.308-1.68-4.317-4.584A49.339,49.339,0,0,0,304.7,75.183a2.58,2.58,0,0,1-.272-2.046c.93-2.18,2.053-4.277,3.109-6.4.119-.24.271-.464.407-.7l.686-.026"
					transform="translate(-205.372 -44.549)"
					fill="#c9c6c9"
				/>
			</g>
		</svg>
	)
}
