import React from "react"
import Previous from "assets/icons/Previous"

const BackArrow = ({ width }) => {
	return (
		<span className="me-1 d-md-none">
			<Previous width={width} />
		</span>
	)
}

export default BackArrow
