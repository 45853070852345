import React from "react"
import { Breadcrumb, BreadcrumbItem, Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap"
import { DashedBox, Footer, Header } from "components"
import placeholder_tab_1 from "assets/images/placeholder_tab_1.png"
import FullScreenTrigger from "components/utility/FullScreenTrigger"
import Wrapper from "components/layout/Wrapper"
import PageTitle from "components/base/PageTitle"
import TimeChart from "charts/TimeChart"
import {UpdateTimeChart} from "charts/TimeChart"
import styles from "../Dashboard/styles.module.scss"
import { Link } from "react-router-dom"


class FinancialHealth extends React.Component {
	constructor(props){
		super(props)
		this.t = props.t
		this.default_tab = "financialhealth-1"
		this.active_tab = null
		this.active_sub_tabs = {
			"financialhealth-1":"financialhealth-1",
			"financialhealth-2":"financialhealth-2",
		}
	}
	handleSelect(key) {
		this.force_active_tab = key.tabIndex
		window.history.pushState({}, '', '/financial-health/'+key.tabIndex)
		this.forceUpdate()
	}

	handleSubSelect(key){
		var tab = key.tabIndex
		this.active_sub_tabs[this.active_tab] = tab;
		UpdateTimeChart(tab, this.t)
		this.force_active_tab = this.active_tab
		this.forceUpdate()	
	}

	render() {
		var t = this.t

		var new_tab 
		if (this.force_active_tab){
			new_tab = this.force_active_tab
			this.force_active_tab = null
		} else if (this.props.tab){
			new_tab = this.props.tab
		} else {
			new_tab = this.default_tab
		}
		if (new_tab !== this.active_tab){
			this.active_tab = new_tab
			this.active_sub_tabs[this.active_tab] = this.active_tab
		}

		var active_sub_tab = this.active_sub_tabs[this.active_tab]

		if (this.active_tab !== active_sub_tab){
			UpdateTimeChart(active_sub_tab, this.t)
		} else {
			UpdateTimeChart(this.active_tab, this.t)
		}
		
		return (
			<Wrapper>
				<Header t={t} />

				<Container fluid className="px-4">
					<Row className="py-3 d-none d-sm-block">
						<Col>
							<Breadcrumb className={styles.breadcrumb}>
								<BreadcrumbItem linkProps={{ to: "/" }} linkAs={Link}>
									{t("home")}
								</BreadcrumbItem>
								<BreadcrumbItem href="/distribution" active className={styles.breadcrumbActiveItem}>
									{t("financial_health_pagetitle")}
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>

					<PageTitle>{t("financial_health_pagetitle")}</PageTitle>

					<Row>
						<Col>
							<Tabs defaultActiveKey="financialhealth-1"  activeKey={this.active_tab} onSelect={(tabIndex) => this.handleSelect({ tabIndex })}>
								<Tab eventKey="financialhealth-1" title={t("fh_tab_1")}>
									<Tabs
										activeKey={this.active_sub_tabs["financialhealth-1"]}
										className="justify-content-end mt-2"
										onSelect={(tabIndex) => this.handleSubSelect({ tabIndex })}
									>
										<Tab eventKey="financialhealth-1" title={t("fh_tab_1_1")}>
											<FullScreenTrigger text={t("Apgrozījums uz 1 darbinieku (mediāna) pa gadiem")} />
											<TimeChart section="financialhealth-1" t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("fh_tab_1_1_text_bold")}</strong> {t("fh_tab_1_1_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="financialhealth-1-2" title={t("fh_tab_1_2")}>
											<FullScreenTrigger text={t("Rentablitāte (aktīvu atdeves) mediāna pa gadiem")} />
											<TimeChart section="financialhealth-1-2" t={t}  />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("fh_tab_1_1_text_bold")}</strong> {t("fh_tab_1_1_text")}
											</DashedBox>
										</Tab>
									</Tabs>
								</Tab>

								<Tab eventKey="financialhealth-2" title={t("fh_tab_2")}>
									<Tabs
										activeKey={this.active_sub_tabs["financialhealth-2"]}
										className="justify-content-end mt-2"
										onSelect={(tabIndex) => this.handleSubSelect({ tabIndex })}
									>
										<Tab eventKey="financialhealth-2" title={t("fh_tab_2_1")}>
											<FullScreenTrigger text={t("Mediānas peļņa pēc nodokļiem pa gadiem")} />
											<TimeChart section="financialhealth-2"  t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("fh_tab_2_1_text_bold")}</strong> {t("fh_tab_1_1_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="financialhealth-2-2" title={t("fh_tab_2_2")}>
											<FullScreenTrigger text={t("Peļņa pēc nodokļiem uz 1 darbinieku (mediāna) pa gadiem")} />
											<TimeChart section="financialhealth-2-2"  t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("fh_tab_2_1_text_bold")}</strong> {t("fh_tab_1_1_text")}
											</DashedBox>
										</Tab>
										<Tab eventKey="financialhealth-2-3" title={t("fh_tab_2_3")}>
											<FullScreenTrigger text={t("Bruto peļņas robeža (mediāna) pa gadiem")} />
											<TimeChart section="financialhealth-2-3"  t={t} />
											<DashedBox className="bg-white px-4 py-3 mt-4">
												<strong>{t("fh_tab_2_1_text_bold")}</strong> {t("fh_tab_1_1_text")}
											</DashedBox>
										</Tab>
									</Tabs>
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Container>

				<Row>
					<Col>
						<Footer secondary t={t}/>
					</Col>
				</Row>
			</Wrapper>
		)
	}
}

export default FinancialHealth
