import React from 'react'

import {GetData, isDataLoaded, SummarizeData, GroupByYear, AddPreviousPeriod, ConvertExcelSerialToDate} from 'tools/Data'
import { FilterByTime, UpdateOnChange, MatchRow,RemoveOnChange } from "tools/Filter"


var dataMap = {
	index: "Eksports_un_NIP",
	"dashboard-1": "Eksports_un_NIP",
	"dashboard-2": "For BI",
	"dashboard-3": "Sheet1",
	"contribution-1": "Eksporta intervāli",
	"contribution-2": "Nodokļi",
	"contribution-2-2": "Nodokļi",
	"contribution-3": "Nodokļi",
	"contribution-3-2": "Nodokļi",
	"contribution-3-3": "Nodokļi",
	"financialhealth-1": "Nodokļi",
	"financialhealth-1-2": "Nodokļi",
	"financialhealth-2": "Nodokļi",
	"financialhealth-2-2": "Nodokļi",
	"financialhealth-2-3": "Nodokļi",
	"real-exports-1": "Kopējais eksports",
	"real-exports-2": "Ārpus-eirozonas eksports",
	"research-development-1": "Izdevumi pētnieciskajam darbam",
	"research-development-2": "Finansējums R&D",
	"research-development-3": "Nodarbinātais zinātnieciskais",
	nace: "ATN010c",
}


var charts = {}
var charts_lang = {}

export function UpdateTimeChart(name, t){
    //console.log("Updating timechart " + name)


    if (!charts[name]) {
        //console.log("Timechart for "+ name + " not found")
        return;
    }
    function update(name, iter){
        if (iter > 100){
            //console.log("Iteractions exceeded " + iter + "; could not get clientWidth for the parent (" + name + ")")
            //console.log(charts[name]._impl.shell.container)
            return;
        }
        

        let container_width = charts[name]._impl.shell.container.clientWidth
        if (!container_width) {
            //console.log("No width for " + name + "container");
            setTimeout(()=>{update(name,iter+1)}, 50)
            return
        }
        //console.log("Width for " + name + " container:" + container_width)

        const cl = localStorage.getItem("lang")
        if (charts_lang[name] !== cl){
            var data = GetData(dataMap[name])
            var columns = data[0]
            var series = buildSeriesFor(name, columns, t)
            charts[name].replaceSettings({series: series})
            charts_lang[name] = cl;
            
            if (name !== "nace"){
                charts[name].replaceSettings({
                    area: {
                        displayUnits: [{ unit: "1 y", name: "1 " + t("Gads") }]
                    }
                }) 
            } else {
                charts[name].replaceSettings({
                    area: {
                        displayUnits: [
                            { unit: "1 y", name: t("Gads") },
                            { unit: "3 M", name: t("Ceturksnis") },
                        ],
                    }
                })
            }
     

            charts[name].replaceSettings({
                toolbar: {
                    periods: [{
                        displayPeriod: "max",
                        name: t("Viss periods")
                    }],
                },
                localization: {
                    toolbar: {
                        customPeriod: t("Daļējs periods"), 
                        backButton: t("Atgriezties"),
                        zoomoutButton: t("Attālināties"),
                    },
                    timeUnitsNames: {
                        "y": t("Gads"),
                        "M": t("Mēnesis")
                    },
                    timeUnitsNamesPlural: {
                        "y": t("Gadi"),
                        "M": t("Mēneši")
                    }

                }
            });

            charts[name].updateSettings({toolbar: {displayUnit: false}});
            charts[name].updateSettings({toolbar: {displayUnit: true}});
        }

        charts[name].updateSize()
        
     //   setTimeout(()=>{
            // this is a temporary solution. should show all data at once
            charts[name].setDisplayPeriod("max", "newestData", false);
       // }, 200)
  
    }
    update(name,0)
}

var data_cache = {}

function getDataFor(section, from, to, unit, success, fail){
    var src = section;
    if (section === "nace"){
        src = section + unit;
    }

    var data = data_cache[src]


    //console.log("Data for", src, data)
    success(data)
}

function buildChart(section, filter, caller){
    if (charts[section]){
        return;
        //charts[section].remove()
    }
    if (!filter){
        filter = section
    }
    var data = null
    var container = caller.container_ref.current;//document.getElementById("chart-"+section)
    if (container){

        if (!isDataLoaded()){
            container.innerHTML = "Loading.."
            if (caller){
                setTimeout(()=>{caller.componentDidMount()}, 100)
                return
            }
        }
        data = GetData(dataMap[section])
        
        var columns = data[0]


        var settings = {
            data: {
                id: "default",
                dataFunction: function (from, to, unit, success, fail) {
                    //console.log("Reloading ", section, filter, from, to, unit)

                    var tmp_data = [...data]
                    tmp_data.splice(0, 1)

                    tmp_data = appendDataLimits(postprocessData(section, tmp_data, columns, filter, unit), section, unit, from, to)
                    
                    var cache_str = section
                    if (section === "nace")
                        cache_str = section+unit
                    
                    data_cache[cache_str] = tmp_data
                    
                    return getDataFor(section, from, to, unit, success, fail)
                },
                useSmallerUnitCache: false,
                units: ["y"]
            },
            area: {
                height: 400,
                minWidth: 300,
                displayUnits: [{ unit: "1 y", name: "1 " + caller.t("Gads") }],
            },
            timeAxis: {
                maxUnitWidth: 2000,
            },
            navigation: {
                initialDisplayPeriod: "max",
            },
            currentTime: {
                enabled: false,
            },
            info: {
                showNoData: false,
                showNullData: false,
            },
            legend: {
                enabled: true,
                maxLineSymbols: 12,
                text: {
                    font: "10px Arial"
                }
            },
            localization: {
                toolbar: {
                    customPeriod: caller.t("Daļējs periods"),
                    backButton: caller.t("Atgriezties"),
                    zoomoutButton: caller.t("Attālināties"),
                },
                timeUnitsNames: {
                    "y": caller.t("Gads"),
                    "M": caller.t("Mēnesis")

                },   
                timeUnitsNamesPlural: {
                    "y": caller.t("Gadi"),
                    "M": caller.t("Mēneši")
                }
            },
            toolbar: {
                periods: [
                    {
                        displayPeriod: "max",
                        name: caller.t("Viss periods")
                    }
                ],
                export: false,
                logScale: false
            },
            series: buildSeriesFor(section, columns, caller.t),
            container: container,
            /*items: {
        styleFunction: function(item,data,index,seriesSettings){
            //console.log(item, data, index, seriesSettings)
            item.fillColor = "green"
        }
    }*/
            interaction: {
                resizing: {
                    enabled: false,
                },
            },
            events: {
                onChartUpdate: function (ev, arg) {
                    FilterByTime(filter, ...arg.chart.targetTime(), arg.chart)
                },
                onTimeChange: function (ev, arg) {
                    FilterByTime(filter, ...arg.chart.targetTime(), arg.chart)
                },
                onSelectionChange: function (ev, arg) {
                    let s = arg.chart.selection();
                    if (s[0] == null) s = arg.chart.targetTime()
                    FilterByTime(filter, ...s, arg.chart)
                },
            },
        }
        //console.log("chart for ", section, "created")
        settings = applyExtraSettings(section, settings, caller)
        var chart = new window.ZoomCharts.TimeChart(
            settings
        );
        charts[section] = chart;
        charts_lang[section] = localStorage.getItem("lang")
        container.style.width = "100%"
    
        charts[section].updateSize();
        chart._id = "time" + section
        //charts[section].updateSettings({area: {height: 400}})
        UpdateOnChange(filter, chart, "default")
    }
}

class TimeChart extends React.Component {
    constructor(props){
        super(props)
        this.section = props.section
        this.filter = props.filter
        this.element = null
        this.t = props.t

        this.container_ref = React.createRef();

    }
    componentDidMount(){
        buildChart(this.section, this.filter, this)
    }
    componentDidUpdate(){
        buildChart(this.section, this.filter, this)
    }
    componentWillUnmount(){
        RemoveOnChange(this.filter, charts[this.section])

        charts[this.section].remove()
        charts[this.section] = null
    }
    render(){
        return <div ref={this.container_ref} />
    }
}



function buildSeriesFor(section, data_series, t){
    if (section === "index"){
        return [
            { id: "1", name: t("NIP 2014-2020"), type: "line", data: { index: 1 }, style: lineStyles["lightBrown"]},
            { id: "2", name: t("NIP 2020-2027"), type: "line", data: { index: 2 }, style: lineStyles["lightGray"] },
            { id: "3", name: t("Preču un pakalpojumu eksports"), type: "line", data: { index: 3 }, style: lineStyles["red"], valueLabels: labelStyles["redBelow"]   },
            { id: "4", name: t("Eksporta prognoze"), type: "line", data: { index: 4 }, style: lineStyles["redDashed"]  },
        ]  
    } else if (section === "dashboard-1"){
        return [
            { id: "1", name: t("NIP 2010-2019"), type: "line", data: { index: 1 }, style: removeSettings(lineStyles["lightBrown"], "marker")},
            { id: "2", name: t("NIP 2020-2029"), type: "line", data: { index: 2 }, style: removeSettings(lineStyles["lightGray"], "marker") },
            { id: "3", name: t("Preču un pakalpojumu eksports"), type: "line", data: { index: 3 }, style: removeSettings(lineStyles["red"], "marker"), valueLabels: labelStyles["redBelow"]   },
            { id: "4", name: t("Eksporta prognoze"), type: "line", data: { index: 4 }, style: removeSettings(lineStyles["redDashed"],"marker")  },
        ]
    } else if (section === "dashboard-2") {
        return [
            { id: "1", name: t("Eksports/IKP LV"), type: "line", data: { index: 1 }, style: removeSettings(lineStyles["red"],"marker"), valueLabels: addSettings(removeSettings(labelStyles["redBelow"], "contentsFunction"), {contentsFunction: formatDecimalAsPercentage})   },
            { id: "2", name: t("Eksports/IKP LT"), type: "line", data: { index: 2 }, style: removeSettings(lineStyles["lightGray"],"marker") },
            { id: "3", name: t("Eksports/IKP EE"), type: "line", data: { index: 3 }, style: removeSettings(lineStyles["lightBrown"],"marker") },
        ]
    } else if (section === "dashboard-3") {
        return [{ id: "1", name: t("REER"), type: "line", data: { index: 1 }, style: removeSettings(lineStyles["red"],"marker"), valueLabels: removeSettings(labelStyles["redBelow"], "contentsFunction")   }]
    } else if (section === "contribution-1" ){
        return [
            { id: "1", name: t("Kopā"), type: "columns", data: { index: 3 },style: addSettings(columnStyles["red"], {widthScale:0.8, clusterPadding: [5,5]}), cluster: "1", valueLabels: removeSettings(labelStyles["blackAbove"],"contentsFunction")    },
            { id: "2", name: t("Eksportē uz ES"), type: "columns", data: { index: 1 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [10,10]}),cluster: "2", valueLabels: removeSettings(labelStyles["blackAbove"],"contentsFunction")  },
            { id: "3", name: t("Eksportē uz 3. valstīm"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [10,10]}), cluster: "2", valueLabels: removeSettings(labelStyles["blackAbove"],"contentsFunction")  },
        ]
    } else if (section === "contribution-2" ){
       return [
            { id: "1", name: t("Preču Eksports"), type: "columns", data: { index: 1 },style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), valueLabels: addSettings(removeSettings(labelStyles["blackAbove"],"contentsFunction"), {contentsFunction: formatThousandsNonCurrency})},
            { id: "2", name: t("Pakalpojumu Eksports"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [10,10]}), valueLabels: addSettings(removeSettings(labelStyles["blackAbove"],"contentsFunction"), {contentsFunction: formatThousandsNonCurrency})  },
            { id: "3", name: t("Neeksports"), type: "columns", data: { index: 3 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [10,10]}),  valueLabels: addSettings(removeSettings(labelStyles["blackAbove"],"contentsFunction"), {contentsFunction: formatThousandsNonCurrency})  },
        ]
    } else if (section === "contribution-2-2" ){
       return [
            { id: "1", name: t("Preču Eksports"), type: "columns", data: { index: 1 },style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), valueLabels: removeSettings(labelStyles["blackAbove"],"contentsFunction")    },
            { id: "2", name: t("Pakalpojumu Eksports"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [10,10]}), valueLabels: removeSettings(labelStyles["blackAbove"],"contentsFunction")  },
            { id: "3", name: t("Neeksports"), type: "columns", data: { index: 3 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [10,10]}),  valueLabels: removeSettings(labelStyles["blackAbove"],"contentsFunction")  },
        ]
    } else if (section === "contribution-3" ){
        return [
            { id: "1", name: t("Nodokļi kopā"), type: "columns", data: { index: 4 },style: addSettings(columnStyles["red"], {widthScale:0.8, clusterPadding: [5,5]}), cluster: "1", valueLabels: labelStyles["blackAbove"]    },
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 1 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [10,10]}),cluster: "2", stack: "1", valueLabels: labelStyles["blackBelow"]  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [10,10]}), cluster: "2", stack: "1", valueLabels: labelStyles["blackAbove"]  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 3 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [10,10]}), cluster: "2", stack: "2", valueLabels: labelStyles["blackAbove"] },

        ]
    } else if (section === "contribution-3-2" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatMillions})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatMillions})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatMillions}) },
        ]
    }else if (section === "contribution-3-3" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatThousands}) },
        ]
    }else if (section === "financialhealth-1" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatThousands}) },
        ]
    } else if (section === "financialhealth-1-2" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatPercentage})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatPercentage})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatPercentage}) },
        ]
    }else if (section === "financialhealth-2" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatThousands}) },
        ]
    }else if (section === "financialhealth-2-2" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatThousands})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatThousands}) },
        ]
    } else if (section === "financialhealth-2-3" ){
        return [
            { id: "2", name: t("Preču eksports"), type: "columns", data: { index: 7 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "2", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatPercentage})  },
            { id: "3", name: t("Pakalpojumu eksports"), type: "columns", data: { index: 8 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "3", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction": formatPercentage})  },
            { id: "4", name: t("Neeksports"), type: "columns", data: { index: 9 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,10]}), stack: "4", valueLabels: addSettings(labelStyles["blackAbove"],{"contentsFunction":formatPercentage}) },
        ]
    } else if (section === "real-exports-1" ){
        return [
            { id: "1", name: t("REER"), type: "columns", data: { index: 1 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "1"  },
            { id: "2", name: t("Pasaules pieprasījums"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,5]}), stack: "1"  },
            { id: "3", name: t("Citi"), type: "columns", data: { index: 3 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "1" },
            { id: "4", name: t("Kopējais eksports"), type: "line", data: { index: 4 }, style: removeSettings(lineStyles["red"],"fillColor"), stack: "2", valueLabels: addSettings(removeSettings(labelStyles["blackAbove"], "contentsFunction"),{"contentsFunction":formatDecimalAsPercentage})},
        ]
    } else if (section === "real-exports-2" ){
        return [
            { id: "1", name: t("REER"), type: "columns", data: { index: 1 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "1"  },
            { id: "2", name: t("Pasaules pieprasījums"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,5]}), stack: "1"  },
            { id: "3", name: t("Citi"), type: "columns", data: { index: 3 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "1" },
            { id: "4", name: t("Papildus eirozonas eksports"), type: "line", data: { index: 4 }, style: removeSettings(lineStyles["red"], "fillColor"), stack: "2", valueLabels: addSettings(removeSettings(labelStyles["blackAbove"], "contentsFunction"),{"contentsFunction":formatDecimalAsPercentage})},
        ]
    }else if (section === "research-development-1" ){
        return [
            { id: "1", name: t("Uzņēmējdarbības Sektors"), type: "columns", data: { index: 1 }, style: addSettings(columnStyles["lightBrown"], {clusterPadding: [5,5]}), stack: "1"  },
            { id: "2", name: t("Valsts Sektors"), type: "columns", data: { index: 2 }, style: addSettings(columnStyles["darkGray"], {clusterPadding: [5,5]}), stack: "1"  },
            { id: "3", name: t("Augstākās izglītības Sektors"), type: "columns", data: { index: 3 }, style: addSettings(columnStyles["mediumGray"], {clusterPadding: [5,5]}), stack: "1" },
            { id: "4", name: t("Kopējais Finansējums Zinātniski Pētnieciskajam Darbam"), type: "line", data: { index: 4 }, style: removeSettings(lineStyles["red"], "fillColor"), stack: "2", valueLabels: removeSettings(labelStyles["blackAbove"], "contentsFunction")},
        ]
    } else if (section === "research-development-2" ){
        return [
            { id: "1", name: t("ES-27"), type: "line", data: { index: 2 }, style: addSettings(lineStyles["lightBrown"], {}), stack: "1"  },
            { id: "2", name: t("Igaunija"), type: "line", data: { index: 3 }, style: addSettings(lineStyles["darkGray"], {}), stack: "2"  },
            { id: "3", name: t("Latvija"), type: "line", data: { index: 4 }, style: addSettings(lineStyles["red"], {}), stack: "3" },
            { id: "4", name: t("Lietuva"), type: "line", data: { index: 5 }, style: removeSettings(lineStyles["lightGray"], "fillColor"), stack: "4"},
            { id: "5", name: t("LV_NAP"), type: "line", data: { index: 6, noDataPolicy: "skip" }, style: removeSettings(lineStyles["red"], "fillColor"), stack: "5"},
        ]
    }else if (section === "research-development-3" ){
        return [
            { id: "1", name: t("ES-27"), type: "line", data: { index: 2 }, style: addSettings(lineStyles["lightBrown"], {}), stack: "1"  },
            { id: "2", name: t("Igaunija"), type: "line", data: { index: 3 }, style: addSettings(lineStyles["darkGray"], {}), stack: "2"  },
            { id: "3", name: t("Latvija"), type: "line", data: { index: 4 }, style: addSettings(lineStyles["red"], {}), stack: "3" },
            { id: "4", name: t("Lietuva"), type: "line", data: { index: 5 }, style: removeSettings(lineStyles["lightGray"], "fillColor"), stack: "4"},
            { id: "5", name: t("LV NAP2021-27 mērķi"), type: "line", data: { index: 6, noDataPolicy: "skip" }, style: removeSettings(lineStyles["red"], "fillColor"), stack: "5"},
        ]
    } else if (section === "nace") {
        return [
            {
                id: "1",
                name: t("Pieaugošs Eksports"),
                type: "columns",
                data: { index: 1 },
                style: addSettings(columnStyles["lightGray"], { clusterPadding: [5, 5] }),
                stack: "1",
            },
            {
                id: "2",
                name: t("Samazinošs Eksports"),
                type: "columns",
                data: { index: 2 },
                style: addSettings(columnStyles["red"], { clusterPadding: [5, 5] }),
                stack: "1",
            },
            {
                id: "3",
                name: t("Izmaiņa pret iepriekšējā gada periodu"),
                type: "line",
                data: { index: 3 },
                style: removeSettings(addSettings(lineStyles["darkGray"], {smoothing: true}), "fillColor"),
                stack: "2",
                valueAxis: "secondary",
                valueLabels: labelStyles["whiteBelow"]
            },
        ]
    }

    
}

function applyExtraSettings(section, settings, caller){
    if (section === "dashboard-1"){
        settings.toolbar = {enabled:false}
    } else if (section === "dashboard-2"){
        settings.toolbar = {enabled:false}
        settings.valueAxisDefault = {
            valueFormatterFunction: function (value, unitName, unitValue, name, extra) {
                return value * 100 + "%"
            },
            size: 40
        }
        settings.info = {}
        settings.info.valueFormatterFunction = function (value) {
            return (value.sum * 100).toFixed(2) + "%"
        }
        
    } else if (section === "dashboard-3"){
        settings.toolbar = {enabled:false}
    } else if (section === "real-exports-1"){
        settings.valueAxisDefault = {
            valueFormatterFunction: function (value, unitName, unitValue, name, extra) {
                return value * 100 + "%"
            },
            size: 40
        }
        settings.info = {}
        settings.info.valueFormatterFunction = function (value) {
            return (value.sum * 100).toFixed(2) + "%"
        }
        settings.area = {
            displayUnits: [
                { unit: "1 y", name: "Year" }
            ],
            height: 400
        }
        settings.timeAxis.minUnitWidth = 5
        //settings.timeAxis.maxUnitWidth = 100


    } else if (section === "real-exports-2") {
        settings.valueAxisDefault = {
            valueFormatterFunction: function (value, unitName, unitValue, name, extra) {
                return value * 100 + "%"
            },
            size: 40
        }
        settings.info = {}
        settings.info.valueFormatterFunction = function (value) {
            if (!value) return;
            return (value.sum * 100).toFixed(2) + "%"
        }
        settings.area = {
            displayUnits: [
                { unit: "1 y", name: "Year" }
            ],
            height: 400

        }
        settings.timeAxis.minUnitWidth = 10
        //settings.timeAxis.maxUnitWidth = 100

    } else if (section === "nace") {
        settings.area = {
            displayUnits: [
                { unit: "1 y", name: caller.t("Gads") },
                { unit: "3 M", name: caller.t("Ceturksnis") },
            ],
            height: 400
        }
        settings.data.units = ["3 M", "y"]
        settings.valueAxisDefault = {
            alignZero: true,
        }
        settings.valueAxis = {
            secondary: {
                zeroLine: "visible",
                enabled: true,
                alignZero: true,
                side: "right",
                valueFormatterFunction: function (value, unitName, unitValue, name, extra) {
                    return (value * 100).toFixed(0) + "%"
                },
                size: 40,
            },
        }
        settings.info = {
            showNoData: false,
            showNullData: false,
        }
        settings.info.valueFormatterFunction = function (value, series) {
            if (series.id === "3") {
                return value ? (value.avg * 100).toFixed(2) + "%" : "n/a"
            } else {
                return value ? "€" + Math.round((value.sum * 10) / 1000000000) / 10 + "&nbsp;bn" : null
            }
        }
        settings.timeAxis.minUnitWidth = 1
    } else if (section === "research-development-3"){
        settings.valueAxisDefault = {
            valueFormatterFunction: function (value, unitName, unitValue, name, extra) {
                return value * 100 + "%"
            },
            size: 40
        }
        settings.info = {}
        settings.info.valueFormatterFunction = function (value) {
            if(!value){
                return "-";
            }
            return (value.sum * 100).toFixed(2) + "%"
        }

    }

    return settings
}

function postprocessData(section, data, columns, filter, unit){

    var new_data = [];

    if (section === "index"){
        return ConvertExcelSerialToDate(data, columns, 0)
    } else if (section === "dashboard-1"){
        return ConvertExcelSerialToDate(data, columns, 0)
    } else if (section === "dashboard-2"){
        /* build metrics */
        var gpd_category = "Gross domestic product at market prices"
        var export_category = "Exports of goods and services"

        var country_index = columns.indexOf("Country")
        var type_index = columns.indexOf("Type")
        var year_index = columns.indexOf("Year")
        var value_index = columns.indexOf("Value")

        data = ConvertExcelSerialToDate(data, columns, year_index)

        var countries = ["Latvia", "Lithuania", "Estonia"]
        
        var gdp_data = {}
        var export_data = {}
        for (var i in countries){
            var country = countries[i]
            gdp_data[country] = {}
            export_data[country] = {}
        }

        var years = {}

        for (i = 0; i < data.length; i++){
            var row = data[i]
            country = row[country_index]
            var year = row[year_index]
            var value = row[value_index]
            var type = row[type_index]
            if (countries.indexOf(country) > -1){
                if (type === gpd_category){
                    gdp_data[country][year] = value
                    years[year] = true
                } else if (type === export_category){
                    export_data[country][year] = value
                    years[year] = true
                } 
            }
        }
        for (i in years){
            new_data.push([
                parseInt(i),
                export_data["Latvia"][i] / gdp_data["Latvia"][i],
                export_data["Lithuania"][i] / gdp_data["Lithuania"][i],
                export_data["Estonia"][i] / gdp_data["Estonia"][i],
            ])
        }

        return new_data

    } else if (section === "dashboard-3"){
        return ConvertExcelSerialToDate(data, columns, 0)
    } else if (section === "contribution-1"){
        years = {}
        var exp_eu = columns.indexOf("Eksports uz ES-28")
        var exp_out = columns.indexOf("Eksports uz 3.valstīm")
        var exp_both = columns.indexOf("Eksports uz ES-28 un 3.valstīm")
        year_index = columns.indexOf("Gads")
        for (i = 0; i < data.length; i++){
            row = data[i]
            year = row[year_index]
            if (!years[year]){
                years[year] = [new Date(year,1,1).getTime(), 0,0,0]
            }
            if (row[exp_eu] === 1){
                years[year][1]++
            }
            if (row[exp_out] === 1){
                years[year][2]++
            }
            if (row[exp_eu] || row[exp_out] || row[exp_both] || true){
                years[year][3]++
            }
        }
        for (i in years){
            new_data.push(years[i])
        }
        return new_data;
    } else if (section === "contribution-2"){

        years = {}
        value_index = columns.indexOf("Darbinieku skaits kopā (pēc VID datiem)")
        var category_index = columns.indexOf("Grupa")
        year_index = columns.indexOf("gads")
        var adata = pivotData(data, year_index, category_index, value_index, ["Preču eksports", "Pak. eksports","Neeksports"])
        new_data = associativeDataToArray(adata, (year)=>{
            return new Date(year,1,1).getTime()
        })
        return new_data
    } else if (section === "contribution-2-2"){

        years = {}
        value_index = columns.indexOf("vid.darbinieku skaits uz 1 uzņēmumu")
        category_index = columns.indexOf("Grupa")
        year_index = columns.indexOf("gads")
        adata = pivotData(data, year_index, category_index, value_index, ["Preču eksports", "Pak. eksports","Neeksports"])
        new_data = associativeDataToArray(adata, (year)=>{
            return new Date(year,1,1).getTime()
        })
        return new_data
    } else if (section === "contribution-3"){

        years = {}
        value_index = columns.indexOf("Budžetā samaksāto nodokļu kopējais apjoms (tūkst. EUR)")
        category_index = columns.indexOf("Grupa")
        year_index = columns.indexOf("gads")
        adata = pivotData(data, year_index, category_index, value_index, ["Preču eksports", "Pak. eksports","Neeksports"],(val)=>{return val*1000})
        for (i in adata){
            adata[i][4] = adata[i][1] + adata[i][2] + adata[i][3] 
        }
        new_data = associativeDataToArray(adata, (year)=>{
            return new Date(year,1,1).getTime()
        })
        return new_data
    } else if (section === "contribution-3-2") {
			years = {}
			value_index = columns.indexOf("vid. samaksāto nodokļu apjoms (tūkst. EUR) uz 1 uzņēmumu")
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(
				data,
				year_index,
				category_index,
				value_index,
				["Preču eksports", "Pak. eksports", "Neeksports"],
				(val) => {
					return val * 1000
				}
			)
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
		} else if (section === "contribution-3-3") {
			years = {}
			value_index = columns.indexOf("vid. samaksāto nodokļu apjoms (tūkst. EUR) uz 1 darbinieku")
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(
				data,
				year_index,
				category_index,
				value_index,
				["Preču eksports", "Pak. eksports", "Neeksports"],
				(val) => {
					return val * 1000
				}
			)
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
		} else if (section === "financialhealth-1") {
			years = {}
			value_index = columns.indexOf("Apgrozījums (EUR) uz 1 darbinieku (mediāna)")
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(
				data,
				year_index,
				category_index,
				value_index,
				["Preču eksports", "Pak. eksports", "Neeksports"]
				
			)
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
		} else if (section === "financialhealth-1-2") {
			years = {}
			value_index = columns.indexOf("rentabilitāte (aktīvu atdeves), mediāna")
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(data, year_index, category_index, value_index, [
				"Preču eksports",
				"Pak. eksports",
				"Neeksports",
			])
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
		} else if (section === "financialhealth-2") {
			years = {}
			value_index = columns.indexOf("mediānas peļņa pēc nodokļiem (EUR)")
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(data, year_index, category_index, value_index, [
				"Preču eksports",
				"Pak. eksports",
				"Neeksports",
			])
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
		} else if (section === "financialhealth-2-2") {
			years = {}
			value_index = columns.indexOf("Peļņa pēc nodokļiem (EUR) uz 1 darbinieku (mediāna)")
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(data, year_index, category_index, value_index, [
				"Preču eksports",
				"Pak. eksports",
				"Neeksports",
			])
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
		} else if (section === "financialhealth-2-3") {
			years = {}
			value_index = columns.indexOf(
				"Koeficients -Bruto peļņas robeža (mediānas vērtība). Formula: Bruto peļņa vai zaudējumi / Neto apgro"
			)
			category_index = columns.indexOf("Grupa")
			year_index = columns.indexOf("gads")
			adata = pivotData(data, year_index, category_index, value_index, [
				"Preču eksports",
				"Pak. eksports",
				"Neeksports",
			])
			for (i in adata) {
				//adata[i][4] = (adata[i][1] + adata[i][2] + adata[i][3])/3
				adata[i][7] = Math.round((adata[i][1] / adata[i][4]) * 10) / 10
				adata[i][8] = Math.round((adata[i][2] / adata[i][5]) * 10) / 10
				adata[i][9] = Math.round((adata[i][3] / adata[i][6]) * 10) / 10
				adata[i][10] = (adata[i][1] + adata[i][2] + adata[i][3]) / (adata[i][4] + adata[i][5] + adata[i][6])
			}
			new_data = associativeDataToArray(adata, (year) => {
				return new Date(year, 1, 1).getTime()
			})
			return new_data
        } else if (section === "real-exports-1") {
			return convertToPercentage(ConvertExcelSerialToDate(data, columns, 0))
		} else if (section === "real-exports-2") {
			return convertToPercentage(ConvertExcelSerialToDate(data, columns, 0))
		} else if (section === "research-development-1") {
			for (i = 1; i < data.length; i++) {
				row = [...data[i]]
				row[0] = new Date(row[0], 1, 1).getTime()
				new_data.push(row)
			}
			return new_data
		} else if (section === "research-development-2") {
			for (i = 1; i < data.length; i++) {
				row = [...data[i]]

				if (row[1] === "Kopējais finansējums") {
					row[1] = null
					row[0] = new Date(row[0], 1, 1).getTime()
					if (!row[6]) row[6] = null
					new_data.push(row)
				}
			}
			return new_data
		} else if (section === "research-development-3") {
			for (i = 1; i < data.length; i++) {
				row = [...data[i]]

				if (row[1] === "KOPĀ") {
					row[1] = null
					row[0] = new Date(row[0], 1, 1).getTime()
					if (!row[6]) row[6] = null
					new_data.push(row)
				}
			}
			return new_data
		} else if (section === "nace") {

            // work in progress

            var month_data = []
            var tmp_data = ConvertExcelSerialToDate(data, columns, 1)
            
            // category filter
            //console.log("Data before filter", tmp_data)
            for (var i = 0; i < tmp_data.length; i++) {
                var row  = tmp_data[i]
                if (!MatchRow(row, filter, ["time"], {"category": 0})){
                    continue;
                }
                month_data.push(row)
	        }
            //console.log("Data after filter", month_data)

            

            month_data = SummarizeData(month_data, 1,2)

            month_data.forEach((item)=>{item[1] *= 1000})

            var year_data = GroupByYear(month_data, 0, 1)

            year_data = AddPreviousPeriod(year_data, 0, 1, 2, 3, 1)
            month_data = AddPreviousPeriod(month_data, 0, 1, 2, 3, 0)

            for (let i = 1; i < year_data.length; i++){
                // replace decreasing with the actual value in the other series
                if (year_data[i][2] > year_data[i][1]) {
                    year_data[i][2] = year_data[i][1]
                    year_data[i][1] = null
                }
                else year_data[i][2] = null
            }
            for (let i = 1; i < month_data.length; i++){
                if (month_data[i][2] > month_data[i][1]) {
                    month_data[i][2] = month_data[i][1]
                    month_data[i][1] = null
                } else month_data[i][2] = null

            }

            return (unit === "y")?year_data:month_data
		} else {
			return data
		}

}
function convertToPercentage(data){
    var new_data = [];
    for (var i = 0; i < data.length; i++){
        var row = data[i];
        var new_row = [...row];
        for (var j = 1; j < row.length; j++){
            new_row[j] = row[j]/100
        }
        new_data.push(new_row);
    }
    return new_data;
}
function formatMillions(value, position) {
    return "€" + Math.round(value*10/1000000)/10 + "&nbsp;M" 
}
function formatThousands(value, position) {
	return "€" + Math.round((value * 10) / 1000) / 10 + "&nbsp;t"
}
function formatThousandsNonCurrency(value, position) {
	return Math.round((value * 10) / 1000) / 10 + "&nbsp;t"
}

function formatPercentage(value, position) {
	return Math.round((value * 100)) / 100 + "%"
}
function formatDecimalAsPercentage(value, position) {
	return Math.round((value * 100*10)) / 10 + "%"
}

function pivotData(data, year_index, category_index, value_index, category_map, value_postprocess=null){
    var new_data = {}
    for (var i = 0; i < data.length; i++){
        var row = data[i]
        var year = row[year_index]
        var category = row[category_index]
        var value = value_postprocess?value_postprocess(row[value_index]):row[value_index]
        if (typeof(new_data[year]) === "undefined"){
            new_data[year] = [...new Array(1+category_map.length*2)]
        }
        new_data[year][0] = year
        new_data[year][category_map.indexOf(category)+1] = value
        var count_index = category_map.indexOf(category) + 1 + category_map.length
        new_data[year][count_index] = new_data[year][count_index]?new_data[year][count_index]+1:1
    }
    return new_data
}

function associativeDataToArray(adata, callback){
    var data = [];
    for (var i in adata){
        var row = adata[i]
        row[0] = callback(row[0])
        data.push(row)
    }
    return data
}

function appendDataLimits(data, section, unit=null, from=null, to=null){
    var min = null;
    var max = null;
    var full_response = {}
    for (var i = 0; i < data.length; i++){
        if (min !== null) min = Math.min(min, data[i][0]); else min = data[i][0]
        if (max !== null) max = Math.max(max, data[i][0]); else max = data[i][0]
    }

    var extra = 0
    if (section === "nace"){
        //extra = 86400*30*1000
    }
    //console.log('Appending data limits', section, unit, from, to)
    full_response["dataLimitFrom"] = min;//-86400*1000 
    full_response["dataLimitTo"] = max+extra+1
    full_response["from"] = from?from:min// - 86400*1000
    full_response["to"] = to?to:(max + extra)
    full_response["values"] = data
    full_response["unit"] = unit?unit:"y"
    full_response["timeZone"] = "UTC"   
    
    
    var d = new Date(min)
    //console.log("Data limit from", min, d.getFullYear(),d.getMonth(),d.getDate(),d.getHours(),d.getMinutes())

    if (section === "nace"){
        //console.log(full_response)
    }
    return full_response
}

function removeSettings(settings, name){
    var new_settings = JSON.parse(JSON.stringify(settings))
    new_settings[name] = null
    return new_settings
}

function addSettings(settings, obj){
    var new_settings = JSON.parse(JSON.stringify(settings))
    for (var i in obj){
        new_settings[i] = obj[i]
    }
    return new_settings;
}

var lineStyles = {}
lineStyles["lightBrown"] = {
	lineColor: "rgba(128,107,111,1)",
	fillColor: "rgba(128,107,111,0.1)",
	lineWidth: 2,
	marker: {
		shape: "circle",
		width: 7,
	},
}
lineStyles["lightGray"] = {
	lineColor: "rgba(179,179,179,1)",
	fillColor: "rgba(179,179,179,0.1)",
	lineWidth: 2,
	marker: {
		shape: "circle",
		width: 7,
	},
}
lineStyles["mediumGray"] = {
	lineColor: "rgba(102,102,102,1)",
	fillColor: "rgba(102,102,102,0.1)",
	lineWidth: 2,
	marker: {
		shape: "circle",
		width: 7,
	},
}
lineStyles["darkGray"] = {
	lineColor: "rgba(51,51,51,1)",
	fillColor: "rgba(51,51,51,0.1)",
	lineWidth: 2,
	marker: {
		shape: "circle",
		width: 7,
	},
}

lineStyles["red"] = {
	lineColor: "rgba(205,22,61,1)",
	fillColor: "rgba(205,22,61,0.1)",
	lineWidth: 2,
	marker: {
		shape: "circle",
		width: 7,
	},
}

lineStyles["redDashed"] = {
	lineColor: "rgba(205,22,61,1)",
	lineWidth: 2,
	lineDash: [10, 10]
}


var columnStyles = {}
columnStyles["lightBrown"] = {
	lineColor: "rgba(128,107,111,1)",
	fillColor: "rgba(128,107,111,1)",
}
columnStyles["darkGray"] = {
	lineColor: "rgba(51,51,51,1)",
	fillColor: "rgba(51,51,51,1)"
}
columnStyles["lightGray"] = {
	lineColor: "rgba(179,179,179,1)",
	fillColor: "rgba(179,179,179,1)",
}
columnStyles["mediumGray"] = {
	lineColor: "rgba(102,102,102,1)",
	fillColor: "rgba(102,102,102,1)"
}
columnStyles["red"] = {
	lineColor: "rgba(205,22,61,1)",
	fillColor: "rgba(205,22,61,1)"
}

var labelStyles = {}
labelStyles["redBelow"] = {
	enabled: true,
	position: "belowValue",
	showNullData: false,
    minFontSize: 8,
    allowOverlap: true,
	style: {
        borderRadius: 5,
		backgroundStyle: {
			fillColor: "rgba(205,22,61,1)",
			lineColor: "rgba(205,22,61,1)",
		},
		textStyle: {
			fillColor: "white",
            font: "14px Arial"
		},
	},
    contentsFunction: function(value, position) {
        return "€" + Math.round(value*10/1000000000)/10 + "&nbsp;bn" 
    }
}
labelStyles["blackAbove"] = {
	enabled: true,
	position: "aboveValue",
	showNullData: false,
	minFontSize: 5,
	allowOverlap: true,
    useStackedValue: false,
	style: {
		borderRadius: 5,
		backgroundStyle: {
			fillColor: "rgba(0,0,0,1)",
			lineColor: "rgba(0,0,0,1)",
		},
		textStyle: {
			fillColor: "white",
			font: "12px Arial",
		},
	},
	contentsFunction: function (value, position) {
		return "€" + Math.round((value * 10) / 1000000000) / 10 + "&nbsp;bn"
	},
}
labelStyles["blackBelow"] = {
	enabled: true,
	position: "belowValue",
	showNullData: false,
	minFontSize: 5,
	allowOverlap: true,
	useStackedValue: false,
	style: {
		borderRadius: 5,
		backgroundStyle: {
			fillColor: "rgba(0,0,0,1)",
			lineColor: "rgba(0,0,0,1)",
		},
		textStyle: {
			fillColor: "white",
			font: "12px Arial",
		},
	},
	contentsFunction: function (value, position) {
		return "€" + Math.round((value * 10) / 1000000000) / 10 + "&nbsp;bn"
	},
}
labelStyles["whiteBelow"] = {
	enabled: true,
	position: "belowValue",
	showNullData: false,
	minFontSize: 5,
	allowOverlap: true,
	useStackedValue: false,
	style: {
		borderRadius: 5,
		backgroundStyle: {
			fillColor: "rgba(255,255,255,0.7)",
			lineColor: "rgba(255,255,255,0.7)",
		},
		textStyle: {
			fillColor: "black",
			font: "12px Arial",
		},
	},
	contentsFunction: function (value, position) {
		return Math.round(value * 1000) / 10 + "%"
	},
}

export default TimeChart

