import React from "react"

import { GetData, isDataLoaded } from "tools/Data"

import { FilterByCategory, UpdateOnChange, MatchRow, RemoveOnChange, GetFilter } from "tools/Filter"

import { Alpha3to2 } from "tools/Countries"

var geo_charts = {}

export function UpdateGeoChart(name) {
	//console.log("Updating " + name)

	if (!geo_charts[name]) return
	function update(name, iter) {
		if (iter > 100) {
			//console.log("Iteracitons exceeded " + iter + "; could not get clientWidth for the parent (" + name + ")")
			return
		}

		let container_width = geo_charts[name]._impl.shell.container.clientWidth
		if (!container_width) {
			setTimeout(() => {
				update(name, iter + 1)
			}, 100)
			return
		}
		geo_charts[name].updateSize()
	}
	update(name, 0)
}

function buildGeoChart(section, filter, caller) {
	if (geo_charts[section]) {
		return;
		//geo_charts[section].remove()
	}
	var data = null
	var chart = null

	var container = document.getElementById("chart-" + section)
	if (container) {
		if (!isDataLoaded()) {
			container.innerHTML = "Loading.."
			if (caller) {
				setTimeout(() => {
					caller.componentDidMount()
				}, 100)
				return
			}
		}


        function formatExportValue(number){
			if (number > 1000 * 1000 * 1000) {
				number = Math.floor(number / (1000 * 1000 * 1000)) + "bn"
			} if (number > 1000 * 1000) {
				number = Math.floor(number / (1000 * 1000)) + "M"
			} else if (number > 1000) {
				number = Math.floor(number / 1000) + "k"
			}
			return "€ " + number
		}

		var settings = {
			background: {
				enabled: false,
			},
			area: {
				height: 350,
			},
			navigation: {
				initialZoom: 1,
				minZoom: 1,
				maxZoom: 6,
			},
			data: [
				{
					id: "shapes",
					url: "/countries.geo.json",
					format: "GeoJSON",
					perBoundsData: false,
				},
				{
					id: "nodes",
					dataFunction: function (request, success, fail) {
						getGeoData(filter, request, success, fail, chart)
					},
				},
			],
			layers: [
				{
					type: "shapes",
					data: { id: "shapes" },
					style: {
						node: {
							fillColor: "rgba(231,	231,	231,1)",
							lineColor: "rgba(181,	181,	181,1)",
							lineWidth: 1,
						},
						nodeSelected: {
							fillColor: "rgba(205,22,61, 0.05)",
							lineColor: "rgba(205,22,61, 0.4)",
							lineWidth: 1,
							shadowBlur: 0,
							shadowColor: "255,255,255,0.01",
						},
						selection: {
							fillColor: "rgba(205,22,61, 0.1)",
						},
					},
				},
				{
					data: { id: "nodes" },
					type: "items",
					aggregation: {
						enabled: true,
						distance: 100,
						// needed for aggregation and aggregatedWeight result
						weightFunction: function (node) {
							return node.value
						},
					},
					style: {
						nodeStyleFunction: function (node) {
							// get data about current aggregated item
							var aggr = node.data.aggregatedNodes
							// get sum of current aggregated item
							var w = node.data.aggregatedWeight
							// use Math.log of Math.log10 for big scale/difference statistics
							// node.radius = Math.log(w);
							// use simple delimeter/divider for node radius
							node.radius = Math.log(w)
							// adding current aggregated item element coount and its elements sum for node label
							var label = ""
							var footer_label = ""
							if (node.removed || !w) {
								label = ""
							} else {
								var countries = 0
								var country_map = {}
								var country = ""
								for (var i = 0; i < aggr.length; i++) {
									country = aggr[i].name
									if (!country_map[country]) {
										countries++
										country_map[country] = true

										if (countries > 2) break
									}
								}

								if (countries === 1) {
									footer_label = country
								}
								label = formatExportValue(w)
							}

							node.label = footer_label
							if (label) {
								node.items = [
									{
										text: label,
									},
								]
							}
						},

						item: {
							backgroundStyle: { fillColor: "rgba(0, 0, 0, 0.8)" },
							textStyle: { fillColor: "white", font: "12px Arial" },
							padding: 2,
							borderRadius: 5,
						},
						nodeLabel: {
							backgroundStyle: { fillColor: "rgba(0, 0, 0, 0.4)" },
							textStyle: { fillColor: "white", font: "12px Arial" },
							padding: 2,
							borderRadius: 5,
						},
						node: {
							lineColor: "rgba(205,22,61,1)",
							fillColor: "rgba(205,22,61,1)",
						},

						aggregatedShape: {
							fillColor: "rgba(205,22,61,0.1)",
							lineColor: "rgba(205,22,61,0.1)",
							shadowBlur: 0,
							shadowColor: "255,255,255,0.01",
							mode: "selected",
						},
						nodeHovered: {
							fillColor: "rgba(205,22,61,0.1)",
							shadowBlur: 0,
							shadowColor: "255,255,255,0.01",
						},
						nodeSelected: {
							fillColor: "rgba(205,22,61,0.1)",
							shadowBlur: 0,
							shadowColor: "255,255,255,0.01",
						},
						selection: {
							fillColor: "transparent",
						},
					},
				},
			],
			interaction: {
				selection: {
					enabled: true,
				},
			},
			events: {
				onSelectionChange: function (ev, arg) {
					//console.log("Selection changed in GEO", arg)
					let values = []
					let node_map = {}
					for (let i = 0; i < arg.selection.length; i++) {
						let node = arg.selection[i]

						if (node.data.aggregatedNodes) {
							for (let j = 0; j < node.data.aggregatedNodes.length; j++) {
								let instance = node.data.aggregatedNodes[j]
								node_map[instance.code] = true
							}
						} else {
							if (node.data.type != "polygon") {
								node_map[node.data.code] = true
							} else {
								node_map[Alpha3to2(node.data.id)] = true
							}
						}
					}
					for (let j in node_map) {
						values.push(j)
					}
					FilterByCategory(filter, "code", values, arg.chart)
				},
			},
			container: container,
		}
		chart = new window.ZoomCharts.GeoChart(settings)
		chart._id = "geo"+section
		geo_charts[section] = chart
		container.style.width = "100%"

		geo_charts[section].updateSize()
	
		UpdateOnChange(filter, chart, "nodes")

	}
}

function getGeoData(filter, request, success, fail, chart){
	var data = GetData("LV_Eksports")
	var countries = GetData("d_Country")

	var country_map = {}
	for (var i = 1; i < countries.length; i++){
		country_map[countries[i][0]] = countries[i]
	}
	var nodes_data = {nodes:[]}
	for (i = 1; i < data.length; i++){

		
		var row = data[i]
		if (!MatchRow(row, filter, ["geo"], {"category":0})) {
			continue
		}

		var country = country_map[data[i][4]];
		var node = {
			id: "c"+i,
			code: data[i][4],
			name: country[3],
			value: data[i][2],
			category: data[i][0],
			coordinates: [country[2],country[1]]
		}
		nodes_data.nodes.push(node)
	}
	success(nodes_data)
	//FilterByCategory(filter, "code", [], chart)
}

class GeoChart extends React.Component {
	constructor(props) {
		super(props)
		this.section = props.section
		this.filter = props.filter
	}
	componentDidMount() {
		buildGeoChart(this.section, this.filter, this)
	}
	componentDidUpdate() {
		buildGeoChart(this.section, this.filter, this)
	}
	componentWillUnmount() {
		RemoveOnChange(this.filter, geo_charts[this.section])
		geo_charts[this.section].remove()
		geo_charts[this.section] = null
	}
	render() {
		const id = "chart-" + this.section
		return React.createElement("div", { id: id, className: this.props.className })
	}
}

export default GeoChart


