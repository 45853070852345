import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))

window.ZoomChartsLicense = "ZCP-ki625u109: ZoomCharts SDK license for Latvijas Eksporta Barometrs";
window.ZoomChartsLicenseKey = "812cad8faf3b214c3d60091c653fb8b259712d5a84b8b7c2b0"+
"295d71c7c09bca50ecee9cc4b7d869e6d4bebc74831b26c67f32739e7687ac3d9c77df58afc2a"+
"dcaaf4f7ed0e8eb688b1efffc029ec4ef91755d323c19763fe01da22f55e8dc3d1e2d30875121"+
"faea201f9579347bca98066ccdd032028bc0ec83ba8c9c775882477e232b59165b148bea4d4ce"+
"d7926f1cf2bc8d995b1202bd95a8a54ddfeb1139f980445316cd2c2d78853a1d4b93169ce3269"+
"cefeb51d5927d14e11aa11440ee88643840de46e8c08b518e77922ed7bd39aa3f46315c1e2e04"+
"c3bc3608d4f49ff78e6b9f1d9a6b5b9def50afcdaffdad6ab851787fc47a06cd45065ff9c6f2a";

root.render(
		<App />
)
/*root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
*/