import React from "react"
import Home from "assets/icons/Home"
import { Nav, Offcanvas } from "react-bootstrap"
import styles from "./styles.module.scss"
import { Link, NavLink } from "react-router-dom"
import LangSwitcher from "components/base/LangSwitcher"

const SideMenu = ({ showMenu, handleMenuClose, t }) => {
	return (
		<Offcanvas show={showMenu} onHide={handleMenuClose} className={styles.offcanvas_menu}>
			<Offcanvas.Header closeButton>
				<div className={styles.lang_menu}><LangSwitcher mode="dark" /></div>

				<Offcanvas.Title></Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Nav className="flex-column">
					<Link to="/" className={styles.title} onClick={handleMenuClose}>
						<Home /> {t("project_title")}
					</Link>

					<Link to="/contribution/contribution-1" onClick={handleMenuClose}>{t("contribution_pagetitle")}</Link>
					<div className={styles.nested}>
						<Link to="/contribution/contribution-1" onClick={handleMenuClose}>{t("contribution_tab_1")}</Link>
						<Link to="/contribution/contribution-2" onClick={handleMenuClose}>{t("contribution_tab_2")}</Link>
						<Link to="/contribution/contribution-3" onClick={handleMenuClose}>{t("contribution_tab_3")}</Link>
						<Link to="/contribution/nace" onClick={handleMenuClose}>{t("contribution_tab_4")}</Link>
					</div>

					<Link to="/financial-health" onClick={handleMenuClose}>{t("financial_health_pagetitle")}</Link>
					<div className={styles.nested}>
						<Link to="/financial-health/financialhealth-1" onClick={handleMenuClose}>{t("fh_tab_1")}</Link>
						<Link to="/financial-health/financialhealth-2" onClick={handleMenuClose}>{t("fh_tab_2")}</Link>
					</div>

					<Link to="/productivity" onClick={handleMenuClose}>{t("productivity_pagetitle")}</Link>
					<div className={styles.nested}>
						<Link to="/productivity/real-exports-1" onClick={handleMenuClose}>{t("prod_tab_1")}</Link>
						<Link to="/productivity/research-development-1" onClick={handleMenuClose}>{t("prod_tab_2")}</Link>
					</div>

					<Link to="/company-interviews" onClick={handleMenuClose}>{t("company_interviews_pagetitle")}</Link>
					<div className={styles.nested}>
						<Link to="/company-interviews/exporter-stories" onClick={handleMenuClose}>{t("comp_tab_1")}</Link>
						<Link to="/company-interviews/export-markets" onClick={handleMenuClose}>{t("comp_tab_2")}</Link>
					</div>
				</Nav>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default SideMenu
