import React from "react"
import styles from "./styles.module.scss"
import FullScreenIcon from "assets/icons/FullScreenIcon"

const FullScreenTrigger = ({ text, style }) => {
	return (
		<div className={`d-block d-sm-none ${styles.full_screen_wrapper}`} style={{ ...style }}>
			{text && <p className="text-center m-0 small">{text}</p>}
			<span className={styles.full_screen_trigger}>
				{/*<FullScreenIcon />*/}
			</span>
		</div>
	)
}

export default FullScreenTrigger
