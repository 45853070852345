import React from "react"
import { Button, Col, Container, Image, Row } from "react-bootstrap"
import placeholder_home from "assets/images/placeholder_home.png"
import BarGraph from "assets/icons/BarGraph"
import styles from "./styles.module.scss"
import { Link } from "react-router-dom"
import { Header, Footer, DashedBox } from "components"
import FullScreenTrigger from "components/utility/FullScreenTrigger"
import TimeChart from "charts/TimeChart"
import {UpdateTimeChart} from "charts/TimeChart"


class Home extends React.Component {
	constructor(props){
		super(props)
		this.t = props.t
	}
	componentDidUpdate(){
		//console.log("Component did update")
		UpdateTimeChart("index", this.t)
	}
	componentDidMount(){
		//console.log("Component did update")
		UpdateTimeChart("index", this.t)
	}
	render(t){
		t = this.t
		return (
			<div className={styles.home_wrapper}>
				<Container fluid="md" className="px-0">
					<div className="d-sm-block">
						<Header home t={t} />
					</div>
				</Container>

				<Row className={styles.home_inner_chart}>
					<Col>
						<TimeChart section="index" t={t} />
					</Col>
				</Row>

				<Row className={styles.home_inner}>
					<Col className="text-center">
						<p className="d-block d-sm-none text-start lh-sm fst-italic">
							* {t("NIP - nacionālās industriālās politikas pamatnostādnes")}
						</p>
						<Link to="/dashboard" className="d-block">
							<Button variant="primary" size="lg" className={styles.btn_with_icon}>
								<span className="me-3">
									<BarGraph width={20} />
								</span>
								{t("btn_home")}
							</Button>
						</Link>
					</Col>
					<Row xs={1} md={2} className="g-3 align-items-stretch">
						<Col>
							<DashedBox className="bg-white px-4 py-3 h-100 fw-light">
								<p className="mb-sm-0">{t("home_block_1")}</p>
								<p className="d-md-none">{t("home_block_2")}</p>
							</DashedBox>
						</Col>
						<Col className="d-none d-md-block">
							<DashedBox className="bg-white px-4 py-3 h-100 fw-light">{t("home_block_2")}</DashedBox>
						</Col>
					</Row>
					<div className={styles.home_inner_affix}>
-					</div>
				</Row>

				<Row>
					<Col>
						<Footer primary t={t} />
					</Col>
				</Row>
			</div>
		)
	}
}

export default Home
