import React from "react"

export default function Home(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={17} height="13.6" viewBox="0 0 17 13.6" {...props}>
			<g id="Home_Icon" transform="translate(-16 384)">
				<path
					id="Path_608"
					data-name="Path 608"
					d="M16,70.8,24.5,64l3.4,2.55V65.417h1.7v2.55L33,70.8H31.3v6.8H27.05V71.933h-3.4V77.6H17.7V70.8Z"
					transform="translate(0 -448)"
					fill="#48484a"
				/>
			</g>
		</svg>
	)
}
